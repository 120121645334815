/** third-party imports */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../index';
import { ProjectsFacade } from './projects.facade';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
    ],
    declarations: [],
    providers: [ProjectsFacade],
})
export class ProjectsStoreModule {}
