/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { NotificationsParser } from '../parsers/notifications.parser';

/** Interfaces */
import MessageRestApi from '../rest-api-interfaces/message.rest.interface';
import MessageDataRestApi from '../rest-api-interfaces/message-data.rest.interface';
import Message from '../interfaces/message.interface';
import MessageData from '../interfaces/message-data.interface';

@Injectable()
export class NotificationsService {
    constructor(private http: HttpClient, private notificationsParser: NotificationsParser) {}

    sendMessage(name: string, data: MessageData): Observable<Message> {
        const serializedMessageData: MessageDataRestApi =
            this.notificationsParser.serializeMessageData(data);

        // trim undefined properties
        Object.keys(serializedMessageData).forEach(
            (key: string) =>
                serializedMessageData[key] === undefined && delete serializedMessageData[key],
        );

        return this.http
            .post(`${environment.notificationsServerUrl}/messages/`, {
                notification_name: name,
                data: serializedMessageData,
            })
            .pipe(map((message: MessageRestApi) => this.notificationsParser.parseMessage(message)));
    }
}
