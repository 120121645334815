/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ConcentrationsParser } from '../parsers/concentrations.parser';

/** Interfaces */
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import Concentration from '../interfaces/concentration.interface';
import ConcentrationRestApi from '../rest-api-interfaces/concentration.rest.interface';
import ConcentrationDetailsRestApi from '../rest-api-interfaces/concentration-details.rest.interface';

@Injectable()
export class ConcentrationsService {
    constructor(private http: HttpClient, private concentrationsParser: ConcentrationsParser) {}

    /**
     * Gets concentrations, parses them into the desired format and
     * returns an Observable of Concentration[].
     */
    getConcentrations(sourceId: string, targetId: string): Observable<Concentration[]> {
        return this.http
            .post(`${environment.metadataServerUrl}/concentrations`, {
                sourceUid: sourceId,
                targetUid: targetId,
            })
            .pipe(
                map((concentration: ResultsRestApi<ConcentrationRestApi>) =>
                    this.concentrationsParser.parseConcentrations(concentration.results),
                ),
            );
    }

    /**
     * Gets concentrations details for a specific entity, parses them into the desired format
     * and returns an Observable of Concentration[].
     * `sourceName` is used as a fallback when IngredientType has no value.
     */
    getConcentrationDetails(
        sourceId: string,
        targetId: string,
        sourceName: string,
    ): Observable<Concentration[]> {
        return this.http
            .post(`${environment.metadataServerUrl}/edges/concentrations`, {
                sourceCui: sourceId,
                targetCui: targetId,
            })
            .pipe(
                map((concentration: ResultsRestApi<ConcentrationDetailsRestApi>) =>
                    this.concentrationsParser.parseConcentrationDetails(
                        concentration.results,
                        sourceName,
                    ),
                ),
            );
    }
}
