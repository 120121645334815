/** third-party imports */
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/** custom imports */
import { TokenService } from './token.service';
import TokenTypes from '../enums/token-types.enum';

@Injectable()
export class LogoutService {
    constructor(private tokenService: TokenService) {}

    /**
     * Calls the tokenService.getToken().
     * If the token exists, it gets removed from the localStorage.
     * It returns an Observable of string.
     */
    logout(tokenKeys: TokenTypes[]): Observable<string> {
        let hasError: boolean = false;
        tokenKeys.forEach((tokenKey: TokenTypes) => {
            const token: string = this.tokenService.getToken(tokenKey);

            if (!token) {
                hasError = true;
            } else {
                this.tokenService.removeToken(tokenKey);
            }
        });

        return hasError
            ? of('An error occured while trying to logout the user.')
            : of('User logged out successfully!');
    }
}
