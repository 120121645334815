/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as tagsActions from './tags.actions';
import { TagsState } from './tags-state.interface';
import Tag from './interfaces/tag.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
export const adapter: EntityAdapter<Tag> = createEntityAdapter<Tag>({
    selectId: (tag: Tag) => tag.name,
});

export const initialState: TagsState = adapter.getInitialState({
    errors: [],
    loading: false,
    loaded: false,
});

const tagsReducer: ActionReducer<TagsState, Action> = createReducer(
    initialState,
    on(tagsActions.getTagsRequest, (state: TagsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(tagsActions.getTagsSuccess, (state: TagsState, { tags }: { tags: Tag[] }) =>
        adapter.setAll(tags, {
            ...state,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        tagsActions.getTagsFailure,
        (state: TagsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(tagsActions.clearNextError, (state: TagsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: TagsState | undefined, action: Action): TagsState =>
    tagsReducer(state, action);

// selectors
export const getEntities: (state: TagsState) => Tag[] = adapter.getSelectors().selectAll;
export const getErrors: (state: TagsState) => ErrorResponse[] = (state: TagsState) => state.errors;
