import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import AlertEvent from '@leap-store/core/src/lib/ui/alerts/interfaces/alert-event.interface';

@Component({
    selector: 'lib-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
    @Input() alert: AlertEvent;
    @Output() alertToBeClosed: EventEmitter<AlertEvent> = new EventEmitter<AlertEvent>();

    timeoutReference: ReturnType<typeof setTimeout>;

    constructor() {}

    ngOnInit(): void {
        this.scheduleTimeout();
    }

    ngOnDestroy(): void {
        this.clearScheduledTimeout();
    }

    onClose(alert: AlertEvent): void {
        this.clearScheduledTimeout();
        this.alertToBeClosed.emit(alert);
    }

    /**
     * Schedules a timeout for the alert and stores its reference to 'timeoutReference'
     * if the timeout exists
     */
    scheduleTimeout(): void {
        if (!this.alert.timeout || this.alert.isPersistent) {
            return;
        }

        this.timeoutReference = setTimeout(() => this.onClose(this.alert), this.alert.timeout);
    }

    /**
     * Clears the stored timeout reference
     */
    clearScheduledTimeout(): void {
        clearTimeout(this.timeoutReference);
    }
}
