import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import Tooltip from './tooltip.interface';

@Component({
    selector: 'lib-tooltip',
    templateUrl: 'tooltip.component.html',
    styleUrls: ['tooltip.component.scss'],
})
/**
 * Wrapper on ngb tooltip library. Renders a ngb tooltip with a custom FontAwesome icon trigger or
 * a span trigger which wraps arbitrary content interpolated via ng-content.
 */
export class TooltipComponent<T extends Record<string, unknown>> implements OnChanges {
    @Input() tooltip: Tooltip;
    @Input() tooltipContentContext: T;
    @Input() isInlineTrigger: boolean;

    arbitraryTriggerContentTemplate: TemplateRef<T>;
    isTooltipContentTemplate: boolean;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tooltip || changes.tooltipContentContext) {
            this.updateIsTooltipContentTemplate();
            this.updateArbitraryTriggerContent();
        }
    }

    updateIsTooltipContentTemplate(): void {
        this.isTooltipContentTemplate = this.tooltip?.content instanceof TemplateRef;
    }

    updateArbitraryTriggerContent(): void {
        this.arbitraryTriggerContentTemplate =
            this.tooltip?.content instanceof TemplateRef ? this.tooltip?.content : null;
    }

    openTooltip(tooltipTrigger: NgbTooltip, event: MouseEvent): void {
        if (this.tooltip?.triggers !== 'manual') {
            return;
        }
        event?.stopPropagation();
        tooltipTrigger?.open();
    }
}
