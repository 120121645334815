import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromProjects from './projects.reducer';

export const getProjectsState = createSelector(getDataState, (state: DataState) => state.projects);
export const getProjects = createSelector(getProjectsState, fromProjects.getEntities);
export const getProject = createSelector(getProjectsState, fromProjects.getProject);
export const getProjectsWithBookmarks = createSelector(
    getProjectsState,
    fromProjects.getProjectsWithBookmarks,
);
export const getProjectsWithoutBookmarks = createSelector(
    getProjectsState,
    fromProjects.getProjectsWithoutBookmarks,
);
export const getProjectsLoading = createSelector(getProjectsState, fromProjects.getProjectsLoading);
export const getProjectsLoaded = createSelector(getProjectsState, fromProjects.getProjectsLoaded);
export const getProjectLoading = createSelector(getProjectsState, fromProjects.getProjectLoading);
export const getProjectLoaded = createSelector(getProjectsState, fromProjects.getProjectLoaded);
export const getUpdatingProject = createSelector(getProjectsState, fromProjects.getUpdatingProject);
export const getDeletingProject = createSelector(getProjectsState, fromProjects.getDeletingProject);
export const getNameUnavailableError = createSelector(
    getProjectsState,
    fromProjects.getNameUnavailableError,
);
export const getShouldFetchProjects = createSelector(
    getProjectsState,
    fromProjects.getShouldFetchProjects,
);
export const getErrors = createSelector(getProjectsState, fromProjects.getErrors);
export const getSuccess = createSelector(getProjectsState, fromProjects.getSuccess);
export const getPageIndex = createSelector(getProjectsState, fromProjects.getPageIndex);
export const getPageSize = createSelector(getProjectsState, fromProjects.getPageSize);
export const getTotal = createSelector(getProjectsState, fromProjects.getTotal);
export const getBlob = createSelector(getProjectsState, fromProjects.getBlob);
