import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import { PathsState } from './paths-state.interface';
import * as fromPaths from './paths.reducer';
import ExplorationPath from './interfaces/exploration-path.interface';
import EnhancedPaths from './interfaces/enhanced-paths';

export const getPathsState = createSelector(getDataState, (state: DataState) => state.paths);
export const getPaths = createSelector(getPathsState, fromPaths.getEntities);
export const getParentInsightId = createSelector(getPathsState, fromPaths.getParentInsightId);
export const getParentMetapathIndex = createSelector(
    getPathsState,
    fromPaths.getParentMetapathIndex,
);
export const getErrors = createSelector(getPathsState, fromPaths.getErrors);
export const getLoading = createSelector(getPathsState, fromPaths.getLoading);
export const getLoaded = createSelector(getPathsState, fromPaths.getLoaded);
export const getPageIndex = createSelector(getPathsState, fromPaths.getPageIndex);
export const getPageSize = createSelector(getPathsState, fromPaths.getPageSize);
export const getTotal = createSelector(getPathsState, fromPaths.getTotal);
export const getSortingOrder = createSelector(getPathsState, fromPaths.getSortingOrder);
export const getBlob = createSelector(getPathsState, fromPaths.getBlob);
export const getFilename = createSelector(getPathsState, fromPaths.getFilename);
export const getEnhancedPaths: (state: PathsState) => EnhancedPaths = (state: PathsState) => {
    const parentInsightId: string = getParentInsightId(state);
    const parentMetapathIndex: number = getParentMetapathIndex(state);
    const paths: ExplorationPath[] = getPaths(state);
    const total: number = getTotal(state);
    const loaded: boolean = getLoaded(state);

    return {
        parentInsightId,
        parentMetapathIndex,
        paths: loaded ? paths : null,
        total,
    };
};
