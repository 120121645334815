import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import { BookmarksState } from './bookmarks-state.interface';
import * as fromBookmarks from './bookmarks.reducer';
import Bookmark from './interfaces/bookmark.interface';
import BookmarksPerType from './interfaces/bookmarks-per-type.interface';

export const getBookmarksState = createSelector(
    getDataState,
    (state: DataState) => state.bookmarks,
);
export const getBookmarks = createSelector(getBookmarksState, fromBookmarks.getBookmarks);
export const getLoading = createSelector(getBookmarksState, fromBookmarks.getLoading);
export const getLoaded = createSelector(getBookmarksState, fromBookmarks.getLoaded);
export const getErrors = createSelector(getBookmarksState, fromBookmarks.getErrors);
export const getSuccess = createSelector(getBookmarksState, fromBookmarks.getSuccess);
export const getPageIndex = createSelector(getBookmarksState, fromBookmarks.getPageIndex);
export const getPageSize = createSelector(getBookmarksState, fromBookmarks.getPageSize);
export const getCountPerBookmarkType = createSelector(
    getBookmarksState,
    fromBookmarks.getCountPerBookmarkType,
);
export const getFilterCounts = createSelector(getBookmarksState, fromBookmarks.getFilterCounts);
export const getDateRange = createSelector(getBookmarksState, fromBookmarks.getDateRange);
export const getCowMilkConcentrationRange = createSelector(
    getBookmarksState,
    fromBookmarks.getCowMilkConcentrationRange,
);
export const getMoleculeWeightRange = createSelector(
    getBookmarksState,
    fromBookmarks.getMoleculeWeightRange,
);
export const getArticleUploadStatus = createSelector(
    getBookmarksState,
    fromBookmarks.getArticleUploadStatus,
);
export const getArticleUploading = createSelector(
    getBookmarksState,
    fromBookmarks.getArticleUploading,
);
export const getArticleUploaded = createSelector(
    getBookmarksState,
    fromBookmarks.getArticleUploaded,
);
export const getBlob = createSelector(getBookmarksState, fromBookmarks.getBlob);
export const getDeletePendingBookmarks = createSelector(
    getBookmarksState,
    fromBookmarks.getDeletePendingBookmarks,
);
export const getPreferences = createSelector(getBookmarksState, fromBookmarks.getPreferences);
export const getEnhancedBookmarks: (state: BookmarksState) => BookmarksPerType = (
    state: BookmarksState,
) => {
    const bookmarksPerType: BookmarksPerType = getBookmarks(state);
    const deletePendingItems: string[] = getDeletePendingBookmarks(state);

    return {
        ...bookmarksPerType,
        bookmarks: bookmarksPerType?.bookmarks.map((bookmark: Bookmark) => ({
            ...bookmark,
            deleting: deletePendingItems.includes(bookmark.id),
        })),
    };
};
