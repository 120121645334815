/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ProjectsService } from './services/projects.service';
import * as projectsActions from './projects.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Project from './interfaces/project.interface';
import BookmarkConfiguration from '../bookmarks/interfaces/configuration.interface';
import ProjectProperties from './interfaces/project-properties.interface';
import Alert from '../../ui/alerts/interfaces/alert.interface';

@Injectable()
export class ProjectsEffects {
    constructor(private actions$: Actions, private projectsService: ProjectsService) {}

    getProjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsRequest),
            switchMap(
                ({
                    pageIndex,
                    pageSize,
                    sortDirection,
                    sortColumn,
                    typeFilter,
                    statusFilter,
                    searchFilter,
                }: {
                    pageIndex: number;
                    pageSize: number;
                    sortDirection: SortingOrder;
                    sortColumn: string;
                    typeFilter: string;
                    statusFilter: string[];
                    searchFilter: string;
                }) =>
                    this.projectsService
                        .getProjects(
                            pageIndex,
                            pageSize,
                            sortDirection,
                            sortColumn,
                            typeFilter,
                            statusFilter,
                            searchFilter,
                        )
                        .pipe(
                            map(
                                ({
                                    paginatedProjects,
                                }: {
                                    paginatedProjects: PaginatedResults<Project>;
                                }) =>
                                    projectsActions.getProjectsSuccess({
                                        paginatedProjects,
                                        sortDirection,
                                        sortColumn,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(projectsActions.getProjectsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectRequest),
            switchMap(({ id }: { id: string }) =>
                this.projectsService.getProject(id).pipe(
                    map((project: Project) => projectsActions.getProjectSuccess({ project })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.getProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getProjectsWithBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsWithBookmarksRequest),
            switchMap(({ configurations }: { configurations: BookmarkConfiguration[] }) =>
                this.projectsService.getProjectsWithBookmarks(configurations).pipe(
                    map(({ paginatedProjects }: { paginatedProjects: Project[] }) =>
                        projectsActions.getProjectsWithBookmarksSuccess({
                            projects: paginatedProjects,
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.getProjectsWithBookmarksFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getProjectsWithoutBookmarks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.getProjectsWithoutBookmarksRequest),
            switchMap(
                ({
                    configurations,
                    search,
                    pageIndex,
                    pageSize,
                }: {
                    configurations: BookmarkConfiguration[];
                    search: string;
                    pageIndex: number;
                    pageSize: number;
                }) =>
                    this.projectsService
                        .getProjectsWithoutBookmarks(configurations, search, pageIndex, pageSize)
                        .pipe(
                            map(
                                ({
                                    paginatedProjects,
                                }: {
                                    paginatedProjects: PaginatedResults<Project>;
                                }) =>
                                    projectsActions.getProjectsWithoutBookmarksSuccess({
                                        paginatedProjects,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    projectsActions.getProjectsWithoutBookmarksFailure({
                                        errorResponse,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    createProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.createProjectRequest),
            switchMap(({ name, suppressAlert }: { name?: string; suppressAlert?: boolean }) =>
                this.projectsService.createProject(name).pipe(
                    map((project: Project) =>
                        projectsActions.createProjectSuccess({ project, suppressAlert }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.createProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    cloneProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.cloneProjectRequest),
            switchMap(({ id, originalProjectName }: { id: string; originalProjectName: string }) =>
                this.projectsService.cloneProject(id, originalProjectName).pipe(
                    map((cloneData: { project: Project; originalProjectName: string }) =>
                        projectsActions.cloneProjectSuccess(cloneData),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.cloneProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    updateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.updateProjectRequest),
            switchMap(
                ({
                    id,
                    project,
                    successMessage,
                }: {
                    id: string;
                    project: ProjectProperties;
                    successMessage: Alert;
                }) =>
                    this.projectsService.updateProject(id, project).pipe(
                        map((updatedProject: Project) =>
                            projectsActions.updateProjectSuccess({
                                project: updatedProject,
                                successMessage,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(projectsActions.updateProjectFailure({ id, errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    toggleFavoriteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.toggleFavoriteProjectRequest),
            switchMap(({ id, favorite }: { id: string; favorite: boolean }) =>
                this.projectsService.toggleFavoriteProject(id, favorite).pipe(
                    map((project: Project) =>
                        projectsActions.toggleFavoriteProjectSuccess({ project }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.toggleFavoriteProjectFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.deleteProjectRequest),
            switchMap(({ id, name }: { id: string; name: string }) =>
                this.projectsService.deleteProject(id).pipe(
                    map(() => projectsActions.deleteProjectSuccess({ id, name })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.deleteProjectFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(projectsActions.downloadProjectRequest),
            switchMap(({ id, preferences }: { id: string; preferences: UserPreferences }) =>
                this.projectsService.downloadProject(id, preferences).pipe(
                    map((blob: Blob) => projectsActions.downloadProjectSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(projectsActions.downloadProjectFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
