import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { ConversationalSearchEffects } from './conversational-search.effects';
import { ConversationalSearchFacade } from './conversational-search.facade';
import { ConversationalSearchParser } from './parsers/conversational-search.parser';
import { ConversationalSearchService } from './services/conversational-search.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([ConversationalSearchEffects]),
    ],
    declarations: [],
    providers: [
        ConversationalSearchParser,
        ConversationalSearchService,
        ConversationalSearchFacade,
    ],
})
export class ConversationalSearchStoreModule {}
