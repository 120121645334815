import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import { ExplainabilityState } from './explainability-state.interface';
import * as fromExplainability from './explainability.reducer';
import ExplorationPath from '../paths/interfaces/exploration-path.interface';
import EnhancedPaths from './interfaces/enhanced-paths';

export const getExplainabilityState = createSelector(
    getDataState,
    (state: DataState) => state.explainability,
);
export const getPaths = createSelector(getExplainabilityState, fromExplainability.getEntities);
export const getParentInsightId = createSelector(
    getExplainabilityState,
    fromExplainability.getParentInsightId,
);
export const getErrors = createSelector(getExplainabilityState, fromExplainability.getErrors);
export const getLoading = createSelector(getExplainabilityState, fromExplainability.getLoading);
export const getLoaded = createSelector(getExplainabilityState, fromExplainability.getLoaded);
export const getSortingOrder = createSelector(
    getExplainabilityState,
    fromExplainability.getSortingOrder,
);
export const getEnhancedPaths: (state: ExplainabilityState) => EnhancedPaths = (
    state: ExplainabilityState,
) => {
    const parentInsightId: string = getParentInsightId(state);
    const paths: ExplorationPath[] = getPaths(state);
    const loading: boolean = getLoading(state);
    const loaded: boolean = getLoaded(state);

    return {
        parentInsightId,
        paths,
        loading,
        loaded,
    };
};
