import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PatentsActionTypes } from './patents-action-types.enum';
import PaginatedPatents from './interfaces/paginated-patents.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

export const getPatentsRequest = createAction(
    PatentsActionTypes.GET_PATENTS_REQUEST,
    props<{
        id: string;
        sortingOptions: SortingOptions;
        startYear: number;
        endYear: number;
        pageSize: number;
        pageIndex: number;
    }>(),
);

export const getPatentsSuccess = createAction(
    PatentsActionTypes.GET_PATENTS_SUCCESS,
    props<{
        paginatedPatents: PaginatedPatents;
    }>(),
);

export const getPatentsFailure = createAction(
    PatentsActionTypes.GET_PATENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadPatentsRequest = createAction(
    PatentsActionTypes.DOWNLOAD_PATENTS_REQUEST,
    props<{
        id: string;
        sortingOptions: SortingOptions;
        startYear: number;
        endYear: number;
    }>(),
);

export const downloadPatentsSuccess = createAction(
    PatentsActionTypes.DOWNLOAD_PATENTS_SUCCESS,
    props<{
        blob: Blob;
    }>(),
);

export const downloadPatentsFailure = createAction(
    PatentsActionTypes.DOWNLOAD_PATENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearPatents = createAction(PatentsActionTypes.CLEAR_PATENTS);

export const clearNextError = createAction(PatentsActionTypes.CLEAR_NEXT_ERROR);
