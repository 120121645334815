import { Pipe, PipeTransform } from '@angular/core';
import { truncate } from '@leap-common/utilities/helpers';

@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
    constructor() {}

    transform(text: string, limit?: number, ellipsis?: string): string {
        return truncate(text, limit, ellipsis);
    }
}
