/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as conversationalSearchActions from './conversational-search.actions';
import { ConversationalSearchState } from './conversational-search-state.interface';
import { ELLIPSIS } from '@leap-common/constants/common';
import { ERROR_TEXT, INITIAL_SEARCH_QUERY } from './constants/conversational-search';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import SearchQuery from './interfaces/search-query.interface';

export const initialState: ConversationalSearchState = {
    queries: {},
    tabIds: [],
    errors: [],
    loading: {},
    loaded: {},
};

const conversationalSearchReducer: ActionReducer<ConversationalSearchState, Action> = createReducer(
    initialState,
    on(
        conversationalSearchActions.performQueryRequest,
        (state: ConversationalSearchState, { query, tabId }: { query: string; tabId: string }) => {
            const queries: SearchQuery[] = state.queries[tabId] || [];
            const lastQueryIndex: number = queries.length - 1;
            const lastQuery: SearchQuery = queries[lastQueryIndex];
            const shouldReplaceLastQuery: boolean =
                lastQuery?.initialText && !lastQuery.question && !lastQuery.answer;
            const currentQuery: SearchQuery = shouldReplaceLastQuery
                ? // update session's first query
                  {
                      ...lastQuery,
                      question: query,
                      answer: ELLIPSIS,
                  }
                : // add new query
                  {
                      ...INITIAL_SEARCH_QUERY,
                      initialText: null,
                      question: query,
                      answer: ELLIPSIS,
                  };

            return {
                ...state,
                queries: {
                    ...state.queries,
                    [tabId]: shouldReplaceLastQuery
                        ? [...queries.slice(0, lastQueryIndex), currentQuery]
                        : [...queries, currentQuery],
                },
                loading: {
                    ...state.loading,
                    [tabId]: true,
                },
                loaded: {
                    ...state.loaded,
                    [tabId]: false,
                },
            };
        },
    ),
    on(
        conversationalSearchActions.performQuerySuccess,
        (
            state: ConversationalSearchState,
            { query, tabId }: { query: SearchQuery; tabId: string },
        ) => {
            // prevent update if session has been deleted
            if (!state.queries[tabId]) {
                return { ...state };
            }

            const lastQueryIndex: number = state.queries[tabId].length - 1;

            return {
                ...state,
                queries: {
                    ...state.queries,
                    [tabId]: [
                        ...state.queries[tabId].slice(0, lastQueryIndex),
                        {
                            ...query,
                            initialText:
                                state.queries[tabId]?.length === 1
                                    ? state.queries[tabId][0].initialText
                                    : null,
                        },
                    ],
                },
                loading: {
                    ...state.loading,
                    [tabId]: false,
                },
                loaded: {
                    ...state.loaded,
                    [tabId]: true,
                },
            };
        },
    ),
    on(
        conversationalSearchActions.performQueryFailure,
        (
            state: ConversationalSearchState,
            { errorResponse, tabId }: { errorResponse: HttpErrorResponse; tabId: string },
        ) => {
            const lastQueryIndex: number = state.queries[tabId].length - 1;
            const lastQuery: SearchQuery = state.queries[tabId]?.[lastQueryIndex];
            const lastQueryWithEmptyAnswer: SearchQuery = {
                ...lastQuery,
                answer: ERROR_TEXT,
            };

            return {
                ...state,
                queries: {
                    ...state.queries,
                    [tabId]: [
                        ...state.queries[tabId].slice(0, lastQueryIndex),
                        lastQueryWithEmptyAnswer,
                    ],
                },
                errors: [...state.errors, errorResponse.error],
                loading: {
                    ...state.loading,
                    [tabId]: false,
                },
                loaded: {
                    ...state.loaded,
                    [tabId]: false,
                },
            };
        },
    ),
    on(
        conversationalSearchActions.initializeSession,
        (
            state: ConversationalSearchState,
            { tabId, hasInitialText }: { tabId: string; hasInitialText: boolean },
        ) => ({
            ...state,
            queries: {
                ...state.queries,
                [tabId]: hasInitialText ? [INITIAL_SEARCH_QUERY] : ([] as SearchQuery[]),
            },
            tabIds: [tabId, ...state.tabIds],
        }),
    ),
    on(
        conversationalSearchActions.deleteSession,
        (state: ConversationalSearchState, { tabId }: { tabId: string }) => ({
            ...state,
            queries: {
                ...state.queries,
                [tabId]: undefined as SearchQuery[],
            },
            tabIds: state.tabIds.filter((id: string) => id !== tabId),
        }),
    ),
    on(conversationalSearchActions.clearNextError, (state: ConversationalSearchState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: ConversationalSearchState | undefined,
    action: Action,
): ConversationalSearchState => conversationalSearchReducer(state, action);

// selectors
export const getQueries: (state: ConversationalSearchState) => Record<string, SearchQuery[]> = (
    state: ConversationalSearchState,
) => state.queries;
export const getTabIds: (state: ConversationalSearchState) => string[] = (
    state: ConversationalSearchState,
) => state.tabIds;
export const getErrors: (state: ConversationalSearchState) => ErrorResponse[] = (
    state: ConversationalSearchState,
) => state.errors;
export const getLoading: (state: ConversationalSearchState) => Record<string, boolean> = (
    state: ConversationalSearchState,
) => state.loading;
export const getLoaded: (state: ConversationalSearchState) => Record<string, boolean> = (
    state: ConversationalSearchState,
) => state.loaded;
