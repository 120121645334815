import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '..';
import { GroupsEffects } from './groups.effects';
import { GroupsService } from './services/groups.service';
import { GroupsParser } from './parsers/groups.parser';
import { GroupsFacade } from './groups.facade';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([GroupsEffects]),
    ],
    declarations: [],
    providers: [GroupsService, GroupsParser, GroupsFacade],
})
export class GroupsStoreModule {}
