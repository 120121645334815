/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { DairyProfilerActionTypes } from './dairy-profiler-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import PaginatedCompounds from '../ingredient-profiler/interfaces/paginated-compounds.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

export const getCompoundsRequest = createAction(
    DairyProfilerActionTypes.GET_COMPOUNDS_REQUEST,
    props<{
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
        preferences: UserPreferences;
    }>(),
);

export const getCompoundsSuccess = createAction(
    DairyProfilerActionTypes.GET_COMPOUNDS_SUCCESS,
    props<{ paginatedCompounds: PaginatedCompounds }>(),
);

export const getCompoundsFailure = createAction(
    DairyProfilerActionTypes.GET_COMPOUNDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getInsightsRequest = createAction(
    DairyProfilerActionTypes.GET_INSIGHTS_REQUEST,
    props<{
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
        sortingOptions?: SortingOptions;
    }>(),
);

export const getInsightsSuccess = createAction(
    DairyProfilerActionTypes.GET_INSIGHTS_SUCCESS,
    props<{ compoundId: string; paginatedInsights: PaginatedInsights }>(),
);

export const getInsightsFailure = createAction(
    DairyProfilerActionTypes.GET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getDairyProductInsightsRequest = createAction(
    DairyProfilerActionTypes.GET_DAIRY_PRODUCT_INSIGHTS_REQUEST,
    props<{
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }>(),
);

export const getDairyProductInsightsSuccess = createAction(
    DairyProfilerActionTypes.GET_DAIRY_PRODUCT_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getDairyProductInsightsFailure = createAction(
    DairyProfilerActionTypes.GET_DAIRY_PRODUCT_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTargetInsightsRequest = createAction(
    DairyProfilerActionTypes.GET_TARGET_INSIGHTS_REQUEST,
    props<{
        targetId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }>(),
);

export const getTargetInsightsSuccess = createAction(
    DairyProfilerActionTypes.GET_TARGET_INSIGHTS_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const getTargetInsightsFailure = createAction(
    DairyProfilerActionTypes.GET_TARGET_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadInsightsRequest = createAction(
    DairyProfilerActionTypes.DOWNLOAD_INSIGHTS_REQUEST,
    props<{
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }>(),
);

export const downloadInsightsSuccess = createAction(
    DairyProfilerActionTypes.DOWNLOAD_INSIGHTS_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadInsightsFailure = createAction(
    DairyProfilerActionTypes.DOWNLOAD_INSIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getSearchSuggestionsRequest = createAction(
    DairyProfilerActionTypes.GET_SEARCH_SUGGESTIONS_REQUEST,
    props<{ query: string; activeSearch: ProfilerSearch }>(),
);

export const getSearchSuggestionsSuccess = createAction(
    DairyProfilerActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
    props<{ suggestionIds: string[] }>(),
);

export const getSearchSuggestionsFailure = createAction(
    DairyProfilerActionTypes.GET_SEARCH_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSearchSuggestions = createAction(
    DairyProfilerActionTypes.CLEAR_SEARCH_SUGGESTIONS,
);

export const resetDiscovery = createAction(DairyProfilerActionTypes.RESET_DISCOVERY);

export const clearNextError = createAction(DairyProfilerActionTypes.CLEAR_NEXT_ERROR);
