export enum ProjectsActionTypes {
    GET_PROJECTS_REQUEST = '[PROJECTS] Get Projects Request',
    GET_PROJECTS_SUCCESS = '[PROJECTS] Get Projects Success',
    GET_PROJECTS_FAILURE = '[PROJECTS] Get Projects Failure',
    GET_PROJECT_REQUEST = '[PROJECTS] Get Project Request',
    GET_PROJECT_SUCCESS = '[PROJECTS] Get Project Success',
    GET_PROJECT_FAILURE = '[PROJECTS] Get Project Failure',
    GET_PROJECTS_WITH_BOOKMARKS_REQUEST = '[PROJECTS] Get Projects With Bookmarks Request',
    GET_PROJECTS_WITH_BOOKMARKS_SUCCESS = '[PROJECTS] Get Projects With Bookmarks Success',
    GET_PROJECTS_WITH_BOOKMARKS_FAILURE = '[PROJECTS] Get Projects With Bookmarks Failure',
    GET_PROJECTS_WITHOUT_BOOKMARKS_REQUEST = '[PROJECTS] Get Projects Without Bookmarks Request',
    GET_PROJECTS_WITHOUT_BOOKMARKS_SUCCESS = '[PROJECTS] Get Projects Without Bookmarks Success',
    GET_PROJECTS_WITHOUT_BOOKMARKS_FAILURE = '[PROJECTS] Get Projects Without Bookmarks Failure',
    CREATE_PROJECT_REQUEST = '[PROJECTS] Create Project Request',
    CREATE_PROJECT_SUCCESS = '[PROJECTS] Create Project Success',
    CREATE_PROJECT_FAILURE = '[PROJECTS] Create Project Failure',
    CLONE_PROJECT_REQUEST = '[PROJECTS] Clone Project Request',
    CLONE_PROJECT_SUCCESS = '[PROJECTS] Clone Project Success',
    CLONE_PROJECT_FAILURE = '[PROJECTS] Clone Project Failure',
    UPDATE_PROJECT_REQUEST = '[PROJECTS] Update Project Request',
    UPDATE_PROJECT_SUCCESS = '[PROJECTS] Update Project Success',
    UPDATE_PROJECT_FAILURE = '[PROJECTS] Update Project Failure',
    TOGGLE_FAVORITE_PROJECT_REQUEST = '[PROJECTS] Toggle Favorite Project Request',
    TOGGLE_FAVORITE_PROJECT_SUCCESS = '[PROJECTS] Toggle Favorite Project Success',
    TOGGLE_FAVORITE_PROJECT_FAILURE = '[PROJECTS] Toggle Favorite Project Failure',
    DELETE_PROJECT_REQUEST = '[PROJECTS] Delete Project Request',
    DELETE_PROJECT_SUCCESS = '[PROJECTS] Delete Project Success',
    DELETE_PROJECT_FAILURE = '[PROJECTS] Delete Project Failure',
    DOWNLOAD_PROJECT_REQUEST = '[PROJECTS] Download Project Request',
    DOWNLOAD_PROJECT_SUCCESS = '[PROJECTS] Download Project Success',
    DOWNLOAD_PROJECT_FAILURE = '[PROJECTS] Download Project Failure',
    CLEAR_NEXT_SUCCESS = '[PROJECTS] Clear Next Success',
    CLEAR_NEXT_ERROR = '[PROJECTS] Clear Next Error',
    CLEAR_NAME_UNAVAILABLE_ERROR = '[PROJECTS] Clear Name Unavailable Error',
    CLEAR_SHOULD_FETCH_PROJECTS = '[PROJECTS] Clear Should Fetch Projects',
}
