/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { ContentWriterService } from './services/content-writer.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './content-writer.actions';

/** Interfaces - Enums */
import FileExtension from '@leap-common/enums/file-extension.enum';
import ContentWriterFormatId from './enums/format-id.enum';
import GeneratedContent from './interfaces/generated-content.interface';
import Configuration from './interfaces/configuration.interface';

@Injectable()
export class ContentWriterEffects {
    constructor(private actions$: Actions, private contentWriterService: ContentWriterService) {}

    getTopics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTopicsRequest),
            switchMap(({ externalIds }: { externalIds: string[] }) =>
                this.contentWriterService.getTopics(externalIds).pipe(
                    map((topics: string[]) => actions.getTopicsSuccess({ topics })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getTopicsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getAllContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAllContentRequest),
            switchMap(
                ({
                    externalIds,
                    configurationPerFormat,
                }: {
                    externalIds: string[];
                    configurationPerFormat: Record<ContentWriterFormatId, Configuration>;
                }) =>
                    this.contentWriterService
                        .getContent({
                            format: ContentWriterFormatId.longArticle,
                            configuration:
                                configurationPerFormat[ContentWriterFormatId.longArticle],
                            externalIds,
                        })
                        .pipe(
                            switchMap((longContent: GeneratedContent) =>
                                this.contentWriterService
                                    .getContent({
                                        format: ContentWriterFormatId.shortPost,
                                        configuration:
                                            configurationPerFormat[ContentWriterFormatId.shortPost],
                                        longContentText: longContent.text,
                                    })
                                    .pipe(
                                        map((shortContent: GeneratedContent) => ({
                                            longContent,
                                            shortContent,
                                        })),
                                    ),
                            ),
                            map(
                                ({
                                    longContent,
                                    shortContent,
                                }: {
                                    longContent: GeneratedContent;
                                    shortContent: GeneratedContent;
                                }) =>
                                    actions.getAllContentSuccess({
                                        longContent,
                                        shortContent,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getAllContentFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getLongContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getLongContentRequest),
            switchMap(
                ({
                    externalIds,
                    configuration,
                }: {
                    externalIds: string[];
                    configuration: Configuration;
                }) =>
                    this.contentWriterService
                        .getContent({
                            format: ContentWriterFormatId.longArticle,
                            configuration,
                            externalIds,
                        })
                        .pipe(
                            map((longContent: GeneratedContent) =>
                                actions.getLongContentSuccess({ longContent }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getLongContentFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getShortContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getShortContentRequest),
            switchMap(
                ({
                    longContentText,
                    configuration,
                }: {
                    longContentText: string;
                    configuration: Configuration;
                }) =>
                    this.contentWriterService
                        .getContent({
                            format: ContentWriterFormatId.shortPost,
                            configuration,
                            longContentText,
                        })
                        .pipe(
                            map((shortContent: GeneratedContent) =>
                                actions.getShortContentSuccess({ shortContent }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getShortContentFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadContent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadContentRequest),
            switchMap(
                ({
                    extension,
                    longContentText,
                    shortContentText,
                }: {
                    extension: FileExtension;
                    longContentText: string;
                    shortContentText: string;
                }) =>
                    this.contentWriterService
                        .downloadContent({ extension, longContentText, shortContentText })
                        .pipe(
                            map((contentBlob: Blob) =>
                                actions.downloadContentSuccess({ contentBlob }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.downloadContentFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
