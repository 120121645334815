/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { NotebookServersParser } from '../parsers/notebook-servers.parser';

/** Interfaces */
import NotebookServerRestApi from '../rest-api-interfaces/notebook-server.rest.interface';
import NotebookServer from '../interfaces/notebook-server.interface';

@Injectable()
export class NotebookServersService {
    constructor(private http: HttpClient, private notebookServersParser: NotebookServersParser) {}

    getConnections(): Observable<Record<string, NotebookServer>> {
        return this.http
            .get(`${environment.notebookServerUrl}/notebook-server/`)
            .pipe(
                map((servers: Record<string, NotebookServerRestApi>) =>
                    this.notebookServersParser.parseNotebookServers(servers),
                ),
            );
    }

    initiateConnection(refreshToken: string): Observable<void> {
        return this.http
            .post(
                `${environment.notebookServerUrl}/notebook-server/`,
                this.notebookServersParser.serializeInitiateNotebookServer(refreshToken),
            )
            .pipe(map(() => undefined));
    }

    closeConnection(): Observable<void> {
        return this.http
            .delete(`${environment.notebookServerUrl}/notebook-server/`)
            .pipe(map(() => undefined));
    }

    logoutJupyterHub(): Observable<void> {
        return this.http
            .get(`${environment.jupyterHubDomain}/hub/logout`)
            .pipe(map(() => undefined));
    }
}
