import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ConcentrationsActionTypes } from './concentrations-action-types.enum';
import Concentration from './interfaces/concentration.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

export const getConcentrationsRequest = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATIONS_REQUEST,
    props<{
        sourceId: string;
        targetId: string;
        bookmarkIds: BookmarkIds;
    }>(),
);

export const getConcentrationsSuccess = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATIONS_SUCCESS,
    props<{
        concentrations: Concentration[];
    }>(),
);

export const getConcentrationsFailure = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getConcentrationDetailsRequest = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATION_DETAILS_REQUEST,
    props<{
        sourceId: string;
        targetId: string;
        sourceName: string;
    }>(),
);

export const getConcentrationDetailsSuccess = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATION_DETAILS_SUCCESS,
    props<{
        concentrationDetails: Concentration[];
    }>(),
);

export const getConcentrationDetailsFailure = createAction(
    ConcentrationsActionTypes.GET_CONCENTRATION_DETAILS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(ConcentrationsActionTypes.CLEAR_NEXT_ERROR);
