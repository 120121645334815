/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DataState } from '../../data-state.interface';
import { getDatabasesRequest, clearNextError } from './databases.actions';
import { getDatabases, getErrors, getLoading, getLoaded } from './databases.selectors';
import Database from './interfaces/database.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class DatabasesFacade {
    databases$: Observable<Record<string, Database>> = this.store.pipe(select(getDatabases));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    constructor(private store: Store<DataState>) {}

    getDatabases(bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getDatabasesRequest({ bookmarkIds }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
