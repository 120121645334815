/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import {
    updateSelectedIngredient,
    updateBaselineIngredient,
    updateSelectedIngredients,
} from './ingredient-analyzer.actions';
import {
    getSelectedIngredient,
    getBaselineIngredient,
    getSelectedIngredients,
} from './ingredient-analyzer.selectors';
import { IngredientAnalyzerState } from './ingredient-analyzer-state.interface';
import Ingredient from '../../data/ingredient-viewer/interfaces/ingredient.interface';

@Injectable()
export class IngredientAnalyzerFacade {
    selectedIngredient$: Observable<Ingredient> = this.store.pipe(select(getSelectedIngredient));
    baselineIngredient$: Observable<Ingredient> = this.store.pipe(select(getBaselineIngredient));
    selectedIngredients$: Observable<Ingredient[]> = this.store.pipe(
        select(getSelectedIngredients),
    );

    constructor(private store: Store<IngredientAnalyzerState>) {}

    updateSelectedIngredient(selectedIngredient: Ingredient): void {
        this.store.dispatch(updateSelectedIngredient({ selectedIngredient }));
    }

    updateBaselineIngredient(baselineIngredient: Ingredient): void {
        this.store.dispatch(updateBaselineIngredient({ baselineIngredient }));
    }

    updateSelectedIngredients(selectedIngredients: Ingredient[]): void {
        this.store.dispatch(updateSelectedIngredients({ selectedIngredients }));
    }
}
