import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { HealthLabelReportEffects } from './health-label-report.effects';
import { HealthLabelReportFacade } from './health-label-report.facade';
import { HealthLabelReportParser } from './parsers/health-label-report.parser';
import { HealthLabelReportService } from './services/health-label-report.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([HealthLabelReportEffects]),
    ],
    declarations: [],
    providers: [HealthLabelReportParser, HealthLabelReportService, HealthLabelReportFacade],
})
export class HealthLabelReportStoreModule {}
