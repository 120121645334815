import { environment } from '@environments/leap/environment';
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';
import HelperMenuItem from '@leap-libs/helper-menu/src/lib/helper-menu-item.interface';
import HelperMenuConfig from '@leap-libs/helper-menu/src/lib/helper-menu-config.interface';

export const APP_SWITCH_ICON: string = 'fa-regular fa-grid';
export const APP_SWITCH_TITLE: string = 'Switch';

export const LANDING_ICON: string = 'fa-kit fa-home-inactive';
export const LANDING_ICON_ACTIVE: string = 'fa-kit fa-home-active';

export const LAYOUT_LANDING_PATH: string = 'landing';
export const LAYOUT_LANDING_TITLE: string = 'Home';

export const CONVERSATIONAL_SEARCH_PATH: string = 'search';
export const CONVERSATIONAL_SEARCH_TITLE: string = 'Search';
export const MY_SEARCHES_TITLE: string = 'My Searches';

export const DISCOVERY_ICON: string = 'fa-regular fa-globe';
export const DISCOVERY_ICON_ACTIVE: string = 'fa-solid fa-globe';
export const DISCOVERY_PATH: string = 'discovery';
export const MY_DISCOVERY_PATH: string = 'discovery/my-discovery';
export const DISCOVER_TITLE: string = 'Discover';
export const MY_DISCOVERY_TITLE: string = 'My Discovery';
export const OPEN_DISCOVERY_TITLE: string = 'Open Discovery';
export const DISCOVERY_TITLE: string = 'Discovery';

export const ARTICLES_ICON: string = 'fa-kit fa-article-browser-inactive';
export const ARTICLES_ICON_ACTIVE: string = 'fa-kit fa-article-browser-active';
export const ARTICLES_PATH: string = 'articles-search';
export const ARTICLES_TITLE: string = 'Articles Search';

export const PROJECTS_ICON: string = 'fa-regular fa-file-chart-column';
export const PROJECTS_ICON_ACTIVE: string = 'fa-solid fa-file-chart-column';
export const PROJECTS_PATH: string = 'projects';
export const PROJECTS_TITLE: string = 'Projects';

export const COW_MILK_PROFILER_ICON: string = 'fa-regular fa-microscope';
export const COW_MILK_PROFILER_ICON_ACTIVE: string = 'fa-solid fa-microscope';
export const COW_MILK_PROFILER_PATH: string = 'cow-milk-profiler';
export const COW_MILK_PROFILER_TITLE: string = 'Cow Milk Profiler';

export const DAIRY_PROFILER_ICON: string = 'fa-kit fa-dairy-profiler-inactive';
export const DAIRY_PROFILER_ICON_ACTIVE: string = 'fa-kit fa-dairy-profiler-active';
export const DAIRY_PROFILER_PATH: string = 'dairy-profiler';
export const DAIRY_PROFILER_TITLE: string = 'Dairy Profiler';

export const INGREDIENT_PROFILER_PATH: string = 'ingredient-profiler';

export const INGREDIENT_VIEWER_ICON: string = 'fa-regular fa-radar';
export const INGREDIENT_VIEWER_ICON_ACTIVE: string = 'fa-solid fa-radar';
export const INGREDIENT_VIEWER_PATH: string = 'ingredient-viewer';
export const INGREDIENT_VIEWER_TITLE: string = 'Ingredient Viewer';

export const INGREDIENT_COMPARISON_ICON: string = 'fa-regular fa-code-compare';
export const INGREDIENT_COMPARISON_ICON_ACTIVE: string = 'fa-solid fa-code-compare';
export const INGREDIENT_COMPARISON_PATH: string = 'ingredient-comparison';
export const INGREDIENT_COMPARISON_TITLE: string = 'Ingredient Comparison';

export const INGREDIENT_MANAGEMENT_ICON: string = 'fa-regular fa-memo-circle-check';
export const INGREDIENT_MANAGEMENT_ICON_ACTIVE: string = 'fa-solid fa-memo-circle-check';
export const INGREDIENT_MANAGEMENT_PATH: string = 'ingredient-management';
export const INGREDIENT_MANAGEMENT_TITLE: string = 'Ingredient Management';

export const USER_SETTINGS_PATH: string = 'user-settings';
export const USER_MANAGEMENT_PATH: string = 'user-management';
export const EMPTY_ROUTE_PATH: string = 'empty-route';

export const NOTEBOOKS_PATH: string = 'power-notebooks';
export const NOTEBOOKS_HELPER_MENU: HelperMenuConfig = {
    mainSection: {
        items: [
            {
                id: 'menu-notebooks-my-power-notebooks',
                title: 'My power notebooks',
                path: `${NOTEBOOKS_PATH}/my-power-notebooks`,
                icon: 'fa-regular fa-notebook',
            },
            {
                id: 'menu-notebooks-templates',
                title: 'Templates',
                path: `${NOTEBOOKS_PATH}/template-notebooks`,
                icon: 'fa-regular fa-square-list',
            },
            {
                id: 'menu-notebooks-file-uploader',
                title: 'File uploader',
                path: `${NOTEBOOKS_PATH}/file-upload`,
                icon: 'fa-regular fa-cloud-arrow-up',
            },
            {
                id: 'menu-notebooks-file-manager',
                title: 'File manager',
                path: `${NOTEBOOKS_PATH}/file-manage`,
                icon: 'fa-regular fa-folder-open',
            },
            {
                id: 'menu-notebooks-pipeline-executions',
                title: 'Pipeline Executions',
                path: `${NOTEBOOKS_PATH}/pipeline-executions`,
                icon: 'fa-regular fa-diagram-sankey',
            },
        ],
    },
};

export const HELP_CENTER_ICON: string = 'fa-regular fa-circle-question';
export const HELP_CENTER_ICON_ACTIVE: string = 'fa-solid fa-circle-question';
export const HELP_CENTER_PATH: string = 'help-center';
export const HELP_CENTER_TITLE: string = 'Help Center';

export const RELEASE_NOTES_TITLE: string = 'Release Notes';
export const RELEASE_NOTES_PATH: string = `${HELP_CENTER_PATH}/release-notes`;
export const RELEASE_NOTES_ICON: string = 'fa-regular fa-memo';
export const RELEASE_NOTES_DESCRIPTION: string =
    'Click here to view the latest release notes. Stay updated on new features, improvements, and bug fixes.';

export const USER_GUIDE_MENU_ITEM: HelperMenuItem = {
    id: 'help-center-user-guide',
    title: 'User guide',
    path: `${HELP_CENTER_PATH}/user-guide`,
    icon: 'fa-regular fa-circle-info',
    shouldOpenInNewTab: true,
};
export const RELEASE_NOTES_MENU_ITEM: HelperMenuItem = {
    id: 'help-center-release-notes',
    title: RELEASE_NOTES_TITLE,
    path: RELEASE_NOTES_PATH,
    icon: RELEASE_NOTES_ICON,
    shouldOpenInNewTab: true,
};
export const FAQS_MENU_ITEM: HelperMenuItem = {
    id: 'help-center-faqs',
    path: `${HELP_CENTER_PATH}/faqs`,
    title: 'FAQs',
    icon: 'fa-regular fa-circle-question',
    shouldOpenInNewTab: true,
};
export const CONTACT_SUPPORT_MENU_ITEM: HelperMenuItem = {
    id: 'help-center-contact-support',
    path: `${HELP_CENTER_PATH}/contact-support`,
    title: 'Contact support',
    icon: 'fa-regular fa-envelope-open-text',
    shouldOpenInNewTab: true,
};
export const SUGGEST_IDEA_MENU_ITEM: HelperMenuItem = {
    id: 'help-center-suggest-an-idea',
    path: `${HELP_CENTER_PATH}/suggest-an-idea`,
    title: 'Suggest an idea',
    icon: 'fa-regular fa-comments-question',
    shouldOpenInNewTab: true,
};

export const LEGAL_ICON: string = `fa-kit fa-${environment.app}-icon-1`;
export const LEGAL_ICON_ACTIVE: string = `fa-kit fa-${environment.app}-icon-1`;
export const LEGAL_PATH: string = 'about';
export const LEGAL_TITLE: string = `About ${environment.product}`;

export const CREDITS_MENU_ITEM: MenuItem = {
    id: 'help-center-credits',
    path: `${HELP_CENTER_PATH}/credits`,
    title: 'Credits',
    icon: `fa-kit fa-${environment.app}-icon-1`,
    shouldOpenInNewTab: true,
};
export const TERMS_OF_USE_MENU_ITEM: MenuItem = {
    id: 'help-center-terms-of-use',
    path: `${HELP_CENTER_PATH}/terms-of-service`,
    title: 'Terms of service',
    icon: 'fa-regular fa-memo-circle-info',
    shouldOpenInNewTab: true,
};
export const PRIVACY_POLICY_MENU_ITEM: MenuItem = {
    id: 'help-center-privacy-policy',
    path: `${HELP_CENTER_PATH}/privacy-policy`,
    title: 'Privacy policy',
    icon: 'fa-regular fa-shield-check',
    shouldOpenInNewTab: true,
};

export const USER_SETTINGS_MENU_ITEM: MenuItem = {
    id: 'menu-user-settings',
    icon: 'fa-regular fa-user',
    activeIcon: 'fa-solid fa-user',
    path: USER_SETTINGS_PATH,
    title: 'User Profile',
};

export const USER_MANAGEMENT_MENU_ITEM: MenuItem = {
    id: 'menu-user-management',
    icon: 'fa-regular fa-gear-complex',
    activeIcon: 'fa-solid fa-gear-complex',
    path: USER_MANAGEMENT_PATH,
    title: 'User Management',
};

export const LOGOUT_MENU_ITEM: MenuItem = {
    id: 'menu-logout',
    icon: 'fa-regular fa-power-off',
    path: 'logout',
    title: 'Logout',
};

export const NOTEBOOKS_MENU_ITEM: MenuItem = {
    id: 'menu-notebooks',
    icon: 'fa-regular fa-notebook',
    activeIcon: 'fa-solid fa-notebook',
    path: NOTEBOOKS_PATH,
    title: 'Power Notebooks',
    helperMenuConfig: NOTEBOOKS_HELPER_MENU,
};

export const CONVERSATIONAL_SEARCH_MENU_ITEM: MenuItem = {
    id: 'menu-conversational-search',
    icon: 'fa-regular fa-comment',
    activeIcon: 'fa-solid fa-comment',
    path: `${CONVERSATIONAL_SEARCH_PATH}/my-searches`,
    title: MY_SEARCHES_TITLE,
};

export const ARTICLES_MENU_ITEM: MenuItem = {
    id: 'menu-articles',
    icon: ARTICLES_ICON,
    activeIcon: ARTICLES_ICON_ACTIVE,
    path: ARTICLES_PATH,
    title: ARTICLES_TITLE,
};

export const COW_MILK_PROFILER_MENU_ITEM: MenuItem = {
    id: 'menu-cow-milk-profiler',
    icon: COW_MILK_PROFILER_ICON,
    activeIcon: COW_MILK_PROFILER_ICON_ACTIVE,
    path: COW_MILK_PROFILER_PATH,
    title: COW_MILK_PROFILER_TITLE,
};

export const DAIRY_PROFILER_MENU_ITEM: MenuItem = {
    id: 'menu-dairy-profiler',
    icon: DAIRY_PROFILER_ICON,
    activeIcon: DAIRY_PROFILER_ICON_ACTIVE,
    path: DAIRY_PROFILER_PATH,
    title: DAIRY_PROFILER_TITLE,
};

export const INGREDIENT_PROFILER_MENU_ITEM: MenuItem = {
    id: 'menu-ingredient-profiler',
    icon: 'fa-regular fa-table-cells',
    activeIcon: 'fa-solid fa-table-cells',
    path: 'ingredient-profiler/discover',
    title: MY_DISCOVERY_TITLE,
    isUnclickable: true,
};

export const INGREDIENT_VIEWER_MENU_ITEM: MenuItem = {
    id: 'menu-ingredient-viewer',
    icon: INGREDIENT_VIEWER_ICON,
    activeIcon: INGREDIENT_VIEWER_ICON_ACTIVE,
    path: INGREDIENT_VIEWER_PATH,
    title: INGREDIENT_VIEWER_TITLE,
};

export const INGREDIENT_COMPARISON_MENU_ITEM: MenuItem = {
    id: 'menu-ingredient-comparison',
    icon: INGREDIENT_COMPARISON_ICON,
    activeIcon: INGREDIENT_COMPARISON_ICON_ACTIVE,
    path: INGREDIENT_COMPARISON_PATH,
    title: INGREDIENT_COMPARISON_TITLE,
};

export const INGREDIENT_MANAGEMENT_MENU_ITEM: MenuItem = {
    id: 'menu-ingredient-management',
    icon: INGREDIENT_MANAGEMENT_ICON,
    activeIcon: INGREDIENT_MANAGEMENT_ICON_ACTIVE,
    path: INGREDIENT_MANAGEMENT_PATH,
    title: INGREDIENT_MANAGEMENT_TITLE,
};
