export const FILE_UPLOAD_SUCCESS_MESSAGE: string = 'File uploaded successfully.';

export const FILE_UPLOAD_ERROR_STATUS: number = 400;

export const FILE_UPLOAD_INVALID_FILE_ERROR: string =
    'The file is invalid. Please upload again based on the template and the guidelines provided.';
export const FILE_UPLOAD_INVALID_INGREDIENTS_ERROR: string =
    'The data of the following ingredients are invalid: %s. The specific ingredients could not be stored. Please upload again based on the template and the guidelines provided.';
export const FILE_UPLOAD_DUPLICATE_AMINO_ACIDS_INGREDIENT_ERROR: string =
    '%s contains duplicated amino acid values, therefore it could not get uploaded.';
export const FILE_UPLOAD_EMPTY_TABS_INGREDIENT_ERROR: string =
    '%s is stored, however it does not have properties in the tabs: %s.';
