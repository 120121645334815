/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { FilesState } from './files-state.interface';
import {
    stageFileToUpload,
    unstageFileToUpload,
    createFilePathRequest,
    updateUploadProgress,
    cancelFileUpload,
    deleteFileRequest,
    deleteFilePathRequest,
    getFilesRequest,
    clearNextError,
    clearShouldFetchFiles,
} from './files.actions';
import {
    getFileToUpload,
    getFiles,
    getLoading,
    getLoaded,
    getErrors,
    getTotal,
    getSortColumn,
    getSortDirection,
    getPageIndex,
    getPageSize,
    getShouldFetchFiles,
    getDeletePendingItems,
} from './files.selectors';
import EnhancedFile from './interfaces/enhanced-file.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class FilesFacade {
    fileToUpload$: Observable<EnhancedFile> = this.store.pipe(select(getFileToUpload));
    files$: Observable<File[]> = this.store.pipe(select(getFiles));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    sortDirection$: Observable<SortingOrder> = this.store.pipe(select(getSortDirection));
    sortColumn$: Observable<string> = this.store.pipe(select(getSortColumn));
    shouldFetchFiles$: Observable<boolean> = this.store.pipe(select(getShouldFetchFiles));
    deletePendingItems$: Observable<string[]> = this.store.pipe(select(getDeletePendingItems));

    constructor(private store: Store<FilesState>) {}

    stageFileToUpload(file: File): void {
        this.store.dispatch(stageFileToUpload({ file }));
    }

    unstageFileToUpload(): void {
        this.store.dispatch(unstageFileToUpload());
    }

    getFiles(
        pageIndex: number,
        pageSize: number,
        sortDirection?: SortingOrder,
        sortColumn?: string,
    ): void {
        this.store.dispatch(
            getFilesRequest({
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
            }),
        );
    }

    uploadFile(file: File, onProgress: (progress: any) => void = null): void {
        this.store.dispatch(createFilePathRequest({ file, onProgress }));
    }

    updateUploadProgress(loadedBytes: number = 0): void {
        this.store.dispatch(updateUploadProgress({ loadedBytes }));
    }

    cancelUpload(): void {
        this.store.dispatch(cancelFileUpload());
    }

    deleteFilePath(id: string): void {
        this.store.dispatch(deleteFilePathRequest({ id }));
    }

    deleteFile(id: string): void {
        this.store.dispatch(deleteFileRequest({ id }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearShouldFetchFiles(): void {
        this.store.dispatch(clearShouldFetchFiles());
    }
}
