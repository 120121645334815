/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { DatabasesEffects } from './databases.effects';
import { DatabasesFacade } from './databases.facade';
import { DatabasesParser } from './parsers/databases.parser';
import { DatabasesService } from './services/databases.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([DatabasesEffects]),
    ],
    declarations: [],
    providers: [DatabasesParser, DatabasesService, DatabasesFacade],
})
export class DatabasesStoreModule {}
