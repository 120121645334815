import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NEW_FEATURES_TEXT } from '../../../constants/landing';
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

@Component({
    selector: 'app-feature-card',
    templateUrl: 'feature-card.view.component.html',
    styleUrls: ['feature-card.view.component.scss'],
})
export class FeatureCardViewComponent {
    @Input() item: MenuItem;
    @Output() clicked: EventEmitter<{ path: string; shouldOpenInNewTab?: boolean }> =
        new EventEmitter<{
            path: string;
            id: string;
        }>();

    readonly newFeaturesText: string = NEW_FEATURES_TEXT;

    constructor() {}

    onClick(event: { path: string; shouldOpenInNewTab?: boolean }): void {
        this.clicked.emit(event);
    }
}
