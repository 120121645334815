export enum UsersActionTypes {
    CREATE_USER_REQUEST = '[USERS] Create User Request',
    CREATE_USER_SUCCESS = '[USERS] Create User Success',
    CREATE_USER_FAILURE = '[USERS] Create User Failure',
    GET_USER_REQUEST = '[USERS] Get User Request',
    GET_USER_SUCCESS = '[USERS] Get User Success',
    GET_USER_FAILURE = '[USERS] Get User Failure',
    UPDATE_USER_REQUEST = '[USERS] Update User Request',
    UPDATE_USER_SUCCESS = '[USERS] Update User Success',
    UPDATE_USER_FAILURE = '[USERS] Update User Failure',
    DELETE_USER_REQUEST = '[USERS] Delete User Request',
    DELETE_USER_SUCCESS = '[USERS] Delete User Success',
    DELETE_USER_FAILURE = '[USERS] Delete User Failure',
    DELETE_USERS_REQUEST = '[USERS] Delete Users Request',
    DELETE_USERS_SUCCESS = '[USERS] Delete Users Success',
    DELETE_USERS_FAILURE = '[USERS] Delete Users Failure',
    GET_USERS_REQUEST = '[USERS] Get Users Request',
    GET_USERS_SUCCESS = '[USERS] Get Users Success',
    GET_USERS_FAILURE = '[USERS] Get Users Failure',
    CLEAR_NEXT_SUCCESS = '[USERS] Clear Next Success',
    CLEAR_NEXT_ERROR = '[USERS] Clear Next Error',
    CLEAR_SHOULD_FETCH_USERS = '[USERS] Clear shouldFetchUsers',
}
