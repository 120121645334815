import Bookmark from '../enums/bookmark.enum';

export const LEGACY_UKG_VERSION: string = 'legacy';

// This version marks the the visibility of the source-target tab in articles modal of closed discovery association. The tab can not function for bookmarks saved prior to this version.
export const CLOSED_DISCOVERY_SOURCE_TARGET_ARTICLES_UKG_VERSION: string = '3.2.0';

export const CREATE_BOOKMARK_SUCCESS_MESSAGE: string = 'Bookmark added to %s';
export const CREATE_BOOKMARKS_SUCCESS_MESSAGE: string = `%s %s added to %s`;
export const CREATE_BOOKMARKS_EXISTING_SUCCESS_MESSAGE: string = `${CREATE_BOOKMARKS_SUCCESS_MESSAGE}. %s %s already existed in some of these projects.`;
export const CREATE_BOOKMARKS_ERROR_MESSAGE: string =
    'All of the selected articles already exist as bookmarks in the %s';

export const ARTICLE_UPLOAD_INVALID_ERROR_STATUS: number = 400;
export const ARTICLE_UPLOAD_NOT_FOUND_ERROR_STATUS: number = 404;
export const ARTICLE_UPLOAD_DUPLICATE_ERROR_STATUS: number = 409;
export const ARTICLE_UPLOAD_POLL_INTERVAL: number = 5000;
export const USER_UPLOADED_ORIGIN_TYPE: string = 'user_uploaded';

export const SERIALIZED_SORTING_FIELD: Record<Bookmark, Record<string, string>> = {
    [Bookmark.entity]: {
        'data.patentsCount': 'noOfPatents',
        'data.moleculeWeight': 'molecularWeight',
        'data.cowMilkConcentration': 'cowMilkConcentration',
        'data.name': 'name',
        'data.categories': 'categories',
        'data.healthLabels': 'healthAreas',
        'data.molecules': 'moleculeClassification',
        'data.labs': 'ucdDmdLab',
        createdAt: 'created_at',
        createdBy: 'creator_mail',
    },
    [Bookmark.associationOpen]: {
        'data.targetName': 'targetName',
        'data.targetCategories': 'targetCategories',
        'data.relationshipType': 'relationshipType',
        'data.koOriginDatabases': 'databases',
        'data.associationOrigins': 'associationOrigins',
        'data.strength': 'associationStrength',
        'data.totalScore': 'associationScore',
        'data.weightRaw': 'coOccurrences',
        'data.patentsCount': 'noOfPatents',
        'data.targetTags': 'targetTags',
        'data.targetHealthLabels': 'healthAreas',
        'data.studyTypes': 'typesOfStudy',
        'data.journals': 'journals',
        'data.cowMilkConcentration': 'cowMilkConcentration',
        'data.targetMolecules': 'moleculeClassification',
        'data.moleculeWeight': 'molecularWeight',
        'data.targetLabs': 'ucdDmdLab',
        createdAt: 'created_at',
        createdBy: 'creator_mail',
    },
    [Bookmark.associationClosed]: {
        'data.intermediateName': 'intermediateNodeName',
        'data.categories': 'intermediateCategories',
        'data.koOriginDatabases': 'databases',
        'data.patentsCount': 'noOfPatents',
        'data.intermediateTags': 'intermediateTags',
        'data.intermediateHealthLabels': 'healthAreas',
        'data.studyTypes': 'typesOfStudy',
        'data.journals': 'journals',
        'data.cowMilkConcentration': 'cowMilkConcentration',
        'data.intermediateMolecules': 'moleculeClassification',
        'data.moleculeWeight': 'molecularWeight',
        'data.intermediateLabs': 'ucdDmdLab',
        createdAt: 'created_at',
        createdBy: 'creator_mail',
    },
    [Bookmark.article]: {
        'data.title': 'title',
        'data.publicationDate': 'publicationDate',
        'data.origin': 'source',
        'data.studyTypes': 'typesOfStudy',
        'data.journal': 'journal',
        createdAt: 'created_at',
        createdBy: 'creator_mail',
    },
};
