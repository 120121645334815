import { Pipe, PipeTransform } from '@angular/core';
import { FormatterService } from '@leap-common/services/formatter.service';

@Pipe({
    name: 'formatString',
})
export class FormatStringPipe implements PipeTransform {
    constructor(private formatterService: FormatterService) {}

    public transform(template: string, ...values: (string | number)[]): string {
        return this.formatterService.formatString(template, ...values);
    }
}
