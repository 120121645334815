/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { iif, Observable, of, timer } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { MetapathsParser } from '../parsers/metapaths.parser';

/** Interfaces */
import PaginatedMetapaths from '../interfaces/paginated-metapaths.interface';
import PaginatedMetapathsRestApi from '../rest-api-interfaces/paginated-metapaths.rest.interface';

@Injectable()
export class MetapathsService {
    constructor(private http: HttpClient, private metapathsParser: MetapathsParser) {}

    /**
     * Gets metapaths, parses them into the desired format and
     * returns an Observable of PaginatedMetapaths.
     */
    getMetapaths(
        ...payload: [
            sourceId: string,
            targetId: string,
            sourceCategories: string[],
            targetCategories: string[],
            sourceSubcategories: string[],
            targetSubcategories: string[],
            pageSize: number,
            pageIndex: number,
        ]
    ): Observable<PaginatedMetapaths> {
        const [
            sourceId,
            targetId,
            sourceCategories,
            targetCategories,
            sourceSubcategories,
            targetSubcategories,
            pageSize,
            pageIndex,
        ]: Parameters<typeof this.getMetapaths> = payload;

        return this.http
            .post(`${environment.metapathsServerUrl}/graphmeta/metapaths/`, {
                sourceUid: sourceId,
                targetUid: targetId,
                sourceMainCategories: sourceCategories,
                targetMainCategories: targetCategories,
                sourceSubCategories: sourceSubcategories,
                targetSubCategories: targetSubcategories,
                pageSize,
                pageIndex: pageIndex + 1,
            })
            .pipe(
                map((paginatedMetapaths: PaginatedMetapathsRestApi) =>
                    this.metapathsParser.parsePaginatedMetapaths(paginatedMetapaths),
                ),
                mergeMap((paginatedMetapaths: PaginatedMetapaths) =>
                    iif(
                        () => paginatedMetapaths.queryStatus === 'Completed',
                        of(paginatedMetapaths),
                        timer(paginatedMetapaths.estimatedDuration).pipe(
                            switchMap(() => this.getMetapaths(...payload)),
                        ),
                    ),
                ),
            );
    }
}
