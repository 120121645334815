import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromNotebookServers from './notebook-servers.reducer';

export const getNotebookServersState = createSelector(
    getDataState,
    (state: DataState) => state.notebookServers,
);
export const getError = createSelector(getNotebookServersState, fromNotebookServers.getError);
export const getLoading = createSelector(getNotebookServersState, fromNotebookServers.getLoading);
export const getLoaded = createSelector(getNotebookServersState, fromNotebookServers.getLoaded);
export const getConnections = createSelector(
    getNotebookServersState,
    fromNotebookServers.getConnections,
);
export const getIsConnectionOpen = createSelector(
    getNotebookServersState,
    fromNotebookServers.getIsConnectionOpen,
);
export const getIsConnectionClosed = createSelector(
    getNotebookServersState,
    fromNotebookServers.getIsConnectionClosed,
);
