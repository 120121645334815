/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { ConversationalSearchService } from './services/conversational-search.service';
import { CategoriesFacade } from '../discovery/categories/categories.facade';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './conversational-search.actions';
import SearchQuery from './interfaces/search-query.interface';

@Injectable()
export class ConversationalSearchEffects {
    constructor(
        private actions$: Actions,
        private conversationalSearchService: ConversationalSearchService,
        private categoriesFacade: CategoriesFacade,
    ) {}

    performQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performQueryRequest),
            mergeMap(
                ({
                    query,
                    tabId,
                    sessionId,
                }: {
                    query: string;
                    tabId: string;
                    sessionId?: string;
                }) =>
                    this.conversationalSearchService.performQuery(query, sessionId).pipe(
                        withLatestFrom(this.categoriesFacade.categories$),
                        switchMap(([searchQuery, categories]: [SearchQuery, string[]]) =>
                            this.conversationalSearchService.enhanceQueryWithReportSections(
                                searchQuery,
                                categories,
                            ),
                        ),
                        map((searchQuery: SearchQuery) =>
                            actions.performQuerySuccess({ query: searchQuery, tabId }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.performQueryFailure({ errorResponse, tabId })),
                        ),
                    ),
            ),
        ),
    );
}
