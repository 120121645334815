/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './categories.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { CategoriesState } from './categories-state.interface';

export const initialState: CategoriesState = {
    categories: {},
    errors: [],
    loading: false,
    loaded: false,
};

const categoriesReducer: ActionReducer<CategoriesState, Action> = createReducer(
    initialState,
    on(actions.getCategoriesRequest, (state: CategoriesState) => ({
        ...state,
        categories: {},
        loading: true,
        loaded: false,
    })),
    on(
        actions.getCategoriesSuccess,
        (state: CategoriesState, { categories }: { categories: Record<string, string[]> }) => ({
            ...state,
            categories,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        actions.getCategoriesFailure,
        (state: CategoriesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearNextError, (state: CategoriesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: CategoriesState | undefined, action: Action): CategoriesState =>
    categoriesReducer(state, action);

// selectors
export const getCategories: (state: CategoriesState) => string[] = (state: CategoriesState) =>
    Object.keys(state.categories);
export const getSubcategoriesPerCategory: (state: CategoriesState) => Record<string, string[]> = (
    state: CategoriesState,
) => state.categories;
export const getErrors: (state: CategoriesState) => ErrorResponse[] = (state: CategoriesState) =>
    state.errors;
export const getLoading: (state: CategoriesState) => boolean = (state: CategoriesState) =>
    state.loading;
export const getLoaded: (state: CategoriesState) => boolean = (state: CategoriesState) =>
    state.loaded;
