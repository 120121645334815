import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgbConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '@environments/leap/environment';
import buildJSON from '../../assets/build.json';

/** Services - Facades */
import { AppService } from './app.service';
import { AnalyticsService } from './analytics/services/analytics.service';
import { RedirectService } from './layout/services/redirect.service';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Interfaces - Enums */
import AlertEvent from '@leap-store/core/src/lib/ui/alerts/interfaces/alert-event.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    @HostListener('window:storage', ['$event'])
    onStorageChange(event: StorageEvent): void {
        this.handleStorageEvent(event);
    }

    // organization
    organization: string = environment.organization;
    title: HTMLLinkElement = document.querySelector('#title');
    favicon: HTMLLinkElement = document.querySelector('#favicon');

    // version
    currentVersion: string;
    latestVersion: string;
    latestVersionSubscription: Subscription;
    versionCheckInterval: ReturnType<typeof setInterval>;

    // alert
    alertEvents$: Observable<AlertEvent[]> = this.alertsFacade.alertEvents$;
    alertEvents: AlertEvent[];

    constructor(
        private router: Router,
        private ngbConfig: NgbConfig,
        private ngbTooltipConfig: NgbTooltipConfig,
        private appService: AppService,
        private analyticsService: AnalyticsService,
        private redirectService: RedirectService,
        private authFacade: AuthFacade,
        private alertsFacade: AlertsFacade,
    ) {
        this.title.innerHTML = environment.product;
        this.favicon.href = `../../assets/graphics/${this.organization}/favicon.svg`;
        this.analyticsService.initializeProviders();
    }

    ngOnInit(): void {
        this.handleAlertEvents();
        this.initializeNgBootstrap();
        this.initializeCurrentVersion();
        this.initializeVersionCheckInterval();
    }

    initializeNgBootstrap(): void {
        this.ngbConfig.animation = false;
        // NgbTooltip global configuration
        this.ngbTooltipConfig.placement = 'auto';
        this.ngbTooltipConfig.container = 'body';
    }

    /** Initializes app current version based on the build.json asset */
    initializeCurrentVersion(): void {
        this.currentVersion = buildJSON.version;
    }

    /**
     * This method listens for the 'storage' event, which is triggered whenever there is
     * a change in localStorage across any open tabs of the same domain.
     *
     * The main goal of this method is to detect changes related to the 'accessToken'
     * in localStorage (indicating a login or a logout).
     *
     * When the 'accessToken' is cleared (newValue is null), the method will:
     * - Dispatch a resetAuth action to clear the authentication state from the store.
     * - Redirect the user to the logout page to ensure consistency across all tabs.
     *
     * When a new 'accessToken' is added (newValue exists and oldValue is null), the method will:
     * - Redirect the user to the landing page to ensure the application is in sync
     *   after a successful login in another tab.
     *
     * This ensures that login and logout events are properly handled across multiple tabs,
     * providing a consistent user experience.
     *
     * It could be beneficial to handle cases where the token is updated in another tab
     * (e.g., after a refresh), where both newValue and oldValue exist but are different.
     * Currently, no action is taken for this scenario.
     */
    handleStorageEvent(event: StorageEvent): void {
        // check if the event relates to the 'accessToken'
        if (event.key === TokenTypes.access) {
            // if the 'accessToken' is removed (logout scenario)
            if (!event.newValue) {
                // the accessToken has been removed, indicating a logout. Clear the store.
                this.authFacade.resetAuth();
                // redirect the user to the logout page
                this.router.navigate(['auth/logout']);
            }

            // if the 'accessToken' is added (login scenario)
            if (event.newValue && !event.oldValue) {
                // redirect the user to the landing or to the terms-of-use page
                this.redirectService.redirectBasedOnTermsOfUseAcceptance();
            }
        }
    }

    /** Subscribes to alertEvents$ and assigns value to alertEvents */
    handleAlertEvents(): void {
        this.alertEvents$.subscribe((alertEvents: AlertEvent[]) => {
            this.alertEvents = alertEvents;
        });
    }

    /**
     * Clears existing alerts when user clicks anywhere in the rest of the page
     */
    onPageClicked(): void {
        if (this.alertEvents.length > 0) {
            // call component's closeAlert() for each alertEvent
            this.alertEvents.forEach((alertEvent: AlertEvent) => this.closeAlert(alertEvent));
        }
    }

    /** Calls the closeAlert() */
    onAlertToBeClosed(alert: AlertEvent): void {
        this.closeAlert(alert, true);
    }

    /**
     * Calls the alertsFacade.removeAlertEvent() which executes the alertEvent onClose callback
     */
    closeAlert(alert: AlertEvent, shouldRemovePersistent: boolean = false): void {
        this.alertsFacade.removeAlertEvent(alert.id, shouldRemovePersistent);
    }

    initializeVersionCheckInterval(): void {
        this.versionCheckInterval = setInterval(() => {
            this.handleVersionUpdate();
        }, 900000); // check for updates every 15'
    }

    clearVersionCheckInterval(): void {
        clearInterval(this.versionCheckInterval);
    }

    handleVersionUpdate(): void {
        this.latestVersionSubscription?.unsubscribe();

        this.latestVersionSubscription = this.appService
            .getLatestVersion()
            .subscribe((latestVersion: string) => {
                this.latestVersion = latestVersion;
                if (this.latestVersion !== this.currentVersion) {
                    this.clearVersionCheckInterval(); // Stop the interval when displaying the prompt
                    this.displayReloadPrompt();
                }
            });
    }

    displayReloadPrompt(): void {
        const isReloadConfirmed: boolean = confirm(
            'Reload to get the updated version of the application. It will take only a few seconds.',
        );

        if (isReloadConfirmed) {
            window.location.reload();
        } else {
            this.initializeVersionCheckInterval(); // Start the interval again if the user cancels the prompt
        }
    }
}
