import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { AuthState } from './auth-state.interface';
import {
    loginUserRequest,
    externalLoginUserRequest,
    logoutUserRequest,
    rehydrateToken,
    refreshLoginRequest,
    getCurrentUser,
    acceptTermsOfUseRequest,
    resetAuth,
    clearResetPasswordKey,
    clearNextError,
} from './auth.actions';
import {
    getAuthJwt,
    getAuthResetPasswordKey,
    getAuthError,
    getAuthLoading,
    getAuthLoaded,
    getAuthCurrentUser,
} from './auth.selectors';
import LoginUser from './interfaces/login-user.interface';
import Jwt from './interfaces/jwt.interface';
import CurrentUser from './interfaces/current-user.interface';
import TokenTypes from './enums/token-types.enum';

@Injectable()
export class AuthFacade {
    currentUser$: Observable<CurrentUser> = this.store.pipe(select(getAuthCurrentUser));
    jwt$: Observable<Jwt> = this.store.pipe(select(getAuthJwt));
    resetPasswordKey$: Observable<string> = this.store.pipe(select(getAuthResetPasswordKey));
    error$: Observable<ErrorResponse[]> = this.store.pipe(select(getAuthError));
    loading$: Observable<boolean> = this.store.pipe(select(getAuthLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getAuthLoaded));

    constructor(private store: Store<AuthState>) {}

    login(user: LoginUser): void {
        this.store.dispatch(loginUserRequest({ user }));
    }

    externalLogin(redirectUri: string, clientId: string, code: string, codeVerifier: string): void {
        this.store.dispatch(
            externalLoginUserRequest({ redirectUri, clientId, code, codeVerifier }),
        );
    }

    logout(tokenKeys: TokenTypes[]): void {
        this.store.dispatch(logoutUserRequest({ tokenKeys }));
    }

    rehydrateToken(key: TokenTypes): void {
        this.store.dispatch(rehydrateToken({ key }));
    }

    refreshToken(): void {
        this.store.dispatch(refreshLoginRequest());
    }

    getCurrentUser(): void {
        this.store.dispatch(getCurrentUser());
    }

    acceptTermsOfUse(): void {
        this.store.dispatch(acceptTermsOfUseRequest());
    }

    resetAuth(): void {
        this.store.dispatch(resetAuth());
    }

    clearResetPasswordKey(): void {
        this.store.dispatch(clearResetPasswordKey());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
