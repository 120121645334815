/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as projectsActions from './projects.actions';
import { ProjectsState } from './projects-state.interface';
import StatusFilterOption from './interfaces/status-filter-option.interface';
import TypeFilterOption from './interfaces/type-filter-option.interface';
import Status from '../../data/projects/enums/status.enum';
import {
    ALL_STATUS_NAME,
    ALL_PROJECTS_TYPE_NAME,
    MY_PROJECTS_TYPE_NAME,
    ARCHIVED_PROJECTS_TYPE_NAME,
    FAVORITE_PROJECTS_TYPE_NAME,
} from '@apps/leap/src/app/shared/constants/projects';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const initialState: ProjectsState = {
    searchFilter: '',
    statusFilters: [
        { name: Status.inProgress, active: true },
        { name: Status.onHold, active: true },
        { name: Status.done, active: true },
        { name: ALL_STATUS_NAME, active: true },
    ],
    typeFilters: [
        {
            name: ALL_PROJECTS_TYPE_NAME,
            value: 'all',
            active: true,
        },
        {
            name: MY_PROJECTS_TYPE_NAME,
            value: 'mine',
            active: false,
        },
        {
            name: FAVORITE_PROJECTS_TYPE_NAME,
            value: 'favourite',
            active: false,
        },
        {
            name: ARCHIVED_PROJECTS_TYPE_NAME,
            value: 'archived',
            active: false,
        },
    ],
    sortDirection: SortingOrder.descending,
    sortColumn: 'createdAt',
};

const projectsReducer: ActionReducer<ProjectsState, Action> = createReducer(
    initialState,
    on(
        projectsActions.updateSearchFilter,
        (state: ProjectsState, { filter }: { filter: string }) => ({
            ...state,
            searchFilter: filter,
        }),
    ),
    on(
        projectsActions.updateStatusFilters,
        (state: ProjectsState, { filters }: { filters: StatusFilterOption[] }) => ({
            ...state,
            statusFilters: filters,
        }),
    ),
    on(
        projectsActions.updateTypeFilters,
        (state: ProjectsState, { filters }: { filters: TypeFilterOption[] }) => ({
            ...state,
            typeFilters: filters,
        }),
    ),
    on(
        projectsActions.updateSortDirection,
        (state: ProjectsState, { sortDirection }: { sortDirection: SortingOrder }) => ({
            ...state,
            sortDirection,
        }),
    ),
    on(
        projectsActions.updateSortColumn,
        (state: ProjectsState, { sortColumn }: { sortColumn: string }) => ({
            ...state,
            sortColumn,
        }),
    ),
);

export const reducer = (state: ProjectsState | undefined, action: Action): ProjectsState =>
    projectsReducer(state, action);

// selectors
export const getSearchFilter: (state: ProjectsState) => string = (state: ProjectsState) =>
    state.searchFilter;
export const getStatusFilters: (state: ProjectsState) => StatusFilterOption[] = (
    state: ProjectsState,
) => state.statusFilters;
export const getTypeFilters: (state: ProjectsState) => TypeFilterOption[] = (
    state: ProjectsState,
) => state.typeFilters;
export const getSortDirection: (state: ProjectsState) => SortingOrder = (state: ProjectsState) =>
    state.sortDirection;
export const getSortColumn: (state: ProjectsState) => string = (state: ProjectsState) =>
    state.sortColumn;
