// used exclusively to extract the profile category number from the BE response
export const PROFILE_CATEGORY_PREFIX: string = 'Category ';

export const DESCRIPTION_PER_PROFILE_CATEGORY: Record<number, string> = {
    1: "Found in Cow's milk only",
    2: "Found in Cow's milk and Cow’s milk Dairy products",
    3: "Found in Cow's milk and other mammalian milks",
    4: "Found in Cow's milk, and in cow and other mammalian Dairy products",
    5: "Found in Cow's milk and in other foods",
};

export const TOOLTIP_PER_PROFILE_CATEGORY: Record<number, string> = {
    1: "This molecule has been found in cow's milk only.",
    2: "This molecule was found in both cow's milk and cow's milk Dairy products (e.g. yogurt, cheese, butter and cream).",
    3: "This molecule was found in cow's milk and other mammalian milk sold commercially (e.g. goat, sheep, buffalo and/or yak milk).",
    4: "This molecule was found both in cow's milk, in cow's milk Dairy products (e.g. yogurt, cheese, butter and cream) and also in other mammalian dairy products (e.g. goat cheese and yogurt).",
    5: "This molecule was found both in cow's milk and also in other foods (e.g. eggs, meat, fish, fruit, etc.)",
};

export const PROFILE_CATEGORY_LABEL: string = 'Category %s';
export const PROFILE_CATEGORY_CONTENT_TEXT: string = `${PROFILE_CATEGORY_LABEL} (%s)`;
export const PROFILE_CATEGORY_ALTERNATIVE_CONTENT_TEXT: string = 'Category %s: %s';
