import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { NotebookServersActionTypes } from './notebook-servers-action-types.enum';
import NotebookServer from './interfaces/notebook-server.interface';
import TokenTypes from '../auth/enums/token-types.enum';

export const getNotebookServersRequest = createAction(
    NotebookServersActionTypes.GET_NOTEBOOK_SERVERS_REQUEST,
);

export const getNotebookServersSuccess = createAction(
    NotebookServersActionTypes.GET_NOTEBOOK_SERVERS_SUCCESS,
    props<{ servers: Record<string, NotebookServer> }>(),
);

export const getNotebookServersFailure = createAction(
    NotebookServersActionTypes.GET_NOTEBOOK_SERVERS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createNotebookServerRequest = createAction(
    NotebookServersActionTypes.CREATE_NOTEBOOK_SERVER_REQUEST,
    props<{ key: TokenTypes }>(),
);

export const createNotebookServerSuccess = createAction(
    NotebookServersActionTypes.CREATE_NOTEBOOK_SERVER_SUCCESS,
);

export const createNotebookServerFailure = createAction(
    NotebookServersActionTypes.CREATE_NOTEBOOK_SERVER_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteNotebookServerRequest = createAction(
    NotebookServersActionTypes.DELETE_NOTEBOOK_SERVER_REQUEST,
);

export const deleteNotebookServerSuccess = createAction(
    NotebookServersActionTypes.DELETE_NOTEBOOK_SERVER_SUCCESS,
);

export const deleteNotebookServerFailure = createAction(
    NotebookServersActionTypes.DELETE_NOTEBOOK_SERVER_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const logoutJupyterHubRequest = createAction(
    NotebookServersActionTypes.LOGOUT_JUPYTER_HUB_REQUEST,
);

export const logoutJupyterHubSuccess = createAction(
    NotebookServersActionTypes.LOGOUT_JUPYTER_HUB_SUCCESS,
);

export const logoutJupyterHubFailure = createAction(
    NotebookServersActionTypes.LOGOUT_JUPYTER_HUB_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(NotebookServersActionTypes.CLEAR_NEXT_ERROR);
