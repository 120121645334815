import { Injectable } from '@angular/core';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';

@Injectable()
export class DownloadService {
    constructor() {}

    createTransientLink(url: string, filename: string = 'download'): HTMLAnchorElement {
        const link: HTMLAnchorElement = document.createElement('a');

        link.setAttribute('style', 'display: none');
        link.setAttribute('href', url);
        link.setAttribute('download', filename);

        return link;
    }

    downloadBlob(blob: Blob, filename: string): void {
        const url: string = window.URL.createObjectURL(blob);
        const link: HTMLAnchorElement = this.createTransientLink(url, filename);

        link.click();
        window.URL.revokeObjectURL(url);
    }

    downloadFile(file: File): void {
        const link: HTMLAnchorElement = this.createTransientLink(file.sas, file.path);
        link.click();
    }
}
