import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NonAuthGuard } from './core/guards/non-auth.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [NonAuthGuard],
        loadChildren: () =>
            import('./auth-layout/auth-layout.module').then((module) => module.AuthLayoutModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
