export enum CombinatorialDiscoveryActionTypes {
    PERFORM_DISCOVERY_REQUEST = '[DISCOVERY][COMBINATORIAL] Perform Discovery Request',
    PERFORM_DISCOVERY_SUCCESS = '[DISCOVERY][COMBINATORIAL] Perform Discovery Success',
    PERFORM_DISCOVERY_FAILURE = '[DISCOVERY][COMBINATORIAL] Perform Discovery Failure',
    DOWNLOAD_DISCOVERY_REQUEST = '[DISCOVERY][COMBINATORIAL] Download Discovery Request',
    DOWNLOAD_DISCOVERY_SUCCESS = '[DISCOVERY][COMBINATORIAL] Download Discovery Success',
    DOWNLOAD_DISCOVERY_FAILURE = '[DISCOVERY][COMBINATORIAL] Download Discovery Failure',
    FETCH_LOCAL_DISCOVERY_REQUEST = '[DISCOVERY][COMBINATORIAL] Fetch Local Discovery Request',
    FETCH_LOCAL_DISCOVERY_SUCCESS = '[DISCOVERY][COMBINATORIAL] Fetch Local Discovery Success',
    FETCH_LOCAL_DISCOVERY_FAILURE = '[DISCOVERY][COMBINATORIAL] Fetch Local Discovery Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[DISCOVERY][COMBINATORIAL] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[DISCOVERY][COMBINATORIAL] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[DISCOVERY][COMBINATORIAL] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[DISCOVERY][COMBINATORIAL] Clear Search Suggestions',
    RESET_DISCOVERY = '[DISCOVERY][COMBINATORIAL] Reset Discovery',
    CLEAR_NEXT_ERROR = '[DISCOVERY][COMBINATORIAL] Clear Next Error',
    SAVE_DB_ID = '[DISCOVERY][COMBINATORIAL] Save DB Id',
    REMOVE_DB_ID = '[DISCOVERY][COMBINATORIAL] Remove DB Id',
    SAVE_SESSION_ID = '[DISCOVERY][COMBINATORIAL] Save Session Id',
    EXIT_DISCOVERY = '[DISCOVERY][COMBINATORIAL] Exit Discovery',
}
