/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { PipelineExecutionsState as PipeExecState } from './pipeline-executions-state.interface';
import PipelineExecution from './interfaces/pipeline-execution.interface';
import * as pipelineExecutionsActions from './pipeline-executions.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const adapter: EntityAdapter<PipelineExecution> = createEntityAdapter<PipelineExecution>();

export const initialState: PipeExecState = adapter.getInitialState({
    pipelineExecutions: null,
    downloadableResult: null,
    error: [],
    loading: false,
    loaded: false,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    sortDirection: SortingOrder.descending,
    sortColumn: 'creationDate',
});

const pipelineExecutionsReducer: ActionReducer<PipeExecState, Action> = createReducer(
    initialState,
    on(pipelineExecutionsActions.getPipelineExecutionsRequest, (state: PipeExecState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        pipelineExecutionsActions.getPipelineExecutionsSuccess,
        (
            state: PipeExecState,
            {
                paginatedPipelineExecutions,
                sortDirection,
                sortColumn,
            }: {
                paginatedPipelineExecutions: PaginatedResults<PipelineExecution>;
                sortDirection: SortingOrder;
                sortColumn: string;
            },
        ) =>
            adapter.setAll(paginatedPipelineExecutions.results, {
                ...state,
                loading: false,
                loaded: true,
                pageIndex: paginatedPipelineExecutions.pageIndex,
                pageSize: paginatedPipelineExecutions.pageSize,
                total: paginatedPipelineExecutions.total,
                sortDirection,
                sortColumn,
            }),
    ),
    on(
        pipelineExecutionsActions.getPipelineExecutionsFailure,
        (state: PipeExecState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(pipelineExecutionsActions.getPipelineResultRequest, (state: PipeExecState) => ({
        ...state,
    })),
    on(
        pipelineExecutionsActions.getPipelineResultSuccess,
        (
            state: PipeExecState,
            {
                pipelineResult,
            }: {
                pipelineResult: File;
            },
        ) => ({ ...state, downloadableResult: pipelineResult }),
    ),
    on(
        pipelineExecutionsActions.getPipelineResultFailure,
        (state: PipeExecState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.error, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(pipelineExecutionsActions.clearDownloadableResult, (state: PipeExecState) => ({
        ...state,
        downloadableResult: null as File,
    })),
    on(pipelineExecutionsActions.clearNextError, (state: PipeExecState) => ({
        ...state,
        error: state.error.slice(1),
    })),
);

export const reducer = (state: PipeExecState | undefined, action: Action): PipeExecState =>
    pipelineExecutionsReducer(state, action);

// selectors
export const getPipelineExecutionsEntities: (state: PipeExecState) => PipelineExecution[] =
    adapter.getSelectors().selectAll;
export const getPipelineResult: (state: PipeExecState) => File = (state: PipeExecState) =>
    state.downloadableResult;
export const getError: (state: PipeExecState) => ErrorResponse[] = (state: PipeExecState) =>
    state.error;
export const getLoading: (state: PipeExecState) => boolean = (state: PipeExecState) =>
    state.loading;
export const getLoaded: (state: PipeExecState) => boolean = (state: PipeExecState) => state.loaded;
export const getPageIndex: (state: PipeExecState) => number = (state: PipeExecState) =>
    state.pageIndex;
export const getPageSize: (state: PipeExecState) => number = (state: PipeExecState) =>
    state.pageSize;
export const getTotal: (state: PipeExecState) => number = (state: PipeExecState) => state.total;
export const getSortDirection: (state: PipeExecState) => SortingOrder = (state: PipeExecState) =>
    state.sortDirection;
export const getSortColumn: (state: PipeExecState) => string = (state: PipeExecState) =>
    state.sortColumn;
