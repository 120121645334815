import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';

@Directive({
    selector: '[libFeatureFlag]',
})
export class FeatureFlagDirective {
    @Input() set libFeatureFlag(feature: string) {
        this.isViewEnabled(feature);
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainerRef: ViewContainerRef,
        private featureFlagsService: FeatureFlagsService,
    ) {}

    /**
     * Enables the the protected view based on the featureFlagsService.isFeatureEnabled().
     * If the feature is enabled creates an embedded view and inserts it in a view container
     * adjacent to the directive's original host element.
     */
    isViewEnabled(feature: string): void {
        const isEnabled: boolean = this.featureFlagsService.isFeatureEnabled(feature);

        if (isEnabled) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}
