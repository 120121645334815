import { Component, Input } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';

/** Interfaces */
import InputValidationError from '../../interfaces/input-validation-error.interface';
import InputOptions from '../../interfaces/input-options.interface';

@Component({
    selector: 'lib-input',
    templateUrl: 'input.component.html',
})
export class InputComponent {
    @Input() parentForm: FormGroup;
    @Input() options: InputOptions;
    @Input() isDisabled: boolean;

    inputValidationErrors: InputValidationError[] = [];

    readonly emptyString: string = EMPTY_STRING;

    constructor() {}

    /**
     * It is called from the template.
     * It clears out the inputValidationErrors[]
     * If the input is invalid, it calls the setValidationErrors() and returns
     * a boolean indicates if the input has been touched or not
     * If the input is valid it returns false
     */
    isInputInvalid(inputId: string): boolean {
        const errors: ValidationErrors = this.parentForm.get(inputId).errors;
        this.inputValidationErrors = [];

        if (errors) {
            this.inputValidationErrors = this.setValidationErrors(errors);
            return this.parentForm.get(inputId).touched;
        }
        return false;
    }

    /**
     * It sets the input validation errors
     * It gets the current validation errors
     * and returns an InputValidationError[] derived from them
     */
    setValidationErrors(errors: ValidationErrors): InputValidationError[] {
        const validationErrors: InputValidationError[] = [];
        for (const [key, value] of Object.entries(errors)) {
            const validationError: InputValidationError = {
                field: key,
                message: value,
            };
            validationErrors.push(validationError);
        }
        return validationErrors;
    }
}
