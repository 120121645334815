/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';

/** custom imports */
import * as discoveryActions from './discovery.actions';
import { DiscoveryState } from './discovery-state.interface';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import NetworkView from './enums/network-view.enum';
import DiscoveryExecution from './interfaces/discovery-execution.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import DiscoverySummary from './interfaces/discovery-summary.interface';

export const initialState: DiscoveryState = {
    activeTabId: null,
    activeNetworkView: null,
    execution: null,
    discovery: null,
    summary: null,
};

const discoveryReducer: ActionReducer<DiscoveryState, Action> = createReducer(
    initialState,
    on(discoveryActions.updateActiveTabId, (state: DiscoveryState, { id }: { id: TabId }) => ({
        ...state,
        activeTabId: id,
    })),
    on(
        discoveryActions.updateActiveNetworkView,
        (state: DiscoveryState, { view }: { view: NetworkView }) => ({
            ...state,
            activeNetworkView: view,
        }),
    ),
    on(
        discoveryActions.updateExecution,
        (state: DiscoveryState, { execution }: { execution: DiscoveryExecution }) => ({
            ...state,
            execution,
        }),
    ),
    on(
        discoveryActions.updateDiscovery,
        (state: DiscoveryState, { discovery }: { discovery: Discovery }) => ({
            ...state,
            discovery,
        }),
    ),
    on(
        discoveryActions.updateSummary,
        (state: DiscoveryState, { summary }: { summary: DiscoverySummary }) => ({
            ...state,
            summary,
        }),
    ),
);

export const reducer = (state: DiscoveryState | undefined, action: Action): DiscoveryState =>
    discoveryReducer(state, action);

// selectors
export const getActiveTabId: (state: DiscoveryState) => TabId = (state: DiscoveryState) =>
    state.activeTabId;
export const getActiveNetworkView: (state: DiscoveryState) => NetworkView = (
    state: DiscoveryState,
) => state.activeNetworkView;
export const getExecution: (state: DiscoveryState) => DiscoveryExecution = (
    state: DiscoveryState,
) => state.execution;
export const getDiscovery: (state: DiscoveryState) => Discovery = (state: DiscoveryState) =>
    state.discovery;
export const getSummary: (state: DiscoveryState) => DiscoverySummary = (state: DiscoveryState) =>
    state.summary;
