/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { IngredientProfilerState } from './ingredient-profiler-state.interface';
import { updateActiveTabId, updateExecution, updateSummary } from './ingredient-profiler.actions';
import { getActiveTabId, getExecution, getSummary } from './ingredient-profiler.selectors';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import DiscoveryExecution from '../discovery/interfaces/discovery-execution.interface';
import DiscoverySummary from '../discovery/interfaces/discovery-summary.interface';

@Injectable()
export class IngredientProfilerFacade {
    activeTabId$: Observable<TabId> = this.store.pipe(select(getActiveTabId));
    execution$: Observable<DiscoveryExecution> = this.store.pipe(select(getExecution));
    summary$: Observable<DiscoverySummary> = this.store.pipe(select(getSummary));

    constructor(private store: Store<IngredientProfilerState>) {}

    updateActiveTabId(id: TabId): void {
        this.store.dispatch(updateActiveTabId({ id }));
    }

    updateExecution(execution: DiscoveryExecution): void {
        this.store.dispatch(updateExecution({ execution }));
    }

    updateSummary(summary: DiscoverySummary): void {
        this.store.dispatch(updateSummary({ summary }));
    }
}
