import { environment } from '@environments/leap/environment';
import { capitalize } from 'lodash';
import Discovery from '../enums/discovery.enum';

export const DISCOVERY: string = 'Discovery';
export const INSIGHT: string = 'insight';
export const ASSOCIATION: string = 'association';

export const OPEN_DISCOVERY_TITLE: string = `${capitalize(Discovery.open)} ${DISCOVERY}`;
export const CLOSED_DISCOVERY_TITLE: string = `${capitalize(Discovery.closed)} ${DISCOVERY}`;
export const COMBINATORIAL_DISCOVERY_TITLE: string = `${capitalize(
    Discovery.combinatorial,
)} ${DISCOVERY}`;

export const METAPATH_SCORE_TOOLTIP_TEXT: string = `This score is used to rank metapaths for each insight in order of significance. Metapaths with higher scores include more specific and informative paths than ones with lower scores.

Score range: 0-100%
Score method: DWPC (Degree Weighted Path Count)`;
export const METAPATH_NODE_TOOLTIP_TEXT: string =
    'Category: %s<br/>Subcategories: <span class="text-break">%s</span>';

export const NOVEL_ASSOCIATIONS_TOOLTIP_TEXT: string = `<b>Novel associations</b> are insights predicted by AI or extracted from analyses of omics data. These insights can reveal
potentially undiscovered connections and relationships between existing entities.`;
export const KNOWN_ASSOCIATIONS_TOOLTIP_TEXT: string = `<b>Known associations</b> are insights found in ingested publicly available sources
(knowledge bases, literature and omics data). They are existing connections and relationships based on scientific evidence.`;
export const ASSOCIATION_SCORE_TOOLTIP_TEXT: string = `The score measures how strong the association is based on the contextual and conceptual similarities of the two terms in the
%s biomedical graph. It is calculated through a combination of NLP and graph models as an aggregated metric and it's based on the cosine
similarity of the embeddings. The closer to 1, the stronger and more relevant the association is.
The score values might be updated with future releases as we include more data and articles.`;
export const TAGS_TOOLTIP_TEXT: string = `Tags are filters that describe a property or functionality of a node.<br/><br/>
<b>Disclaimer</b>: Some insights might not have tags assigned to them. The Tags functionality is being continuously enriched.`;
export const PATENTS_TOOLTIP_TEXT: string = `This column shows the total number of available patents for each node. Full list of the patents can be found in Node Wiki.<br/><br/> Patents source: PubChem`;
export const CONCENTRATIONS_TOOLTIP_TEXT: string =
    environment.app === 'd3'
        ? `This filter allows you to select, a specific concentration range for an ingredient. The available measurement units for cow milk are mg/L and mg/cup, while for the rest of ingredients is mg/100g.<br><br>
<b>Disclaimer:</b> The concentrations used for filtering, excluding the ones of cow's milk, refer to the average number of concentration based on the data derived from FooDB.`
        : environment.app === 'ingpro'
        ? `This filter allows you to select a specific range of concentration for your selected ingredient. The available measurement unit is mg/100g.<br><br>
<b>Disclaimer:</b> The concentrations used for filtering refer to the average number of concentration based on the data derived from FooDB.`
        : `This filter allows you to select, based on your discovery, a specific range of concentration for an ingredient. The available measurement unit is mg/100g.<br><br>
<b>Disclaimer:</b> The concentrations used for filtering refer to the average number of concentration based on the data derived from FooDB.`;

export const NETWORK_CLASSIC_DEFAULT_LIMIT: number = 500;
export const NETWORK_CLASSIC_LIMIT_OPTIONS: number[] = [10, 25, 50, 75, 100, 250, 500, 1000, 1500];
export const NETWORK_NEW_DEFAULT_LIMIT: number = 25;
export const NETWORK_NEW_LIMIT_OPTIONS: number[] = [10, 25, 50, 75, 100, 250, 500];
export const RELATIONSHIP_OTHER_LABEL: string = 'other';
export const RELATIONSHIP_GENERIC_LABEL: string = 'is related';
export const RELATIONSHIP_ORIGIN_LITERATURE_LABEL: string = 'Literature';
export const RELATIONSHIP_TYPE_ARTICLES_TOOLTIP_TEXT: string = 'View related articles';
export const UNKNOWN_CATEGORY_LABEL: string = 'Other';
export const MULTIPLE_CATEGORIES_LABEL: string = 'Multiple Categories';
export const RELATIONSHIP_TYPE_PROBABILITY_TOOLTIP_TEXT: string =
    'Predicted relationship type with probability <span class="probability">%s</span> (scoring range from 0 to 1)';
export const DISCOVERY_IN_NEW_TAB_TOOLTIP_TEXT: string = 'Open discovery in a new tab';

export const OPEN_DISCOVERY_ALTERNATIVE_COLUMNS_ORDER: string[] = [
    'feedback',
    'feedback-spacing',
    'rankingIndex',
    'explorationPaths',
    'targetName',
    'cowMilkConcentration',
    'prevalence',
    'sourceConcentration',
    'targetProfileCategory',
    'targetHealthLabels',
    'studyTypes',
    'koOriginDatabases',
    'targetLabs',
    'relationshipType',
    'strength',
    'articlesCount',
    'weightRaw',
    'journals',
    'associationOrigins',
    'targetMolecules',
    'moleculeWeight',
    'categories',
    'subcategories',
    'targetTags',
    'patentsCount',
    'totalScore',
    'proteinOrigins',
];
export const CLOSED_DISCOVERY_ALTERNATIVE_COLUMNS_ORDER: string[] = [
    'rankingIndex',
    'intermediateName',
    'cowMilkConcentration',
    'concentrationE0',
    'concentrationE1',
    'intermediateProfileCategory',
    'intermediateHealthLabels',
    'studyTypes',
    'koOriginDatabases',
    'intermediateLabs',
    'relationshipType',
    'articlesCount',
    'coOccurrences',
    'journals',
    'associationOrigins',
    'intermediateMolecules',
    'moleculeWeight',
    'categories',
    'subcategories',
    'intermediateTags',
    'weightScoreSum',
    'patentsCount',
    'proteinOrigins',
];
export const CLOSED_DISCOVERY_ALTERNATIVE_DETAILS_COLUMNS_ORDER: string[] = [
    'feedback',
    'feedback-spacing',
    'name',
    'cowMilkConcentration',
    'concentrationE0',
    'concentrationE1',
    'profileCategory',
    'healthLabels',
    'studyTypes',
    'koOriginDatabases',
    'labs',
    'relationshipType',
    'articlesCount',
    'weight',
    'journals',
    'associationOrigins',
    'molecules',
    'moleculeWeight',
    'categories',
    'subcategories',
    'tags',
    'score',
    'patentsCount',
    'proteinOrigins',
];

export const MOLECULE_WEIGHT_UNIT: string = 'g/mol';

export const COW_MILK_NAME: string = 'Cow Milk';

export const INVALID_DISCOVERY_TEXT: string = 'Invalid discovery.';

export const COMPOUND_CATEGORY: string = 'Chemicals/Compounds';
export const FOOD_CATEGORY: string = 'Food';

export const CONCENTRATION_COLUMN_NAME: string = 'Concentration in %s';
