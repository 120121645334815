/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as reportActions from './compound-report.actions';
import { CompoundReportState } from './compound-report-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import TermsSentences from './interfaces/terms-sentences.interface';

export const initialState: CompoundReportState = {
    errors: [],
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    effectSentences: null,
    healthLabels: null,
    healthLabelsLoading: false,
    healthLabelsLoaded: false,
    healthLabelSentences: null,
    studyTypes: null,
    studyTypesLoading: false,
    studyTypesLoaded: false,
    studyType: null,
    total: null,
    blob: null,
};

const compoundReportReducer: ActionReducer<CompoundReportState, Action> = createReducer(
    initialState,
    on(reportActions.getOverviewRequest, (state: CompoundReportState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
        total: null as number,
    })),
    on(
        reportActions.getOverviewSuccess,
        (
            state: CompoundReportState,
            { overview, total }: { overview: Overview; total: number },
        ) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
            total,
        }),
    ),
    on(
        reportActions.getOverviewFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(reportActions.getEffectSentencesRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getEffectSentencesSuccess,
        (state: CompoundReportState, { effectSentences }: { effectSentences: TermsSentences }) => ({
            ...state,
            effectSentences,
        }),
    ),
    on(
        reportActions.getEffectSentencesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.getHealthLabelsRequest, (state: CompoundReportState) => ({
        ...state,
        healthLabelsLoading: true,
        healthLabelsLoaded: false,
    })),
    on(
        reportActions.getHealthLabelsSuccess,
        (state: CompoundReportState, { healthLabels }: { healthLabels: HealthLabel[] }) => ({
            ...state,
            healthLabels,
            healthLabelsLoading: false,
            healthLabelsLoaded: true,
        }),
    ),
    on(
        reportActions.getHealthLabelsFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            healthLabelsLoading: false,
            healthLabelsLoaded: false,
        }),
    ),
    on(reportActions.getHealthLabelSentencesRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getHealthLabelSentencesSuccess,
        (state: CompoundReportState, { sentences }: { sentences: TermsSentences }) => ({
            ...state,
            healthLabelSentences: sentences,
        }),
    ),
    on(
        reportActions.getHealthLabelSentencesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(reportActions.getStudyTypesRequest, (state: CompoundReportState) => ({
        ...state,
        studyTypesLoading: true,
        studyTypesLoaded: false,
    })),
    on(
        reportActions.getStudyTypesSuccess,
        (state: CompoundReportState, { studyTypes }: { studyTypes: StudyTypes }) => ({
            ...state,
            studyTypes,
            studyTypesLoading: false,
            studyTypesLoaded: true,
        }),
    ),
    on(
        reportActions.getStudyTypesFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            studyTypesLoading: false,
            studyTypesLoaded: false,
        }),
    ),
    on(reportActions.getStudyTypeRequest, (state: CompoundReportState) => ({
        ...state,
    })),
    on(
        reportActions.getStudyTypeSuccess,
        (state: CompoundReportState, { studyType }: { studyType: StudyType }) => ({
            ...state,
            studyType,
        }),
    ),
    on(
        reportActions.getStudyTypeFailure,
        (state: CompoundReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(
        reportActions.downloadRequest,
        (state: CompoundReportState): CompoundReportState => ({
            ...state,
            blob: null,
        }),
    ),
    on(
        reportActions.downloadSuccess,
        (state: CompoundReportState, { blob }: { blob: Blob }): CompoundReportState => ({
            ...state,
            blob,
        }),
    ),
    on(
        reportActions.downloadFailure,
        (
            state: CompoundReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ): CompoundReportState => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            blob: null,
        }),
    ),
    on(reportActions.resetReport, () => initialState),
    on(reportActions.clearNextError, (state: CompoundReportState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: CompoundReportState | undefined,
    action: Action,
): CompoundReportState => compoundReportReducer(state, action);

// selectors
export const getErrors: (state: CompoundReportState) => ErrorResponse[] = (
    state: CompoundReportState,
) => state.errors;
export const getOverview: (state: CompoundReportState) => Overview = (state: CompoundReportState) =>
    state.overview;
export const getOverviewLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.overviewLoaded;
export const getEffectSentences: (state: CompoundReportState) => TermsSentences = (
    state: CompoundReportState,
) => state.effectSentences;
export const getHealthLabels: (state: CompoundReportState) => HealthLabel[] = (
    state: CompoundReportState,
) => state.healthLabels;
export const getHealthLabelsLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.healthLabelsLoading;
export const getHealthLabelsLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.healthLabelsLoaded;
export const getHealthLabelSentences: (state: CompoundReportState) => TermsSentences = (
    state: CompoundReportState,
) => state.healthLabelSentences;
export const getStudyTypes: (state: CompoundReportState) => StudyTypes = (
    state: CompoundReportState,
) => state.studyTypes;
export const getStudyTypesLoading: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.studyTypesLoading;
export const getStudyTypesLoaded: (state: CompoundReportState) => boolean = (
    state: CompoundReportState,
) => state.studyTypesLoaded;
export const getStudyType: (state: CompoundReportState) => StudyType = (
    state: CompoundReportState,
) => state.studyType;
export const getTotal: (state: CompoundReportState) => number = (state: CompoundReportState) =>
    state.total;
export const getBlob: (state: CompoundReportState) => Blob = (state: CompoundReportState) =>
    state.blob;
