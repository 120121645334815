/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { ProjectsState } from './projects-state.interface';
import {
    updateSearchFilter,
    updateStatusFilters,
    updateTypeFilters,
    updateSortDirection,
    updateSortColumn,
} from './projects.actions';
import {
    getSearchFilter,
    getStatusFilters,
    getTypeFilters,
    getSortDirection,
    getSortColumn,
} from './projects.selectors';
import StatusFilterOption from './interfaces/status-filter-option.interface';
import TypeFilterOption from './interfaces/type-filter-option.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class ProjectsFacade {
    searchFilter$: Observable<string> = this.store.pipe(select(getSearchFilter));
    statusFilters$: Observable<StatusFilterOption[]> = this.store.pipe(select(getStatusFilters));
    typeFilters$: Observable<TypeFilterOption[]> = this.store.pipe(select(getTypeFilters));
    sortDirection$: Observable<SortingOrder> = this.store.pipe(select(getSortDirection));
    sortColumn$: Observable<string> = this.store.pipe(select(getSortColumn));

    constructor(private store: Store<ProjectsState>) {}

    updateSearchFilter(filter: string): void {
        this.store.dispatch(updateSearchFilter({ filter }));
    }

    updateStatusFilters(filters: StatusFilterOption[]): void {
        this.store.dispatch(updateStatusFilters({ filters }));
    }

    updateTypeFilters(filters: TypeFilterOption[]): void {
        this.store.dispatch(updateTypeFilters({ filters }));
    }

    updateSortDirection(sortDirection: SortingOrder): void {
        this.store.dispatch(updateSortDirection({ sortDirection }));
    }

    updateSortColumn(sortColumn: string): void {
        this.store.dispatch(updateSortColumn({ sortColumn }));
    }
}
