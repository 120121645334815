import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromConcentrations from './concentrations.reducer';

export const getConcentrationsState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.concentrations,
);

export const getConcentrations = createSelector(
    getConcentrationsState,
    fromConcentrations.getEntities,
);
export const getErrors = createSelector(getConcentrationsState, fromConcentrations.getErrors);
export const getLoading = createSelector(getConcentrationsState, fromConcentrations.getLoading);
export const getLoaded = createSelector(getConcentrationsState, fromConcentrations.getLoaded);
export const getAreNotDetected = createSelector(
    getConcentrationsState,
    fromConcentrations.getAreNotDetected,
);
export const getConcentrationDetails = createSelector(
    getConcentrationsState,
    fromConcentrations.getConcentrationDetails,
);
export const getConcentrationDetailsLoading = createSelector(
    getConcentrationsState,
    fromConcentrations.getConcentrationDetailsLoading,
);
export const getConcentrationDetailsLoaded = createSelector(
    getConcentrationsState,
    fromConcentrations.getConcentrationDetailsLoaded,
);
