import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from '..';
import { UsersEffects } from './users.effects';
import { UsersService } from './services/users.service';
import { UsersParser } from './parsers/users.parser';
import { UsersFacade } from './users.facade';
import { HttpParamsService } from '@leap-common/services/http-params.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([UsersEffects]),
    ],
    declarations: [],
    providers: [UsersService, UsersParser, UsersFacade, HttpParamsService],
})
export class UsersStoreModule {}
