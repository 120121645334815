import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

/** Constants */
import {
    CONVERSATIONAL_SEARCH_PATH,
    DISCOVERY_PATH,
    LAYOUT_LANDING_PATH,
} from '../constants/menu-items';

/** Services */
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';

/** Interfaces */
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';

@Injectable()
export class RedirectService {
    constructor(
        private router: Router,
        private featureFlagsService: FeatureFlagsService,
        private currentUserService: CurrentUserService,
    ) {}

    redirectToLanding(): void {
        const isLayoutLandingEnabled = this.featureFlagsService.isFeatureEnabled('layoutLanding');
        const isConversationalSearchEnabled =
            this.featureFlagsService.isFeatureEnabled('conversationalSearch');

        this.router.navigate([
            isLayoutLandingEnabled
                ? `/${LAYOUT_LANDING_PATH}`
                : isConversationalSearchEnabled
                ? `/${CONVERSATIONAL_SEARCH_PATH}`
                : `/${DISCOVERY_PATH}`,
        ]);
    }

    redirectToTermsOfUse(): void {
        this.router.navigate(['/legal/terms-of-service'], {
            queryParams: { shouldAcceptTerms: true },
        });
    }

    redirectBasedOnTermsOfUseAcceptance(): void {
        const currentUser: CurrentUser = this.currentUserService.getUserIdentity();

        const areTermsOfUseAccepted: boolean = currentUser.areTermsOfUseAccepted;

        if (areTermsOfUseAccepted) {
            this.redirectToLanding();
        } else {
            this.redirectToTermsOfUse();
        }
    }
}
