import { Component, Input } from '@angular/core';
import Header from '@leap-common/interfaces/header.interface';

@Component({
    selector: 'lib-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
})
export class HeaderComponent {
    @Input() header: Header;

    constructor() {}
}
