/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromContentWriter from './content-writer.reducer';

export const getContentWriterState = createSelector(
    getDataState,
    (state: DataState) => state.contentWriter,
);

export const getErrors = createSelector(getContentWriterState, fromContentWriter.getErrors);
export const getTopics = createSelector(getContentWriterState, fromContentWriter.getTopics);
export const getTopicsLoading = createSelector(
    getContentWriterState,
    fromContentWriter.getTopicsLoading,
);
export const getTopicsLoaded = createSelector(
    getContentWriterState,
    fromContentWriter.getTopicsLoaded,
);
export const getLongContent = createSelector(
    getContentWriterState,
    fromContentWriter.getLongContent,
);
export const getLongContentLoading = createSelector(
    getContentWriterState,
    fromContentWriter.getLongContentLoading,
);
export const getLongContentLoaded = createSelector(
    getContentWriterState,
    fromContentWriter.getLongContentLoaded,
);
export const getShortContent = createSelector(
    getContentWriterState,
    fromContentWriter.getShortContent,
);
export const getShortContentLoading = createSelector(
    getContentWriterState,
    fromContentWriter.getShortContentLoading,
);
export const getShortContentLoaded = createSelector(
    getContentWriterState,
    fromContentWriter.getShortContentLoaded,
);
export const getContentBlob = createSelector(
    getContentWriterState,
    fromContentWriter.getContentBlob,
);
