import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { IngredientComparisonEffects } from './ingredient-comparison.effects';
import { IngredientComparisonFacade } from './ingredient-comparison.facade';
import { IngredientComparisonParser } from './parsers/ingredient-comparison.parser';
import { IngredientComparisonService } from './services/ingredient-comparison.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([IngredientComparisonEffects]),
    ],
    declarations: [],
    providers: [
        IngredientComparisonParser,
        IngredientComparisonService,
        IngredientComparisonFacade,
    ],
})
export class IngredientComparisonStoreModule {}
