/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ProjectsParser } from '../parsers/projects.parser';
import { BookmarksParser } from '../../bookmarks/parsers/bookmarks.parser';
import { HttpParamsService } from '@leap-common/services/http-params.service';

/** Interfaces - Types - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ProjectRestApi from '../rest-api-interfaces/project.rest.interface';
import Project from '../interfaces/project.interface';
import ProjectPropertiesRestApi from '../rest-api-interfaces/project-properties.rest.interface';
import ProjectProperties from '../interfaces/project-properties.interface';
import BookmarkConfiguration from '../../bookmarks/interfaces/configuration.interface';
import BookmarkConfigurationRestApi from '../../bookmarks/rest-api-types/configuration.rest.type';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

@Injectable()
export class ProjectsService {
    constructor(
        private http: HttpClient,
        private projectsParser: ProjectsParser,
        private bookmarksParser: BookmarksParser,
        private httpParamsService: HttpParamsService,
    ) {}

    /**
     * Gets the projects, parses them into the desired format and
     * returns an Observable of PaginatedResults<Project>.
     */
    getProjects(
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
        typeFilter: string,
        statusFilter: string[],
        searchFilter: string,
    ): Observable<{
        paginatedProjects: PaginatedResults<Project>;
    }> {
        const serializedOrder: string = this.projectsParser.serializeProjectsOrderByParameter(
            sortDirection,
            sortColumn,
        );
        const serializedStatusFilter: string =
            this.projectsParser.serializeStatusFilterParameter(statusFilter);

        return this.http
            .get(`${environment.projectsServerUrl}/projects/`, {
                params: {
                    pageIndex: (pageIndex + 1).toString(),
                    pageSize: pageSize.toString(),
                    orderBy: serializedOrder,
                    filter: typeFilter,
                    status: serializedStatusFilter,
                    query: searchFilter,
                },
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<ProjectRestApi>) => ({
                    paginatedProjects: this.projectsParser.parsePaginatedResults(paginatedResults),
                })),
            );
    }

    /**
     * Gets a specific project, parses it into the desired format and
     * returns an Observable of Project.
     */
    getProject(id: string): Observable<Project> {
        return this.http
            .get(`${environment.projectsServerUrl}/projects/${id}/`)
            .pipe(map((project: ProjectRestApi) => this.projectsParser.parseProject(project)));
    }

    /**
     * Gets the projects that contain all of the provided bookmarks, parses them into the desired
     * format and returns an Observable of Project[].
     */
    getProjectsWithBookmarks(configurations: BookmarkConfiguration[]): Observable<{
        paginatedProjects: Project[];
    }> {
        const serializedBookmarkType: string = this.bookmarksParser.serializeBookmarkType(
            configurations[0].type,
        );
        const serializedConfigurations: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfigurations(configurations);

        return this.http
            .post(`${environment.projectsServerUrl}/projects-with-bookmark/`, {
                bookmark_type: serializedBookmarkType,
                bookmark_configuration: serializedConfigurations,
            })
            .pipe(
                map((projects: ResultsRestApi<ProjectRestApi>) => ({
                    paginatedProjects: this.projectsParser.parseProjects(projects.results),
                })),
            );
    }

    /**
     * Gets the projects that do not contain at least one of the provided bookmarks, parses them
     * into the desired format and returns an Observable of PaginatedResults<Project>.
     */
    getProjectsWithoutBookmarks(
        configurations: BookmarkConfiguration[],
        search: string,
        pageIndex: number,
        pageSize: number,
    ): Observable<{
        paginatedProjects: PaginatedResults<Project>;
    }> {
        const params: HttpParams = this.httpParamsService.createHttpParams({
            query: search,
            pageIndex: (pageIndex + 1).toString(),
            pageSize: pageSize.toString(),
        });
        const serializedBookmarkType: string = this.bookmarksParser.serializeBookmarkType(
            configurations[0].type,
        );
        const serializedConfiguration: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfigurations(configurations);

        return this.http
            .post(
                `${environment.projectsServerUrl}/projects-without-bookmark/`,
                {
                    bookmark_type: serializedBookmarkType,
                    bookmark_configuration: serializedConfiguration,
                },
                { params },
            )
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<ProjectRestApi>) => ({
                    paginatedProjects: this.projectsParser.parsePaginatedResults(paginatedResults),
                })),
            );
    }

    /**
     * Creates a new project, maps the response to the desired format and returns an
     * Observable of Project.
     */
    createProject(name: string): Observable<Project> {
        return this.http
            .post(`${environment.projectsServerUrl}/projects/`, { name })
            .pipe(map((project: ProjectRestApi) => this.projectsParser.parseProject(project)));
    }

    /**
     * Sends the project id to the server in order to clone it and maps the response to
     * the desired format. It returns an Observable of Project and the name of the
     * original project which is needed to create the success alert.
     */
    cloneProject(
        id: string,
        originalProjectName: string,
    ): Observable<{ project: Project; originalProjectName: string }> {
        return this.http
            .post(`${environment.projectsServerUrl}/projects/duplicate/`, {
                copy_from: id,
            })
            .pipe(
                map((project: ProjectRestApi) => ({
                    project: this.projectsParser.parseProject(project),
                    originalProjectName,
                })),
            );
    }

    /**
     * Sends the project id and the project properties to be updated to the server in
     * order to update an existing project and maps the response to the desired format.
     * It returns an Observable of Project.
     */
    updateProject(id: string, project: ProjectProperties): Observable<Project> {
        const serializedProject: ProjectPropertiesRestApi =
            this.projectsParser.serializeProjectProperties(project);

        return this.http
            .patch(`${environment.projectsServerUrl}/projects/${id}/`, serializedProject)
            .pipe(
                map((responseProject: ProjectRestApi) =>
                    this.projectsParser.parseProject(responseProject),
                ),
            );
    }

    /**
     * Calls the addFavoriteProject() or the removeFavoriteProject() depending on the
     * favorite parameter in order to add or remove an existing project from favorites.
     * It returns an Observable of Project.
     */
    toggleFavoriteProject(id: string, favorite: boolean): Observable<Project> {
        return favorite ? this.addFavoriteProject(id) : this.removeFavoriteProject(id);
    }

    /**
     * Sends the project id to server in order to add an existing project to favorites
     * and maps the response to the desired format. It returns an Observable of Project.
     */
    addFavoriteProject(id: string): Observable<Project> {
        return this.http
            .post(`${environment.projectsServerUrl}/projects/${id}/favourite/`, {})
            .pipe(map((project: ProjectRestApi) => this.projectsParser.parseProject(project)));
    }

    /**
     * Sends the project id to server in order to remove an existing project from
     * favorites and maps the response to the desired format. It returns an Observable
     * of Project.
     */
    removeFavoriteProject(id: string): Observable<Project> {
        return this.http
            .delete(`${environment.projectsServerUrl}/projects/${id}/favourite/`)
            .pipe(map((project: ProjectRestApi) => this.projectsParser.parseProject(project)));
    }

    /**
     * Makes a call with the project id to server in order to delete the specific project and
     * maps the response to the desired format. It returns an Observable of void.
     */
    deleteProject(id: string): Observable<void> {
        return this.http
            .delete(`${environment.projectsServerUrl}/projects/${id}/`)
            .pipe(map(() => undefined));
    }

    downloadProject(id: string, preferences: UserPreferences): Observable<Blob> {
        return this.http.post(
            `${environment.projectsServerUrl}/projects/${id}/download/`,
            {
                preferences,
            },
            {
                headers: new HttpHeaders({
                    Accept: 'text/csv',
                }),
                responseType: 'blob',
            },
        );
    }
}
