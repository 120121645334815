import { createAction, props } from '@ngrx/store';
import { ProfilerActionTypes } from './profiler-action-types.enum';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';

export const updateActiveTabId = createAction(
    ProfilerActionTypes.UPDATE_ACTIVE_TAB_ID,
    props<{ id: TabId }>(),
);

export const reset = createAction(ProfilerActionTypes.RESET);
