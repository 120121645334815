import { Pipe, PipeTransform } from '@angular/core';
import { BLACK_CIRCLE_DELIMITER } from '@leap-common/constants/delimiters';

@Pipe({
    name: 'join',
})
export class JoinPipe implements PipeTransform {
    constructor() {}

    transform(array: string[], delimiter: string = BLACK_CIRCLE_DELIMITER): string {
        if (!array) {
            return;
        }

        return array.join(delimiter);
    }
}
