export enum ArticlesActionTypes {
    PERFORM_QUERY_REQUEST = '[ARTICLES] Perform Query Request',
    PERFORM_QUERY_SUCCESS = '[ARTICLES] Perform Query Success',
    PERFORM_QUERY_FAILURE = '[ARTICLES] Perform Query Failure',
    DOWNLOAD_QUERY_ARTICLES_REQUEST = '[ARTICLES] Download Query Articles Request',
    DOWNLOAD_QUERY_ARTICLES_SUCCESS = '[ARTICLES] Download Query Articles Success',
    DOWNLOAD_QUERY_ARTICLES_FAILURE = '[ARTICLES] Download Query Articles Failure',
    GET_ARTICLES_INFO_REQUEST = '[ARTICLES] Get Articles Info Request',
    GET_ARTICLES_INFO_SUCCESS = '[ARTICLES] Get Articles Info Success',
    GET_ARTICLES_INFO_FAILURE = '[ARTICLES] Get Articles Info Failure',
    CLEAR_ARTICLES_INFO = '[ARTICLES] Clear Articles Info',
    SELECT_ARTICLE_INFO = '[ARTICLES] Select Article Info',
    CLEAR_SELECTED_ARTICLE_INFO = '[ARTICLES] Clear Selected Article Info',
    GET_ARTICLES_REQUEST = '[ARTICLES] Get Articles Request',
    GET_ARTICLES_SUCCESS = '[ARTICLES] Get Articles Success',
    GET_ARTICLES_FAILURE = '[ARTICLES] Get Articles Failure',
    DOWNLOAD_ARTICLES_REQUEST = '[ARTICLES] Download Articles Request',
    DOWNLOAD_ARTICLES_SUCCESS = '[ARTICLES] Download Articles Success',
    DOWNLOAD_ARTICLES_FAILURE = '[ARTICLES] Download Articles Failure',
    GET_TERM_ARTICLES_REQUEST = '[ARTICLES] Get Term Articles Request',
    GET_TERM_ARTICLES_SUCCESS = '[ARTICLES] Get Term Articles Success',
    GET_TERM_ARTICLES_FAILURE = '[ARTICLES] Get Term Articles Failure',
    DOWNLOAD_TERM_ARTICLES_REQUEST = '[ARTICLES] Download Term Articles Request',
    DOWNLOAD_TERM_ARTICLES_SUCCESS = '[ARTICLES] Download Term Articles Success',
    DOWNLOAD_TERM_ARTICLES_FAILURE = '[ARTICLES] Download Term Articles Failure',
    GET_ARTICLE_REQUEST = '[ARTICLES] Get Article Request',
    GET_ARTICLE_SUCCESS = '[ARTICLES] Get Article Success',
    GET_ARTICLE_FAILURE = '[ARTICLES] Get Article Failure',
    DOWNLOAD_FULL_ARTICLE_REQUEST = '[ARTICLES] Download Full Article Request',
    DOWNLOAD_FULL_ARTICLE_SUCCESS = '[ARTICLES] Download Full Article Success',
    DOWNLOAD_FULL_ARTICLE_FAILURE = '[ARTICLES] Download Full Article Failure',
    DOWNLOAD_SELECTED_ARTICLES_REQUEST = '[ARTICLES] Download Selected Articles Request',
    DOWNLOAD_SELECTED_ARTICLES_SUCCESS = '[ARTICLES] Download Selected Articles Success',
    DOWNLOAD_SELECTED_ARTICLES_FAILURE = '[ARTICLES] Download Selected Articles Failure',
    GET_BIBTEX_REQUEST = '[ARTICLES] Get BibTex Request',
    GET_BIBTEX_SUCCESS = '[ARTICLES] Get BibTex Success',
    GET_BIBTEX_FAILURE = '[ARTICLES] Get BibTex Failure',
    DOWNLOAD_BIBTEX_REQUEST = '[ARTICLES] Download BibTex Request',
    DOWNLOAD_BIBTEX_SUCCESS = '[ARTICLES] Download BibTex Success',
    DOWNLOAD_BIBTEX_FAILURE = '[ARTICLES] Download BibTex Failure',
    CLEAR_TERM_ARTICLES = '[ARTICLES] Clear Term Articles',
    CLEAR_DATE_RANGE = '[ARTICLES] Clear Date Range',
    CLEAR_COUNTS = '[ARTICLES] Clear Counts',
    CLEAR_NEXT_ERROR = '[ARTICLES] Clear Next Error',
}
