import { Injectable } from '@angular/core';

/** Interfaces */
import Tag from '../interfaces/tag.interface';

@Injectable()
export class TagsParser {
    constructor() {}

    /** Parses string[] to Tag[] */
    parseTags(tags: string[]): Tag[] {
        return tags.map((tag: string) => this.parseTag(tag));
    }

    /** Parses string to Tag */
    parseTag(tag: string): Tag {
        return {
            name: tag,
        };
    }

    /** Serializes tags */
    serializeTags(tags: Tag[]): string[] {
        return tags.map((tag: Tag) => this.serializeTag(tag));
    }

    /** Serializes tag */
    serializeTag(tag: Tag): string {
        return tag.name;
    }
}
