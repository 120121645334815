import { Component, Input, TemplateRef } from '@angular/core';

/** Constants */
import { BULLET_DELIMITER } from '@leap-common/constants/delimiters';

@Component({
    selector: 'lib-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
    @Input() currentYear: number;
    @Input() isCopyrightDisplayed: boolean = true;
    @Input() isConfidentialDisplayed: boolean = false;
    @Input() customContentLeft: TemplateRef<unknown>;
    @Input() customContentRight: TemplateRef<unknown>;

    readonly bulletDelimiter: string = BULLET_DELIMITER;

    constructor() {}
}
