import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
})
export class IncludesPipe implements PipeTransform {
    constructor() {}

    transform<T = string | number>(array: T[], value: T): boolean {
        return Boolean(array?.includes(value));
    }
}
