/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromIngredientManagement from './ingredient-management.reducer';

export const getIngredientManagementState = createSelector(
    getDataState,
    (state: DataState) => state.ingredientManagement,
);

export const getSuccess = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getSuccess,
);
export const getErrors = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getErrors,
);
export const getFileUploadResult = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getFileUploadResult,
);
export const getIngredients = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getEntities,
);
export const getLoading = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getLoading,
);
export const getLoaded = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getLoaded,
);
export const getTotal = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getTotal,
);
export const getShouldFetchIngredients = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getShouldFetchIngredients,
);
export const getIngredientBlob = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getIngredientBlob,
);
export const getTemplateBlob = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getTemplateBlob,
);
export const getInstructionsBlob = createSelector(
    getIngredientManagementState,
    fromIngredientManagement.getInstructionsBlob,
);
