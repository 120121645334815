import { ActionReducer, combineReducers } from '@ngrx/store';
import { DiscoveryState } from './discovery-state.interface';

/** reducers */
import * as fromOpen from './open/open-discovery.reducer';
import * as fromClosed from './closed/closed-discovery.reducer';
import * as fromCombinatorial from './combinatorial/combinatorial-discovery.reducer';
import * as fromCategories from './categories/categories.reducer';
import * as fromSuggestions from './suggestions/suggestions.reducer';
import * as fromSynonyms from './synonyms/synonyms.reducer';
import * as fromDefinitions from './definitions/definitions.reducer';
import * as fromTerms from './terms/terms.reducer';
import * as fromDatabases from './databases/databases.reducer';
import * as fromConcentrations from './concentrations/concentrations.reducer';
import * as fromPatents from './patents/patents.reducer';

export const reducers: ActionReducer<DiscoveryState> = combineReducers({
    open: fromOpen.reducer,
    closed: fromClosed.reducer,
    combinatorial: fromCombinatorial.reducer,
    categories: fromCategories.reducer,
    suggestions: fromSuggestions.reducer,
    synonyms: fromSynonyms.reducer,
    definitions: fromDefinitions.reducer,
    terms: fromTerms.reducer,
    databases: fromDatabases.reducer,
    concentrations: fromConcentrations.reducer,
    patents: fromPatents.reducer,
});
