import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ClosedDiscoveryActionTypes } from './closed-discovery-action-types.enum';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

export const performDiscoveryRequest = createAction(
    ClosedDiscoveryActionTypes.PERFORM_DISCOVERY_REQUEST,
    props<{
        source: string;
        target: string;
        preferences: UserPreferences;
        filters: ExecutionFilters;
        pageSize: number;
        pageIndex: number;
    }>(),
);

export const performDiscoverySuccess = createAction(
    ClosedDiscoveryActionTypes.PERFORM_DISCOVERY_SUCCESS,
    props<{ paginatedInsights: PaginatedInsights }>(),
);

export const performDiscoveryFailure = createAction(
    ClosedDiscoveryActionTypes.PERFORM_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadDiscoveryRequest = createAction(
    ClosedDiscoveryActionTypes.DOWNLOAD_DISCOVERY_REQUEST,
    props<{
        source: string;
        target: string;
        preferences: UserPreferences;
        filters?: ExecutionFilters;
    }>(),
);

export const downloadDiscoverySuccess = createAction(
    ClosedDiscoveryActionTypes.DOWNLOAD_DISCOVERY_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadDiscoveryFailure = createAction(
    ClosedDiscoveryActionTypes.DOWNLOAD_DISCOVERY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getSearchSuggestionsRequest = createAction(
    ClosedDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_REQUEST,
    props<{
        source: string;
        target: string;
        query: string;
    }>(),
);

export const getSearchSuggestionsSuccess = createAction(
    ClosedDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_SUCCESS,
    props<{ suggestionIds: string[] }>(),
);

export const getSearchSuggestionsFailure = createAction(
    ClosedDiscoveryActionTypes.GET_SEARCH_SUGGESTIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearSearchSuggestions = createAction(
    ClosedDiscoveryActionTypes.CLEAR_SEARCH_SUGGESTIONS,
);

export const resetDiscovery = createAction(ClosedDiscoveryActionTypes.RESET_DISCOVERY);

export const clearNextError = createAction(ClosedDiscoveryActionTypes.CLEAR_NEXT_ERROR);

export const saveSessionId = createAction(
    ClosedDiscoveryActionTypes.SAVE_SESSION_ID,
    props<{ id: string }>(),
);
