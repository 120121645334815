/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './synonyms.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { SynonymsState } from './synonyms-state.interface';
import Synonyms from './types/synonyms.type';

export const initialState: SynonymsState = {
    synonyms: null,
    errors: [],
    loading: false,
    loaded: false,
};

const synonymsReducer: ActionReducer<SynonymsState, Action> = createReducer(
    initialState,
    on(actions.getSynonymsRequest, (state: SynonymsState) => ({
        ...state,
        synonyms: null as Synonyms,
        loading: true,
        loaded: false,
    })),
    on(
        actions.getSynonymsSuccess,
        (state: SynonymsState, { synonyms }: { synonyms: Synonyms }) => ({
            ...state,
            synonyms,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        actions.getSynonymsFailure,
        (state: SynonymsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearSynonyms, (state: SynonymsState) => ({
        ...state,
        synonyms: null as Synonyms,
        loading: false,
        loaded: false,
        errors: [] as ErrorResponse[],
    })),
    on(actions.clearNextError, (state: SynonymsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: SynonymsState | undefined, action: Action): SynonymsState =>
    synonymsReducer(state, action);

// selectors
export const getSynonyms: (state: SynonymsState) => Synonyms = (state: SynonymsState) =>
    state.synonyms;
export const getErrors: (state: SynonymsState) => ErrorResponse[] = (state: SynonymsState) =>
    state.errors;
export const getLoading: (state: SynonymsState) => boolean = (state: SynonymsState) =>
    state.loading;
export const getLoaded: (state: SynonymsState) => boolean = (state: SynonymsState) => state.loaded;
