/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import {
    getPipelineExecutionsRequest,
    clearNextError,
    clearDownloadableResult,
    getPipelineResultRequest,
} from './pipeline-executions.actions';
import {
    getPipelineExecutions,
    getDownloadableResult,
    getError,
    getLoading,
    getLoaded,
    getPageIndex,
    getPageSize,
    getTotal,
    getSortColumn,
    getSortDirection,
} from './pipeline-executions.selectors';
import PipelineExecution from './interfaces/pipeline-execution.interface';
import { PipelineExecutionsState } from './pipeline-executions-state.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class PipelineExecutionsFacade {
    pipelineExecutions$: Observable<PipelineExecution[]> = this.store.pipe(
        select(getPipelineExecutions),
    );
    downloadableResult$: Observable<File> = this.store.pipe(select(getDownloadableResult));
    error$: Observable<ErrorResponse[]> = this.store.pipe(select(getError));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    sortDirection$: Observable<SortingOrder> = this.store.pipe(select(getSortDirection));
    sortColumn$: Observable<string> = this.store.pipe(select(getSortColumn));

    constructor(private store: Store<PipelineExecutionsState>) {}

    getPipelineExecutions(
        pageIndex: number,
        pageSize: number,
        sortDirection?: SortingOrder,
        sortColumn?: string,
    ): void {
        this.store.dispatch(
            getPipelineExecutionsRequest({
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
            }),
        );
    }

    getPipelineResult(id: string): void {
        this.store.dispatch(getPipelineResultRequest({ id }));
    }

    clearDownloadableResult(): void {
        this.store.dispatch(clearDownloadableResult());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
