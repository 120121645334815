/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { PathsService } from './services/paths.service';
import * as pathsActions from './paths.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import ExplorationPath from './interfaces/exploration-path.interface';
import MetapathBaseExplorationPath from '../metapaths/interfaces/metapath-base-exploration-path.interface';

@Injectable()
export class PathsEffects {
    constructor(private actions$: Actions, private pathsService: PathsService) {}

    getPaths$ = createEffect(() =>
        this.actions$.pipe(
            ofType(pathsActions.getPathsRequest),
            mergeMap(
                ({
                    parentInsightId,
                    parentMetapathIndex,
                    sourceId,
                    targetId,
                    metapathBaseExplorationPath,
                    pageSize,
                    pageIndex,
                    sortingOrder,
                }: {
                    parentInsightId: string;
                    parentMetapathIndex: number;
                    sourceId: string;
                    targetId: string;
                    metapathBaseExplorationPath: MetapathBaseExplorationPath;
                    pageSize: number;
                    pageIndex: number;
                    sortingOrder: SortingOrder;
                }) =>
                    this.pathsService
                        .getPaths(
                            sourceId,
                            targetId,
                            metapathBaseExplorationPath,
                            pageSize,
                            pageIndex,
                            sortingOrder,
                        )
                        .pipe(
                            map((paginatedPaths: PaginatedResults<ExplorationPath>) =>
                                pathsActions.getPathsSuccess({
                                    parentInsightId,
                                    parentMetapathIndex,
                                    paginatedPaths,
                                    sortingOrder,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(pathsActions.getPathsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadPaths$ = createEffect(() =>
        this.actions$.pipe(
            ofType(pathsActions.downloadPathsRequest),
            mergeMap(
                ({
                    filename,
                    sourceId,
                    targetId,
                    metapathBaseExplorationPath,
                    sortingOrder,
                }: {
                    filename: string;
                    sourceId: string;
                    targetId: string;
                    metapathBaseExplorationPath: MetapathBaseExplorationPath;
                    sortingOrder: SortingOrder;
                }) =>
                    this.pathsService
                        .downloadPaths(
                            sourceId,
                            targetId,
                            metapathBaseExplorationPath,
                            sortingOrder,
                        )
                        .pipe(
                            map((blob: Blob) =>
                                pathsActions.downloadPathsSuccess({ blob, filename }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(pathsActions.downloadPathsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
