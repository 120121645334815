/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { PatentsEffects } from './patents.effects';
import { PatentsParser } from './parsers/patents.parser';
import { PatentsService } from './services/patents.service';
import { PatentsFacade } from './patents.facade';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([PatentsEffects]),
    ],
    declarations: [],
    providers: [PatentsParser, PatentsService, PatentsFacade],
})
export class PatentsStoreModule {}
