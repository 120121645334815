import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromTerms from './terms.reducer';

export const getArticlesState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.terms,
);

export const getOverview = createSelector(getArticlesState, fromTerms.getOverview);
export const getErrors = createSelector(getArticlesState, fromTerms.getErrors);
export const getLoading = createSelector(getArticlesState, fromTerms.getLoading);
export const getLoaded = createSelector(getArticlesState, fromTerms.getLoaded);
