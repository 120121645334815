/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as reportActions from './report.actions';
import { ReportState } from './report-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Statistics from './interfaces/statistics.interface';
import TopResults from './types/top-results.type';
import ReferenceSummarizationPerCategory from './interfaces/reference-summarization-per-category.interface';
import Reference from './interfaces/reference.interface';
import ReportSectionId from './enums/report-section-id.enum';
import Background from './interfaces/background.interface';

export const initialState: ReportState = {
    errors: [],
    introduction: null,
    introductionLoading: false,
    introductionLoaded: false,
    background: null,
    backgroundLoading: false,
    backgroundLoaded: false,
    statistics: null,
    statisticsLoading: false,
    statisticsLoaded: false,
    topResults: null,
    topResultsLoading: false,
    topResultsLoaded: false,
    referencesSummarization: [],
    referencesSummarizationLoading: false,
    referencesSummarizationLoaded: false,
    references: [],
    referencesLoading: false,
    referencesLoaded: false,
    blob: null,
};

const gptReducer: ActionReducer<ReportState, Action> = createReducer(
    initialState,
    on(reportActions.getIntroductionRequest, (state: ReportState) => ({
        ...state,
        introductionLoading: true,
        introductionLoaded: false,
    })),
    on(
        reportActions.getIntroductionSuccess,
        (state: ReportState, { introduction }: { introduction: string }) => ({
            ...state,
            introduction,
            introductionLoading: false,
            introductionLoaded: true,
        }),
    ),
    on(
        reportActions.getIntroductionFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            introductionLoading: false,
            introductionLoaded: false,
        }),
    ),
    on(reportActions.getBackgroundRequest, (state: ReportState) => ({
        ...state,
        backgroundLoading: true,
        backgroundLoaded: false,
    })),
    on(
        reportActions.getBackgroundSuccess,
        (state: ReportState, { background }: { background: Background }) => ({
            ...state,
            background,
            backgroundLoading: false,
            backgroundLoaded: true,
        }),
    ),
    on(
        reportActions.getBackgroundFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            backgroundLoading: false,
            backgroundLoaded: false,
        }),
    ),
    on(reportActions.getStatisticsRequest, (state: ReportState) => ({
        ...state,
        statisticsLoading: true,
        statisticsLoaded: false,
    })),
    on(
        reportActions.getStatisticsSuccess,
        (state: ReportState, { statistics }: { statistics: Statistics }) => ({
            ...state,
            statistics,
            statisticsLoading: false,
            statisticsLoaded: true,
        }),
    ),
    on(
        reportActions.getStatisticsFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            statisticsLoading: false,
            statisticsLoaded: false,
        }),
    ),
    on(reportActions.getTopResultsRequest, (state: ReportState) => ({
        ...state,
        topResultsLoading: true,
        topResultsLoaded: false,
    })),
    on(
        reportActions.getTopResultsSuccess,
        (state: ReportState, { topResults }: { topResults: TopResults }) => ({
            ...state,
            topResults,
            topResultsLoading: false,
            topResultsLoaded: true,
        }),
    ),
    on(
        reportActions.getTopResultsFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            topResultsLoading: false,
            topResultsLoaded: false,
        }),
    ),
    on(reportActions.getReferencesSummarizationRequest, (state: ReportState) => ({
        ...state,
        referencesSummarizationLoading: true,
        referencesSummarizationLoaded: false,
    })),
    on(
        reportActions.getReferencesSummarizationSuccess,
        (
            state: ReportState,
            {
                referencesSummarization,
            }: { referencesSummarization: ReferenceSummarizationPerCategory[] },
        ) => ({
            ...state,
            referencesSummarization,
            referencesSummarizationLoading: false,
            referencesSummarizationLoaded: true,
        }),
    ),
    on(
        reportActions.getReferencesSummarizationFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            referencesSummarizationLoading: false,
            referencesSummarizationLoaded: false,
        }),
    ),
    on(reportActions.getReferencesRequest, (state: ReportState) => ({
        ...state,
        referencesLoading: true,
        referencesLoaded: false,
    })),
    on(
        reportActions.getReferencesSuccess,
        (state: ReportState, { references }: { references: Reference[] }) => ({
            ...state,
            references,
            referencesLoading: false,
            referencesLoaded: true,
        }),
    ),
    on(
        reportActions.getReferencesFailure,
        (state: ReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            referencesLoading: false,
            referencesLoaded: false,
        }),
    ),
    on(
        reportActions.downloadRequest,
        (state: ReportState): ReportState => ({
            ...state,
            blob: null,
        }),
    ),
    on(
        reportActions.downloadSuccess,
        (state: ReportState, { blob }: { blob: Blob }): ReportState => ({
            ...state,
            blob,
        }),
    ),
    on(
        reportActions.downloadFailure,
        (
            state: ReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ): ReportState => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            blob: null,
        }),
    ),
    on(reportActions.clearNextError, (state: ReportState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: ReportState | undefined, action: Action): ReportState =>
    gptReducer(state, action);

// selectors
export const getErrors: (state: ReportState) => ErrorResponse[] = (state: ReportState) =>
    state.errors;
export const getIntroduction: (state: ReportState) => string = (state: ReportState) =>
    state.introduction;
export const getIntroductionLoading: (state: ReportState) => boolean = (state: ReportState) =>
    state.introductionLoading;
export const getIntroductionLoaded: (state: ReportState) => boolean = (state: ReportState) =>
    state.introductionLoaded;
export const getBackground: (state: ReportState) => Background = (state: ReportState) =>
    state.background;
export const getBackgroundLoading: (state: ReportState) => boolean = (state: ReportState) =>
    state.backgroundLoading;
export const getBackgroundLoaded: (state: ReportState) => boolean = (state: ReportState) =>
    state.backgroundLoaded;
export const getStatistics: (state: ReportState) => Statistics = (state: ReportState) =>
    state.statistics;
export const getStatisticsLoading: (state: ReportState) => boolean = (state: ReportState) =>
    state.statisticsLoading;
export const getStatisticsLoaded: (state: ReportState) => boolean = (state: ReportState) =>
    state.statisticsLoaded;
export const getTopResults: (state: ReportState) => TopResults = (state: ReportState) =>
    state.topResults;
export const getTopResultsLoading: (state: ReportState) => boolean = (state: ReportState) =>
    state.topResultsLoading;
export const getTopResultsLoaded: (state: ReportState) => boolean = (state: ReportState) =>
    state.topResultsLoaded;
export const getReferencesSummarization: (
    state: ReportState,
) => ReferenceSummarizationPerCategory[] = (state: ReportState) => state.referencesSummarization;
export const getReferencesSummarizationLoading: (state: ReportState) => boolean = (
    state: ReportState,
) => state.referencesSummarizationLoading;
export const getReferencesSummarizationLoaded: (state: ReportState) => boolean = (
    state: ReportState,
) => state.referencesSummarizationLoaded;
export const getReferences: (state: ReportState) => Reference[] = (state: ReportState) =>
    state.references;
export const getReferencesLoading: (state: ReportState) => boolean = (state: ReportState) =>
    state.referencesLoading;
export const getReferencesLoaded: (state: ReportState) => boolean = (state: ReportState) =>
    state.referencesLoaded;
export const getLoading: (state: ReportState) => boolean = (state: ReportState) =>
    Object.keys(ReportSectionId).some(
        (camelCasedSectionId: keyof typeof ReportSectionId) =>
            state[`${camelCasedSectionId}Loading`],
    );
export const getBlob: (state: ReportState) => Blob = (state: ReportState) => state.blob;
