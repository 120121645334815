import { NgModule } from '@angular/core';
import { FeatureFlagsService } from './feature-flags.service';
import { FeatureGuard } from './feature.guard';
import { FeatureFlagDirective } from './feature-flag.directive';

@NgModule({
    declarations: [FeatureFlagDirective],
    imports: [],
    exports: [FeatureFlagDirective],
    providers: [FeatureFlagsService, FeatureGuard],
})
export class FeatureFlagsModule {}
