export enum ReportActionTypes {
    GET_INTRODUCTION_REQUEST = '[REPORT][INTRODUCTION] Get Introduction Request',
    GET_INTRODUCTION_SUCCESS = '[REPORT][INTRODUCTION] Get Introduction Success',
    GET_INTRODUCTION_FAILURE = '[REPORT][INTRODUCTION] Get Introduction Failure',
    GET_BACKGROUND_REQUEST = '[REPORT][BACKGROUND] Get Background Request',
    GET_BACKGROUND_SUCCESS = '[REPORT][BACKGROUND] Get Background Success',
    GET_BACKGROUND_FAILURE = '[REPORT][BACKGROUND] Get Background Failure',
    GET_STATISTICS_REQUEST = '[REPORT][STATISTICS] Get Statistics Request',
    GET_STATISTICS_SUCCESS = '[REPORT][STATISTICS] Get Statistics Success',
    GET_STATISTICS_FAILURE = '[REPORT][STATISTICS] Get Statistics Failure',
    GET_TOP_RESULTS_REQUEST = '[REPORT][TOP RESULTS] Get Top Results Request',
    GET_TOP_RESULTS_SUCCESS = '[REPORT][TOP RESULTS] Get Top Results Success',
    GET_TOP_RESULTS_FAILURE = '[REPORT][TOP RESULTS] Get Top Results Failure',
    GET_REFERENCES_SUMMARIZATION_REQUEST = '[REPORT][REFERENCES SUMMARIZATION] Get References Summarization Request',
    GET_REFERENCES_SUMMARIZATION_SUCCESS = '[REPORT][REFERENCES SUMMARIZATION] Get References Summarization Success',
    GET_REFERENCES_SUMMARIZATION_FAILURE = '[REPORT][REFERENCES SUMMARIZATION] Get References Summarization Failure',
    GET_REFERENCES_REQUEST = '[REPORT][REFERENCES] Get References Request',
    GET_REFERENCES_SUCCESS = '[REPORT][REFERENCES] Get References Success',
    GET_REFERENCES_FAILURE = '[REPORT][REFERENCES] Get References Failure',
    DOWNLOAD_REQUEST = '[REPORT][DOWNLOAD] Download Report Request',
    DOWNLOAD_SUCCESS = '[REPORT][DOWNLOAD] Download Report Success',
    DOWNLOAD_FAILURE = '[REPORT][DOWNLOAD] Download Report Failure',
    CLEAR_NEXT_ERROR = '[REPORT] Clear Next Error',
}
