/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { NotificationsEffects } from './notifications.effects';
import { NotificationsFacade } from './notifications.facade';
import { NotificationsParser } from './parsers/notifications.parser';
import { NotificationsService } from './services/notifications.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([NotificationsEffects]),
    ],
    declarations: [],
    providers: [NotificationsParser, NotificationsService, NotificationsFacade],
})
export class NotificationsStoreModule {}
