/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { ReportActionTypes } from './report-action-types.enum';
import FileExtension from '@leap-common/enums/file-extension.enum';
import ReportPayload from './interfaces/report-payload.interface';
import Background from './interfaces/background.interface';
import Statistics from './interfaces/statistics.interface';
import TopResults from './types/top-results.type';
import ReferenceSummarizationPerCategory from './interfaces/reference-summarization-per-category.interface';
import Reference from './interfaces/reference.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';

export const getIntroductionRequest = createAction(
    ReportActionTypes.GET_INTRODUCTION_REQUEST,
    props<{ payload: ReportPayload }>(),
);

export const getIntroductionSuccess = createAction(
    ReportActionTypes.GET_INTRODUCTION_SUCCESS,
    props<{ introduction: string }>(),
);

export const getIntroductionFailure = createAction(
    ReportActionTypes.GET_INTRODUCTION_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getBackgroundRequest = createAction(
    ReportActionTypes.GET_BACKGROUND_REQUEST,
    props<{ payload: ReportPayload }>(),
);

export const getBackgroundSuccess = createAction(
    ReportActionTypes.GET_BACKGROUND_SUCCESS,
    props<{ background: Background }>(),
);

export const getBackgroundFailure = createAction(
    ReportActionTypes.GET_BACKGROUND_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStatisticsRequest = createAction(
    ReportActionTypes.GET_STATISTICS_REQUEST,
    props<{ payload: ReportPayload }>(),
);

export const getStatisticsSuccess = createAction(
    ReportActionTypes.GET_STATISTICS_SUCCESS,
    props<{ statistics: Statistics }>(),
);

export const getStatisticsFailure = createAction(
    ReportActionTypes.GET_STATISTICS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTopResultsRequest = createAction(
    ReportActionTypes.GET_TOP_RESULTS_REQUEST,
    props<{ payload: ReportPayload; discovery: Discovery; studyTypesOrder: string[] }>(),
);

export const getTopResultsSuccess = createAction(
    ReportActionTypes.GET_TOP_RESULTS_SUCCESS,
    props<{ topResults: TopResults }>(),
);

export const getTopResultsFailure = createAction(
    ReportActionTypes.GET_TOP_RESULTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getReferencesSummarizationRequest = createAction(
    ReportActionTypes.GET_REFERENCES_SUMMARIZATION_REQUEST,
    props<{ payload: ReportPayload }>(),
);

export const getReferencesSummarizationSuccess = createAction(
    ReportActionTypes.GET_REFERENCES_SUMMARIZATION_SUCCESS,
    props<{ referencesSummarization: ReferenceSummarizationPerCategory[] }>(),
);

export const getReferencesSummarizationFailure = createAction(
    ReportActionTypes.GET_REFERENCES_SUMMARIZATION_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getReferencesRequest = createAction(
    ReportActionTypes.GET_REFERENCES_REQUEST,
    props<{ payload: ReportPayload }>(),
);

export const getReferencesSuccess = createAction(
    ReportActionTypes.GET_REFERENCES_SUCCESS,
    props<{ references: Reference[] }>(),
);

export const getReferencesFailure = createAction(
    ReportActionTypes.GET_REFERENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadRequest = createAction(
    ReportActionTypes.DOWNLOAD_REQUEST,
    props<{ payload: ReportPayload; format: FileExtension }>(),
);

export const downloadSuccess = createAction(
    ReportActionTypes.DOWNLOAD_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadFailure = createAction(
    ReportActionTypes.DOWNLOAD_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(ReportActionTypes.CLEAR_NEXT_ERROR);
