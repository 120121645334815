/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { IngredientComparisonActionTypes } from './ingredient-comparison-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Ingredient from '../ingredient-viewer/interfaces/ingredient.interface';
import Bioactivity from './interfaces/bioactivity.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import StatisticsItem from '../report/interfaces/statistics-item.interface';

export const getIngredientsRequest = createAction(
    IngredientComparisonActionTypes.GET_INGREDIENTS_REQUEST,
    props<{ ids: string[] }>(),
);

export const getIngredientsSuccess = createAction(
    IngredientComparisonActionTypes.GET_INGREDIENTS_SUCCESS,
    props<{ paginatedIngredients: PaginatedResults<Ingredient> }>(),
);

export const getIngredientsFailure = createAction(
    IngredientComparisonActionTypes.GET_INGREDIENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);
export const getBioactivityRequest = createAction(
    IngredientComparisonActionTypes.GET_BIOACTIVITY_REQUEST,
    props<{ ingredientIds: string[]; baselineId: string }>(),
);

export const getBioactivitySuccess = createAction(
    IngredientComparisonActionTypes.GET_BIOACTIVITY_SUCCESS,
    props<{ bioactivity: Bioactivity[] }>(),
);

export const getBioactivityFailure = createAction(
    IngredientComparisonActionTypes.GET_BIOACTIVITY_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getAminoAcidsRequest = createAction(
    IngredientComparisonActionTypes.GET_AMINO_ACIDS_REQUEST,
    props<{ ingredientIds: string[] }>(),
);

export const getAminoAcidsSuccess = createAction(
    IngredientComparisonActionTypes.GET_AMINO_ACIDS_SUCCESS,
    props<{ aminoAcids: Record<string, StatisticsItem[]> }>(),
);

export const getAminoAcidsFailure = createAction(
    IngredientComparisonActionTypes.GET_AMINO_ACIDS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getMolecularWeightsRequest = createAction(
    IngredientComparisonActionTypes.GET_MOLECULAR_WEIGHTS_REQUEST,
    props<{ ingredientIds: string[] }>(),
);

export const getMolecularWeightsSuccess = createAction(
    IngredientComparisonActionTypes.GET_MOLECULAR_WEIGHTS_SUCCESS,
    props<{ molecularWeights: MultiLineChartDataItem[] }>(),
);

export const getMolecularWeightsFailure = createAction(
    IngredientComparisonActionTypes.GET_MOLECULAR_WEIGHTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(IngredientComparisonActionTypes.CLEAR_NEXT_ERROR);
