enum ReportSectionId {
    introduction = 'introduction',
    background = 'background',
    statistics = 'statistics',
    topResults = 'top-results',
    referencesSummarization = 'references-summarization',
    references = 'references',
}

export default ReportSectionId;
