import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromDefinitions from './definitions.reducer';

export const getDefinitionsState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.definitions,
);

export const getDefinitions = createSelector(getDefinitionsState, fromDefinitions.getDefinitions);
export const getErrors = createSelector(getDefinitionsState, fromDefinitions.getErrors);
export const getLoading = createSelector(getDefinitionsState, fromDefinitions.getLoading);
export const getLoaded = createSelector(getDefinitionsState, fromDefinitions.getLoaded);
