import { Component, EventEmitter, Input, Output } from '@angular/core';
import HelperMenuConfig from './helper-menu-config.interface';
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

@Component({
    selector: 'lib-helper-menu',
    templateUrl: 'helper-menu.component.html',
    styleUrls: ['helper-menu.component.scss'],
})
export class HelperMenuComponent {
    @Input() config: HelperMenuConfig;
    @Output() itemClicked: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

    constructor() {}

    onMenuItemClick(item: MenuItem): void {
        this.itemClicked.emit(item);
    }
}
