/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { pushAlertEvent, removeAlertEvent, clearAlertEvents } from './alerts.actions';
import { getAlertEvents } from './alerts.selectors';
import AlertEvent from './interfaces/alert-event.interface';
import { AlertsState } from './alerts-state.interface';

@Injectable()
export class AlertsFacade {
    alertEvents$: Observable<AlertEvent[]> = this.store.pipe(select(getAlertEvents));

    constructor(private store: Store<AlertsState>) {}

    pushAlertEvent(alertEvent: AlertEvent): void {
        this.store.dispatch(pushAlertEvent({ alertEvent }));
    }

    removeAlertEvent(id: string, shouldRemovePersistent: boolean = false): void {
        this.store.dispatch(removeAlertEvent({ id, shouldRemovePersistent }));
    }

    clearAlertEvents(shouldRemovePersistent: boolean = false): void {
        this.store.dispatch(clearAlertEvents({ shouldRemovePersistent }));
    }
}
