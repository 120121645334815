/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as reportActions from './articles-report.actions';
import { ArticlesReportState } from './articles-report-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Associations from './interfaces/associations.interface';
import KeyFinding from './interfaces/key-finding.interface';
import TheoreticalFrameworkItem from './interfaces/theoretical-framework-item.interface';
import Reference from '../report/interfaces/reference.interface';
import Summary from '../report/interfaces/summary.interface';

export const initialState: ArticlesReportState = {
    errors: [],
    overview: null,
    overviewLoading: false,
    overviewLoaded: false,
    associations: null,
    associationsLoading: false,
    associationsLoaded: false,
    keyFindings: [],
    keyFindingsLoading: false,
    keyFindingsLoaded: false,
    termSummaries: [],
    termSummariesLoading: false,
    termSummariesLoaded: false,
    criticalAnalysis: null,
    criticalAnalysisLoading: false,
    criticalAnalysisLoaded: false,
    theoreticalFrameworkItems: [],
    theoreticalFrameworkLoading: false,
    theoreticalFrameworkLoaded: false,
    references: [],
    referencesLoading: false,
    referencesLoaded: false,
    blob: null,
};

const articlesReportReducer: ActionReducer<ArticlesReportState, Action> = createReducer(
    initialState,
    on(reportActions.getOverviewRequest, (state: ArticlesReportState) => ({
        ...state,
        overviewLoading: true,
        overviewLoaded: false,
    })),
    on(
        reportActions.getOverviewSuccess,
        (state: ArticlesReportState, { overview }: { overview: string }) => ({
            ...state,
            overview,
            overviewLoading: false,
            overviewLoaded: true,
        }),
    ),
    on(
        reportActions.getOverviewFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            overviewLoading: false,
            overviewLoaded: false,
        }),
    ),
    on(reportActions.getAssociationsRequest, (state: ArticlesReportState) => ({
        ...state,
        associationsLoading: true,
        associationsLoaded: false,
    })),
    on(
        reportActions.getAssociationsSuccess,
        (state: ArticlesReportState, { associations }: { associations: Associations }) => ({
            ...state,
            associations,
            associationsLoading: false,
            associationsLoaded: true,
        }),
    ),
    on(
        reportActions.getAssociationsFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            associationsLoading: false,
            associationsLoaded: false,
        }),
    ),
    on(reportActions.getKeyFindingsRequest, (state: ArticlesReportState) => ({
        ...state,
        keyFindingsLoading: true,
        keyFindingsLoaded: false,
    })),
    on(
        reportActions.getKeyFindingsSuccess,
        (state: ArticlesReportState, { keyFindings }: { keyFindings: KeyFinding[] }) => ({
            ...state,
            keyFindings,
            keyFindingsLoading: false,
            keyFindingsLoaded: true,
        }),
    ),
    on(
        reportActions.getKeyFindingsFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            keyFindingsLoading: false,
            keyFindingsLoaded: false,
        }),
    ),
    on(reportActions.getTermSummariesRequest, (state: ArticlesReportState) => ({
        ...state,
        termSummariesLoading: true,
        termSummariesLoaded: false,
    })),
    on(
        reportActions.getTermSummariesSuccess,
        (state: ArticlesReportState, { termSummaries }: { termSummaries: Summary[] }) => ({
            ...state,
            termSummaries,
            termSummariesLoading: false,
            termSummariesLoaded: true,
        }),
    ),
    on(
        reportActions.getTermSummariesFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            termSummariesLoading: false,
            termSummariesLoaded: false,
        }),
    ),
    on(reportActions.getCriticalAnalysisRequest, (state: ArticlesReportState) => ({
        ...state,
        criticalAnalysisLoading: true,
        criticalAnalysisLoaded: false,
    })),
    on(
        reportActions.getCriticalAnalysisSuccess,
        (state: ArticlesReportState, { criticalAnalysis }: { criticalAnalysis: string }) => ({
            ...state,
            criticalAnalysis,
            criticalAnalysisLoading: false,
            criticalAnalysisLoaded: true,
        }),
    ),
    on(
        reportActions.getCriticalAnalysisFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            criticalAnalysisLoading: false,
            criticalAnalysisLoaded: false,
        }),
    ),
    on(reportActions.getTheoreticalFrameworkRequest, (state: ArticlesReportState) => ({
        ...state,
        theoreticalFrameworkLoading: true,
        theoreticalFrameworkLoaded: false,
    })),
    on(
        reportActions.getTheoreticalFrameworkSuccess,
        (
            state: ArticlesReportState,
            {
                theoreticalFrameworkItems,
            }: { theoreticalFrameworkItems: TheoreticalFrameworkItem[] },
        ) => ({
            ...state,
            theoreticalFrameworkItems,
            theoreticalFrameworkLoading: false,
            theoreticalFrameworkLoaded: true,
        }),
    ),
    on(
        reportActions.getTheoreticalFrameworkFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            theoreticalFrameworkLoading: false,
            theoreticalFrameworkLoaded: false,
        }),
    ),
    on(reportActions.getReferencesRequest, (state: ArticlesReportState) => ({
        ...state,
        referencesLoading: true,
        referencesLoaded: false,
    })),
    on(
        reportActions.getReferencesSuccess,
        (state: ArticlesReportState, { references }: { references: Reference[] }) => ({
            ...state,
            references,
            referencesLoading: false,
            referencesLoaded: true,
        }),
    ),
    on(
        reportActions.getReferencesFailure,
        (state: ArticlesReportState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            referencesLoading: false,
            referencesLoaded: false,
        }),
    ),
    on(
        reportActions.downloadReportRequest,
        (state: ArticlesReportState): ArticlesReportState => ({
            ...state,
            blob: null,
        }),
    ),
    on(
        reportActions.downloadReportSuccess,
        (state: ArticlesReportState, { blob }: { blob: Blob }): ArticlesReportState => ({
            ...state,
            blob,
        }),
    ),
    on(
        reportActions.downloadReportFailure,
        (
            state: ArticlesReportState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ): ArticlesReportState => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            blob: null,
        }),
    ),
    on(reportActions.resetReport, () => initialState),
    on(reportActions.clearNextError, (state: ArticlesReportState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: ArticlesReportState | undefined,
    action: Action,
): ArticlesReportState => articlesReportReducer(state, action);

// selectors
export const getErrors: (state: ArticlesReportState) => ErrorResponse[] = (
    state: ArticlesReportState,
) => state.errors;
export const getOverview: (state: ArticlesReportState) => string = (state: ArticlesReportState) =>
    state.overview;
export const getOverviewLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.overviewLoading;
export const getOverviewLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.overviewLoaded;
export const getAssociations: (state: ArticlesReportState) => Associations = (
    state: ArticlesReportState,
) => state.associations;
export const getAssociationsLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.associationsLoading;
export const getAssociationsLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.associationsLoaded;
export const getKeyFindings: (state: ArticlesReportState) => KeyFinding[] = (
    state: ArticlesReportState,
) => state.keyFindings;
export const getKeyFindingsLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.keyFindingsLoading;
export const getKeyFindingsLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.keyFindingsLoaded;
export const getTermSummaries: (state: ArticlesReportState) => Summary[] = (
    state: ArticlesReportState,
) => state.termSummaries;
export const getTermSummariesLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.termSummariesLoading;
export const getTermSummariesLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.termSummariesLoaded;
export const getCriticalAnalysis: (state: ArticlesReportState) => string = (
    state: ArticlesReportState,
) => state.criticalAnalysis;
export const getCriticalAnalysisLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.criticalAnalysisLoading;
export const getCriticalAnalysisLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.criticalAnalysisLoaded;
export const getTheoreticalFrameworkItems: (
    state: ArticlesReportState,
) => TheoreticalFrameworkItem[] = (state: ArticlesReportState) => state.theoreticalFrameworkItems;
export const getTheoreticalFrameworkLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.theoreticalFrameworkLoading;
export const getTheoreticalFrameworkLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.theoreticalFrameworkLoaded;
export const getReferences: (state: ArticlesReportState) => Reference[] = (
    state: ArticlesReportState,
) => state.references;
export const getReferencesLoading: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.referencesLoading;
export const getReferencesLoaded: (state: ArticlesReportState) => boolean = (
    state: ArticlesReportState,
) => state.referencesLoaded;
export const getBlob: (state: ArticlesReportState) => Blob = (state: ArticlesReportState) =>
    state.blob;
