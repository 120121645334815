import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '@leap-common/utilities/helpers';

@Pipe({
    name: 'pluralize',
})
export class PluralizePipe implements PipeTransform {
    constructor() {}

    transform(singularWord: string, count?: number): string {
        return pluralize(singularWord, count);
    }
}
