/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ArticlesReportParser } from '../parsers/articles-report.parser';
import { ReportParser } from '../../report/parsers/report.parser';
import AssociationsRestApi from '../rest-api-interfaces/associations.rest.interface';
import Associations from '../interfaces/associations.interface';
import KeyFindingRestApi from '../rest-api-interfaces/key-finding.rest.interface';
import KeyFinding from '../interfaces/key-finding.interface';
import TheoreticalFrameworkItemRestApi from '../rest-api-interfaces/theoretical-framework-item.rest.interface';
import TheoreticalFrameworkItem from '../interfaces/theoretical-framework-item.interface';
import BookmarkArticle from '../../bookmarks/types/article.type';
import Article from '../../articles/interfaces/article.interface';
import ReferenceRestApi from '../../report/rest-api-interfaces/reference.rest.interface';
import Reference from '../../report/interfaces/reference.interface';
import Summary from '../../report/interfaces/summary.interface';
import TermSummaryRestApi from '../rest-api-interfaces/term-summary.rest.interface';

@Injectable()
export class ArticlesReportService {
    constructor(
        private http: HttpClient,
        private articlesReportParser: ArticlesReportParser,
        private reportParser: ReportParser,
    ) {}

    getOverview(
        projectId: string,
        bookmarkIds: string[],
        shouldRegenerate: boolean,
    ): Observable<string> {
        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Overview`,
                {
                    bookmarksUids: bookmarkIds,
                    regenerate: shouldRegenerate,
                },
            )
            .pipe(map(({ overview }: { overview: string }) => overview));
    }

    getAssociations(projectId: string, bookmarkIds: string[]): Observable<Associations> {
        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Associations and Articles`,
                {
                    bookmarksUids: bookmarkIds,
                },
            )
            .pipe(
                map(({ titlesPerAssociation }: { titlesPerAssociation: AssociationsRestApi }) =>
                    this.articlesReportParser.parseAssociations(titlesPerAssociation),
                ),
            );
    }

    getKeyFindings(
        projectId: string,
        bookmarks: BookmarkArticle[],
        shouldRegenerate: boolean,
    ): Observable<KeyFinding[]> {
        const [bookmarkIds, articles]: [string[], Article[]] = bookmarks.reduce(
            (
                [bookmarkIdsAccumulator, articlesAccumulator]: [string[], Article[]],
                { id, data }: BookmarkArticle,
            ) => [
                [...bookmarkIdsAccumulator, id],
                [...articlesAccumulator, data],
            ],
            [[], []],
        ) as [string[], Article[]];

        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Key Findings and Methodologies`,
                {
                    bookmarksUids: bookmarkIds,
                    regenerate: shouldRegenerate,
                },
            )
            .pipe(
                map(({ keyFindings }: { keyFindings: KeyFindingRestApi[] }) =>
                    this.articlesReportParser.parseKeyFindings(keyFindings, articles),
                ),
            );
    }

    getTermSummaries(
        projectId: string,
        bookmarkIds: string[],
        shouldRegenerate: boolean,
    ): Observable<Summary[]> {
        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Summary per Term`,
                {
                    bookmarksUids: bookmarkIds,
                    regenerate: shouldRegenerate,
                },
            )
            .pipe(
                map(({ summaryPerTerm }: { summaryPerTerm: TermSummaryRestApi[] }) =>
                    this.articlesReportParser.parseTermSummaries(summaryPerTerm),
                ),
            );
    }

    getCriticalAnalysis(
        projectId: string,
        bookmarkIds: string[],
        shouldRegenerate: boolean,
    ): Observable<string> {
        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Critical Analysis`,
                {
                    bookmarksUids: bookmarkIds,
                    regenerate: shouldRegenerate,
                },
            )
            .pipe(map(({ criticalAnalysis }: { criticalAnalysis: string }) => criticalAnalysis));
    }

    getTheoreticalFramework(
        projectId: string,
        bookmarks: BookmarkArticle[],
        shouldRegenerate: boolean,
    ): Observable<TheoreticalFrameworkItem[]> {
        const [bookmarkIds, articles]: [string[], Article[]] = bookmarks.reduce(
            (
                [bookmarkIdsAccumulator, articlesAccumulator]: [string[], Article[]],
                { id, data }: BookmarkArticle,
            ) => [
                [...bookmarkIdsAccumulator, id],
                [...articlesAccumulator, data],
            ],
            [[], []],
        ) as [string[], Article[]];

        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=Theoretical Framework`,
                {
                    bookmarksUids: bookmarkIds,
                    regenerate: shouldRegenerate,
                },
            )
            .pipe(
                map(
                    ({
                        theoreticalFramework,
                    }: {
                        theoreticalFramework: TheoreticalFrameworkItemRestApi[];
                    }) =>
                        this.articlesReportParser.parseTheoreticalFrameworkItems(
                            theoreticalFramework,
                            articles,
                        ),
                ),
            );
    }

    getReferences(projectId: string, bookmarkIds: string[]): Observable<Reference[]> {
        return this.http
            .post(
                `${environment.projectsReportServerUrl}/projects/${projectId}/report/?section=References`,
                {
                    bookmarksUids: bookmarkIds,
                },
            )
            .pipe(
                map(({ references }: { references: ReferenceRestApi[] }) =>
                    this.reportParser.parseReferences(references),
                ),
            );
    }

    download(projectId: string, bookmarkIds: string[]): Observable<Blob> {
        return this.http.post(
            `${environment.projectsReportServerUrl}/projects/${projectId}/report/download/`,
            {
                bookmarksUids: bookmarkIds,
            },
            {
                responseType: 'blob',
            },
        );
    }
}
