/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

/** custom imports */
import { OpenDiscoveryService } from './services/open-discovery.service';
import * as DiscoveryActions from './open-discovery.actions';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class OpenDiscoveryEffects {
    constructor(private actions$: Actions, private openDiscoveryService: OpenDiscoveryService) {}

    downloadDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.downloadDiscoveryRequest),
            switchMap(
                ({
                    source,
                    targetCategories,
                    preferences,
                    filters,
                }: {
                    source: string;
                    targetCategories: string[];
                    preferences: UserPreferences;
                    filters?: ExecutionFilters;
                }) =>
                    this.openDiscoveryService
                        .download(source, targetCategories, preferences, filters)
                        .pipe(
                            map((blob: Blob) =>
                                DiscoveryActions.downloadDiscoverySuccess({ blob }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(DiscoveryActions.downloadDiscoveryFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.getSearchSuggestionsRequest),
            switchMap(({ source, query }: { source: string; query: string }) =>
                this.openDiscoveryService.getSearchSuggestions(source, query).pipe(
                    map((suggestionIds: string[]) =>
                        DiscoveryActions.getSearchSuggestionsSuccess({ suggestionIds }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            DiscoveryActions.getSearchSuggestionsFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
