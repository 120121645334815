/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { IngredientManagementState } from './ingredient-management-state.interface';
import {
    uploadFileRequest,
    getIngredientsRequest,
    updateNameRequest,
    updateNotesRequest,
    downloadIngredientRequest,
    deleteIngredientRequest,
    downloadTemplateRequest,
    downloadInstructionsRequest,
    clearNextSuccess,
    clearNextError,
    resetIngredientManagement,
} from './ingredient-management.actions';
import {
    getSuccess,
    getErrors,
    getFileUploadResult,
    getIngredients,
    getLoading,
    getLoaded,
    getTotal,
    getShouldFetchIngredients,
    getIngredientBlob,
    getTemplateBlob,
    getInstructionsBlob,
} from './ingredient-management.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import Ingredient from '@leap-store/core/src/lib/data/ingredient-viewer/interfaces/ingredient.interface';
import FileUploadResult from './interfaces/file-upload-result.interface';

@Injectable()
export class IngredientManagementFacade {
    success$: Observable<Alert[]> = this.store.pipe(select(getSuccess));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    fileUploadResult$: Observable<FileUploadResult> = this.store.pipe(select(getFileUploadResult));
    ingredients$: Observable<Ingredient[]> = this.store.pipe(select(getIngredients));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    shouldFetchIngredients$: Observable<boolean> = this.store.pipe(
        select(getShouldFetchIngredients),
    );
    ingredientBlob$: Observable<Blob> = this.store.pipe(select(getIngredientBlob));
    templateBlob$: Observable<Blob> = this.store.pipe(select(getTemplateBlob));
    instructionsBlob$: Observable<Blob> = this.store.pipe(select(getInstructionsBlob));

    constructor(private store: Store<IngredientManagementState>) {}

    uploadFile(file: File): void {
        this.store.dispatch(uploadFileRequest({ file }));
    }

    getIngredients({
        pageSize,
        pageIndex,
        searchQuery,
        sortingOptions,
        suppressLoading = false,
        ids,
    }: {
        pageSize?: number;
        pageIndex?: number;
        searchQuery?: string;
        sortingOptions?: SortingOptions;
        suppressLoading?: boolean;
        ids?: string[];
    }): void {
        this.store.dispatch(
            getIngredientsRequest({
                pageSize,
                pageIndex,
                searchQuery,
                sortingOptions,
                suppressLoading,
                ids,
            }),
        );
    }

    updateName(id: string, name: string): void {
        this.store.dispatch(updateNameRequest({ id, name }));
    }

    updateNotes(id: string, notes: string): void {
        this.store.dispatch(updateNotesRequest({ id, notes }));
    }

    downloadIngredient(id: string): void {
        this.store.dispatch(downloadIngredientRequest({ id }));
    }

    deleteIngredient(id: string): void {
        this.store.dispatch(deleteIngredientRequest({ id }));
    }

    downloadTemplate(): void {
        this.store.dispatch(downloadTemplateRequest());
    }

    downloadInstructions(): void {
        this.store.dispatch(downloadInstructionsRequest());
    }

    clearNextSuccess(): void {
        this.store.dispatch(clearNextSuccess());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    resetIngredientManagement(): void {
        this.store.dispatch(resetIngredientManagement());
    }
}
