import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import { MetapathsState } from './metapaths-state.interface';
import * as fromMetapaths from './metapaths.reducer';
import Metapath from './interfaces/metapath.interface';
import EnhancedMetapaths from './interfaces/enhanced-metapaths';

export const getMetapathsState = createSelector(
    getDataState,
    (state: DataState) => state.metapaths,
);
export const getMetapaths = createSelector(getMetapathsState, fromMetapaths.getEntities);
export const getParentInsightId = createSelector(
    getMetapathsState,
    fromMetapaths.getParentInsightId,
);
export const getErrors = createSelector(getMetapathsState, fromMetapaths.getErrors);
export const getLoading = createSelector(getMetapathsState, fromMetapaths.getLoading);
export const getLoaded = createSelector(getMetapathsState, fromMetapaths.getLoaded);
export const getPageIndex = createSelector(getMetapathsState, fromMetapaths.getPageIndex);
export const getPageSize = createSelector(getMetapathsState, fromMetapaths.getPageSize);
export const getTotal = createSelector(getMetapathsState, fromMetapaths.getTotal);
export const getEstimatedDuration = createSelector(
    getMetapathsState,
    fromMetapaths.getEstimatedDuration,
);
export const getEnhancedMetapaths: (state: MetapathsState) => EnhancedMetapaths = (
    state: MetapathsState,
) => {
    const parentInsightId: string = getParentInsightId(state);
    const metapaths: Metapath[] = getMetapaths(state);
    const loading: boolean = getLoading(state);
    const loaded: boolean = getLoaded(state);
    const total: number = getTotal(state);
    const estimatedDuration: number = getEstimatedDuration(state);

    return {
        parentInsightId,
        metapaths,
        loading,
        loaded,
        total,
        estimatedDuration,
    };
};
