import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { DairyProfilerEffects } from './dairy-profiler.effects';
import { DairyProfilerFacade } from './dairy-profiler.facade';
import { DairyProfilerService } from './services/dairy-profiler.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([DairyProfilerEffects]),
    ],
    declarations: [],
    providers: [DairyProfilerService, DairyProfilerFacade],
})
export class DairyProfilerStoreModule {}
