import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import * as groupsActions from './groups.actions';
import { GroupsState } from './groups-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';

export const initialState: GroupsState = {
    errors: [],
    success: [],
    list: [],
    departments: [],
    searchedDepartments: null, // is null when no search is happening
    createdDepartment: null,
    loading: false,
    loaded: false,
    deletePendingItems: [],
    shouldFetchDepartments: false,
};

const groupsReducer: ActionReducer<GroupsState, Action> = createReducer(
    initialState,
    on(groupsActions.getGroupsRequest, (state: GroupsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        groupsActions.getGroupsSuccess,
        (
            state: GroupsState,
            { paginatedGroups }: { paginatedGroups: PaginatedResults<UserGroup> },
        ) => ({
            ...state,
            list: paginatedGroups.results,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        groupsActions.getGroupsFailure,
        (state: GroupsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(groupsActions.createGroupRequest, (state: GroupsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(groupsActions.createGroupSuccess, (state: GroupsState, { group }: { group: UserGroup }) => ({
        ...state,
        createdDepartment: group,
        loading: false,
        loaded: true,
        success: [
            ...state.success,
            {
                ...group,
                message: `${group.name} created successfully!`,
            },
        ],
    })),
    on(
        groupsActions.createGroupFailure,
        (state: GroupsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: true,
        }),
    ),
    on(groupsActions.getDepartmentsRequest, (state: GroupsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        groupsActions.getDepartmentsSuccess,
        (state: GroupsState, { departments }: { departments: UserGroup[] }) => ({
            ...state,
            departments,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        groupsActions.getDepartmentsFailure,
        (state: GroupsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(groupsActions.updateGroupRequest, (state: GroupsState) => ({
        ...state,
    })),
    on(groupsActions.updateGroupSuccess, (state: GroupsState, { group }: { group: UserGroup }) => ({
        ...state,
        list: state.list.map((existingGroup: UserGroup) =>
            existingGroup.id === group.id ? { ...existingGroup, name: group.name } : existingGroup,
        ),
        departments: state.departments.map((department: UserGroup) =>
            department.id === group.id ? { ...department, name: group.name } : department,
        ),
        searchedDepartments:
            state.searchedDepartments &&
            state.searchedDepartments.map((department: UserGroup) =>
                department.id === group.id ? { ...department, name: group.name } : department,
            ),
        shouldFetchDepartments: true,
        success: [
            ...state.success,
            {
                ...group,
                message: `${group.name} updated successfully!`,
            },
        ],
    })),
    on(
        groupsActions.updateGroupFailure,
        (state: GroupsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(groupsActions.deleteGroupRequest, (state: GroupsState, { id }: { id: string }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, id],
    })),
    on(groupsActions.deleteGroupSuccess, (state: GroupsState, { group }: { group: UserGroup }) => ({
        ...state,
        list: state.list.filter((item: UserGroup) => item.id !== group.id),
        departments: state.departments.filter((item: UserGroup) => item.id !== group.id),
        searchedDepartments:
            state.searchedDepartments &&
            state.searchedDepartments.filter((item: UserGroup) => item.id !== group.id),
        shouldFetchDepartments: true,
        deletePendingItems: state.deletePendingItems.filter(
            (pendingItem: string) => pendingItem !== group.id,
        ),
        success: [
            ...state.success,
            {
                ...group,
                message: `${group.name} deleted successfully!`,
            },
        ],
    })),
    on(
        groupsActions.deleteGroupFailure,
        (
            state: GroupsState,
            { id, errorResponse }: { id: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => pendingItem !== id,
            ),
            loading: false,
            loaded: true,
        }),
    ),
    on(groupsActions.deleteGroupsRequest, (state: GroupsState, { ids }: { ids: string[] }) => ({
        ...state,
        deletePendingItems: [...state.deletePendingItems, ...ids],
    })),
    on(
        groupsActions.deleteGroupsSuccess,
        (
            state: GroupsState,
            { paginatedGroups }: { paginatedGroups: PaginatedResults<UserGroup> },
        ) => {
            const groupsIds: string[] = paginatedGroups.results.map((group: UserGroup) => group.id);
            return {
                ...state,
                list: state.list.filter((item: UserGroup) => !groupsIds.includes(item.id)),
                departments: state.departments.filter(
                    (department: UserGroup) => !groupsIds.includes(department.id),
                ),
                searchedDepartments:
                    state.searchedDepartments &&
                    state.searchedDepartments.filter(
                        (searched: UserGroup) => !groupsIds.includes(searched.id),
                    ),
                shouldFetchDepartments: true,
                deletePendingItems: state.deletePendingItems.filter(
                    (pendingItem: string) => !groupsIds.includes(pendingItem),
                ),
                success: [
                    ...state.success,
                    ...paginatedGroups.results.map((group: UserGroup) => ({
                        ...group,
                        message: `${group.name} deleted successfully!`,
                    })),
                ],
            };
        },
    ),
    on(
        groupsActions.deleteGroupsFailure,
        (
            state: GroupsState,
            { ids, errorResponse }: { ids: string[]; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            deletePendingItems: state.deletePendingItems.filter(
                (pendingItem: string) => !ids.includes(pendingItem),
            ),
            loading: false,
            loaded: true,
        }),
    ),
    on(
        groupsActions.searchDepartments,
        (state: GroupsState, { searchQuery }: { searchQuery: string }) => ({
            ...state,
            searchedDepartments: searchQuery
                ? state.departments.filter((department: UserGroup) =>
                      department.name.toLowerCase().includes(searchQuery.toLowerCase()),
                  )
                : null,
        }),
    ),
    on(groupsActions.clearCreatedDepartment, (state: GroupsState) => ({
        ...state,
        createdDepartment: null as UserGroup,
    })),
    on(groupsActions.clearNextSuccess, (state: GroupsState) => ({
        ...state,
        success: state.success.slice(1),
    })),
    on(groupsActions.clearNextError, (state: GroupsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(groupsActions.clearShouldFetchDepartments, (state: GroupsState) => ({
        ...state,
        shouldFetchDepartments: false,
    })),
);

export const reducer = (state: GroupsState | undefined, action: Action): GroupsState =>
    groupsReducer(state, action);

// selectors
export const getGroupsErrors: (state: GroupsState) => ErrorResponse[] = (state: GroupsState) =>
    state.errors;
export const getGroupsSuccess: (state: GroupsState) => UserGroup[] = (state: GroupsState) =>
    state.success;
export const getCreatedDepartment: (state: GroupsState) => UserGroup = (state: GroupsState) =>
    state.createdDepartment;
export const getGroupsList: (state: GroupsState) => UserGroup[] = (state: GroupsState) =>
    state.list;
export const getDepartmentsList: (state: GroupsState) => UserGroup[] = (state: GroupsState) =>
    state.departments;
export const getGroupsLoading: (state: GroupsState) => boolean = (state: GroupsState) =>
    state.loading;
export const getGroupsLoaded: (state: GroupsState) => boolean = (state: GroupsState) =>
    state.loaded;
export const getSearchedDepartments: (state: GroupsState) => UserGroup[] = (state: GroupsState) =>
    state.searchedDepartments;
export const getDeletePendingItems: (state: GroupsState) => string[] = (state: GroupsState) =>
    state.deletePendingItems;
export const getShouldFetchDepartments: (state: GroupsState) => boolean = (state: GroupsState) =>
    state.shouldFetchDepartments;
