/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { ClosedDiscoveryEffects } from './closed-discovery.effects';
import { ClosedDiscoveryFacade } from './closed-discovery.facade';
import { ClosedDiscoveryParser } from './parsers/closed-discovery.parser';
import { ClosedDiscoveryService } from './services/closed-discovery.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([ClosedDiscoveryEffects]),
    ],
    declarations: [],
    providers: [
        ClosedDiscoveryParser,
        ClosedDiscoveryService,
        ClosedDiscoveryFacade,
        InsightParser,
        InsightsService,
    ],
})
export class ClosedDiscoveryStoreModule {}
