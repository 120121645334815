import { Component, Input } from '@angular/core';

/** Constants */
import { SUBTITLE } from '../../../constants/landing';

/** Enums */
import Organization from '@apps/leap/src/app/shared/enums/organization.enum';

@Component({
    selector: 'app-header',
    templateUrl: 'header.view.component.html',
    styleUrls: ['header.view.component.scss'],
})
export class HeaderViewComponent {
    @Input() organization: string;

    readonly subtitle: string = SUBTITLE;

    constructor() {}

    setLogo(): Record<string, string> {
        const logo: Record<string, string> = {
            'background-image': `url('assets/graphics/${this.organization}/login-logo.svg')`,
            'background-repeat': 'no-repeat',
            'background-size': 'contain',
            'background-position': 'center',
            height:
                this.organization === Organization.mr || this.organization === Organization.mrPlant
                    ? '70px'
                    : this.organization === Organization.pp
                    ? '75px'
                    : this.organization === Organization.dmi
                    ? '95px'
                    : this.organization === Organization.dgIngredientAnalyzer
                    ? '60px'
                    : '90px',
        };

        return logo;
    }
}
