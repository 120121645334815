import { Injectable } from '@angular/core';
import { fromPairs, isEqual, mapValues, uniqWith } from 'lodash';

/** Services - Parsers */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';

/** Constants */
import {
    RELATIONSHIP_GENERIC_LABEL,
    RELATIONSHIP_ORIGIN_LITERATURE_LABEL,
} from '@apps/leap/src/app/shared/constants/discovery';

/** Interfaces - Types - Enums */
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import LabsRestApi from '../rest-api-interfaces/labs.rest.interface';
import HealthLabelsRestApi from '../rest-api-types/health-labels.rest.type';
import HealthLabelRestApi from '../rest-api-interfaces/health-label.rest.interface';
import HealthLabel from '../interfaces/health-label.interface';
import IdentifiersRestApi from '../rest-api-types/identifiers.rest.type';
import Identifiers from '../types/identifiers.type';
import IdentifierRestApi from '../rest-api-types/identifier.rest.type';
import Identifier from '../interfaces/identifier.interface';
import ProteinOriginsRestApi from '../rest-api-types/protein-origins.rest.type';
import ProteinOriginRestApi from '../rest-api-types/protein-origin.rest.type';
import ProteinOrigins from '../types/protein-origins.type';
import OriginsInfoPerRelationshipRestApi from '../rest-api-types/origins-info-per-relationship.rest.type';
import OriginsInfoPerRelationship from '../types/origins-info-per-relationship.type';
import OriginsInfoRestApi from '../rest-api-interfaces/origins-info.rest.interface';
import RelationshipInfoRestApi from '../rest-api-interfaces/relationship-info.rest.interface';
import StudyTypesRestApi from '../rest-api-interfaces/study-types.rest.interface';
import JournalsRestApi from '../rest-api-interfaces/journals.rest.interface';
import UnitConversionRestApi from '../rest-api-interfaces/unit-conversion.rest.interface';
import UnitConversion from '../interfaces/unit-conversion.interface';
import PatentsCountsRestApi from '../rest-api-types/patents-counts.rest.type';
import PatentsCounts from '../types/patents-counts.type';
import ContainingIngredientsRestApi from '../rest-api-types/containing-ingredients.rest.type';
import ContainingIngredients from '../types/containing-ingredients.type';
import IngredientRestApi from '../rest-api-interfaces/ingredient.rest.interface';
import Ingredient from '../interfaces/ingredient.interface';
import RelationshipOrigin from '../interfaces/relationship-origin.interface';
import RelationshipOriginRestApi from '../rest-api-interfaces/relationship-origin.rest.interface';
import ConcentrationIdentifierRestApi from '../rest-api-interfaces/concentration-identifier.rest.interface';
import ConcentrationMeasurement from '@apps/leap/src/app/shared/types/concentration-measurement.type';
import ConcentrationMeasurementsRestApi from '../rest-api-types/concentration-measurements.rest.type';
import ConcentrationMeasurementUnit from '@apps/leap/src/app/shared/enums/concentration-measurement-unit.enum';
import ConcentrationMeasurementRestApi from '../rest-api-interfaces/concentration-measurement.rest.interface';
import PrevalenceRestApi from '../rest-api-interfaces/prevalence.rest.interface';
import Prevalence from '../interfaces/prevalence.interface';
import PrevalenceRange from '../interfaces/prevalence-range.interface';
import PrevalencesRestApi from '../rest-api-types/prevalences.rest.type';
import Prevalences from '../types/prevalences.type';

@Injectable()
export class MetadataParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private insightParser: InsightParser,
    ) {}

    parseLabs(labs: LabsRestApi): string[] {
        return labs['UCD DMD lab']?.sort();
    }

    parseHealthLabels(healthLabels: HealthLabelsRestApi): HealthLabel[] {
        return Object.entries(healthLabels)
            .reduce(
                (
                    accumulator: HealthLabel[],
                    [name, { description }]: [string, HealthLabelRestApi],
                ) => {
                    accumulator.push({ name, description });
                    return accumulator;
                },
                [],
            )
            .sort(
                this.arrayHandlerService.getSortWithResolver(
                    ({ name }: HealthLabel) => name,
                    SortingOrder.ascending,
                ),
            );
    }

    parseIdentifiers(identifiers: IdentifiersRestApi): Identifiers {
        return Object.entries(identifiers).reduce(
            (accumulator: Identifiers, [key, value]: [string, IdentifierRestApi[]]) => {
                accumulator[key] =
                    value?.map((identifier: IdentifierRestApi) =>
                        this.parseIdentifier(identifier),
                    ) || [];
                return accumulator;
            },
            {},
        );
    }

    parseProteinOrigins(proteinOrigins: ProteinOriginsRestApi): ProteinOrigins {
        return Object.entries(proteinOrigins).reduce(
            (accumulator: ProteinOrigins, [key, value]: [string, ProteinOriginRestApi[]]) => {
                accumulator[key] = this.insightParser.parseProteinOrigins(value);
                return accumulator;
            },
            {},
        );
    }

    parsePatentsCounts(patentsCounts: PatentsCountsRestApi): PatentsCounts {
        return patentsCounts;
    }

    parseContainingIngredients(ingredients: ContainingIngredientsRestApi): ContainingIngredients {
        return ingredients
            ? mapValues(ingredients, (ingredient: IngredientRestApi[]) =>
                  this.parseIngredients(ingredient),
              )
            : null;
    }

    parseIdentifier([id, url]: IdentifierRestApi): Identifier {
        return {
            id,
            url,
        };
    }

    parseRelationshipsOriginsInfo(
        originsInfoPerRelationshipRaw: OriginsInfoPerRelationshipRestApi,
    ): Record<string, OriginsInfoPerRelationship> {
        const relationshipsInfo: Record<string, OriginsInfoPerRelationship> = {};

        for (const [ids, relationshipOrigin] of Object.entries(originsInfoPerRelationshipRaw) as [
            string,
            OriginsInfoRestApi,
        ][]) {
            if (!relationshipOrigin?.relation_source) {
                relationshipsInfo[ids] = null;
                continue;
            }
            let relationshipsOfOther: string[] = [];
            let originsOfOther: RelationshipOrigin[] = [];

            for (const [relationship, sourceOrigins] of Object.entries(
                relationshipOrigin.relation_source,
            ) as [string, RelationshipInfoRestApi][]) {
                if (sourceOrigins.mapped === 'other') {
                    relationshipsOfOther = [...relationshipsOfOther, relationship];
                    originsOfOther = [
                        ...originsOfOther,
                        ...sourceOrigins.origins
                            .filter(
                                (origin: RelationshipOriginRestApi) =>
                                    !originsOfOther.some(
                                        (otherOrigin: RelationshipOrigin) =>
                                            otherOrigin.name === origin.source,
                                    ),
                            )
                            .map(this.parseRelationshipOrigin),
                    ];
                    relationshipsInfo[ids] = {
                        ...relationshipsInfo[ids],
                        other: {
                            relationships: relationshipsOfOther,
                            origins: originsOfOther,
                            articlesCount: relationshipOrigin?.relation_counts.other,
                        },
                    };
                } else {
                    const origins: RelationshipOrigin[] = [
                        ...(relationshipsInfo[ids]?.[sourceOrigins.mapped]?.origins
                            ? relationshipsInfo[ids][sourceOrigins.mapped].origins
                            : []),
                        ...this.parseRelationshipOrigins(sourceOrigins.origins),
                    ];

                    relationshipsInfo[ids] = {
                        ...relationshipsInfo[ids],
                        [sourceOrigins.mapped]: {
                            origins: uniqWith(origins, isEqual),
                            articlesCount:
                                relationshipOrigin?.relation_counts[sourceOrigins.mapped],
                        },
                    };

                    if (
                        Object.prototype.hasOwnProperty.call(
                            relationshipsInfo[ids],
                            RELATIONSHIP_GENERIC_LABEL,
                        )
                    ) {
                        relationshipsInfo[ids][RELATIONSHIP_GENERIC_LABEL] = {
                            ...relationshipsInfo[ids][RELATIONSHIP_GENERIC_LABEL],
                            origins: relationshipsInfo[ids][
                                RELATIONSHIP_GENERIC_LABEL
                            ].origins.filter(
                                (origin: RelationshipOrigin) =>
                                    origin.name !== RELATIONSHIP_ORIGIN_LITERATURE_LABEL,
                            ),
                        };
                    }
                }
            }
        }
        return relationshipsInfo;
    }

    parseRelationshipOrigins(
        relationshipOrigins: RelationshipOriginRestApi[],
    ): RelationshipOrigin[] {
        return relationshipOrigins.map(this.parseRelationshipOrigin);
    }

    parseRelationshipOrigin(relationshipOrigin: RelationshipOriginRestApi): RelationshipOrigin {
        return {
            name: relationshipOrigin.source,
            url: relationshipOrigin.url,
        };
    }

    parseStudyTypes(studyTypes: StudyTypesRestApi): string[] {
        return studyTypes.types_of_study;
    }

    parseJournals(journals: JournalsRestApi): string[] {
        return journals.journals?.sort();
    }

    parseUnitConversions(unitConversions: UnitConversionRestApi[]): UnitConversion[] {
        return unitConversions;
    }

    parseShouldShowConcentration(
        concentrations: ConcentrationIdentifierRestApi[],
        identifier: string,
    ): boolean {
        return Boolean(
            identifier &&
                concentrations?.some(
                    ({ cui }: ConcentrationIdentifierRestApi) => cui === identifier,
                ),
        );
    }

    parseConcentrationMeasurement(
        measurement: ConcentrationMeasurementRestApi,
    ): ConcentrationMeasurement {
        return measurement
            ? {
                  value: measurement.concentration ?? null,
                  max: measurement.maxConcentration ?? null,
                  min: measurement.minConcentration ?? null,
                  source: measurement.source,
                  unit: measurement.unit as ConcentrationMeasurementUnit,
              }
            : null;
    }

    parseIngredients(ingredients: IngredientRestApi[]): Ingredient[] {
        return Array.isArray(ingredients) ? ingredients.map(this.parseIngredient) : [];
    }

    parseIngredient(ingredient: IngredientRestApi): Ingredient {
        return { id: ingredient.cui, name: ingredient.name };
    }

    parsePrevalences(prevalences: PrevalencesRestApi): Prevalences {
        return mapValues(prevalences, (prevalence: PrevalenceRestApi) =>
            this.parsePrevalence(prevalence),
        );
    }

    parsePrevalence(prevalence: PrevalenceRestApi): Prevalence {
        return prevalence && prevalence.percentage !== null
            ? {
                  percentage: this.parsePrevalencePercentage(prevalence.percentage),
                  detectedSamples: prevalence.detectedSamples,
                  totalSamples: prevalence.totalSamples,
              }
            : null;
    }

    parsePrevalencePercentage(percentage: number): number {
        return Number(percentage.toFixed(2));
    }

    parsePrevalenceRange(
        minPercentage: number,
        maxPercentage: number,
        minDetectedSamples: number,
        maxDetectedSamples: number,
    ): PrevalenceRange {
        return {
            percentage: [minPercentage, maxPercentage],
            detectedSamples: [minDetectedSamples, maxDetectedSamples],
        };
    }

    serializeConcentrationMeasurements(
        ...concentrationsPerIdentifier: [string, ConcentrationMeasurement][]
    ): ConcentrationMeasurementsRestApi {
        return fromPairs(
            concentrationsPerIdentifier
                .filter(([, concentration]: [string, ConcentrationMeasurement]) => concentration)
                .map(([id, concentration]: [string, ConcentrationMeasurement]) => [
                    id,
                    this.serializeConcentrationMeasurement(concentration),
                ]),
        );
    }

    serializeConcentrationMeasurement(
        concentration: ConcentrationMeasurement,
    ): ConcentrationMeasurementRestApi {
        return {
            concentration: concentration.value,
            maxConcentration: concentration.max,
            minConcentration: concentration.min,
            source: concentration.source,
            unit: concentration.unit,
        };
    }

    serializePrevalence(prevalence: Prevalence): PrevalenceRestApi {
        return prevalence
            ? {
                  percentage: prevalence.percentage,
                  detectedSamples: prevalence.detectedSamples,
                  totalSamples: prevalence.totalSamples,
              }
            : null;
    }
}
