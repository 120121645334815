import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromNotifications from './notifications.reducer';

export const getNotificationsState = createSelector(
    getDataState,
    (state: DataState) => state.notifications,
);
export const getMessage = createSelector(getNotificationsState, fromNotifications.getMessage);
export const getErrors = createSelector(getNotificationsState, fromNotifications.getErrors);
export const getLoading = createSelector(getNotificationsState, fromNotifications.getLoading);
export const getLoaded = createSelector(getNotificationsState, fromNotifications.getLoaded);
