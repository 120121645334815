import { createSelector, MemoizedSelector, DefaultProjectorFn } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from './../index';
import { RolesState } from './roles-state.interface';
import * as fromRoles from './roles.reducer';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

export const getRolesState: MemoizedSelector<
    object,
    RolesState,
    DefaultProjectorFn<RolesState>
> = createSelector(getDataState, (state: DataState) => state.roles);

export const getRolesErrors: MemoizedSelector<
    object,
    ErrorResponse[],
    DefaultProjectorFn<ErrorResponse[]>
> = createSelector(getRolesState, fromRoles.getRolesErrors);

export const getRolesList: MemoizedSelector<
    object,
    UserRole[],
    DefaultProjectorFn<UserRole[]>
> = createSelector(getRolesState, fromRoles.getRolesList);

export const getRolesLoading: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(getRolesState, fromRoles.getRolesLoading);

export const getRolesLoaded: MemoizedSelector<
    object,
    boolean,
    DefaultProjectorFn<boolean>
> = createSelector(getRolesState, fromRoles.getRolesLoaded);
