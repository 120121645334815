import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

/** Interfaces - Types */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import PathRestApi from '../rest-api-interfaces/path.rest.interface';
import PathNodeRestApi from '../rest-api-interfaces/path-node.rest.interface';
import ExplorationPath from '../interfaces/exploration-path.interface';
import PathSegmentEdge from '../interfaces/path-segment-edge.interface';
import PathSegmentNode from '../interfaces/path-segment-node.interface';
import PathSegments from '../types/path-segments.type';
import ExplainabilityPathRestApi from '../../explainability/rest-api-interfaces/explainability-path.rest.interface';
import MetapathBaseExplorationPath from '@leap-store/core/src/lib/data/metapaths/interfaces/metapath-base-exploration-path.interface';
import MetapathSegmentNode from '@leap-store/core/src/lib/data/metapaths/interfaces/metapath-segment-node.interface';
import CategoriesRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/categories.rest.interface';
import SubcategoriesRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/subcategories.rest.interface';
import RelationsRestApi from '@leap-store/core/src/lib/data/metapaths/rest-api-interfaces/relations.rest.interface';

@Injectable()
export class PathsParser {
    constructor() {}

    /** Parses Paginated Paths */
    parsePaginatedPaths(
        paginatedPaths: PaginatedResultsRestApi<PathRestApi>,
    ): PaginatedResults<ExplorationPath> {
        return {
            results: paginatedPaths.results ? this.parsePaths(paginatedPaths.results) : [],
            pageSize: paginatedPaths.pageSize,
            pageIndex: paginatedPaths.pageIndex,
            total: paginatedPaths.total,
        };
    }

    /** Parses BE Paths to integrate them on the FE */
    parsePaths(paths: PathRestApi[]): ExplorationPath[] {
        return paths.map((path: PathRestApi) => this.parsePath(path));
    }

    /** Parses PathRestApi to ExplorationPath */
    parsePath(path: PathRestApi): ExplorationPath {
        return {
            id: this.getUuid(),
            segments: this.parsePathSegments(path),
            importance: path.percentOfDWPC,
        };
    }

    /**
     * Parses PathRestApi to PathSegments.
     */
    parsePathSegments(path: PathRestApi | ExplainabilityPathRestApi): PathSegments {
        return [
            {
                node: this.parseNodeSegment(path.nodes[0]),
                edge: this.parseEdgeSegment(path.relations[0]),
            },
            {
                node: this.parseNodeSegment(path.nodes[1]),
                edge: this.parseEdgeSegment(path.relations[1]),
            },
            { node: this.parseNodeSegment(path.nodes[2]), edge: null },
        ];
    }

    /**
     * Parses a rest api path node into a node path segment
     */
    parseNodeSegment(node: PathNodeRestApi): PathSegmentNode {
        return {
            id: node.uid,
            name: node.name,
            category: node.category,
            subcategories: node.subcategories,
            tags: node.tags,
        };
    }

    /**
     * Creates an edge path segment from a relation string
     * */
    parseEdgeSegment(relation: string): PathSegmentEdge {
        return {
            relation,
        };
    }

    getUuid(): string {
        return uuid();
    }

    serializeCategories({ segments }: MetapathBaseExplorationPath): CategoriesRestApi {
        return segments.reduce(
            (
                categories: CategoriesRestApi,
                { node }: { node: MetapathSegmentNode; edge?: PathSegmentEdge },
                index: number,
            ) => {
                switch (index) {
                    case 0:
                        categories.source = node.category;
                        break;
                    case 1:
                        categories.intermediate = node.category;
                        break;
                    case 2:
                        categories.target = node.category;
                        break;
                }
                return categories;
            },
            { source: undefined, intermediate: undefined, target: undefined },
        );
    }

    serializeSubcategories({ segments }: MetapathBaseExplorationPath): SubcategoriesRestApi {
        return segments.reduce(
            (
                subcategories: SubcategoriesRestApi,
                { node }: { node: MetapathSegmentNode; edge?: PathSegmentEdge },
                index: number,
            ) => {
                switch (index) {
                    case 0:
                        subcategories.source = node.subcategories[0];
                        break;
                    case 1:
                        subcategories.intermediate = node.subcategories;
                        break;
                    case 2:
                        subcategories.target = node.subcategories[0];
                        break;
                }
                return subcategories;
            },
            { source: undefined, intermediate: undefined, target: undefined },
        );
    }

    serializeRelations({ segments }: MetapathBaseExplorationPath): RelationsRestApi {
        return segments.reduce(
            (
                relations: RelationsRestApi,
                { edge }: { node: MetapathSegmentNode; edge?: PathSegmentEdge },
                index: number,
            ) => {
                switch (index) {
                    case 0:
                        relations.source = edge.relation;
                        break;
                    case 1:
                        relations.target = edge.relation;
                        break;
                }
                return relations;
            },
            { source: undefined, target: undefined },
        );
    }
}
