import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    createUserRequest,
    updateUserRequest,
    deleteUserRequest,
    getUsersRequest,
    clearNextSuccess,
    clearNextError,
    deleteUsersRequest,
    clearShouldFetchUsers,
} from './users.actions';
import { UsersState } from './users-state.interface';
import User from '@leap-store/core/src/lib/data/users/interfaces/user.interface';
import UserProperties from '@leap-store/core/src/lib/data/users/interfaces/user-properties.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import {
    getEntities,
    getErrors,
    getSuccess,
    getLoading,
    getLoaded,
    getPageIndex,
    getPageSize,
    getTotal,
    getSortDirection,
    getSortColumn,
    getUpdatePendingItems,
    getDeletePendingItems,
    getEnhancedUsers,
    getShouldFetchUsers,
} from './users.selectors';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class UsersFacade {
    users$: Observable<User[]> = this.store.pipe(select(getEntities));
    enhancedUsers$: Observable<User[]> = this.store.pipe(select(getEnhancedUsers));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    success$: Observable<User[]> = this.store.pipe(select(getSuccess));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    sortDirection$: Observable<SortingOrder> = this.store.pipe(select(getSortDirection));
    sortColumn$: Observable<string> = this.store.pipe(select(getSortColumn));
    updatePendingItems$: Observable<string[]> = this.store.pipe(select(getUpdatePendingItems));
    deletePendingItems$: Observable<string[]> = this.store.pipe(select(getDeletePendingItems));
    shouldFetchUsers$: Observable<boolean> = this.store.pipe(select(getShouldFetchUsers));

    constructor(private store: Store<UsersState>) {}

    createUser(user: User, app: string): void {
        this.store.dispatch(createUserRequest({ user, app }));
    }

    updateUser(id: string, properties: UserProperties): void {
        this.store.dispatch(updateUserRequest({ id, properties }));
    }

    deleteUser(id: string): void {
        this.store.dispatch(deleteUserRequest({ id }));
    }

    deleteUsers(ids: string[]): void {
        this.store.dispatch(deleteUsersRequest({ ids }));
    }

    getUsers(
        currentUserEmail: string,
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
        app: string,
        department: string = '',
        search: string = '',
    ): void {
        this.store.dispatch(
            getUsersRequest({
                currentUserEmail,
                pageIndex,
                pageSize,
                sortDirection,
                sortColumn,
                app,
                department,
                search,
            }),
        );
    }

    clearNextSuccess(): void {
        this.store.dispatch(clearNextSuccess());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearShouldFetchUsers(): void {
        this.store.dispatch(clearShouldFetchUsers());
    }
}
