/** third-party imports */
import { Injectable } from '@angular/core';

/** custom imports */
import AbortSettings from '../interfaces/abort-settings.interface';

@Injectable()
export class FilesUtilitiesService {
    constructor() {}

    /**
     * Returns an instance of abort controller which we can use to cancel
     * upload procedure when necessary
     */
    getAbortSettingsInstance(): AbortSettings {
        const abortController: AbortController = new AbortController();
        const abortSignal: AbortSignal = abortController.signal;

        return { controller: abortController, signal: abortSignal };
    }
}
