import { createAction, props, ActionCreator } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthActionTypes } from './auth-action-types.enum';
import Jwt from './interfaces/jwt.interface';
import LoginUser from './interfaces/login-user.interface';
import CurrentUser from './interfaces/current-user.interface';
import TokenTypes from './enums/token-types.enum';

export const loginUserRequest: ActionCreator<
    AuthActionTypes.LOGIN_USER_REQUEST,
    (props: { user: LoginUser }) => {
        user: LoginUser;
    } & TypedAction<AuthActionTypes.LOGIN_USER_REQUEST>
> = createAction(AuthActionTypes.LOGIN_USER_REQUEST, props<{ user: LoginUser }>());

export const loginUserSuccess: ActionCreator<
    AuthActionTypes.LOGIN_USER_SUCCESS,
    (props: { jwt: Jwt; currentUser: CurrentUser }) => {
        jwt: Jwt;
        currentUser: CurrentUser;
    } & TypedAction<AuthActionTypes.LOGIN_USER_SUCCESS>
> = createAction(
    AuthActionTypes.LOGIN_USER_SUCCESS,
    props<{ jwt: Jwt; currentUser: CurrentUser }>(),
);

export const loginUserResetPasswordSuccess: ActionCreator<
    AuthActionTypes.LOGIN_USER_RESET_PASSWORD_SUCCESS,
    (props: { key: string }) => {
        key: string;
    } & TypedAction<AuthActionTypes.LOGIN_USER_RESET_PASSWORD_SUCCESS>
> = createAction(AuthActionTypes.LOGIN_USER_RESET_PASSWORD_SUCCESS, props<{ key: string }>());

export const loginUserFailure: ActionCreator<
    AuthActionTypes.LOGIN_USER_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<AuthActionTypes.LOGIN_USER_FAILURE>
> = createAction(AuthActionTypes.LOGIN_USER_FAILURE, props<{ errorResponse: HttpErrorResponse }>());

export const externalLoginUserRequest: ActionCreator<
    AuthActionTypes.EXTERNAL_LOGIN_USER_REQUEST,
    (props: { redirectUri: string; clientId: string; code: string; codeVerifier: string }) => {
        redirectUri: string;
        clientId: string;
        code: string;
        codeVerifier: string;
    } & TypedAction<AuthActionTypes.EXTERNAL_LOGIN_USER_REQUEST>
> = createAction(
    AuthActionTypes.EXTERNAL_LOGIN_USER_REQUEST,
    props<{ redirectUri: string; clientId: string; code: string; codeVerifier: string }>(),
);

export const externalLoginUserSuccess: ActionCreator<
    AuthActionTypes.EXTERNAL_LOGIN_USER_SUCCESS,
    (props: { jwt: Jwt; currentUser: CurrentUser }) => {
        jwt: Jwt;
        currentUser: CurrentUser;
    } & TypedAction<AuthActionTypes.EXTERNAL_LOGIN_USER_SUCCESS>
> = createAction(
    AuthActionTypes.EXTERNAL_LOGIN_USER_SUCCESS,
    props<{ jwt: Jwt; currentUser: CurrentUser }>(),
);

export const externalLoginUserFailure: ActionCreator<
    AuthActionTypes.EXTERNAL_LOGIN_USER_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<AuthActionTypes.EXTERNAL_LOGIN_USER_FAILURE>
> = createAction(
    AuthActionTypes.EXTERNAL_LOGIN_USER_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const logoutUserRequest: ActionCreator<
    AuthActionTypes.LOGOUT_USER_REQUEST,
    (props: { tokenKeys: TokenTypes[] }) => {
        tokenKeys: TokenTypes[];
    } & TypedAction<AuthActionTypes.LOGOUT_USER_REQUEST>
> = createAction(AuthActionTypes.LOGOUT_USER_REQUEST, props<{ tokenKeys: TokenTypes[] }>());

export const logoutUserSuccess: ActionCreator<
    AuthActionTypes.LOGOUT_USER_SUCCESS,
    (props: { message: string }) => {
        message: string;
    } & TypedAction<AuthActionTypes.LOGOUT_USER_SUCCESS>
> = createAction(AuthActionTypes.LOGOUT_USER_SUCCESS, props<{ message: string }>());

export const logoutUserFailure: ActionCreator<
    AuthActionTypes.LOGOUT_USER_FAILURE,
    (props: { message: string }) => {
        message: string;
    } & TypedAction<AuthActionTypes.LOGOUT_USER_FAILURE>
> = createAction(AuthActionTypes.LOGOUT_USER_FAILURE, props<{ message: string }>());

export const rehydrateToken: ActionCreator<
    AuthActionTypes.REHYDRATE_TOKEN,
    (props: { key: TokenTypes }) => {
        key: TokenTypes;
    } & TypedAction<AuthActionTypes.REHYDRATE_TOKEN>
> = createAction(AuthActionTypes.REHYDRATE_TOKEN, props<{ key: TokenTypes }>());

export const rehydrateTokenSuccess: ActionCreator<
    AuthActionTypes.REHYDRATE_TOKEN_SUCCESS,
    (props: { key: TokenTypes; token: string }) => {
        key: TokenTypes;
        token: string;
    } & TypedAction<AuthActionTypes.REHYDRATE_TOKEN_SUCCESS>
> = createAction(
    AuthActionTypes.REHYDRATE_TOKEN_SUCCESS,
    props<{ key: TokenTypes; token: string }>(),
);

export const rehydrateTokenFailure: ActionCreator<
    AuthActionTypes.REHYDRATE_TOKEN_FAILURE,
    (props: {
        key: TokenTypes;
    }) => { key: TokenTypes } & TypedAction<AuthActionTypes.REHYDRATE_TOKEN_FAILURE>
> = createAction(AuthActionTypes.REHYDRATE_TOKEN_FAILURE, props<{ key: TokenTypes }>());

export const refreshLoginRequest: ActionCreator<
    AuthActionTypes.REFRESH_LOGIN_REQUEST,
    () => TypedAction<AuthActionTypes.REFRESH_LOGIN_REQUEST>
> = createAction(AuthActionTypes.REFRESH_LOGIN_REQUEST);

export const refreshLoginSuccess: ActionCreator<
    AuthActionTypes.REFRESH_LOGIN_SUCCESS,
    (props: { jwt: Jwt }) => {
        jwt: Jwt;
    } & TypedAction<AuthActionTypes.REFRESH_LOGIN_SUCCESS>
> = createAction(AuthActionTypes.REFRESH_LOGIN_SUCCESS, props<{ jwt: Jwt }>());

export const refreshLoginFailure: ActionCreator<
    AuthActionTypes.REFRESH_LOGIN_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<AuthActionTypes.REFRESH_LOGIN_FAILURE>
> = createAction(
    AuthActionTypes.REFRESH_LOGIN_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getCurrentUser: ActionCreator<
    AuthActionTypes.GET_CURRENT_USER,
    () => TypedAction<AuthActionTypes.GET_CURRENT_USER>
> = createAction(AuthActionTypes.GET_CURRENT_USER);

export const getCurrentUserSuccess: ActionCreator<
    AuthActionTypes.GET_CURRENT_USER_SUCCESS,
    (props: { currentUser: CurrentUser }) => {
        currentUser: CurrentUser;
    } & TypedAction<AuthActionTypes.GET_CURRENT_USER_SUCCESS>
> = createAction(AuthActionTypes.GET_CURRENT_USER_SUCCESS, props<{ currentUser: CurrentUser }>());

export const getCurrentUserFailure: ActionCreator<
    AuthActionTypes.GET_CURRENT_USER_FAILURE,
    () => TypedAction<AuthActionTypes.GET_CURRENT_USER_FAILURE>
> = createAction(AuthActionTypes.GET_CURRENT_USER_FAILURE);

export const acceptTermsOfUseRequest: ActionCreator<
    AuthActionTypes.ACCEPT_TERMS_OF_USE_REQUEST,
    () => TypedAction<AuthActionTypes.ACCEPT_TERMS_OF_USE_REQUEST>
> = createAction(AuthActionTypes.ACCEPT_TERMS_OF_USE_REQUEST);

export const acceptTermsOfUseSuccess: ActionCreator<
    AuthActionTypes.ACCEPT_TERMS_OF_USE_SUCCESS,
    (props: { jwt: Jwt }) => {
        jwt: Jwt;
    } & TypedAction<AuthActionTypes.ACCEPT_TERMS_OF_USE_SUCCESS>
> = createAction(AuthActionTypes.ACCEPT_TERMS_OF_USE_SUCCESS, props<{ jwt: Jwt }>());

export const acceptTermsOfUseFailure: ActionCreator<
    AuthActionTypes.ACCEPT_TERMS_OF_USE_FAILURE,
    (props: { errorResponse: HttpErrorResponse }) => {
        errorResponse: HttpErrorResponse;
    } & TypedAction<AuthActionTypes.ACCEPT_TERMS_OF_USE_FAILURE>
> = createAction(
    AuthActionTypes.ACCEPT_TERMS_OF_USE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetAuth: ActionCreator<
    AuthActionTypes.RESET_AUTH,
    () => TypedAction<AuthActionTypes.RESET_AUTH>
> = createAction(AuthActionTypes.RESET_AUTH);

export const clearResetPasswordKey: ActionCreator<
    AuthActionTypes.CLEAR_RESET_PASSWORD_KEY,
    () => TypedAction<AuthActionTypes.CLEAR_RESET_PASSWORD_KEY>
> = createAction(AuthActionTypes.CLEAR_RESET_PASSWORD_KEY);

export const clearNextError: ActionCreator<
    AuthActionTypes.CLEAR_NEXT_ERROR,
    () => TypedAction<AuthActionTypes.CLEAR_NEXT_ERROR>
> = createAction(AuthActionTypes.CLEAR_NEXT_ERROR);
