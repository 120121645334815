/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { MetadataActionTypes } from './metadata-action-types.enum';
import HealthLabel from './interfaces/health-label.interface';
import Identifiers from './types/identifiers.type';
import OriginsInfoPerRelationship from './types/origins-info-per-relationship.type';
import UnitConversion from './interfaces/unit-conversion.interface';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';
import ProteinOrigins from './types/protein-origins.type';
import PatentsCounts from './types/patents-counts.type';
import ContainingIngredients from './types/containing-ingredients.type';
import Prevalences from './types/prevalences.type';

export const getLabsRequest = createAction(MetadataActionTypes.GET_LABS_REQUEST);

export const getLabsSuccess = createAction(
    MetadataActionTypes.GET_LABS_SUCCESS,
    props<{ labs: string[] }>(),
);

export const getLabsFailure = createAction(
    MetadataActionTypes.GET_LABS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getHealthLabelsRequest = createAction(MetadataActionTypes.GET_HEALTH_LABELS_REQUEST);

export const getHealthLabelsSuccess = createAction(
    MetadataActionTypes.GET_HEALTH_LABELS_SUCCESS,
    props<{ healthLabels: HealthLabel[] }>(),
);

export const getHealthLabelsFailure = createAction(
    MetadataActionTypes.GET_HEALTH_LABELS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getPrevalencesRequest = createAction(
    MetadataActionTypes.GET_PREVALENCES_REQUEST,
    props<{ ids: string[] }>(),
);

export const getPrevalencesSuccess = createAction(
    MetadataActionTypes.GET_PREVALENCES_SUCCESS,
    props<{ prevalences: Prevalences }>(),
);

export const getPrevalencesFailure = createAction(
    MetadataActionTypes.GET_PREVALENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearPrevalences = createAction(MetadataActionTypes.CLEAR_PREVALENCES);

export const getIdentifiersRequest = createAction(
    MetadataActionTypes.GET_IDENTIFIERS_REQUEST,
    props<{ ids: string[]; bookmarkIds: BookmarkIds }>(),
);

export const getIdentifiersSuccess = createAction(
    MetadataActionTypes.GET_IDENTIFIERS_SUCCESS,
    props<{ identifiers: Identifiers }>(),
);

export const getIdentifiersFailure = createAction(
    MetadataActionTypes.GET_IDENTIFIERS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearIdentifiers = createAction(MetadataActionTypes.CLEAR_IDENTIFIERS);

export const getProteinOriginsRequest = createAction(
    MetadataActionTypes.GET_PROTEIN_ORIGINS_REQUEST,
    props<{ ids: string[] }>(),
);

export const getProteinOriginsSuccess = createAction(
    MetadataActionTypes.GET_PROTEIN_ORIGINS_SUCCESS,
    props<{ proteinOrigins: ProteinOrigins }>(),
);

export const getProteinOriginsFailure = createAction(
    MetadataActionTypes.GET_PROTEIN_ORIGINS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearProteinOrigins = createAction(MetadataActionTypes.CLEAR_PROTEIN_ORIGINS);

export const getPatentsCountsRequest = createAction(
    MetadataActionTypes.GET_PATENTS_COUNTS_REQUEST,
    props<{ ids: string[] }>(),
);

export const getPatentsCountsSuccess = createAction(
    MetadataActionTypes.GET_PATENTS_COUNTS_SUCCESS,
    props<{ patentsCounts: PatentsCounts }>(),
);

export const getPatentsCountsFailure = createAction(
    MetadataActionTypes.GET_PATENTS_COUNTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearPatentsCounts = createAction(MetadataActionTypes.CLEAR_PATENTS_COUNTS);

export const getContainingIngredientsRequest = createAction(
    MetadataActionTypes.GET_CONTAINING_INGREDIENTS_REQUEST,
    props<{ ids: string[]; bookmarkIds: BookmarkIds }>(),
);

export const getContainingIngredientsSuccess = createAction(
    MetadataActionTypes.GET_CONTAINING_INGREDIENTS_SUCCESS,
    props<{ containingIngredients: ContainingIngredients }>(),
);

export const getContainingIngredientsFailure = createAction(
    MetadataActionTypes.GET_CONTAINING_INGREDIENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearContainingIngredients = createAction(
    MetadataActionTypes.CLEAR_CONTAINING_INGREDIENTS,
);

export const getRelationshipsInfoRequest = createAction(
    MetadataActionTypes.GET_RELATIONSHIPS_INFO_REQUEST,
    props<{ ids: [string, string][]; bookmarkIds: BookmarkIds }>(),
);

export const getRelationshipsInfoSuccess = createAction(
    MetadataActionTypes.GET_RELATIONSHIPS_INFO_SUCCESS,
    props<{ relationshipsInfo: Record<string, OriginsInfoPerRelationship> }>(),
);

export const getRelationshipsInfoFailure = createAction(
    MetadataActionTypes.GET_RELATIONSHIPS_INFO_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearRelationshipsInfo = createAction(MetadataActionTypes.CLEAR_RELATIONSHIPS_INFO);

export const getStudyTypesRequest = createAction(MetadataActionTypes.GET_STUDY_TYPES_REQUEST);

export const getStudyTypesSuccess = createAction(
    MetadataActionTypes.GET_STUDY_TYPES_SUCCESS,
    props<{ studyTypes: string[] }>(),
);

export const getStudyTypesFailure = createAction(
    MetadataActionTypes.GET_STUDY_TYPES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getJournalsRequest = createAction(MetadataActionTypes.GET_JOURNALS_REQUEST);

export const getJournalsSuccess = createAction(
    MetadataActionTypes.GET_JOURNALS_SUCCESS,
    props<{ journals: string[] }>(),
);

export const getJournalsFailure = createAction(
    MetadataActionTypes.GET_JOURNALS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getUnitConversionsRequest = createAction(
    MetadataActionTypes.GET_UNIT_CONVERSIONS_REQUEST,
);

export const getUnitConversionsSuccess = createAction(
    MetadataActionTypes.GET_UNIT_CONVERSIONS_SUCCESS,
    props<{ unitConversions: UnitConversion[] }>(),
);

export const getUnitConversionsFailure = createAction(
    MetadataActionTypes.GET_UNIT_CONVERSIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getArticlesIngestionDateRequest = createAction(
    MetadataActionTypes.GET_ARTICLES_INGESTION_DATE_REQUEST,
);

export const getArticlesIngestionDateSuccess = createAction(
    MetadataActionTypes.GET_ARTICLES_INGESTION_DATE_SUCCESS,
    props<{ articlesIngestionDate: string }>(),
);

export const getArticlesIngestionDateFailure = createAction(
    MetadataActionTypes.GET_ARTICLES_INGESTION_DATE_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(MetadataActionTypes.CLEAR_NEXT_ERROR);
