import { Injectable } from '@angular/core';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Injectable()
export class JwtOptionsService {
    constructor() {}

    /** list with domains that are allowed to perform authenticated requests */
    allowedDomains: string[] = ['localhost:3000'];

    /** it returns the user's token */
    tokenGetter(): string {
        return localStorage.getItem(TokenTypes.access);
    }
}
