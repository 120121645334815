import { Injectable } from '@angular/core';
import { uniq } from 'lodash';

/** Services - Parsers */
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { MetadataParser } from '../../../metadata/parsers/metadata.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';

/** Interfaces - Types - Enums */
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import PaginatedInsightsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import InsightCategoriesRestApi from '@apps/leap/src/app/shared/rest-api-types/insight-categories.rest.type';
import InsightsPerCategoryRestApi from '../rest-api-types/insights-per-category.rest.type';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import Insight from '../interfaces/insight.interface';
import InsightsPerCategory from '../types/insights-per-category.type';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import AssociationOrigin from '@apps/leap/src/app/shared/enums/association-origin.enum';

@Injectable()
export class OpenDiscoveryParser {
    constructor(
        private insightParser: InsightParser,
        private metadataParser: MetadataParser,
        private insightsService: InsightsService,
        private arrayHandlerService: ArrayHandlerService,
    ) {}

    /** Parses Paginated Insights */
    parsePaginatedResults(
        paginatedInsights: PaginatedInsightsRestApi<InsightRestApi, InsightsPerCategoryRestApi>,
    ): PaginatedInsights {
        const totalCategories: InsightCategoriesRestApi[] = paginatedInsights?.results?.map(
            ({ targetCategories }: InsightRestApi) => targetCategories,
        );

        return {
            results: paginatedInsights.results ? this.parseInsights(paginatedInsights.results) : [],
            filterCounts: this.insightParser.parseFilterCounts(paginatedInsights.counts),
            displayingInsights: paginatedInsights.totalDisplayingInsights,
            total: paginatedInsights.total,
            oldestOccurrence: paginatedInsights.totalOldestPublicationDate,
            newestOccurrence: paginatedInsights.totalNewestPublicationDate,
            minCowMilkConcentration: paginatedInsights.smallestCowMilkConcentration,
            maxCowMilkConcentration: paginatedInsights.largestCowMilkConcentration,
            minSourceConcentration: paginatedInsights.smallestSourceConcentration,
            maxSourceConcentration: paginatedInsights.largestSourceConcentration,
            prevalenceRange: this.metadataParser.parsePrevalenceRange(
                paginatedInsights.smallestPrevalence,
                paginatedInsights.largestPrevalence,
                paginatedInsights.smallestDetectedSamples,
                paginatedInsights.largestDetectedSamples,
            ),
            minMoleculeWeight: paginatedInsights.smallestMolecularWeight,
            maxMoleculeWeight: paginatedInsights.largestMolecularWeight,
            shouldShowSourceConcentration: this.metadataParser.parseShouldShowConcentration(
                paginatedInsights.concentrationCuis,
                paginatedInsights?.results?.[0]?.sourceUid,
            ),
            pageIndex: paginatedInsights.pageIndex,
            pageSize: paginatedInsights.pageSize,
            insightsPerCategory: this.parseInsightsPerCategory(
                paginatedInsights.totalInsightsPerCategory,
            ),
            groupedCategories: this.insightsService.getGroupedCategories(totalCategories),
            preferences: paginatedInsights.preferences,
        };
    }

    /** Parses BE Insights to integrate them on the FE */
    parseInsights(insights: InsightRestApi[]): Insight[] {
        return insights.map((insight: InsightRestApi) => this.parseInsight(insight));
    }

    /** Parses InsightRestApi to Insight */
    parseInsight(insight: InsightRestApi): Insight {
        const associationOrigins: AssociationOrigin[] = this.insightParser.parseAssociationOrigins(
            insight.associationOrigins,
        );
        const associationIds: string[] = associationOrigins.map(
            (associationOrigin: AssociationOrigin) =>
                this.insightsService.generateAssociationId(associationOrigin, insight.isNovel),
        );
        const relationshipType: Record<string, string> = this.insightParser.parseRelationshipType(
            insight.relationshipType,
        );
        const sortedSourceCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(insight.sourceCategories);
        const sortedTargetCategories: InsightCategoriesRestApi =
            this.arrayHandlerService.sortObjectByValues(insight.targetCategories);

        return {
            id: `${insight.sourceUid}_${insight.targetUid}`,
            type: Discovery.open,
            sourceCategories: sortedSourceCategories || {},
            sourceId: insight.sourceUid,
            sourceName: insight.sourceName,
            sourcePrior: insight.sourcePrior,
            targetCategories: sortedTargetCategories || {},
            targetId: insight.targetUid,
            targetName: insight.targetName,
            targetPrior: insight.targetPrior,
            totalScore: this.insightParser.parseScore(insight.totalScore),
            weightRaw: insight.weightRawDirect,
            weightScore: insight.weightScoreDirect,
            rankingIndex: insight.index,
            isNovel: insight.isNovel,
            associationOrigins,
            associationIds,
            strength: this.insightParser.parseScoreClassification(insight.associationStrength),
            relationshipType,
            relationshipTypeValues:
                this.insightParser.parseRelationshipTypeValues(relationshipType),
            isRelationshipTypePredicted: insight.isRelationshipTypePredicted,
            predictedRelationshipTypeProbability: this.insightParser.parseProbability(
                insight.relationshipTypeProbability,
            ),
            linkProbability: insight.linkProbability,
            targetArticlesCount: this.insightParser.parseArticlesCount(insight.noOfArticles),
            patentsCount: insight.noOfPatents,
            newestOccurrence: this.insightParser.parseDate(insight.newestPublicationDate),
            oldestOccurrence: this.insightParser.parseDate(insight.oldestPublicationDate),
            sortTargetName: insight.targetName.toLowerCase(),
            synonyms: this.insightParser.parseSynonyms(insight.synonyms),
            koOriginDatabases: this.insightParser.parseKOOriginDatabases(insight.koSourceDB),
            sourceTags: this.insightParser.parseTags(insight.sourceTags?.Tags),
            targetTags: this.insightParser.parseTags(insight.targetTags?.Tags),
            sourceHealthLabels: this.insightParser.parseHealthLabels(
                insight.sourceTags?.['Health areas'],
            ),
            targetHealthLabels: this.insightParser.parseHealthLabels(
                insight.targetTags?.['Health areas'],
            ),
            studyTypes: insight.typesOfStudy || [],
            journals: this.insightParser.parseJournals(insight.journals),
            sourceMolecules: this.insightParser.parseMolecules(
                insight.sourceTags?.['Molecule classification'],
            ),
            targetMolecules: this.insightParser.parseMolecules(
                insight.targetTags?.['Molecule classification'],
            ),
            sourceLabs: this.insightParser.parseLabs(insight.sourceTags?.['UCD DMD lab']),
            targetLabs: this.insightParser.parseLabs(insight.targetTags?.['UCD DMD lab']),
            sourceProfileCategory: this.insightParser.parseProfileCategories(
                insight.sourceTags?.['Uniqueness profile'],
            )?.[0],
            targetProfileCategory: this.insightParser.parseProfileCategories(
                insight.targetTags?.['Uniqueness profile'],
            )?.[0],
            subcategories: insight.targetCategories
                ? Object.keys(insight.targetCategories).sort(
                      (subcategoryA: string, subcategoryB: string) =>
                          this.arrayHandlerService.sort(subcategoryA, subcategoryB, {
                              direction: SortingOrder.ascending,
                          }),
                  )
                : [],
            categories: sortedTargetCategories ? uniq(Object.values(sortedTargetCategories)) : [],
            moleculeWeight: insight.molecularWeight,
            cowMilkConcentration: insight.cowMilkConcentration,
            prevalence: this.metadataParser.parsePrevalence(insight.prevalence),
            articlesCount: insight.noOfCommonArticles,
            proteinOrigins: this.insightParser.parseProteinOrigins(insight.sourceProtein),
            sourceConcentration: this.metadataParser.parseConcentrationMeasurement(
                insight.concentrations?.[insight.sourceUid],
            ),
        };
    }

    parseInsightsPerCategory(insightsPerCategory: InsightsPerCategoryRestApi): InsightsPerCategory {
        const parsedInsightsPerCategory: InsightsPerCategory = {};

        Object.keys(insightsPerCategory).forEach((category: string) => {
            parsedInsightsPerCategory[category] = {
                total: insightsPerCategory[category].total,
                novel: insightsPerCategory[category].novel,
            };
        });
        return parsedInsightsPerCategory;
    }

    serializeInsight(insight: Insight): InsightRestApi {
        return {
            sourceCategories: insight.sourceCategories,
            sourceUid: insight.sourceId,
            sourceName: insight.sourceName,
            sourcePrior: insight.sourcePrior,
            targetCategories: insight.targetCategories,
            targetUid: insight.targetId,
            targetName: insight.targetName,
            targetPrior: insight.targetPrior,
            totalScore: insight.totalScore,
            weightRawDirect: insight.weightRaw,
            weightScoreDirect: insight.weightScore,
            index: insight.rankingIndex,
            isNovel: insight.isNovel,
            associationOrigins: this.insightParser.serializeAssociationOrigins(
                insight.associationOrigins,
            ),
            associationStrength: insight.strength,
            relationshipType: this.insightParser.serializeRelationshipType(
                insight.relationshipType,
            ),
            isRelationshipTypePredicted: insight.isRelationshipTypePredicted,
            relationshipTypeProbability: insight.predictedRelationshipTypeProbability,
            linkProbability: insight.linkProbability,
            noOfArticles: this.insightParser.serializeArticlesCount(insight.targetArticlesCount),
            noOfPatents: insight.patentsCount,
            newestPublicationDate: this.insightParser.serializeDate(insight.newestOccurrence),
            oldestPublicationDate: this.insightParser.serializeDate(insight.oldestOccurrence),
            synonyms: this.insightParser.serializeSynonyms(insight.synonyms),
            koSourceDB: this.insightParser.serializeKOOriginDatabases(insight.koOriginDatabases),
            sourceTags: this.insightParser.serializeTags(
                insight.sourceTags,
                insight.sourceHealthLabels,
                insight.sourceLabs,
                insight.sourceMolecules,
                this.insightParser.serializeProfileCategories([insight.sourceProfileCategory]),
            ),
            targetTags: this.insightParser.serializeTags(
                insight.targetTags,
                insight.targetHealthLabels,
                insight.targetLabs,
                insight.targetMolecules,
                this.insightParser.serializeProfileCategories([insight.targetProfileCategory]),
            ),
            typesOfStudy: insight.studyTypes,
            journals: insight.journals,
            molecularWeight: insight.moleculeWeight,
            cowMilkConcentration: insight.cowMilkConcentration,
            prevalence: this.metadataParser.serializePrevalence(insight.prevalence),
            noOfCommonArticles: insight.articlesCount,
            sourceProtein: this.insightParser.serializeProteinOrigins(insight.proteinOrigins),
            concentrations: this.metadataParser.serializeConcentrationMeasurements([
                insight.sourceId,
                insight.sourceConcentration,
            ]),
        };
    }
}
