import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import UserRole from '@leap-store/core/src/lib/data/roles/enums/user-role.enum';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';
import Role from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

@Injectable()
export class RoleService {
    currentUser$: Observable<CurrentUser> = this.authFacade.currentUser$;

    constructor(private authFacade: AuthFacade) {}

    /**
     * Triggers the authFacade.getCurrentUser().
     * Maps the currentUser$ and checks whether a user has administrator privileges or not.
     * Returns an Observable<boolean> based on the above condition.
     */
    hasUserAdministratorPrivileges(): Observable<boolean> {
        this.authFacade.getCurrentUser();

        return this.currentUser$.pipe(
            map((currentUser: CurrentUser) =>
                Boolean(currentUser?.roles?.some((role: Role) => role.name === UserRole.admin)),
            ),
        );
    }
}
