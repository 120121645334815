import { formatDate } from '@angular/common';
import { camelCase, isEqual } from 'lodash';
import { MonoTypeOperatorFunction } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { SPECIAL_CHARACTERS_REGEX } from '@leap-common/constants/regexes';
import {
    DEFAULT_TRUNCATION_LIMIT,
    DOT,
    ELLIPSIS,
    EMPTY_STRING,
    MINIMUM_DISPLAYED_VALUE,
    WHITESPACE,
} from '@leap-common/constants/common';
import { UNDERSCORE_DELIMITER } from '@leap-common/constants/delimiters';

export const hexToRGBA = (hex: string, alpha: number = 1): string => {
    const r: number = parseInt(hex.slice(1, 3), 16);
    const g: number = parseInt(hex.slice(3, 5), 16);
    const b: number = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const elementHasAncestor = <T extends HTMLElement = HTMLElement>(
    element: T,
    selector: string,
): boolean => Boolean(element.closest(selector));

export const isWhitelistedElementClicked = <T extends HTMLElement = HTMLElement>(
    clickedElement: T,
    whitelistedSelectors: string[],
): boolean => elementHasAncestor(clickedElement, whitelistedSelectors.join(','));

/**
 * This method is not intended to act as a unique id generator.
 * Accepts a string and optionally a splitDelimiter and a joinDelimiter and generates an id based on the provided value.
 * It converts the string value to lowercase, splits it to splitDelimiter and gets the first element of the produced array.
 * Replaces the ' ' with the provided joinDelimiter.
 * If no splitDelimiter is provided the default value is '/'
 * If no joinDelimiter is provided the default value is '-'
 */
export const generateIdBasedOnDelimitedValue = (
    value: string,
    splitDelimiter: string = '/',
    joinDelimiter: string = '-',
): string =>
    value?.toLowerCase().split(splitDelimiter)[0].split(WHITESPACE).join(joinDelimiter) ||
    EMPTY_STRING;

export const replaceDelimiter = (
    value: string,
    oldDelimiter: string = WHITESPACE,
    newDelimiter: string = UNDERSCORE_DELIMITER,
): string => value.replace(new RegExp(oldDelimiter, 'g'), newDelimiter);

/**
 * Generates a random number between [0 - limit)
 */
export const generateRandomNumber = (limit: number): number => Math.floor(Math.random() * limit);

/**
 * Determines if the provided value is actually the first occurrence within the array, matching
 * intentionally the signature expected for the `Array.prototype.filter` callback.
 * Despite its simple implementation, when used in a `Array.prototype.filter` call it enables the
 * <em>creation a new array of unique elements</em>. It is a good alternative to Set API because
 * there the original order of elements is not guaranteed (it produces an unsorted set).
 */
export const isFirstOccurrence = <T>(value: T, index: number, array: T[]): boolean =>
    array.indexOf(value) === index;

/**
 * Determines if a value is truthy (non-undefined, non-null, non 0, non-empty-string, etc).
 * It is meant to be used in a `Array.prototype.filter` call, whenever we want to filter out falsy elements
 * and keep the truthy ones. The main benefit that this simple implementation provides is that the
 * system doesn't have to create multiple times this callback to pass it in this filtering chains.
 */
export const isTruthy = <T>(value: T): boolean => Boolean(value);

/**
 * Accepts an ms number and "waits" that many milliseconds. Essentially it can be viewed as a more
 * "clean" and declarative setTimeout() and can be used in situations where we need to defer the execution
 * of the code for a few moments.
 */
export const wait = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Filters object properties based on a callback function.
 */
export const filterObject = <T = Record<string, unknown>>(
    object: T,
    filter?: (value: T[keyof T], key: keyof T) => boolean,
): Partial<T> =>
    (Object.keys(object) as (keyof T)[]).reduce((accumulator: T, key: keyof T) => {
        if (filter(object[key], key)) {
            accumulator[key] = object[key];
        }
        return accumulator;
    }, {});

/**
 * Finds and returns the first matched sibling using a selector.
 */
export const findNextSibling = <R extends Element = HTMLElement>(
    element: Element,
    selector: string,
): R | null => {
    // If there's no selector, return null
    if (!selector) {
        return null;
    }

    let sibling: Element = element.nextElementSibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) {
            return sibling as R;
        }
        sibling = sibling.nextElementSibling;
    }

    return null;
};

/**
 * Query element itself or its descendants to find the one bearing the described data attribute
 * and retrieve its string value.
 */
export const extractDataAttributeValue = (
    source: HTMLElement,
    dataAttributeName: `data-${string}`,
): string => {
    const foundDataElement: HTMLElement = source.hasAttribute(dataAttributeName)
        ? source
        : source.querySelector(`[${dataAttributeName}]`);
    const startPropertyIndex: number = dataAttributeName.indexOf('-') + 1;
    const dataPropertyName: string = camelCase(dataAttributeName.substring(startPropertyIndex));
    return foundDataElement?.dataset[dataPropertyName];
};

/**
 * Creates the distinctUntilChanged pipe with a callback function that uses the lodash method isEqual
 * to compare objects.
 */
export const createDistinctUntilObjectChanged = <T>(): MonoTypeOperatorFunction<T> =>
    distinctUntilChanged<T>((objectA: T, objectB: T) => isEqual(objectA, objectB));

export const getTimestamp = (): string => formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss', 'en');

export const getDate = (): string => formatDate(new Date(), 'yyyy-MM-dd', 'en');

export const joinFilenameParts = (parts: string[], extension: string = EMPTY_STRING): string =>
    `${parts.filter(isTruthy).join(UNDERSCORE_DELIMITER)}${
        extension ? DOT : EMPTY_STRING
    }${extension}`;

/**
 * Escapes special regex characters in a string which is going to be used as a pattern
 * (i.e. argument in a RegExp constructor call)
 */
export const escapeRegExpSpecialChars = (rawPattern: string): string =>
    rawPattern.replace(SPECIAL_CHARACTERS_REGEX, '\\$&');

export const stripHTMLTags = (html: string): string =>
    html && new DOMParser().parseFromString(html, 'text/html').body.textContent;

/**
 * Prevents the click event from bubbling up to the parent element and cancels the
 * event if it is cancelable.
 */
export const preventDefaultAndStopPropagation = (event: MouseEvent): void => {
    event.preventDefault();
    event.stopPropagation();
};

export const truncate = (
    text: string,
    limit: number = DEFAULT_TRUNCATION_LIMIT,
    ellipsis: string = ELLIPSIS,
): null | string => {
    if (!text) {
        return '';
    }
    const shouldUseEllipsis: boolean = text.length > limit;
    return `${text.substring(0, limit)}${shouldUseEllipsis ? ellipsis : EMPTY_STRING}`;
};

export const roundAndApplyThreshold = (
    value: number,
    locale?: string,
    decimalDigits: number = 2,
    threshold: number = MINIMUM_DISPLAYED_VALUE,
): string =>
    value < threshold
        ? `<${threshold}`
        : locale
        ? Number(value.toFixed(decimalDigits)).toLocaleString(locale, {
              minimumFractionDigits: decimalDigits,
          })
        : value.toFixed(decimalDigits);

/**
 * Maps a number between 0 and 1 to a percentage.
 * Example: value = 0.1234 and decimalDigits = 1, returns 12.3
 */
export const mapDecimalToPercentage = (value: number, decimalDigits?: number): number => {
    const percentage: number = 100 * value;
    return decimalDigits === undefined ? percentage : Number(percentage.toFixed(decimalDigits));
};

export const mapEmailToUsername = (email: string): string => email.split('@')[0];

export const findIndexByProperty = <T extends Record<string, any>>(
    searchedItem: T,
    items: T[],
    property: keyof T,
): number => items?.findIndex((item: T) => item?.[property] === searchedItem?.[property]);

/**
 * A simplified pluralize utility, that can be used to avoid repeating conditionals.
 *
 * @param singularWord A string in singular form that will be returned in plural.
 * @param count The number that determines if the pluralize will be applied. If not provided the plural form is returned.
 * @returns The plural form of given singular word.
 */
export const pluralize = (singularWord: string, count?: number): string => {
    if (count === 1 || count === -1 || !singularWord) {
        return singularWord;
    }

    // List of common endings that require "es" for pluralization.
    // There are exceptions (e.g.: photo > photos).
    const endingsRequiringEsSuffix: string[] = ['s', 'z', 'o', 'x', 'sh', 'ch'];

    const requiresEsSuffix: boolean = endingsRequiringEsSuffix.some((suffix: string) =>
        singularWord.endsWith(suffix),
    );

    const yEnding: string = 'y';
    const hasYEnding: boolean = singularWord.endsWith(yEnding);

    return requiresEsSuffix
        ? `${singularWord}es`
        : hasYEnding
        ? `${singularWord.slice(0, -1)}ies`
        : `${singularWord}s`;
};

/**
 * Compares versions with a dot delimiter, for example 1.2.3 > 1.1.4.
 * Returns true when versionA is newer than versionB.
 */
export const isNewerVersion = (versionA: string, versionB: string): boolean => {
    const partsA: number[] = versionA?.split(DOT).map(Number) || [];
    const partsB: number[] = versionB?.split(DOT).map(Number) || [];

    for (let index: number = 0; index < partsA.length; index++) {
        const partA: number = partsA[index];
        const partB: number = partsB[index];

        if (partA > partB) {
            return true;
        } else if (partA < partB) {
            return false;
        }
    }

    return false;
};

export const isNewerOrSameVersion = (versionA: string, versionB: string): boolean =>
    isNewerVersion(versionA, versionB) || versionA === versionB;

export const scrollToBottom = (element: EventTarget): void => {
    if (!(element instanceof HTMLElement)) {
        return;
    }
    element.scrollTop = element.scrollHeight;
};

export const mapUploaderEventToFile = (event: Event): File =>
    (event.target as HTMLInputElement).files?.[0];

// Use Clipboard API to copy text
export const copyToClipboard = (text: string): Promise<void> => navigator.clipboard.writeText(text);

/**
 * Retrieves the computed styles (CSS properties) of the root element.
 * It uses `getComputedStyle()` to return the styles that are actually
 * being applied to the `document.documentElement` (the root HTML element) after
 * all stylesheets and inline styles have been processed.
 */
export const computedStyles: CSSStyleDeclaration = getComputedStyle(document.documentElement);

/**
 * Retrieves the computed value of a specified CSS property and trims any whitespace.
 */
export const getComputedPropertyValue = (property: string): string =>
    computedStyles.getPropertyValue(property).trim();

export const joinWithDifferentLastDelimiter = (
    array: (string | number)[],
    delimiter: string,
    lastDelimiter: string,
): string =>
    array.length > 1
        ? `${array.slice(0, -1).join(delimiter)}${lastDelimiter}${array[array.length - 1]}`
        : array.join(EMPTY_STRING);

/**
 * Opens the user's email client to send an email with the provided subject and body.
 */
export const openEmailClient = (subject: string, body: string): void => {
    window.open(`mailto:?subject=${subject}&body=${encodeURIComponent(body)}`, '_self');
};
