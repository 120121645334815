import { Injectable } from '@angular/core';
import { fromPairs } from 'lodash';

/** Constants */
import { US_LOCALE } from '@leap-common/constants/common';

/** Types - Enums */
import GenericSortFunction from '@leap-common/types/generic-sort-function.type';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class ArrayHandlerService {
    constructor() {}

    /**
     * Splits array into chunks of either even size (when chunkSize is a number) or
     * uneven size (when chunkSize is an array of numbers)
     */
    splitInChunks<T>(
        array: T[],
        chunkSize: number | number[] = array.length,
        leadWithRemainder?: boolean, // supported only when chunkSize is not an array
    ): T[][] {
        if (Array.isArray(chunkSize)) {
            if (!chunkSize.length) {
                return [array.slice()];
            }
            const chunks: T[][] = [];
            let sizeIdx: number = 0;
            let startIdx: number = 0; // inclusive
            let endIdx: number = Math.min(startIdx + chunkSize[sizeIdx], array.length); // exclusive
            while (startIdx !== endIdx) {
                chunks.push(array.slice(startIdx, endIdx));
                startIdx = endIdx;
                sizeIdx = chunkSize[sizeIdx + 1] ? sizeIdx + 1 : sizeIdx;
                endIdx = Math.min(startIdx + chunkSize[sizeIdx], array.length);
            }
            return chunks;
        }

        if (leadWithRemainder) {
            const remainder: number = array.length % chunkSize;
            if (remainder) {
                return [
                    array.slice(0, remainder),
                    ...this.splitInChunks(array.slice(remainder), chunkSize),
                ];
            }
        }

        return array
            .map((e, i) => (i % chunkSize === 0 ? array.slice(i, i + chunkSize) : null))
            .filter((e) => e) as T[][];
    }

    /**
     * Defines the sorting order based on the order[].
     */
    getSortOrder =
        <S, T = S>(
            order: T[],
            resolver: (s: S) => T | S = (x: S): S => x,
            direction?: SortingOrder,
            shouldPlaceNonExistentValuesLast: boolean = true,
        ): GenericSortFunction<S> =>
        (s1: S, s2: S) => {
            const index1: number = order.indexOf(resolver(s1) as T);
            const index2: number = order.indexOf(resolver(s2) as T);

            if (shouldPlaceNonExistentValuesLast) {
                if (index1 < 0) {
                    return 1;
                }
                if (index2 < 0) {
                    return -1;
                }
            }

            if (direction === SortingOrder.ascending) {
                return index1 < index2 ? -1 : index1 > index2 ? 1 : 0;
            }

            if (direction === SortingOrder.descending) {
                return index1 > index2 ? -1 : index1 < index2 ? 1 : 0;
            }

            if (index1 > -1) {
                return index2 > -1 ? index1 - index2 : -1;
            }

            return index2 > -1 ? 1 : 0;
        };

    /** Creates a sort callback with a custom resolver */
    getSortWithResolver<S, T extends number | string>(
        resolver: (s: S) => T,
        direction?: SortingOrder,
        shouldPlaceEmptyValuesLast?: boolean,
    ): GenericSortFunction<S> {
        return (s1: S, s2: S) => {
            const resolved1: T = resolver(s1);
            const resolved2: T = resolver(s2);
            const sortingCoefficient = direction === SortingOrder.ascending ? 1 : -1;
            // if both values transformed into strings compare lexicographically (lower-cased)
            if (typeof resolved1 === 'string' && typeof resolved2 === 'string') {
                const lcResolved1: string = resolved1.toLowerCase();
                const lcResolved2: string = resolved2.toLowerCase();
                const order = lcResolved1 < lcResolved2 ? -1 : lcResolved1 > lcResolved2 ? 1 : 0;
                return sortingCoefficient * order;
            }
            // if both values transformed into numbers compare numerically
            if (typeof resolved1 === 'number' && typeof resolved2 === 'number') {
                return sortingCoefficient * (resolved1 - resolved2);
            }
            // if we need to take into account the empty values and put them last
            if (shouldPlaceEmptyValuesLast) {
                if (resolved1 === null || resolved1 === undefined || resolved1 === '') {
                    return 1;
                }

                if (resolved2 === null || resolved2 === undefined || resolved2 === '') {
                    return -1;
                }
            }

            // else cannot compare and return same order
            return 0;
        };
    }

    /**
     * Creates a custom sort callback which defines a sorting order based on ordered property tuples.
     * The tuples ordain the sort order by defining which property names are considered first, then
     * the direction of the sort ('asc' for ascending or 'desc' for descending order) and finally
     * an optional resolver function which can resolve non string or number values to string|number.
     * If the tuples array is empty the order remains unchanged.
     *
     * @param orderedPropertyTuples - array of property tuples comprised of a property name,
     * a sort direction for the specific property, and an optional resolver when the property
     * value is not a number nor a string
     */
    getSortByOrderedProperties<S extends object>(
        orderedPropertyTuples: [
            propertyName: keyof S,
            propertySortDirection: SortingOrder,
            propertyResolver?: (value: S[keyof S]) => string | number,
        ][],
    ): GenericSortFunction<S> {
        // if no ordered property tuples are provided keep existing order (no sorting)
        if (!orderedPropertyTuples?.length) {
            return (): number => 0;
        }
        const stringNumberTypes = ['string', 'number'];
        return (objectA: S, objectB: S): number =>
            orderedPropertyTuples.reduce(
                (
                    comparisonResult,
                    [propertyName, propertySortDirection, propertyResolver]: [
                        keyof S,
                        SortingOrder,
                        (value: S[keyof S]) => string | number,
                    ],
                ) => {
                    if (comparisonResult) {
                        return comparisonResult;
                    }
                    let valueA: number | string;
                    let valueB: number | string;

                    // if property resolver is provided (for non string or numeric properties)
                    // then resolve the respective values using it
                    if (propertyResolver) {
                        valueA = propertyResolver(objectA[propertyName]);
                        valueB = propertyResolver(objectB[propertyName]);
                    }
                    // when the resolver is not provided we extract directly the respective property
                    // values and if they are not string or number we coerce them to strings
                    else {
                        valueA = stringNumberTypes.includes(typeof objectA[propertyName])
                            ? (objectA[propertyName] as unknown as string | number)
                            : String(objectA[propertyName]);
                        valueB = stringNumberTypes.includes(typeof objectB[propertyName])
                            ? (objectB[propertyName] as unknown as string | number)
                            : String(objectB[propertyName]);
                    }
                    const sortingCoefficient: -1 | 1 =
                        propertySortDirection === SortingOrder.descending ? -1 : 1;

                    // if both values transformed into strings compare lexicographically (lower-cased)
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        const lowercasedValueA: string = valueA.toLowerCase();
                        const lowercasedValueB: string = valueB.toLowerCase();
                        const order =
                            lowercasedValueA < lowercasedValueB
                                ? -1
                                : lowercasedValueA > lowercasedValueB
                                ? 1
                                : 0;
                        comparisonResult = sortingCoefficient * order;
                    }
                    // if both values transformed into numbers compare numerically
                    else if (typeof valueA === 'number' && typeof valueB === 'number') {
                        comparisonResult = sortingCoefficient * (valueA - valueB);
                    }
                    // else cannot compare and assume same order
                    else {
                        comparisonResult = 0;
                    }

                    return comparisonResult;
                },
                0,
            );
    }

    sort(
        recordA: any,
        recordB: any,
        {
            property,
            direction,
            shouldPlaceEmptyValuesLast,
            shouldPlaceZerosLast,
        }: {
            property?: string;
            direction: SortingOrder;
            shouldPlaceEmptyValuesLast?: boolean;
            shouldPlaceZerosLast?: boolean;
        },
    ): number {
        let a: string | number = property ? recordA[property] : recordA;
        let b: string | number = property ? recordB[property] : recordB;

        if (typeof a === 'string') {
            a = a.toLowerCase();
        }

        if (typeof b === 'string') {
            b = b.toLowerCase();
        }

        if (shouldPlaceEmptyValuesLast) {
            if (a === null || a === undefined || a === '') {
                return 1;
            }

            if (b === null || b === undefined || b === '') {
                return -1;
            }
        }

        if (shouldPlaceZerosLast) {
            if (a === 0) {
                return 1;
            }

            if (b === 0) {
                return -1;
            }
        }

        if (direction === SortingOrder.ascending) {
            return a < b ? -1 : a > b ? 1 : 0;
        }

        if (direction === SortingOrder.descending) {
            return a > b ? -1 : a < b ? 1 : 0;
        }
    }

    /**
     * Maps records (objects) to arrays based on a chosen (common) property, a sorting direction and
     * optionally a chosen locale (default is US) and then compares those arrays against each other
     * based on the following criteria/assumptions:
     * - no mutation occurs to input params
     * - the values to be compared are arrays, which may be empty
     * - empty arrays are always placed last in comparisons
     * - the mapped arrays to be compared are `string[]` or `number[]`
     * - in the case of string comparisons, we lowercase the elements in order to avoid bugs
     * - array which is a subset of the comparison counterpart is placed first
     *
     * @example empty array case
     * recordA = {prop: []};
     * recordB = {prop: [2,3]};
     * sortMappedArrays(recordA, recordB, {property: 'prop', direction: SortingDirection.ascending}) // 1
     *
     * @example string arrays where 2nd is a subset of the first
     * recordA = {prop: ['cat', 'dog', 'tiger']};
     * recordB = {prop: ['cat', 'dog']};
     * sortMappedArrays(recordA, recordB, {property: 'prop', direction: SortingDirection.ascending}) // 1
     *
     * @example string arrays with unequal elements
     * recordA = {prop: ['cat', 'dog', 'tiger']};
     * recordB = {prop: ['cat', 'lion', 'wolf']};
     * sortMappedArrays(recordA, recordB, {property: 'prop', direction: SortingDirection.descending}) // 1
     */
    sortObjectsBasedOnCommonArrayProperty<
        T extends Record<P, string[] | number[]>,
        P extends keyof T,
    >(
        recordA: T,
        recordB: T,
        {
            property,
            direction,
            locale = US_LOCALE,
        }: {
            property: P;
            direction: SortingOrder;
            locale?: string | string[];
        },
    ): number {
        const arrayA: string[] | number[] = recordA[property];
        const arrayB: string[] | number[] = recordB[property];

        // map direction param to a number for easier computations
        const sortingCoefficient: -1 | 1 = direction === SortingOrder.descending ? -1 : 1;

        // deal with empty arrays
        if (!arrayA?.length || !arrayB?.length) {
            return (arrayB?.length || 0) - (arrayA?.length || 0);
        }

        const minArraySize: number = Math.min(arrayA.length, arrayB.length);
        for (let i: number = 0; i < minArraySize; i++) {
            let a: string | number = arrayA[i];
            let b: string | number = arrayB[i];
            if (typeof a === 'string') {
                a = a.toLowerCase();
            }
            if (typeof b === 'string') {
                b = b.toLowerCase();
            }
            // if element types the same then compare directly
            if (typeof a === typeof b) {
                // both are strings
                if (typeof a === 'string') {
                    const stringComparison: number = a.localeCompare(b as string, locale);
                    // if not equal strings
                    if (stringComparison) {
                        return sortingCoefficient * stringComparison;
                    }
                }
                // both are numbers (not equal)
                else if (a !== b) {
                    return sortingCoefficient * (a - (b as number));
                }
            }
            // else compare lexicographically
            else {
                const lexicographicComparison: number = String(a).localeCompare(String(b), locale);
                // if not equal entities
                if (lexicographicComparison !== 0) {
                    return sortingCoefficient * lexicographicComparison;
                }
            }
        }

        // we reach this point when all elements are equal till the end of the shorter array
        // then we favor the shorter array returning it first
        return arrayA.length - arrayB.length;
    }

    sortObjectByValues<T extends number | string>(
        object: Record<string, T> | undefined,
        direction: SortingOrder = SortingOrder.ascending,
    ): Record<string, T> {
        if (!object) {
            return;
        }

        return fromPairs(
            Object.entries(object).sort(
                this.getSortWithResolver(([, value]: [string, T]) => value, direction),
            ),
        );
    }

    /**
     * Combines multiple sort functions into one, with the priority being determined by their order
     * in the input. This allows us to sort based on a secondary column when the values in the
     * primary one are equal.
     */
    addSecondarySorter<S>(...sorters: GenericSortFunction<S>[]): GenericSortFunction<S> {
        return (e1: S, e2: S) => {
            for (const sorter of sorters) {
                const comparison: number = sorter(e1, e2);

                if (comparison) {
                    return comparison;
                }
            }

            return 0;
        };
    }
}
