/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Project from '../projects/interfaces/project.interface';
import {
    getBookmarksRequest,
    clearBookmarks,
    createEntityBookmarksRequest,
    createAssociationOpenBookmarksRequest,
    createAssociationClosedBookmarksRequest,
    createArticleBookmarksRequest,
    uploadArticleRequest,
    downloadArticleBookmarksRequest,
    deleteBookmarkRequest,
    updateNotesRequest,
    clearNextSuccess,
    clearNextError,
    clearShouldFetch,
} from './bookmarks.actions';
import {
    getBookmarks,
    getEnhancedBookmarks,
    getLoading,
    getLoaded,
    getErrors,
    getSuccess,
    getPageIndex,
    getPageSize,
    getCountPerBookmarkType,
    getFilterCounts,
    getDateRange,
    getCowMilkConcentrationRange,
    getMoleculeWeightRange,
    getArticleUploadStatus,
    getArticleUploading,
    getArticleUploaded,
    getBlob,
    getDeletePendingBookmarks,
    getPreferences,
} from './bookmarks.selectors';
import Bookmark from './interfaces/bookmark.interface';
import BookmarksPerType from './interfaces/bookmarks-per-type.interface';
import BookmarkType from './enums/bookmark.enum';
import BookmarkOrigin from './interfaces/origin.interface';
import BookmarkConfiguration from './interfaces/configuration.interface';
import BookmarkIds from './interfaces/bookmark-ids.interface';
import CountPerBookmarkType from './interfaces/count-per-bookmark-type.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import { BookmarksState } from './bookmarks-state.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import FilterCountsPerBookmarkType from './interfaces/filter-counts-per-bookmark-type.interface';
import RangePerBookmarkType from './interfaces/range-per-bookmark-type.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import ArticleUploadStatus from './enums/article-upload-status.enum';

@Injectable()
export class BookmarksFacade {
    bookmarks$: Observable<BookmarksPerType> = this.store.pipe(select(getBookmarks));
    enhancedBookmarks$: Observable<BookmarksPerType> = this.store.pipe(
        select(getEnhancedBookmarks),
    );
    loading$: Observable<Partial<Record<BookmarkType, boolean>>> = this.store.pipe(
        select(getLoading),
    );
    loaded$: Observable<Partial<Record<BookmarkType, boolean>>> = this.store.pipe(
        select(getLoaded),
    );
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    success$: Observable<Alert[]> = this.store.pipe(select(getSuccess));
    pageIndex$: Observable<number> = this.store.pipe(select(getPageIndex));
    pageSize$: Observable<number> = this.store.pipe(select(getPageSize));
    countPerBookmarkType$: Observable<CountPerBookmarkType> = this.store.pipe(
        select(getCountPerBookmarkType),
    );
    filterCounts$: Observable<FilterCountsPerBookmarkType> = this.store.pipe(
        select(getFilterCounts),
    );
    dateRange$: Observable<RangePerBookmarkType> = this.store.pipe(select(getDateRange));
    cowMilkConcentrationRange$: Observable<RangePerBookmarkType> = this.store.pipe(
        select(getCowMilkConcentrationRange),
    );
    moleculeWeightRange$: Observable<RangePerBookmarkType> = this.store.pipe(
        select(getMoleculeWeightRange),
    );
    preferences$: Observable<Partial<Record<BookmarkType, UserPreferences>>> = this.store.pipe(
        select(getPreferences),
    );
    articleUploadStatus$: Observable<ArticleUploadStatus> = this.store.pipe(
        select(getArticleUploadStatus),
    );
    articleUploading$: Observable<boolean> = this.store.pipe(select(getArticleUploading));
    articleUploaded$: Observable<boolean> = this.store.pipe(select(getArticleUploaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    deletePendingBookmarks$: Observable<string[]> = this.store.pipe(
        select(getDeletePendingBookmarks),
    );

    constructor(private store: Store<BookmarksState>) {}

    getBookmarks(
        projectId: string,
        bookmarkType: BookmarkType,
        filters: ExecutionFilters,
        preferences: UserPreferences,
        studyTypesOrder: string[],
        suppressLoading?: boolean,
        pageIndex?: number,
        pageSize?: number,
        sortingOptions?: SortingOptions,
    ): void {
        this.store.dispatch(
            getBookmarksRequest({
                projectId,
                bookmarkType,
                filters,
                preferences,
                studyTypesOrder,
                suppressLoading,
                pageIndex,
                pageSize,
                sortingOptions,
            }),
        );
    }

    clearBookmarks(): void {
        this.store.dispatch(clearBookmarks());
    }

    createEntityBookmarks(
        projects: Project[],
        origin: BookmarkOrigin,
        configuration: BookmarkConfiguration,
        notes: string,
    ): void {
        this.store.dispatch(
            createEntityBookmarksRequest({ projects, origin, configuration, notes }),
        );
    }

    createAssociationOpenBookmarks(
        projects: Project[],
        configuration: BookmarkConfiguration,
        notes: string,
    ): void {
        this.store.dispatch(
            createAssociationOpenBookmarksRequest({ projects, configuration, notes }),
        );
    }

    createAssociationClosedBookmarks(
        projects: Project[],
        configuration: BookmarkConfiguration,
        notes: string,
    ): void {
        this.store.dispatch(
            createAssociationClosedBookmarksRequest({
                projects,
                configuration,
                notes,
            }),
        );
    }

    createArticleBookmarks(
        projects: Project[],
        origin: BookmarkOrigin,
        configurations: BookmarkConfiguration[],
        notes: string,
    ): void {
        this.store.dispatch(
            createArticleBookmarksRequest({ projects, origin, configurations, notes }),
        );
    }

    uploadArticle({
        projectId,
        doi,
        file,
    }: {
        projectId: string;
        doi?: string;
        file?: File;
    }): void {
        this.store.dispatch(uploadArticleRequest({ projectId, doi, file }));
    }

    downloadArticleBookmarks(projectId: string, filters: ExecutionFilters): void {
        this.store.dispatch(downloadArticleBookmarksRequest({ projectId, filters }));
    }

    updateNotes(bookmarkIds: BookmarkIds, notes: string): void {
        this.store.dispatch(updateNotesRequest({ bookmarkIds, notes }));
    }

    deleteBookmark(
        projectId: string,
        bookmarkId: string,
        bookmarks: Bookmark[],
        bookmarkType: BookmarkType,
    ): void {
        this.store.dispatch(
            deleteBookmarkRequest({ projectId, bookmarkId, bookmarks, bookmarkType }),
        );
    }

    clearNextSuccess(): void {
        this.store.dispatch(clearNextSuccess());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearShouldFetch(): void {
        this.store.dispatch(clearShouldFetch());
    }
}
