import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { GroupsService } from './services/groups.service';
import * as groupsActions from './groups.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';

@Injectable()
export class GroupsEffects {
    constructor(private actions$: Actions, private groupsService: GroupsService) {}

    getGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.getGroupsRequest),
            switchMap(() =>
                this.groupsService.getAvailableUserGroups().pipe(
                    map((paginatedGroups: PaginatedResults<UserGroup>) =>
                        groupsActions.getGroupsSuccess({ paginatedGroups }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(groupsActions.getGroupsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    createGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.createGroupRequest),
            switchMap(
                ({ name, label, parentId }: { name: string; label: string; parentId: string }) =>
                    this.groupsService.createGroup(name, label, parentId).pipe(
                        map((group: UserGroup) => groupsActions.createGroupSuccess({ group })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(groupsActions.createGroupFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getDepartments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.getDepartmentsRequest),
            switchMap(({ parentId }: { parentId: string }) =>
                this.groupsService.getGroupsTree(parentId).pipe(
                    map((departments: UserGroup[]) =>
                        groupsActions.getDepartmentsSuccess({ departments }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(groupsActions.getDepartmentsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    updateGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.updateGroupRequest),
            switchMap(({ id, name }: { id: string; name: string }) =>
                this.groupsService.updateGroup(id, name).pipe(
                    map((group: UserGroup) => groupsActions.updateGroupSuccess({ group })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(groupsActions.updateGroupFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteGroup$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.deleteGroupRequest),
            switchMap(({ id }: { id: string }) =>
                this.groupsService.deleteGroup(id).pipe(
                    map((group: UserGroup) => groupsActions.deleteGroupSuccess({ group })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(groupsActions.deleteGroupFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(groupsActions.deleteGroupsRequest),
            switchMap(({ ids }: { ids: string[] }) =>
                this.groupsService.deleteGroups(ids).pipe(
                    map((paginatedGroups: PaginatedResults<UserGroup>) =>
                        groupsActions.deleteGroupsSuccess({ paginatedGroups }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(groupsActions.deleteGroupsFailure({ ids, errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
