export enum ContentWriterActionTypes {
    GET_TOPICS_REQUEST = '[CONTENT WRITER][TOPICS] Get Topics Request',
    GET_TOPICS_SUCCESS = '[CONTENT WRITER][TOPICS] Get Topics Success',
    GET_TOPICS_FAILURE = '[CONTENT WRITER][TOPICS] Get Topics Failure',
    GET_ALL_CONTENT_REQUEST = '[CONTENT WRITER][ALL CONTENT] Get All Content Request',
    GET_ALL_CONTENT_SUCCESS = '[CONTENT WRITER][ALL CONTENT] Get All Content Success',
    GET_ALL_CONTENT_FAILURE = '[CONTENT WRITER][ALL CONTENT] Get All Content Failure',
    GET_LONG_CONTENT_REQUEST = '[CONTENT WRITER][LONG CONTENT] Get Long Content Request',
    GET_LONG_CONTENT_SUCCESS = '[CONTENT WRITER][LONG CONTENT] Get Long Content Success',
    GET_LONG_CONTENT_FAILURE = '[CONTENT WRITER][LONG CONTENT] Get Long Content Failure',
    GET_SHORT_CONTENT_REQUEST = '[CONTENT WRITER][SHORT CONTENT] Get Short Content Request',
    GET_SHORT_CONTENT_SUCCESS = '[CONTENT WRITER][SHORT CONTENT] Get Short Content Success',
    GET_SHORT_CONTENT_FAILURE = '[CONTENT WRITER][SHORT CONTENT] Get Short Content Failure',
    DOWNLOAD_CONTENT_REQUEST = '[CONTENT WRITER] Download Content Request',
    DOWNLOAD_CONTENT_SUCCESS = '[CONTENT WRITER] Download Content Success',
    DOWNLOAD_CONTENT_FAILURE = '[CONTENT WRITER] Download Content Failure',
}
