/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { CompoundReportState } from './compound-report-state.interface';
import {
    getOverviewRequest,
    getEffectSentencesRequest,
    getHealthLabelsRequest,
    getHealthLabelSentencesRequest,
    getStudyTypesRequest,
    getStudyTypeRequest,
    downloadRequest,
    resetReport,
    clearNextError,
} from './compound-report.actions';
import {
    getErrors,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getEffectSentences,
    getHealthLabels,
    getHealthLabelsLoading,
    getHealthLabelsLoaded,
    getHealthLabelSentences,
    getStudyTypes,
    getStudyTypesLoading,
    getStudyTypesLoaded,
    getStudyType,
    getTotal,
    getBlob,
} from './compound-report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Overview from './interfaces/overview.interface';
import Effect from '../ingredient-profiler/enums/effect.enum';
import TermsSentences from './interfaces/terms-sentences.interface';
import HealthLabel from './interfaces/health-label.interface';
import StudyTypes from './interfaces/study-types.interface';
import StudyType from './interfaces/study-type.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class CompoundReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    effectSentences$: Observable<TermsSentences> = this.store.pipe(select(getEffectSentences));
    healthLabels$: Observable<HealthLabel[]> = this.store.pipe(select(getHealthLabels));
    healthLabelsLoading$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoading));
    healthLabelsLoaded$: Observable<boolean> = this.store.pipe(select(getHealthLabelsLoaded));
    healthLabelSentences$: Observable<TermsSentences> = this.store.pipe(
        select(getHealthLabelSentences),
    );
    studyTypes$: Observable<StudyTypes> = this.store.pipe(select(getStudyTypes));
    studyTypesLoading$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoading));
    studyTypesLoaded$: Observable<boolean> = this.store.pipe(select(getStudyTypesLoaded));
    studyType$: Observable<StudyType> = this.store.pipe(select(getStudyType));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<CompoundReportState>) {}

    getOverview({
        compoundId,
        ingredientName,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getOverviewRequest({ compoundId, ingredientName, filters }));
    }

    getEffectSentences({
        compoundId,
        ingredientName,
        effect,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        effect: Effect;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(
            getEffectSentencesRequest({ compoundId, ingredientName, effect, filters }),
        );
    }

    getHealthLabels({
        compoundId,
        ingredientName,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getHealthLabelsRequest({ compoundId, ingredientName, filters }));
    }

    getHealthLabelSentences({
        compoundId,
        ingredientName,
        healthLabel,
        effect,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        healthLabel: string;
        effect?: Effect;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(
            getHealthLabelSentencesRequest({
                compoundId,
                ingredientName,
                healthLabel,
                effect,
                filters,
            }),
        );
    }

    getStudyTypes({
        compoundId,
        ingredientName,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getStudyTypesRequest({ compoundId, ingredientName, filters }));
    }

    getStudyType({
        compoundId,
        ingredientName,
        name,
        filters,
    }: {
        compoundId: string;
        ingredientName: string;
        name: string;
        filters?: ExecutionFilters;
    }): void {
        this.store.dispatch(getStudyTypeRequest({ compoundId, ingredientName, name, filters }));
    }

    resetReport(): void {
        this.store.dispatch(resetReport());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    downloadReport({
        compoundId,
        ingredientName,
    }: {
        compoundId: string;
        ingredientName: string;
    }): void {
        this.store.dispatch(downloadRequest({ compoundId, ingredientName }));
    }
}
