/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

@Injectable()
export class CowMilkProfilerService {
    constructor(private http: HttpClient) {}

    getSearchSuggestions(query: string, activeSearch: ProfilerSearch): Observable<string[]> {
        const searchPath: string =
            activeSearch === ProfilerSearch.compounds ||
            activeSearch === ProfilerSearch.targetInsights
                ? 'components'
                : ProfilerSearch.insights
                ? 'targets'
                : null;

        return this.http
            .post(`${environment.profilerServerUrl}/${searchPath}/search`, {
                searchString: query,
            })
            .pipe(map(({ results }: ResultsRestApi<string>) => results));
    }
}
