import { Injectable } from '@angular/core';
import ForgotPasswordRestApi from '../rest-api-interfaces/forgot-password.rest.interface';
import ForgotPassword from '../interfaces/forgot-password.interface';

@Injectable()
export class ForgotPasswordParser {
    constructor() {}

    parseForgotPassword(forgotPassword: ForgotPasswordRestApi): ForgotPassword {
        return { message: forgotPassword.message };
    }
}
