import { environment } from '@environments/leap/environment';
import { COLOR_PRIMARY, COLOR_SECONDARY } from '@leap-common/themes/branding';
import { COLOR_GREY } from '@leap-common/themes/common';
import StatisticsItem from '@leap-store/core/src/lib/data/report/interfaces/statistics-item.interface';
import RelationshipGroup from '@leap-store/core/src/lib/data/ingredient-profiler/enums/relationship-group.enum';
import Effect from '@leap-store/core/src/lib/data/ingredient-profiler/enums/effect.enum';

export const RELATIONSHIP_GROUPS: RelationshipGroup[] = [
    RelationshipGroup.prevents,
    RelationshipGroup.causes,
    RelationshipGroup.other,
];

export const EFFECTS: Effect[] = [Effect.beneficial, Effect.adverse, Effect.unclassified];
export const CLASSIFIED_EFFECTS: Effect[] = [Effect.beneficial, Effect.adverse];

export const OVERVIEW_SECTION_TITLE: string = 'Overview';
export const HEALTH_LABELS_SECTION_TITLE: string = 'Health areas';

export const OVERVIEW_SECTION_RELATIONSHIP_GROUPS_SUBTITLE: string =
    'This section contains a short overview of the selected health areas. A short description and a visual representation of the total prevents/causes relationship types that have been found per each health area, in terms of supporting evidence, %s and associations.';
export const OVERVIEW_SECTION_RELATIONSHIP_GROUPS_SUBTITLE_DEFAULT_FILTER: string = `${OVERVIEW_SECTION_RELATIONSHIP_GROUPS_SUBTITLE} By default, this report considers associations derived from literature, while those sourced solely from databases are excluded. To include all associations within the report, remove the respective filter.`;
export const OVERVIEW_SECTION_TOOLTIP_TEXT: string =
    'This section contains a visual representation of the selected health areas. Per each health area, the total prevents & causes associations to medical conditions are displayed, based on the currently active filters.';

export const HEALTH_LABELS_SECTION_RELATIONSHIP_GROUPS_SUBTITLE: string =
    'This section contains per each selected health area and for each relationship type group (prevents, causes, other) a summary, a visual representation based on the number of associations and the top %s based on the number of supporting evidence.';
export const HEALTH_LABELS_SECTION_EFFECTS_SUBTITLE: string =
    'This section contains per each selected health area and for each effect (beneficial, adverse, unclassified) a summary, a visual representation based on the number of associations and the top %s based on the number of supporting evidence.';
export const HEALTH_LABEL_RELATIONSHIP_GROUP_SUMMARIES_TITLE: string =
    'Summary of %s per relationship type group';
export const HEALTH_LABEL_EFFECT_SUMMARIES_TITLE: string = 'Summary of %s per effect';
export const HEALTH_LABEL_RELATIONSHIP_GROUP_SUMMARY_PLACEHOLDER_TEXT: string =
    'No references exist for this relationship type group.';
export const HEALTH_LABEL_EFFECT_SUMMARY_PLACEHOLDER_TEXT: string =
    'No references exist for this effect.';
export const HEALTH_LABEL_RELATIONSHIP_GROUP_TOP_COMPOUNDS_TITLE: string =
    'Top %s per relationship type group';
export const HEALTH_LABEL_EFFECT_TOP_COMPOUNDS_TITLE: string = 'Top %s per effect classification';
export const HEALTH_LABEL_RELATIONSHIP_GROUP_TOP_COMPOUNDS_HEADER_TITLES: Record<
    RelationshipGroup,
    string
> = {
    [RelationshipGroup.prevents]: 'Top %s that "prevent" associated medical terms (within %s)',
    [RelationshipGroup.causes]: 'Top %s that "cause" associated medical terms (within %s)',
    [RelationshipGroup.other]: 'Top %s within the "other" group for %s',
};
export const HEALTH_LABEL_EFFECT_TOP_COMPOUNDS_HEADER_TITLES: Record<Effect, string> = {
    [Effect.beneficial]: 'Top %s with beneficial effect on %s',
    [Effect.adverse]: 'Top %s with adverse effect on %s',
    [Effect.unclassified]: 'Top %s with unclassified effect on %s',
};
export const HEALTH_LABEL_NO_TARGETS_PLACEHOLDER: string =
    'No associations found for this health area.';
export const HEALTH_LABEL_NO_RELATIONSHIP_GROUP_COMPOUNDS_PLACEHOLDER: string =
    'No %s found for this relationship type group.';
export const HEALTH_LABEL_NO_EFFECT_COMPOUNDS_PLACEHOLDER: string = 'No %s found for this effect.';
export const REFERENCE_INDEX_HEADER_TOOLTIP_TEXT: string =
    'Most relevant literature found with regard to each top associated medical term.';
export const DAIRY_RELEVANCE_REFERENCE_INDEX_HEADER_TOOLTIP_TEXT: string =
    'Articles that are most relevant to dairy';
export const NO_HEALTH_LABELS_PLACEHOLDER_TEXT: string =
    'No associations have been found classified under any of the available Health areas';
export const HEALTH_LABELS_CHIP_TEXT: string = '%s effects';

export const DONUT_CHART_COLOR_SCALE: string[] = [
    COLOR_PRIMARY[100],
    COLOR_PRIMARY[200],
    COLOR_PRIMARY[300],
    COLOR_PRIMARY[400],
    COLOR_PRIMARY[500],
    COLOR_PRIMARY[600],
    COLOR_PRIMARY[700],
    COLOR_PRIMARY[800],
    COLOR_PRIMARY[900],
    '#075254', // not included in the palette
    '#053a3c', // not included in the palette
    '#022b2c', // not included in the palette
];
export const DONUT_CHART_PROPERTY_TO_VISUALISE: keyof StatisticsItem = 'percentage';
export const RELATIONSHIP_CHART_PROPERTY_TO_VISUALISE: keyof StatisticsItem = 'percentage';
export const JOURNALS_CHART_PROPERTY_TO_VISUALISE: keyof StatisticsItem = 'percentage';
export const OVERVIEW_RELATIONSHIP_GROUPS_CHART_TITLE: string = `Health areas chart for "${RelationshipGroup.prevents}" and "${RelationshipGroup.causes}"`;
export const OVERVIEW_EFFECTS_CHART_TITLE: string = `Interactive Health areas graph for ${Effect.beneficial} and ${Effect.adverse} effects`;
export const OVERVIEW_EFFECTS_CHART_SUBTITLE: string =
    'Click on any of the bars in the graph or buttons below to view the relevant information';
export const OVERVIEW_CHART_SUBTITLE: string = '(%s found to associated medical terms)';
export const RELATIONSHIP_GROUPS_CHART_SIDE_LABELS: Record<string, RelationshipGroup> = {
    positive: RelationshipGroup.prevents,
    negative: RelationshipGroup.causes,
};
export const EFFECTS_CHART_SIDE_LABELS: Record<string, Effect> = {
    positive: Effect.beneficial,
    negative: Effect.adverse,
};
export const RELATIONSHIP_GROUPS_COLOR_SCALE: Record<RelationshipGroup, string> =
    environment.app === 'd3'
        ? {
              [RelationshipGroup.prevents]: COLOR_PRIMARY[900],
              [RelationshipGroup.causes]: COLOR_SECONDARY[900],
              [RelationshipGroup.other]: COLOR_SECONDARY[600],
          }
        : {
              [RelationshipGroup.prevents]: COLOR_SECONDARY[800],
              [RelationshipGroup.causes]: COLOR_PRIMARY[800],
              [RelationshipGroup.other]: COLOR_SECONDARY[600],
          };
export const EFFECTS_COLOR_SCALE: Record<Effect, string> =
    environment.app === 'd3'
        ? {
              [Effect.beneficial]: COLOR_PRIMARY[900],
              [Effect.adverse]: COLOR_SECONDARY[900],
              [Effect.unclassified]: COLOR_SECONDARY[600],
          }
        : {
              [Effect.beneficial]: COLOR_SECONDARY[800],
              [Effect.adverse]: COLOR_PRIMARY[800],
              [Effect.unclassified]: COLOR_SECONDARY[600],
          };
export const CHART_PROPERTY_TO_VISUALISE: keyof StatisticsItem = 'value';
export const RELATIONSHIP_GROUPS_BAR_CHART_TITLE: string = `"${RelationshipGroup.prevents}" and "${RelationshipGroup.causes}" associations to associated medical terms`;
export const EFFECTS_BAR_CHART_TITLE: string = `${Effect.beneficial}, ${Effect.adverse} and ${Effect.unclassified} associations to associated medical terms`;
export const BAR_CHART_LEGEND_TOOLTIP_ITEMS: Record<RelationshipGroup, string> = {
    [RelationshipGroup.prevents]:
        'This group contains all the associated medical terms in this health area that include at least one of the relationship types: reduces, decreases, treats, prevents. The associations with contradicting relationship types, from both prevents and causes, are included in both groups.',
    [RelationshipGroup.causes]:
        'This group contains all the associated medical terms in this health area that include at least one of the relationship types: increases, causes, produces. The associations with contradicting relationship types, from both prevents and causes, are included in both groups.',
    [RelationshipGroup.other]:
        'The results available from this group contain all the associations from the relationship types: other, affects, coexists with, interacts with, is related.',
};
export const DONUT_LEGEND_TOOLTIP_ITEMS: Record<string, string> = {
    [RelationshipGroup.other]:
        "Relationship types: other, affected by, decreased by, increased by, produced by, prevents, causes. <br><br>Associated medical terms with the relationship types 'prevents' and 'causes' exist within this group as they do not suggest a positive-negative relationship within the health area.",
};
export const DONUT_CHART_GREY_SCALE: string[] = [
    COLOR_GREY[400],
    COLOR_GREY[500],
    COLOR_GREY[600],
    COLOR_GREY[700],
    COLOR_GREY[800],
];
export const DONUT_CHART_TITLE: string = 'Breakdown of the relationship type group "Other"';
export const CHART_TOOLTIP_NO_DATA_NOTIFICATION: string =
    'No <b>medical conditions</b> found for this Health area';
export const VALUE_TOGGLE_TITLE: string = 'Y-axis unit:';
export const COUNT_TOGGLE_TEXT: string = 'Count';
export const PERCENTAGE_TOGGLE_TEXT: string = 'Percentage';
export const RADIO_BUTTONS_GROUP_LABEL: string = 'View graph by:';
export const RADIO_BUTTONS_GROUP_NAME: string = 'overview-chart-value';
export const X_AXIS_NAME: string = 'X';
export const X_AXIS_LABEL: string = 'Horizontal axis:';
export const X_AXIS_TEXT_WITH_INGREDIENT: string =
    'Health areas associated with %s in alphabetical order';
export const X_AXIS_TEXT_WITHOUT_INGREDIENT: string = 'Health areas (sorted alphabetically)';
export const Y_AXIS_NAME: string = 'Y';
export const Y_AXIS_LABEL: string = 'Vertical axis:';
export const Y_AXIS_TEXT: string = '%s found to associated medical terms';
export const SUPPORTING_EVIDENCE_AXIS_TEXT: string = 'Supporting evidence (literature)';
export const COUNTS_LABEL: string = 'Counts';
export const TOTAL_BENEFICIAL_LABEL: string = 'Total Beneficial';
export const TOTAL_ADVERSE_LABEL: string = 'Total Adverse';
export const ALL_HEALTH_AREAS_LABEL: string = 'all';
