import { environment } from '@environments/leap/environment';
import { INITIAL_QUERY_TEXT } from '../../gpt/constants/gpt';
import SearchQuery from '../interfaces/search-query.interface';

export const INITIAL_SEARCH_QUERY: SearchQuery = {
    id: '',
    initialText: INITIAL_QUERY_TEXT,
    question: '',
    answer: '',
    sessionId: '',
    confidence: null,
    confidenceScores: {},
    articles: [],
};

export const ERROR_TEXT: string = `${environment.product} could not generate a response. Please try again.`;

export const NO_DATA_SEARCH_QUERY_STATUS: string = 'no_data';
export const INVALID_SEARCH_QUERY_STATUS: string = 'invalid';
export const NO_DATA_SEARCH_QUERY_ANSWER: string =
    'I have searched all of our knowledge, but could not find data with enough supporting evidence to answer your question. Please try a different one.';
export const INVALID_SEARCH_QUERY_ANSWER: string =
    'It seems like the question you are asking is not around the topics of Life Science, Human Health and Nutrition. Please try to ask a different question closer to these topics.';
