import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { GroupsState } from './groups-state.interface';
import {
    getGroupsRequest,
    clearNextError,
    clearNextSuccess,
    getDepartmentsRequest,
    deleteGroupRequest,
    deleteGroupsRequest,
    createGroupRequest,
    updateGroupRequest,
    searchDepartments,
    clearCreatedDepartment,
    clearShouldFetchDepartments,
} from './groups.actions';
import {
    getGroupsSuccess,
    getGroupsErrors,
    getGroupsLoading,
    getGroupsLoaded,
    getGroupsList,
    getCreatedDepartment,
    getDepartmentsList,
    getDeletePendingItems,
    getSearchedDepartments,
    getShouldFetchDepartments,
} from './groups.selectors';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';

@Injectable()
export class GroupsFacade {
    groups$: Observable<UserGroup[]> = this.store.pipe(select(getGroupsList));
    departments$: Observable<UserGroup[]> = this.store.pipe(select(getDepartmentsList));
    createdDepartment$: Observable<UserGroup> = this.store.pipe(select(getCreatedDepartment));
    searchedDepartments$: Observable<UserGroup[]> = this.store.pipe(select(getSearchedDepartments));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getGroupsErrors));
    success$: Observable<UserGroup[]> = this.store.pipe(select(getGroupsSuccess));
    loading$: Observable<boolean> = this.store.pipe(select(getGroupsLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getGroupsLoaded));
    deletePendingItems$: Observable<string[]> = this.store.pipe(select(getDeletePendingItems));
    shouldFetchDepartments$: Observable<boolean> = this.store.pipe(
        select(getShouldFetchDepartments),
    );

    constructor(private store: Store<GroupsState>) {}

    getGroups(): void {
        this.store.dispatch(getGroupsRequest());
    }

    getDepartments(parentId: string): void {
        this.store.dispatch(getDepartmentsRequest({ parentId }));
    }

    deleteGroup(id: string): void {
        this.store.dispatch(deleteGroupRequest({ id }));
    }

    deleteGroups(ids: string[]): void {
        this.store.dispatch(deleteGroupsRequest({ ids }));
    }

    createGroup(name: string, label: string, parentId: string): void {
        this.store.dispatch(createGroupRequest({ name, label, parentId }));
    }

    updateGroup(id: string, name: string): void {
        this.store.dispatch(updateGroupRequest({ id, name }));
    }

    searchDepartments(searchQuery: string): void {
        this.store.dispatch(searchDepartments({ searchQuery }));
    }

    clearCreatedDepartment(): void {
        this.store.dispatch(clearCreatedDepartment());
    }

    clearNextSuccess(): void {
        this.store.dispatch(clearNextSuccess());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    clearShouldFetchDepartments(): void {
        this.store.dispatch(clearShouldFetchDepartments());
    }
}
