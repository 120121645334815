/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { CombinatorialDiscoveryEffects } from './combinatorial-discovery.effects';
import { CombinatorialDiscoveryFacade } from './combinatorial-discovery.facade';
import { CombinatorialDiscoveryParser } from './parsers/combinatorial-discovery.parser';
import { CombinatorialDiscoveryService } from './services/combinatorial-discovery.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([CombinatorialDiscoveryEffects]),
    ],
    declarations: [],
    providers: [
        CombinatorialDiscoveryParser,
        CombinatorialDiscoveryService,
        CombinatorialDiscoveryFacade,
        InsightParser,
        InsightsService,
    ],
})
export class CombinatorialDiscoveryStoreModule {}
