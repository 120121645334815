import { Directive, Input, Renderer2, ElementRef, OnChanges } from '@angular/core';
import { round } from 'lodash';

@Directive({
    selector: '[libScientificNotation]',
})
export class ScientificNotationDirective implements OnChanges {
    @Input() value: number;
    @Input() min: number = 0.01;
    @Input() max: number = 1000;
    @Input() decimalDigits: number = 2;

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnChanges(): void {
        this.formatValue();
    }

    formatValue(): void {
        if (this.value === null || Number.isNaN(this.value)) {
            return;
        }

        let formattedValue: string;

        if (!this.value) {
            formattedValue = Number(0).toFixed(this.decimalDigits);
        } else if (this.value >= this.min && this.value <= this.max) {
            formattedValue = round(this.value, this.decimalDigits).toFixed(this.decimalDigits);
        } else {
            const exponent: number = Math.floor(Math.log10(this.value));
            const coefficient: number = round(
                this.value / Math.pow(10, exponent),
                this.decimalDigits,
            );

            formattedValue = `${coefficient}&times;10<sup>${exponent}</sup>`;
        }

        this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', formattedValue);
    }
}
