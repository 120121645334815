export enum PatentsActionTypes {
    GET_PATENTS_REQUEST = '[DISCOVERY][PATENTS] Get Patents Request',
    GET_PATENTS_SUCCESS = '[DISCOVERY][PATENTS] Get Patents Success',
    GET_PATENTS_FAILURE = '[DISCOVERY][PATENTS] Get Patents Failure',
    DOWNLOAD_PATENTS_REQUEST = '[DISCOVERY][PATENTS] Download Patents Request',
    DOWNLOAD_PATENTS_SUCCESS = '[DISCOVERY][PATENTS] Download Patents Success',
    DOWNLOAD_PATENTS_FAILURE = '[DISCOVERY][PATENTS] Download Patents Failure',
    CLEAR_PATENTS = '[DISCOVERY][PATENTS] Clear Patents',
    CLEAR_NEXT_ERROR = '[DISCOVERY][PATENTS] Clear Next Error',
}
