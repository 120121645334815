/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { TagsService } from './services/tags.service';
import * as tagsActions from './tags.actions';
import Tag from './interfaces/tag.interface';

@Injectable()
export class TagsEffects {
    constructor(private actions$: Actions, private tagsService: TagsService) {}

    getTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(tagsActions.getTagsRequest),
            switchMap(() =>
                this.tagsService.getTags().pipe(
                    map((tags: Tag[]) => tagsActions.getTagsSuccess({ tags })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(
                            tagsActions.getTagsFailure({
                                errorResponse,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );
}
