import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { IngredientManagementEffects } from './ingredient-management.effects';
import { IngredientManagementFacade } from './ingredient-management.facade';
import { IngredientManagementParser } from './parsers/ingredient-management.parser';
import { IngredientManagementService } from './services/ingredient-management.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([IngredientManagementEffects]),
    ],
    declarations: [],
    providers: [
        IngredientManagementParser,
        IngredientManagementService,
        IngredientManagementFacade,
    ],
})
export class IngredientManagementStoreModule {}
