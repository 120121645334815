/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import { DiscoveryState } from './discovery-state.interface';
import {
    updateActiveTabId,
    updateActiveNetworkView,
    updateExecution,
    updateDiscovery,
    updateSummary,
} from './discovery.actions';
import {
    getActiveTabId,
    getActiveNetworkView,
    getExecution,
    getDiscovery,
    getSummary,
} from './discovery.selectors';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import NetworkView from './enums/network-view.enum';
import DiscoveryExecution from './interfaces/discovery-execution.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import DiscoverySummary from './interfaces/discovery-summary.interface';

@Injectable()
export class DiscoveryFacade {
    activeTabId$: Observable<TabId> = this.store.pipe(select(getActiveTabId));
    activeNetworkView$: Observable<NetworkView> = this.store.pipe(select(getActiveNetworkView));
    execution$: Observable<DiscoveryExecution> = this.store.pipe(select(getExecution));
    discovery$: Observable<Discovery> = this.store.pipe(select(getDiscovery));
    summary$: Observable<DiscoverySummary> = this.store.pipe(select(getSummary));

    constructor(private store: Store<DiscoveryState>) {}

    updateActiveTabId(id: TabId): void {
        this.store.dispatch(updateActiveTabId({ id }));
    }

    updateActiveNetworkView(view: NetworkView): void {
        this.store.dispatch(updateActiveNetworkView({ view }));
    }

    updateExecution(execution: DiscoveryExecution): void {
        this.store.dispatch(updateExecution({ execution }));
    }

    updateDiscovery(discovery: Discovery): void {
        this.store.dispatch(updateDiscovery({ discovery }));
    }

    updateSummary(summary: DiscoverySummary): void {
        this.store.dispatch(updateSummary({ summary }));
    }
}
