/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AppService {
    constructor(private http: HttpClient) {}

    getLatestVersion(): Observable<string> {
        return this.http
            .get('../../assets/build.json')
            .pipe(map((buildJson: Record<string, string>) => buildJson.version));
    }
}
