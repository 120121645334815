/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { GPTParser } from '../parsers/gpt.parser';
import QueryResponseRestApi from '../rest-api-interfaces/query-response.rest.interface';
import QueryResponse from '../interfaces/query-response.interface';
import QAPairRestApi from '../rest-api-interfaces/qa-pair.rest.interface';
import QAPair from '../interfaces/qa-pair.interface';

@Injectable()
export class GPTService {
    constructor(private http: HttpClient, private gptParser: GPTParser) {}

    performStructuredOpenDiscovery(
        source: string,
        sourceId: string,
        sourceCategories: string[],
        targetCategories: string[],
    ): Observable<QueryResponse> {
        return this.http
            .post(`${environment.gptServerUrl}/structured-query/open-discovery/`, {
                sourceTerm: source,
                sourceUid: sourceId,
                sourceTermCategories: sourceCategories,
                targetCategories,
            })
            .pipe(
                map((queryResponse: QueryResponseRestApi) =>
                    this.gptParser.parseQueryResponse(queryResponse),
                ),
            );
    }

    performStructuredClosedDiscovery(
        source: string,
        sourceId: string,
        sourceCategories: string[],
        target: string,
        targetId: string,
        targetCategories: string[],
    ): Observable<QueryResponse> {
        return this.http
            .post(`${environment.gptServerUrl}/structured-query/closed-discovery/`, {
                sourceTerm: source,
                sourceUid: sourceId,
                sourceTermCategories: sourceCategories,
                targetTerm: target,
                targetUid: targetId,
                targetTermCategories: targetCategories,
            })
            .pipe(
                map((queryResponse: QueryResponseRestApi) =>
                    this.gptParser.parseQueryResponse(queryResponse),
                ),
            );
    }

    performFreeQuery(query: string, sessionId: string): Observable<QueryResponse> {
        return this.http
            .post(`${environment.gptServerUrl}/free-query/`, {
                query,
                session_uuid: sessionId,
            })
            .pipe(
                map((queryResponse: QueryResponseRestApi) =>
                    this.gptParser.parseQueryResponse(queryResponse),
                ),
            );
    }

    regenerateQuery(id: string): Observable<QueryResponse> {
        return this.http
            .patch(`${environment.gptServerUrl}/query-regeneration/${id}/`, {})
            .pipe(
                map((queryResponse: QueryResponseRestApi) =>
                    this.gptParser.parseQueryResponse(queryResponse),
                ),
            );
    }

    downloadSessionHistory(id: string, extension: string): Observable<Blob> {
        return this.http.get(
            `${environment.gptServerUrl}/session-history/${id}/download/?filetype=${extension}`,
            {
                responseType: 'blob',
            },
        );
    }

    performAssistantQuery(query: string): Observable<QAPair> {
        return this.http
            .post(`${environment.gptServerUrl}/assistance/`, {
                message: query,
            })
            .pipe(map((queryResponse: QAPairRestApi) => this.gptParser.parseQAPair(queryResponse)));
    }

    getAssistantQueries(): Observable<QAPair[]> {
        return this.http
            .get(`${environment.gptServerUrl}/assistance/`)
            .pipe(
                map((queryResponse: Record<string, QAPairRestApi[]>) =>
                    this.gptParser.parseQAPairs(queryResponse.messages),
                ),
            );
    }

    deleteAssistantQueries(): Observable<QAPair[]> {
        return this.http
            .post(`${environment.gptServerUrl}/assistance/clear/`, {})
            .pipe(
                map((queryResponse: Record<string, QAPairRestApi[]>) =>
                    this.gptParser.parseQAPairs(queryResponse.messages),
                ),
            );
    }
}
