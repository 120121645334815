import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { GroupsActionTypes } from './groups-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserGroup from '@leap-store/core/src/lib/data/auth/interfaces/user-group.interface';

export const getGroupsRequest = createAction(GroupsActionTypes.GET_GROUPS_REQUEST);

export const getGroupsSuccess = createAction(
    GroupsActionTypes.GET_GROUPS_SUCCESS,
    props<{ paginatedGroups: PaginatedResults<UserGroup> }>(),
);

export const getGroupsFailure = createAction(
    GroupsActionTypes.GET_GROUPS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const createGroupRequest = createAction(
    GroupsActionTypes.CREATE_GROUP_REQUEST,
    props<{ name: string; label: string; parentId: string }>(),
);

export const createGroupSuccess = createAction(
    GroupsActionTypes.CREATE_GROUP_SUCCESS,
    props<{ group: UserGroup }>(),
);

export const createGroupFailure = createAction(
    GroupsActionTypes.CREATE_GROUP_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const updateGroupRequest = createAction(
    GroupsActionTypes.UPDATE_GROUP_REQUEST,
    props<{ id: string; name: string }>(),
);

export const updateGroupSuccess = createAction(
    GroupsActionTypes.UPDATE_GROUP_SUCCESS,
    props<{ group: UserGroup }>(),
);

export const updateGroupFailure = createAction(
    GroupsActionTypes.UPDATE_GROUP_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getDepartmentsRequest = createAction(
    GroupsActionTypes.GET_DEPARTMENTS_REQUEST,
    props<{ parentId: string }>(),
);

export const getDepartmentsSuccess = createAction(
    GroupsActionTypes.GET_DEPARTMENTS_SUCCESS,
    props<{ departments: UserGroup[] }>(),
);

export const getDepartmentsFailure = createAction(
    GroupsActionTypes.GET_DEPARTMENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteGroupRequest = createAction(
    GroupsActionTypes.DELETE_GROUP_REQUEST,
    props<{ id: string }>(),
);

export const deleteGroupSuccess = createAction(
    GroupsActionTypes.DELETE_GROUP_SUCCESS,
    props<{ group: UserGroup }>(),
);

export const deleteGroupFailure = createAction(
    GroupsActionTypes.DELETE_GROUP_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const deleteGroupsRequest = createAction(
    GroupsActionTypes.DELETE_GROUPS_REQUEST,
    props<{ ids: string[] }>(),
);

export const deleteGroupsSuccess = createAction(
    GroupsActionTypes.DELETE_GROUPS_SUCCESS,
    props<{ paginatedGroups: PaginatedResults<UserGroup> }>(),
);

export const deleteGroupsFailure = createAction(
    GroupsActionTypes.DELETE_GROUPS_FAILURE,
    props<{ ids: string[]; errorResponse: HttpErrorResponse }>(),
);

export const searchDepartments = createAction(
    GroupsActionTypes.SEARCH_DEPARTMENTS,
    props<{ searchQuery: string }>(),
);

export const clearCreatedDepartment = createAction(GroupsActionTypes.CLEAR_CREATED_DEPARTMENT);
export const clearNextError = createAction(GroupsActionTypes.CLEAR_NEXT_ERROR);
export const clearNextSuccess = createAction(GroupsActionTypes.CLEAR_NEXT_SUCCESS);
export const clearShouldFetchDepartments = createAction(
    GroupsActionTypes.CLEAR_SHOULD_FETCH_DEPARTMENTS,
);
