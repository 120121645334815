/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { IngredientProfilerState } from './ingredient-profiler-state.interface';
import { getDataState } from '../index';
import * as fromIngredientProfiler from './ingredient-profiler.reducer';
import Insight from './interfaces/insight.interface';
import EnhancedInsights from './interfaces/enhanced-insights';

export const getIngredientProfilerState = createSelector(
    getDataState,
    (state: DataState) => state.ingredientProfiler,
);
export const getSuggestions = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSuggestions,
);
export const getSuggestionsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSuggestionsLoading,
);
export const getSuggestionsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSuggestionsLoaded,
);
export const getErrors = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getErrors,
);
export const getCustomIngredient = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCustomIngredient,
);
export const getCustomIngredientLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCustomIngredientLoading,
);
export const getCustomIngredientLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCustomIngredientLoaded,
);
export const getCompoundsTotal = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCompoundsTotal,
);
export const getCompoundsDisplaying = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCompoundsDisplaying,
);
export const getCompoundsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCompoundsLoading,
);
export const getCompoundsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCompoundsLoaded,
);
export const getMinSourceConcentration = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getMinSourceConcentration,
);
export const getMaxSourceConcentration = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getMaxSourceConcentration,
);
export const getParentCompoundId = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getParentCompoundId,
);
export const getInsights = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsights,
);
export const getInsightsTotal = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsightsTotal,
);
export const getInsightsDisplaying = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsightsDisplaying,
);
export const getInsightsPageIndex = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsightsPageIndex,
);
export const getInsightsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsightsLoading,
);
export const getInsightsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getInsightsLoaded,
);
export const getIngredientInsightsTotal = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getIngredientInsightsTotal,
);
export const getIngredientInsightsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getIngredientInsightsLoading,
);
export const getIngredientInsightsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getIngredientInsightsLoaded,
);
export const getTargetInsights = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getTargetInsights,
);
export const getTargetInsightsTotal = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getTargetInsightsTotal,
);
export const getTargetInsightsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getTargetInsightsLoading,
);
export const getTargetInsightsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getTargetInsightsLoaded,
);
export const getOldestOccurrence = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getOldestOccurrence,
);
export const getNewestOccurrence = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getNewestOccurrence,
);
export const getShouldShowSourceConcentration = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getShouldShowSourceConcentration,
);
export const getIsCustomIngredient = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getIsCustomIngredient,
);
export const getBlob = createSelector(getIngredientProfilerState, fromIngredientProfiler.getBlob);
export const getCategories = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCategories,
);
export const getSubcategoriesPerCategory = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSubcategoriesPerCategory,
);
export const getCategoriesLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCategoriesLoading,
);
export const getCategoriesLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getCategoriesLoaded,
);
export const getOverview = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getOverview,
);
export const getOverviewLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getOverviewLoading,
);
export const getOverviewLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getOverviewLoaded,
);
export const getHealthLabelsStatistics = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getHealthLabelsStatistics,
);
export const getHealthLabelsStatisticsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getHealthLabelsStatisticsLoading,
);
export const getHealthLabelsStatisticsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getHealthLabelsStatisticsLoaded,
);
export const getRelationshipsPerGroup = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getRelationshipsPerGroup,
);
export const getHealthLabelSummaries = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getHealthLabelSummaries,
);
export const getHealthLabelTopCompounds = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getHealthLabelTopCompounds,
);
export const getSearchSuggestions = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSearchSuggestionsLoaded,
);

export const getEnhancedInsights: (state: IngredientProfilerState) => EnhancedInsights = (
    state: IngredientProfilerState,
) => {
    const parentCompoundId: string = getParentCompoundId(state);
    const insights: Insight[] = getInsights(state);
    const loading: boolean = getInsightsLoading(state);
    const loaded: boolean = getInsightsLoaded(state);
    const total: number = getInsightsTotal(state);
    const displaying: number = getInsightsDisplaying(state);

    return {
        parentCompoundId,
        insights,
        loading,
        loaded,
        total,
        displaying,
    };
};
