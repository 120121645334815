/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { RolesParser } from '../parsers/roles.parser';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRoleRestApi from '@leap-store/core/src/lib/data/auth/rest-api-interfaces/user-role.rest.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

@Injectable()
export class RolesService {
    constructor(private http: HttpClient, private rolesParser: RolesParser) {}

    /**
     * Gets a list of UserRoleRestApi with the available user roles and
     * maps the response to the desired format. It returns an Observable of PaginatedResults<UserRole>.
     */
    getAvailableUserRoles(): Observable<PaginatedResults<UserRole>> {
        return this.http
            .get(`${environment.serverUrl}/roles/`)
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<UserRoleRestApi>) =>
                    this.rolesParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }
}
