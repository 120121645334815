import { Injectable } from '@angular/core';
import { countBy, flatten } from 'lodash';

/** Services - Parsers */
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { InsightsService } from '@apps/leap/src/app/shared/services/insights.service';

/** Interfaces - Types - Enums */
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import PaginatedInsightsRestApi from '@apps/leap/src/app/shared/rest-api-interfaces/paginated-insights.rest.interface';
import PaginatedInsights from '../interfaces/paginated-insights.interface';
import InsightRestApi from '../rest-api-interfaces/insight.rest.interface';
import Insight from '../interfaces/insight.interface';
import InsightsPerCategoryRestApi from '../rest-api-types/insights-per-category.rest.type';
import InsightsPerCategory from '../types/insights-per-category.type';
import Query from '../interfaces/query.interface';
import QueryRestApi from '../rest-api-interfaces/query.rest.interface';
import InsightRelationshipType from '@apps/leap/src/app/shared/types/discovery-insight-relationship-type.type';
import InsightsPerRelationshipTypeOrigin from '@apps/leap/src/app/shared/types/insights-per-relationship-type-origin.type';
import AssociationType from '@apps/leap/src/app/shared/enums/association-type.enum';
import AssociationOrigin from '@apps/leap/src/app/shared/enums/association-origin.enum';
import CombinatorialDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/combinatorial-discovery-relation.enum';

@Injectable()
export class CombinatorialDiscoveryParser {
    constructor(private insightParser: InsightParser, private insightsService: InsightsService) {}

    /** Parses Paginated Insights */
    parsePaginatedResults(
        paginatedInsights: PaginatedInsightsRestApi<InsightRestApi, InsightsPerCategoryRestApi>,
    ): PaginatedInsights {
        const totalAssociationIds: string[] = flatten(
            paginatedInsights.results?.map(
                ({ is_novel_a_c, association_origins_a_c }: InsightRestApi) =>
                    this.insightParser
                        .parseAssociationOrigins(association_origins_a_c)
                        .map((associationOrigin: AssociationOrigin) =>
                            this.insightsService.generateAssociationId(
                                associationOrigin,
                                is_novel_a_c,
                            ),
                        ),
            ),
        );

        const totalAssociationTypes: AssociationType[] = flatten(
            paginatedInsights.results?.map(({ is_novel_a_c }: InsightRestApi) =>
                is_novel_a_c ? AssociationType.novel : AssociationType.known,
            ),
        );

        const totalRelationshipsAB: InsightRelationshipType[] =
            paginatedInsights?.results?.map(({ relationship_type_a_b }: InsightRestApi) =>
                this.insightParser.parseRelationshipType(relationship_type_a_b),
            ) || [];

        const totalRelationshipsBC: InsightRelationshipType[] =
            paginatedInsights?.results?.map(({ relationship_type_b_c }: InsightRestApi) =>
                this.insightParser.parseRelationshipType(relationship_type_b_c),
            ) || [];

        const totalRelationshipsAC: InsightRelationshipType[] =
            paginatedInsights?.results?.map(({ relationship_type_a_c }: InsightRestApi) =>
                this.insightParser.parseRelationshipType(relationship_type_a_c),
            ) || [];

        const totalRelationshipsValuesAB: string[] =
            this.insightsService.getRelationshipsValues(totalRelationshipsAB);
        const totalRelationshipsValuesBC: string[] =
            this.insightsService.getRelationshipsValues(totalRelationshipsBC);
        const totalRelationshipsValuesAC: string[] =
            this.insightsService.getRelationshipsValues(totalRelationshipsAC);

        const countPerRelationshipTypeOriginValuesAB: Record<string, number> = countBy(
            paginatedInsights.results,
            'is_relationship_type_a_b_predicted',
        );

        const countPerRelationshipTypeOriginAB: InsightsPerRelationshipTypeOrigin =
            this.insightsService.mapCountToCountPerRelationshipTypeOrigin(
                countPerRelationshipTypeOriginValuesAB,
                CombinatorialDiscoveryRelation.AB,
            );

        const countPerRelationshipTypeOriginValuesBC: Record<string, number> = countBy(
            paginatedInsights.results,
            'is_relationship_type_b_c_predicted',
        );

        const countPerRelationshipTypeOriginBC: InsightsPerRelationshipTypeOrigin =
            this.insightsService.mapCountToCountPerRelationshipTypeOrigin(
                countPerRelationshipTypeOriginValuesBC,
                CombinatorialDiscoveryRelation.BC,
            );

        const countPerRelationshipTypeOriginValuesAC: Record<string, number> = countBy(
            paginatedInsights.results,
            'is_relationship_type_a_c_predicted',
        );

        const countPerRelationshipTypeOriginAC: InsightsPerRelationshipTypeOrigin =
            this.insightsService.mapCountToCountPerRelationshipTypeOrigin(
                countPerRelationshipTypeOriginValuesAC,
                CombinatorialDiscoveryRelation.AC,
            );

        return {
            results: paginatedInsights.results ? this.parseInsights(paginatedInsights.results) : [],
            pageIndex: paginatedInsights.pageIndex,
            pageSize: paginatedInsights.pageSize,
            total: paginatedInsights.total,
            displayingInsights: paginatedInsights.totalDisplayingInsights,
            insightsPerCategory: this.parseInsightsPerCategory(
                paginatedInsights.totalInsightsPerCategory,
            ),
            oldestOccurrence: paginatedInsights.totalOldestPublicationDate,
            newestOccurrence: paginatedInsights.totalNewestPublicationDate,
            totalRelationshipsAB,
            totalRelationshipsBC,
            totalRelationshipsAC,
            countPerAssociation: countBy(totalAssociationIds),
            countPerAssociationType: countBy(totalAssociationTypes),
            countPerRelationshipTypeAB: countBy(totalRelationshipsValuesAB),
            countPerRelationshipTypeBC: countBy(totalRelationshipsValuesBC),
            countPerRelationshipTypeAC: countBy(totalRelationshipsValuesAC),
            countPerRelationshipTypeOriginAB,
            countPerRelationshipTypeOriginBC,
            countPerRelationshipTypeOriginAC,
            status: paginatedInsights.queryStatus,
        };
    }

    /** Parses BE Insights to integrate them on the FE */
    parseInsights(insights: InsightRestApi[]): Insight[] {
        return insights.map((insight: InsightRestApi) => this.parseInsight(insight));
    }

    /** Parses InsightRestApi to Insight */
    parseInsight(insight: InsightRestApi): Insight {
        const associationIds: string[] = this.insightParser
            .parseAssociationOrigins(insight.association_origins_a_c)
            .map((associationOrigin: AssociationOrigin) =>
                this.insightsService.generateAssociationId(associationOrigin, insight.is_novel_a_c),
            );
        const relationshipTypeAB: Record<string, string> = this.insightParser.parseRelationshipType(
            insight.relationship_type_a_b,
        );
        const relationshipTypeBC: Record<string, string> = this.insightParser.parseRelationshipType(
            insight.relationship_type_b_c,
        );
        const relationshipTypeAC: Record<string, string> = this.insightParser.parseRelationshipType(
            insight.relationship_type_a_c,
        );

        return {
            type: Discovery.combinatorial,
            id: `${insight.point_a_uid}_${insight.point_b_uid}_${insight.point_c_uid}`,
            rankingIndex: insight.index ? insight.index : 0,
            triangleScore: this.insightParser.parseScore(insight.triangleScore),
            categoryA: this.insightParser.parseCategory(insight.point_a_category),
            nameA: insight.point_a_name,
            idA: insight.point_a_uid,
            categoryB: this.insightParser.parseCategory(insight.point_b_category),
            nameB: insight.point_b_name,
            idB: insight.point_b_uid,
            categoryC: this.insightParser.parseCategory(insight.point_c_category),
            nameC: insight.point_c_name,
            idC: insight.point_c_uid,
            totalScoreAB: this.insightParser.parseScore(insight.total_score_a_b),
            weightRawAB: insight.weight_raw_direct_a_b,
            weightScoreAB: insight.weight_score_direct_a_b,
            relationshipTypeAB,
            relationshipTypeValuesAB:
                this.insightParser.parseRelationshipTypeValues(relationshipTypeAB),
            isRelationshipTypeABPredicted: insight.is_relationship_type_a_b_predicted,
            predictedRelationshipTypeProbabilityAB: this.insightParser.parseProbability(
                insight.relationship_type_probability_a_b,
            ),
            linkProbabilityAB: insight.link_probability_a_b,
            totalScoreBC: this.insightParser.parseScore(insight.total_score_b_c),
            weightRawBC: insight.weight_raw_direct_b_c,
            weightScoreBC: insight.weight_score_direct_b_c,
            relationshipTypeBC,
            relationshipTypeValuesBC:
                this.insightParser.parseRelationshipTypeValues(relationshipTypeBC),
            isRelationshipTypeBCPredicted: insight.is_relationship_type_b_c_predicted,
            predictedRelationshipTypeProbabilityBC: this.insightParser.parseProbability(
                insight.relationship_type_probability_b_c,
            ),
            linkProbabilityBC: insight.link_probability_b_c,
            totalScoreAC: this.insightParser.parseScore(insight.total_score_a_c),
            weightRawAC: insight.weight_raw_direct_a_c,
            weightScoreAC: insight.weight_score_direct_a_c,
            relationshipTypeAC,
            relationshipTypeValuesAC:
                this.insightParser.parseRelationshipTypeValues(relationshipTypeAC),
            isRelationshipTypeACPredicted: insight.is_relationship_type_a_c_predicted,
            predictedRelationshipTypeProbabilityAC: this.insightParser.parseProbability(
                insight.relationship_type_probability_a_c,
            ),
            linkProbabilityAC: insight.link_probability_a_c,
            associationIds,
            oldestOccurrence: this.insightParser.parseDate(insight.oldest_publication_date),
            newestOccurrence: this.insightParser.parseDate(insight.newest_publication_date),
            isNovelAB: insight.is_novel_a_b,
            isNovelBC: insight.is_novel_b_c,
            isNovelAC: insight.is_novel_a_c,
            associationOriginsAB: this.insightParser.parseAssociationOrigins(
                insight.association_origins_a_b,
            ),
            associationOriginsBC: this.insightParser.parseAssociationOrigins(
                insight.association_origins_b_c,
            ),
            associationOriginsAC: this.insightParser.parseAssociationOrigins(
                insight.association_origins_a_c,
            ),
            synonymsB: this.insightParser.parseSynonyms(insight.synonyms_b),
            synonymsC: this.insightParser.parseSynonyms(insight.synonyms_c),
            koOriginDatabasesAB: this.insightParser.parseKOOriginDatabases(
                insight.ko_source_db_a_b,
            ),
            koOriginDatabasesBC: this.insightParser.parseKOOriginDatabases(
                insight.ko_source_db_b_c,
            ),
            koOriginDatabasesAC: this.insightParser.parseKOOriginDatabases(
                insight.ko_source_db_a_c,
            ),
        };
    }

    parseInsightsPerCategory(insightsPerCategory: InsightsPerCategoryRestApi): InsightsPerCategory {
        const parsedInsightsPerCategory: InsightsPerCategory = {};

        Object.keys(insightsPerCategory).forEach((category: string) => {
            parsedInsightsPerCategory[category] = {
                total: insightsPerCategory[category].total,
                novel: insightsPerCategory[category].novel,
            };
        });

        return parsedInsightsPerCategory;
    }

    serializeInsight(insight: Insight): InsightRestApi {
        return {
            index: insight.rankingIndex ? insight.rankingIndex : null,
            triangleScore: insight.triangleScore ? insight.triangleScore : null,
            point_a_category: this.insightParser.serializeCategory(insight.categoryA),
            point_a_name: insight.nameA,
            point_a_uid: insight.idA,
            point_b_category: this.insightParser.serializeCategory(insight.categoryB),
            point_b_name: insight.nameB,
            point_b_uid: insight.idB,
            point_c_category: this.insightParser.serializeCategory(insight.categoryC),
            point_c_name: insight.nameC,
            point_c_uid: insight.idC,
            is_novel_a_b: insight.isNovelAB,
            association_origins_a_b: this.insightParser.serializeAssociationOrigins(
                insight.associationOriginsAB,
            ),
            total_score_a_b: insight.totalScoreAB,
            weight_raw_direct_a_b: insight.weightRawAB,
            weight_score_direct_a_b: insight.weightScoreAB,
            relationship_type_a_b: this.insightParser.serializeRelationshipType(
                insight.relationshipTypeAB,
            ),
            is_relationship_type_a_b_predicted: insight.isRelationshipTypeABPredicted,
            relationship_type_probability_a_b: insight.predictedRelationshipTypeProbabilityAB,
            link_probability_a_b: insight.linkProbabilityAB,
            is_novel_b_c: insight.isNovelBC,
            association_origins_b_c: this.insightParser.serializeAssociationOrigins(
                insight.associationOriginsBC,
            ),
            total_score_b_c: insight.totalScoreBC,
            weight_raw_direct_b_c: insight.weightRawBC,
            weight_score_direct_b_c: insight.weightScoreBC,
            relationship_type_b_c: this.insightParser.serializeRelationshipType(
                insight.relationshipTypeBC,
            ),
            is_relationship_type_b_c_predicted: insight.isRelationshipTypeBCPredicted,
            relationship_type_probability_b_c: insight.predictedRelationshipTypeProbabilityBC,
            link_probability_b_c: insight.linkProbabilityBC,
            is_novel_a_c: insight.isNovelAC,
            association_origins_a_c: this.insightParser.serializeAssociationOrigins(
                insight.associationOriginsAC,
            ),
            total_score_a_c: insight.totalScoreAC,
            weight_raw_direct_a_c: insight.weightRawAC,
            weight_score_direct_a_c: insight.weightScoreAC,
            relationship_type_a_c: this.insightParser.serializeRelationshipType(
                insight.relationshipTypeAC,
            ),
            is_relationship_type_a_c_predicted: insight.isRelationshipTypeACPredicted,
            relationship_type_probability_a_c: insight.predictedRelationshipTypeProbabilityAC,
            link_probability_a_c: insight.linkProbabilityAC,
            oldest_publication_date: this.insightParser.serializeDate(insight.oldestOccurrence),
            newest_publication_date: this.insightParser.serializeDate(insight.newestOccurrence),
            synonyms_b: this.insightParser.serializeSynonyms(insight.synonymsB),
            synonyms_c: this.insightParser.serializeSynonyms(insight.synonymsC),
            ko_source_db_a_b: this.insightParser.serializeKOOriginDatabases(
                insight.koOriginDatabasesAB,
            ),
            ko_source_db_b_c: this.insightParser.serializeKOOriginDatabases(
                insight.koOriginDatabasesBC,
            ),
            ko_source_db_a_c: this.insightParser.serializeKOOriginDatabases(
                insight.koOriginDatabasesAC,
            ),
        };
    }

    serializeCombinatorialQuery(query: Query): QueryRestApi {
        return {
            query_uid: query.term ? query.term : null,
            category_a: query.category1 ? query.category1 : null,
            category_b: query.category2 ? query.category2 : null,
        };
    }
}
