/** third-party libraries */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { IngredientComparisonParser } from '../parsers/ingredient-comparison.parser';
import Bioactivity from '../interfaces/bioactivity.interface';
import AminoAcidsRestApi from '../rest-api-interfaces/amino-acids.rest.interface';
import MolecularWeightsRestApi from '../rest-api-interfaces/molecular-weights.rest.interface';
import MultiLineChartDataItem from '@leap-libs/charts/src/lib/interfaces/multi-line-chart-data-item.interface';
import StatisticsItem from '../../report/interfaces/statistics-item.interface';

@Injectable()
export class IngredientComparisonService {
    constructor(
        private http: HttpClient,
        private ingredientComparisonParser: IngredientComparisonParser,
    ) {}

    getBioactivity(ingredients: string[], baselineIngredient: string): Observable<Bioactivity[]> {
        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/comparison/bioactivity/`, {
                ingredientUids: ingredients,
                baseline: baselineIngredient,
            })
            .pipe(
                map((results: Bioactivity[]) =>
                    this.ingredientComparisonParser.parseBioactivities(results),
                ),
            );
    }

    getAminoAcids(ingredients: string[]): Observable<Record<string, StatisticsItem[]>> {
        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/comparison/amino-acids/`, {
                ingredientUids: ingredients,
            })
            .pipe(
                map((results: AminoAcidsRestApi[]) =>
                    this.ingredientComparisonParser.parseAminoAcids(results),
                ),
            );
    }

    getMolecularWeight(ingredients: string[]): Observable<MultiLineChartDataItem[]> {
        return this.http
            .post(`${environment.ingredientAnalyzerServerUrl}/comparison/molecular-weights/`, {
                ingredientUids: ingredients,
            })
            .pipe(
                map((result: MolecularWeightsRestApi[]) =>
                    this.ingredientComparisonParser.parseMolecularWeights(result),
                ),
            );
    }
}
