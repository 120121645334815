enum Organization {
    dgIngredientAnalyzer = 'dg-ingredient-analyzer',
    dmi = 'dmi',
    mrPlant = 'mr-plant',
    mr = 'mr',
    ppIngredientProfiler = 'pp-ingredient-profiler',
    pp = 'pp',
}

export default Organization;
