import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DecoratedInputComponent } from './decorated-input.component';
import { TooltipModule } from '@leap-libs/tooltip/src/public-api';

@NgModule({
    declarations: [DecoratedInputComponent],
    imports: [CommonModule, TooltipModule],
    exports: [DecoratedInputComponent],
})
export class DecoratedInputModule {}
