/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as notificationsActions from './notifications.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { NotificationsState } from './notifications-state.interface';
import Message from './interfaces/message.interface';

export const adapter: EntityAdapter<Message> = createEntityAdapter<Message>();

export const initialState: NotificationsState = adapter.getInitialState({
    message: null,
    errors: [],
    loading: false,
    loaded: false,
});

const notificationsReducer: ActionReducer<NotificationsState, Action> = createReducer(
    initialState,
    on(notificationsActions.sendMessageRequest, (state: NotificationsState) => ({
        ...state,
        message: null as Message,
        loading: true,
        loaded: false,
    })),
    on(
        notificationsActions.sendMessageSuccess,
        (state: NotificationsState, { message }: { message: Message }) => ({
            ...state,
            message,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        notificationsActions.sendMessageFailure,
        (state: NotificationsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(notificationsActions.clearMessage, (state: NotificationsState) => ({
        ...state,
        message: null as Message,
        loading: false,
        loaded: false,
    })),
    on(notificationsActions.clearNextError, (state: NotificationsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: NotificationsState | undefined,
    action: Action,
): NotificationsState => notificationsReducer(state, action);

// selectors
export const getMessage: (state: NotificationsState) => Message = (state: NotificationsState) =>
    state.message;
export const getErrors: (state: NotificationsState) => ErrorResponse[] = (
    state: NotificationsState,
) => state.errors;
export const getLoading: (state: NotificationsState) => boolean = (state: NotificationsState) =>
    state.loading;
export const getLoaded: (state: NotificationsState) => boolean = (state: NotificationsState) =>
    state.loaded;
