import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-legal-link',
    templateUrl: 'link.view.component.html',
    styleUrls: ['link.view.component.scss'],
})
export class LinkViewComponent {
    @Input() id: string;
    @Input() title: string;
    @Input() isTextWhite: boolean;
    @Input() isTextSmaller: boolean;
    @Output() clicked: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    onClick(event: MouseEvent, id: string): void {
        event.preventDefault();
        this.clicked.emit(id);
    }
}
