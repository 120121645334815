/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { DatabasesService } from './services/databases.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './databases.actions';
import Database from './interfaces/database.interface';
import BookmarkIds from '../../bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class DatabasesEffects {
    constructor(
        private actions$: Actions,
        private databasesService: DatabasesService,
        private bookmarksService: BookmarksService,
    ) {}

    getDatabases$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getDatabasesRequest),
            mergeMap(({ bookmarkIds }: { bookmarkIds: BookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getDatabases(bookmarkIds)
                    : this.databasesService.getDatabases()
                ).pipe(
                    map((databases: Record<string, Database>) =>
                        actions.getDatabasesSuccess({ databases }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getDatabasesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
