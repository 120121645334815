import { Component, HostBinding } from '@angular/core';

/** Constants */
import {
    PRIVACY_POLICY_HEADER,
    PRIVACY_POLICY_PATH,
    PRIVACY_POLICY_LOADING_TEXT,
} from '../../../constants/legal';

/** Interfaces */
import Header from '@leap-common/interfaces/header.interface';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: 'privacy-policy.container.component.html',
    styleUrls: ['privacy-policy.container.component.scss'],
})
export class PrivacyPolicyContainerComponent {
    @HostBinding('class') classes = 'content-wrapper';

    readonly header: Header = PRIVACY_POLICY_HEADER;
    readonly path: string = PRIVACY_POLICY_PATH;
    readonly loadingText: string = PRIVACY_POLICY_LOADING_TEXT;

    constructor() {}
}
