/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromFiles from './files.reducer';

export const getFilesState = createSelector(getDataState, (state: DataState) => state.files);

export const getFileToUpload = createSelector(getFilesState, fromFiles.getFileToUpload);
export const getFiles = createSelector(getFilesState, fromFiles.getFileEntities);
export const getErrors = createSelector(getFilesState, fromFiles.getErrors);
export const getLoading = createSelector(getFilesState, fromFiles.getLoading);
export const getLoaded = createSelector(getFilesState, fromFiles.getLoaded);
export const getPageIndex = createSelector(getFilesState, fromFiles.getPageIndex);
export const getPageSize = createSelector(getFilesState, fromFiles.getPageSize);
export const getTotal = createSelector(getFilesState, fromFiles.getTotal);
export const getSortDirection = createSelector(getFilesState, fromFiles.getSortDirection);
export const getSortColumn = createSelector(getFilesState, fromFiles.getSortColumn);
export const getShouldFetchFiles = createSelector(getFilesState, fromFiles.getShouldFetchFiles);
export const getDeletePendingItems = createSelector(getFilesState, fromFiles.getDeletePendingItems);
