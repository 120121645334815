import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { environment } from '@environments/leap/environment';

/** Facades - Services */
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';
import { GPTFacade } from '@leap-store/core/src/lib/data/gpt/gpt.facade';
import { CurrentUserService } from '@leap-store/core/src/lib/data/auth/services/current-user.service';
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';

/** Interfaces - Enums */
import Organization from '@apps/leap/src/app/shared/enums/organization.enum';
import Jwt from '@leap-store/core/src/lib/data/auth/interfaces/jwt.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';
import CurrentUser from '@leap-store/core/src/lib/data/auth/interfaces/current-user.interface';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authFacade: AuthFacade,
        private gptFacade: GPTFacade,
        private currentUserService: CurrentUserService,
        private featureFlagsService: FeatureFlagsService,
        private router: Router,
    ) {}

    /**
     * Sets authentication headers to http request.
     * Updates the url for specific services based on the alias property of the user organization. This change should not get applied on PHI.
     * If the httpRequest url contains '/hub/logout' not append any extra headers.
     * If the httpRequest url does not contain '/hub/logout'
     *   If the jwt does not exist we append the 'Content-Type' and 'Accept' headers to the request.
     *   If the jwt exists we also append the 'Authentication' header to the request.
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request: HttpRequest<any> = httpRequest;

        return this.authFacade.jwt$.pipe(
            first(),
            mergeMap((jwt: Jwt) => {
                const requestUrl: string = request.url;
                const isUrlDedicated: boolean =
                    requestUrl.includes(environment.autocompleteServerUrl) ||
                    requestUrl.includes(environment.discoveryServerUrl) ||
                    requestUrl.includes(environment.metadataServerUrl) ||
                    requestUrl.includes(environment.metapathsServerUrl) ||
                    requestUrl.includes(environment.projectsServerUrl) ||
                    requestUrl.includes(environment.projectsReportServerUrl) ||
                    requestUrl.includes(environment.gptServerUrl) ||
                    requestUrl.includes(environment.reportServerUrl) ||
                    requestUrl.includes(environment.dairyProfilerServerUrl) ||
                    requestUrl.includes(environment.profilerServerUrl) ||
                    requestUrl.includes(environment.profilerAutocompleteServerUrl) ||
                    requestUrl.includes(environment.ingredientAnalyzerServerUrl) ||
                    requestUrl.includes(environment.uploadServerUrl) ||
                    requestUrl.includes(environment.jupyterHubDomain);

                // in case of PHI or PHI Plant there is no need to update the url, since it is already dedicated to a specific client
                if (
                    isUrlDedicated &&
                    environment.organization !== Organization.mr &&
                    environment.organization !== Organization.mrPlant
                ) {
                    const url: string = this.convertToTenantUrl(requestUrl);

                    // if url does not exist logout and redirect to login
                    if (!url) {
                        this.logoutAndRedirectToLogin();
                    }

                    request = request.clone({ url });
                }

                if (request.url.includes('/hub/logout')) {
                    return next.handle(request);
                }

                if (
                    !request.url.includes('/ingredient') &&
                    !request.url.includes('/articles/user-uploaded/files')
                ) {
                    request = request.clone({
                        setHeaders: {
                            'Content-Type': 'application/json; charset=utf-8',
                            Accept: 'application/json',
                        },
                    });
                }

                if (jwt?.accessToken) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwt.accessToken}`,
                        },
                    });
                }

                return next.handle(request).pipe(
                    catchError((error: HttpErrorResponse) => {
                        if ([401, 403].includes(error.status)) {
                            // auto logout if 401 or 403 response returned from api
                            if (
                                requestUrl.includes(environment.projectsServerUrl) &&
                                error.status === 403
                            ) {
                                this.router.navigate(['/projects']);
                            } else {
                                this.logoutAndRedirectToLogin();
                            }
                        }
                        return throwError(error);
                    }),
                );
            }),
        );
    }

    convertToTenantUrl(requestUrl: string): string {
        const currentUser: CurrentUser = this.currentUserService.getUserIdentity();
        const domainIndex: number = requestUrl.indexOf(environment.domain);
        const tenant: string = currentUser.organizations?.[0]?.alias; // naming the currentUser.organization.alias tenant in order to refer to multitenancy

        // in case of missing the currentUser organization return
        if (!tenant) {
            return;
        }

        // place the tenant identifier at the end of the URL subdomain
        // https:// subdomain.domain/api => https:// subdomain-tenant.domain/api
        const url: string = `${requestUrl.substring(
            0,
            domainIndex - 1,
        )}-${tenant}.${requestUrl.substring(domainIndex, requestUrl.length)}`;

        return url;
    }

    logoutAndRedirectToLogin(): void {
        this.deleteAssistantQueries();
        this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
        this.router.navigate(['/auth/login']);
    }

    deleteAssistantQueries(): void {
        const isChatAssistantEnabled: boolean =
            this.featureFlagsService.isFeatureEnabled('chatAssistant');

        if (isChatAssistantEnabled) {
            this.gptFacade.deleteAssistantQueries();
        }
    }
}
