/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { NotebookServersService } from './services/notebook-servers.service';
import * as NotebookServersActions from './notebook-servers.actions';
import NotebookServer from './interfaces/notebook-server.interface';
import { TokenService } from '../auth/services/token.service';
import TokenTypes from '../auth/enums/token-types.enum';

@Injectable()
export class NotebookServersEffects {
    constructor(
        private actions$: Actions,
        private notebookServersService: NotebookServersService,
        private tokenService: TokenService,
    ) {}

    getNotebookServers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebookServersActions.getNotebookServersRequest),
            switchMap(() =>
                this.notebookServersService.getConnections().pipe(
                    map((servers: Record<string, NotebookServer>) =>
                        NotebookServersActions.getNotebookServersSuccess({ servers }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebookServersActions.getNotebookServersFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    createNotebookServer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebookServersActions.createNotebookServerRequest),
            switchMap(({ key }: { key: TokenTypes }) =>
                this.notebookServersService
                    .initiateConnection(this.tokenService.getToken(key))
                    .pipe(
                        map(() => NotebookServersActions.createNotebookServerSuccess()),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                NotebookServersActions.createNotebookServerFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    deleteNotebookServer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebookServersActions.deleteNotebookServerRequest),
            switchMap(() =>
                this.notebookServersService.closeConnection().pipe(
                    map(() => NotebookServersActions.deleteNotebookServerSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebookServersActions.deleteNotebookServerFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    logoutJupyterHub$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebookServersActions.logoutJupyterHubRequest),
            switchMap(() =>
                this.notebookServersService.logoutJupyterHub().pipe(
                    map(() => NotebookServersActions.logoutJupyterHubSuccess()),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebookServersActions.logoutJupyterHubFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
