import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromCategories from './categories.reducer';

export const getCategoriesState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.categories,
);

export const getCategories = createSelector(getCategoriesState, fromCategories.getCategories);
export const getSubcategoriesPerCategory = createSelector(
    getCategoriesState,
    fromCategories.getSubcategoriesPerCategory,
);
export const getErrors = createSelector(getCategoriesState, fromCategories.getErrors);
export const getLoading = createSelector(getCategoriesState, fromCategories.getLoading);
export const getLoaded = createSelector(getCategoriesState, fromCategories.getLoaded);
