import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CANCEL_TEXT } from '@leap-common/constants/common';
import { ACCEPT_TERMS_OF_USE_BUTTON_TEXT } from '../../../constants/legal';

@Component({
    selector: 'app-legal-footer',
    templateUrl: 'footer.view.component.html',
    styleUrls: ['footer.view.component.scss'],
})
export class FooterViewComponent {
    @Input() isAcceptButtonEnabled: boolean;
    @Output() acceptClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() cancelClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly acceptText: string = ACCEPT_TERMS_OF_USE_BUTTON_TEXT;
    readonly cancelText: string = CANCEL_TEXT;

    constructor() {}

    onAcceptClicked(): void {
        this.acceptClicked.emit(true);
    }

    onCancelClicked(): void {
        this.cancelClicked.emit(true);
    }
}
