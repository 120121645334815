import { Injectable } from '@angular/core';

import ClosedDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/closed/interfaces/insight.interface';
import CombinatorialDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/combinatorial/interfaces/insight.interface';
import OpenDiscoveryInsight from '@leap-store/core/src/lib/data/discovery/open/interfaces/insight.interface';
import ClosedDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/closed-discovery-relation.enum';
import CombinatorialDiscoveryRelation from '@leap-store/core/src/lib/ui/discovery/enums/combinatorial-discovery-relation.enum';
import NetworkNode from '@leap-libs/charts/src/lib/interfaces/node.interface';
import NetworkLink from '@leap-libs/charts/src/lib/interfaces/link.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import Insight from '@apps/leap/src/app/shared/types/discovery-insight.type';
import Insights from '@apps/leap/src/app/shared/types/discovery-insights.type';
import Relation from '@apps/leap/src/app/shared/types/discovery-relation.type';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import FilterRange from '@apps/leap/src/app/shared/modules/filters/interfaces/filter-range.interface';
import Measurement from '@apps/leap/src/app/shared/interfaces/measurement.interface';

@Injectable()
export class TypeGuardService {
    constructor() {}

    isOpenDiscoveryInsight(insight: Insight): insight is OpenDiscoveryInsight {
        return insight.type === Discovery.open;
    }

    isClosedDiscoveryInsight(insight: Insight): insight is ClosedDiscoveryInsight {
        return insight.type === Discovery.closed;
    }

    isCombinatorialDiscoveryInsight(insight: Insight): insight is CombinatorialDiscoveryInsight {
        return insight.type === Discovery.combinatorial;
    }

    /**
     * Assume homomorphic array of OpenDiscoveryInsight[] elements
     *
     * @param insights the homomorphic array to be narrowed to open discovery insights
     * @returns boolean
     */
    areOpenDiscoveryInsights(insights: Insights): insights is OpenDiscoveryInsight[] {
        return !insights.length || this.isOpenDiscoveryInsight(insights[0]);
    }

    /**
     * Assume homomorphic array of ClosedDiscoveryInsight[] elements
     *
     * @param insights the homomorphic array to be narrowed to closed discovery insights
     * @returns boolean
     */
    areClosedDiscoveryInsights(insights: Insights): insights is ClosedDiscoveryInsight[] {
        return !insights.length || this.isClosedDiscoveryInsight(insights[0]);
    }

    /**
     * Assume homomorphic array of CombinatorialDiscoveryInsight[] elements
     *
     * @param insights the homomorphic array to be narrowed to combinatorial discovery insights
     * @returns boolean
     */
    areCombinatorialDiscoveryInsights(
        insights: Insights,
    ): insights is CombinatorialDiscoveryInsight[] {
        return !insights.length || this.isCombinatorialDiscoveryInsight(insights[0]);
    }

    isClosedDiscoveryRelation(relation: Relation): relation is ClosedDiscoveryRelation {
        return Object.values(ClosedDiscoveryRelation).some(
            (closedDiscoveryRelation: ClosedDiscoveryRelation) =>
                closedDiscoveryRelation === relation,
        );
    }

    isCombinatorialDiscoveryRelation(
        relation: Relation,
    ): relation is CombinatorialDiscoveryRelation {
        return Object.values(CombinatorialDiscoveryRelation).some(
            (combinatorialDiscoveryRelation: CombinatorialDiscoveryRelation) =>
                combinatorialDiscoveryRelation === relation,
        );
    }

    isItemNetworkNode(
        object: NetworkNode | NetworkLink,
        typeGuardProp: keyof NetworkNode | keyof NetworkLink,
    ): object is NetworkNode {
        return typeGuardProp in object;
    }

    isFilter(filter: Filter | Filter[] | FilterRange): filter is Filter {
        return filter && 'isActive' in filter;
    }

    areFilters(filters: Filter | Filter[] | FilterRange): filters is Filter[] {
        return Array.isArray(filters) && (!filters.length || this.isFilter(filters[0]));
    }

    isFilterRange(filter: Filter | Filter[] | FilterRange): filter is FilterRange {
        return filter && 'range' in filter;
    }

    isFilterSwitch(filter: Filter | Filter[] | FilterRange): filter is Filter {
        return filter && 'isActiveByDefault' in filter;
    }

    isMeasurement(value: Measurement | number): value is Measurement {
        return (
            value &&
            typeof value === 'object' &&
            ['value', 'unit'].every((property: string) =>
                Object.prototype.hasOwnProperty.call(value, property),
            )
        );
    }
}
