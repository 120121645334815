import { capitalize } from 'lodash';
import { environment } from '@environments/leap/environment';
import {
    EXTERNAL_POLICY_LINK,
    EXTERNAL_POLICY_TEXT,
    EXTERNAL_POLICY_DISCLAIMER_TEXT,
} from './external';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';

export const GPT_TAB_TITLE: string = `${environment.product}${capitalize(TabId.gpt)}`;
export const ERROR_TEXT: string = `${GPT_TAB_TITLE} encountered a problem. Click on the regenerate button to try again.`;
export const LOADING_TEXT: string = `${GPT_TAB_TITLE} is generating your response.`;

export const DISCLAIMER_TEXT: string = `${GPT_TAB_TITLE} is currently in Beta phase.
Answers combine knowledge from ${environment.product} and Generative AI Models. Relevance to ${environment.product} results and accuracy of statements may vary.
To learn more about how your data is handled please refer to our
<a class="branding-link" href="legal/privacy-policy" target="_blank">Privacy Policy</a> and the
<a class="branding-link" href="${EXTERNAL_POLICY_LINK}" target="_blank">${EXTERNAL_POLICY_TEXT}</a>.
${EXTERNAL_POLICY_DISCLAIMER_TEXT}`;
export const DISCLAIMER_ICON: string = 'fa-solid fa-triangle-exclamation';

export const PLACEHOLDER_TEXT: string = 'Enter your question here...';
