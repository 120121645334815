import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { TagsActionTypes } from './tags-action-types.enum';
import Tag from './interfaces/tag.interface';

export const getTagsRequest = createAction(TagsActionTypes.GET_TAGS_REQUEST);

export const getTagsSuccess = createAction(
    TagsActionTypes.GET_TAGS_SUCCESS,
    props<{ tags: Tag[] }>(),
);

export const getTagsFailure = createAction(
    TagsActionTypes.GET_TAGS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(TagsActionTypes.CLEAR_NEXT_ERROR);
