export enum NotebooksActionTypes {
    GET_NOTEBOOKS_REQUEST = '[NOTEBOOKS] Get Notebooks Request',
    GET_NOTEBOOKS_SUCCESS = '[NOTEBOOKS] Get Notebooks Success',
    GET_NOTEBOOKS_FAILURE = '[NOTEBOOKS] Get Notebooks Failure',
    GET_NOTEBOOK_REQUEST = '[NOTEBOOKS] Get Notebook Request',
    GET_NOTEBOOK_SUCCESS = '[NOTEBOOKS] Get Notebook Success',
    GET_NOTEBOOK_FAILURE = '[NOTEBOOKS] Get Notebook Failure',
    CREATE_NOTEBOOK_REQUEST = '[NOTEBOOKS] Create Notebook Request',
    CREATE_NOTEBOOK_SUCCESS = '[NOTEBOOKS] Create Notebook Success',
    CREATE_NOTEBOOK_FAILURE = '[NOTEBOOKS] Create Notebook Failure',
    CLONE_NOTEBOOK_REQUEST = '[NOTEBOOKS] Clone Notebook Request',
    CLONE_NOTEBOOK_SUCCESS = '[NOTEBOOKS] Clone Notebook Success',
    CLONE_NOTEBOOK_FAILURE = '[NOTEBOOKS] Clone Notebook Failure',
    UPDATE_NOTEBOOK_REQUEST = '[NOTEBOOKS] Update Notebook Request',
    UPDATE_NOTEBOOK_SUCCESS = '[NOTEBOOKS] Update Notebook Success',
    UPDATE_NOTEBOOK_FAILURE = '[NOTEBOOKS] Update Notebook Failure',
    DELETE_NOTEBOOK_REQUEST = '[NOTEBOOKS] Delete Notebook Request',
    DELETE_NOTEBOOK_SUCCESS = '[NOTEBOOKS] Delete Notebook Success',
    DELETE_NOTEBOOK_FAILURE = '[NOTEBOOKS] Delete Notebook Failure',
    CLEAR_NEXT_ERROR = '[NOTEBOOKS] Clear Next Error',
    CLEAR_SHOULD_FETCH_NOTEBOOKS = '[NOTEBOOKS] Clear shouldFetchNotebooks',
}
