import { Pipe, PipeTransform } from '@angular/core';
import { intersection } from 'lodash';

@Pipe({
    name: 'intersection',
})
export class IntersectionPipe implements PipeTransform {
    constructor() {}

    public transform<T = unknown>(itemsA: T[], itemsB: T[]): T[] {
        return intersection(itemsA, itemsB);
    }
}
