import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromDiscovery from './open-discovery.reducer';

export const getOpenDiscoveryState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.open,
);

export const getFilterCounts = createSelector(getOpenDiscoveryState, fromDiscovery.getFilterCounts);
export const getDisplaying = createSelector(getOpenDiscoveryState, fromDiscovery.getDisplaying);
export const getTotal = createSelector(getOpenDiscoveryState, fromDiscovery.getTotal);
export const getOldestOccurrence = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getOldestOccurrence,
);
export const getNewestOccurrence = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getNewestOccurrence,
);
export const getMinCowMilkConcentration = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMinCowMilkConcentration,
);
export const getMaxCowMilkConcentration = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMaxCowMilkConcentration,
);
export const getMinSourceConcentration = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMinSourceConcentration,
);
export const getMaxSourceConcentration = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMaxSourceConcentration,
);
export const getPrevalenceRange = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getPrevalenceRange,
);
export const getMinMoleculeWeight = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMinMoleculeWeight,
);
export const getMaxMoleculeWeight = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getMaxMoleculeWeight,
);
export const getInsightsPerCategory = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getInsightsPerCategory,
);
export const getGroupedCategories = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getGroupedCategories,
);
export const getShouldShowSourceConcentration = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getShouldShowSourceConcentration,
);
export const getPreferences = createSelector(getOpenDiscoveryState, fromDiscovery.getPreferences);
export const getSearchSuggestions = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getSearchSuggestions,
);
export const getSearchSuggestionsLoading = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoading,
);
export const getSearchSuggestionsLoaded = createSelector(
    getOpenDiscoveryState,
    fromDiscovery.getSearchSuggestionsLoaded,
);
export const getErrors = createSelector(getOpenDiscoveryState, fromDiscovery.getErrors);
export const getLoading = createSelector(getOpenDiscoveryState, fromDiscovery.getLoading);
export const getLoaded = createSelector(getOpenDiscoveryState, fromDiscovery.getLoaded);
export const getBlob = createSelector(getOpenDiscoveryState, fromDiscovery.getBlob);
export const getSessionId = createSelector(getOpenDiscoveryState, fromDiscovery.getSessionId);
