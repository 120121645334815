/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as discoveryActions from './closed-discovery.actions';
import * as openDiscoveryActions from '../open/open-discovery.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ClosedDiscoveryState } from './closed-discovery-state.interface';
import Insight from './interfaces/insight.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import InsightsPerCategory from './types/insights-per-category.type';
import InsightGroupedCategories from '@apps/leap/src/app/shared/types/insight-grouped-categories.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

export const adapter: EntityAdapter<Insight> = createEntityAdapter<Insight>();

export const initialState: ClosedDiscoveryState = adapter.getInitialState({
    filterCounts: null,
    displaying: null,
    total: null,
    oldestOccurrence: null,
    newestOccurrence: null,
    minCowMilkConcentration: null,
    maxCowMilkConcentration: null,
    minSourceConcentration: null,
    maxSourceConcentration: null,
    minTargetConcentration: null,
    maxTargetConcentration: null,
    minMoleculeWeight: null,
    maxMoleculeWeight: null,
    insightsPerCategory: null,
    groupedCategories: null,
    shouldShowSourceConcentration: false,
    shouldShowTargetConcentration: false,
    preferences: null,
    searchSuggestions: [],
    searchSuggestionsLoading: false,
    searchSuggestionsLoaded: false,
    errors: [],
    loading: false,
    loaded: false,
    blob: null,
    sessionId: null,
});

const discoveryReducer: ActionReducer<ClosedDiscoveryState, Action> = createReducer(
    initialState,
    on(openDiscoveryActions.performDiscoveryRequest, (state: ClosedDiscoveryState) =>
        adapter.removeAll(state),
    ),
    on(discoveryActions.performDiscoveryRequest, (state: ClosedDiscoveryState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        discoveryActions.performDiscoverySuccess,
        (
            state: ClosedDiscoveryState,
            { paginatedInsights }: { paginatedInsights: PaginatedInsights },
        ) => ({
            ...state,
            filterCounts: paginatedInsights.filterCounts,
            displaying: paginatedInsights.displayingInsights,
            total: paginatedInsights.total,
            oldestOccurrence: paginatedInsights.oldestOccurrence,
            newestOccurrence: paginatedInsights.newestOccurrence,
            minCowMilkConcentration: paginatedInsights.minCowMilkConcentration,
            maxCowMilkConcentration: paginatedInsights.maxCowMilkConcentration,
            minSourceConcentration: paginatedInsights.minSourceConcentration,
            maxSourceConcentration: paginatedInsights.maxSourceConcentration,
            minTargetConcentration: paginatedInsights.minTargetConcentration,
            maxTargetConcentration: paginatedInsights.maxTargetConcentration,
            minMoleculeWeight: paginatedInsights.minMoleculeWeight,
            maxMoleculeWeight: paginatedInsights.maxMoleculeWeight,
            insightsPerCategory: paginatedInsights.insightsPerCategory,
            groupedCategories: paginatedInsights.groupedCategories,
            shouldShowSourceConcentration: paginatedInsights.shouldShowSourceConcentration,
            shouldShowTargetConcentration: paginatedInsights.shouldShowTargetConcentration,
            preferences: paginatedInsights.preferences,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        discoveryActions.performDiscoveryFailure,
        (state: ClosedDiscoveryState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(discoveryActions.downloadDiscoveryRequest, (state: ClosedDiscoveryState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        discoveryActions.downloadDiscoverySuccess,
        (state: ClosedDiscoveryState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        discoveryActions.downloadDiscoveryFailure,
        (state: ClosedDiscoveryState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(discoveryActions.getSearchSuggestionsRequest, (state: ClosedDiscoveryState) => ({
        ...state,
        searchSuggestionsLoading: true,
        searchSuggestionsLoaded: false,
    })),
    on(
        discoveryActions.getSearchSuggestionsSuccess,
        (state: ClosedDiscoveryState, { suggestionIds }: { suggestionIds: string[] }) => ({
            ...state,
            searchSuggestions: suggestionIds,
            searchSuggestionsLoading: false,
            searchSuggestionsLoaded: true,
        }),
    ),
    on(
        discoveryActions.getSearchSuggestionsFailure,
        (state: ClosedDiscoveryState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            discoverySearchSuggestionsLoading: false,
            discoverySearchSuggestionsLoaded: false,
        }),
    ),
    on(discoveryActions.clearSearchSuggestions, (state: ClosedDiscoveryState) => ({
        ...state,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
    })),
    on(discoveryActions.resetDiscovery, (state: ClosedDiscoveryState) => ({
        ...state,
        filterCounts: null as InsightFilterCounts,
        displaying: null as number,
        total: null as number,
        oldestOccurrence: null as number,
        newestOccurrence: null as number,
        minCowMilkConcentration: null as number,
        maxCowMilkConcentration: null as number,
        minSourceConcentration: null as number,
        maxSourceConcentration: null as number,
        minTargetConcentration: null as number,
        maxTargetConcentration: null as number,
        minMoleculeWeight: null as number,
        maxMoleculeWeight: null as number,
        insightsPerCategory: null as InsightsPerCategory,
        groupedCategories: null as InsightGroupedCategories,
        shouldShowSourceConcentration: false,
        shouldShowTargetConcentration: false,
        preferences: null as UserPreferences,
        searchSuggestions: null as string[],
        searchSuggestionsLoading: false,
        searchSuggestionsLoaded: false,
        loading: false,
        loaded: false,
    })),
    on(discoveryActions.clearNextError, (state: ClosedDiscoveryState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(discoveryActions.saveSessionId, (state: ClosedDiscoveryState, { id }: { id: string }) => ({
        ...state,
        sessionId: id,
    })),
);

export const reducer = (
    state: ClosedDiscoveryState | undefined,
    action: Action,
): ClosedDiscoveryState => discoveryReducer(state, action);

// selectors
export const getFilterCounts: (state: ClosedDiscoveryState) => InsightFilterCounts = (
    state: ClosedDiscoveryState,
) => state.filterCounts;
export const getDisplaying: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.displaying;
export const getTotal: (state: ClosedDiscoveryState) => number = (state: ClosedDiscoveryState) =>
    state.total;
export const getOldestOccurrence: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.oldestOccurrence;
export const getNewestOccurrence: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.newestOccurrence;
export const getMinCowMilkConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.minCowMilkConcentration;
export const getMaxCowMilkConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.maxCowMilkConcentration;
export const getMinSourceConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.minSourceConcentration;
export const getMaxSourceConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.maxSourceConcentration;
export const getMinTargetConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.minTargetConcentration;
export const getMaxTargetConcentration: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.maxTargetConcentration;
export const getMinMoleculeWeight: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.minMoleculeWeight;
export const getMaxMoleculeWeight: (state: ClosedDiscoveryState) => number = (
    state: ClosedDiscoveryState,
) => state.maxMoleculeWeight;
export const getInsightsPerCategory: (state: ClosedDiscoveryState) => InsightsPerCategory = (
    state: ClosedDiscoveryState,
) => state.insightsPerCategory;
export const getGroupedCategories: (state: ClosedDiscoveryState) => InsightGroupedCategories = (
    state: ClosedDiscoveryState,
) => state.groupedCategories;
export const getShouldShowSourceConcentration: (state: ClosedDiscoveryState) => boolean = (
    state: ClosedDiscoveryState,
) => state.shouldShowSourceConcentration;
export const getShouldShowTargetConcentration: (state: ClosedDiscoveryState) => boolean = (
    state: ClosedDiscoveryState,
) => state.shouldShowTargetConcentration;
export const getPreferences: (state: ClosedDiscoveryState) => UserPreferences = (
    state: ClosedDiscoveryState,
) => state.preferences;
export const getSearchSuggestions: (state: ClosedDiscoveryState) => string[] = (
    state: ClosedDiscoveryState,
) => state.searchSuggestions;
export const getSearchSuggestionsLoading: (state: ClosedDiscoveryState) => boolean = (
    state: ClosedDiscoveryState,
) => state.searchSuggestionsLoading;
export const getSearchSuggestionsLoaded: (state: ClosedDiscoveryState) => boolean = (
    state: ClosedDiscoveryState,
) => state.searchSuggestionsLoaded;
export const getErrors: (state: ClosedDiscoveryState) => ErrorResponse[] = (
    state: ClosedDiscoveryState,
) => state.errors;
export const getLoading: (state: ClosedDiscoveryState) => boolean = (state: ClosedDiscoveryState) =>
    state.loading;
export const getLoaded: (state: ClosedDiscoveryState) => boolean = (state: ClosedDiscoveryState) =>
    state.loaded;
export const getBlob: (state: ClosedDiscoveryState) => Blob = (state: ClosedDiscoveryState) =>
    state.blob;
export const getSessionId: (state: ClosedDiscoveryState) => string = (
    state: ClosedDiscoveryState,
) => state.sessionId;
