import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromNotebooks from './notebooks.reducer';

export const getNotebooksState = createSelector(
    getDataState,
    (state: DataState) => state.notebooks,
);
export const getNotebooks = createSelector(getNotebooksState, fromNotebooks.getNotebooksEntities);
export const getNotebook = createSelector(getNotebooksState, fromNotebooks.getNotebook);
export const getNotebookLoading = createSelector(
    getNotebooksState,
    fromNotebooks.getNotebookLoading,
);
export const getNotebookLoaded = createSelector(getNotebooksState, fromNotebooks.getNotebookLoaded);
export const getTemplates = createSelector(getNotebooksState, fromNotebooks.getTemplates);
export const getError = createSelector(getNotebooksState, fromNotebooks.getError);
export const getLoading = createSelector(getNotebooksState, fromNotebooks.getLoading);
export const getLoaded = createSelector(getNotebooksState, fromNotebooks.getLoaded);
export const getPageIndex = createSelector(getNotebooksState, fromNotebooks.getPageIndex);
export const getPageSize = createSelector(getNotebooksState, fromNotebooks.getPageSize);
export const getTotal = createSelector(getNotebooksState, fromNotebooks.getTotal);
export const getSortDirection = createSelector(getNotebooksState, fromNotebooks.getSortDirection);
export const getSortColumn = createSelector(getNotebooksState, fromNotebooks.getSortColumn);
export const getDeletePendingItems = createSelector(
    getNotebooksState,
    fromNotebooks.getDeletePendingItems,
);
export const getShouldFetchNotebooks = createSelector(
    getNotebooksState,
    fromNotebooks.getShouldFetchNotebooks,
);
