import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { FeatureFlagsService } from './feature-flags.service';

@Injectable()
export class FeatureGuard implements CanActivate, CanLoad {
    constructor(private featureFlagsService: FeatureFlagsService, private router: Router) {}

    /**
     * Prevents unauthorized users from accessing certain routes.
     */
    canActivate(route: ActivatedRouteSnapshot): boolean {
        return this.isRouteEnabled(route);
    }

    /**
     * Prevents the application from loading entire modules lazily if the user
     * is not authorized to do so.
     */
    canLoad(route: Route): boolean {
        return this.isRouteEnabled(route);
    }

    /**
     * Enables the protected route based on the featureFlagsService.isFeatureEnabled().
     * If the guard should not get applied, allow the user to access the route.
     * If the feature is not enabled re-route the user to the given path.
     * If there is no given path, redirect them to the '/'.
     */
    isRouteEnabled(route: ActivatedRouteSnapshot | Route): boolean {
        const {
            data: { feature, path, shouldApplyGuard = true },
        } = route;

        if (!shouldApplyGuard) {
            return true;
        }

        if (feature) {
            const isEnabled: boolean = this.featureFlagsService.isFeatureEnabled(feature);

            if (isEnabled) {
                return true;
            }
        }

        this.router.navigate([path ? path : '/']);
        return false;
    }
}
