import {
    Component,
    AfterViewInit,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
    NgZone,
} from '@angular/core';

@Component({
    selector: 'app-iframe',
    templateUrl: 'iframe.view.component.html',
})
export class IframeViewComponent implements AfterViewInit, OnDestroy {
    @Input() path: string;
    @Input() shouldHandleScroll: boolean;
    @Output() scrolledToBottom: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('iframe') iframe: ElementRef;

    iframeWindow: Window;
    scrollListener: EventListener;
    isScrolledToBottom: boolean = false;
    latestIsScrolledToBottom: boolean = false;

    constructor(private ngZone: NgZone) {}

    ngAfterViewInit(): void {
        if (!this.shouldHandleScroll) {
            return;
        }

        this.initializeScrollHandling();
    }

    ngOnDestroy(): void {
        if (!this.shouldHandleScroll) {
            return;
        }

        if (this.iframeWindow && this.scrollListener) {
            this.iframeWindow.removeEventListener('scroll', this.scrollListener);
        }

        this.scrolledToBottom.emit(false);
    }

    initializeScrollHandling(): void {
        const iframeElement: HTMLIFrameElement = this.iframe.nativeElement;

        iframeElement.onload = (): void => {
            this.iframeWindow = iframeElement.contentWindow;
            const iframeDocument: Document = this.iframeWindow.document;

            // store the event listener function
            this.scrollListener = (): void => {
                this.onScroll(this.iframeWindow, iframeDocument);
            };

            // add the scroll event listener
            this.iframeWindow.addEventListener('scroll', this.scrollListener);
        };
    }

    onScroll(iframeWindow: Window, iframeDocument: Document): void {
        const scrollTop: number = iframeWindow.scrollY;
        const scrollHeight: number = iframeDocument.documentElement.scrollHeight;
        const clientHeight: number = iframeDocument.documentElement.clientHeight;

        // define a small tolerance value to overcome the floating-point precision problem when the browser window is zoomed.
        // Zooming can introduce small inaccuracies in these values due to the way the browser handles rendering.
        const tolerance: number = 1; // pixels

        this.isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - tolerance;

        // updates and emits `scrolledToBottom` only when it changes, to prevent unnecessary emissions
        if (this.isScrolledToBottom !== this.latestIsScrolledToBottom) {
            this.latestIsScrolledToBottom = this.isScrolledToBottom;

            // Events triggered from an iframe are handled outside of Angular's zone by default.
            // To counter this, we force change detection by wrapping emit within `ngZone.run()`
            this.ngZone.run(() => {
                this.scrolledToBottom.emit(this.isScrolledToBottom);
            });
        }
    }
}
