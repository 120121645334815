import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DELETE_ICON } from '../../../constants/chat';

@Component({
    selector: 'app-chat-delete',
    templateUrl: 'chat-delete.view.component.html',
    styleUrls: ['chat-delete.view.component.scss'],
})
export class ChatDeleteViewComponent {
    @Input() isDisabled: boolean;
    @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly icon: string = DELETE_ICON;

    constructor() {}

    onClick(): void {
        this.clicked.emit(true);
    }
}
