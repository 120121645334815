/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as bookmarksActions from './bookmarks.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { BookmarksState } from './bookmarks-state.interface';
import { NOTES_SUCCESS_MESSAGE } from '@leap-libs/notes/src/lib/constants/notes';
import {
    ARTICLE_UPLOAD_INVALID_ERROR_STATUS,
    ARTICLE_UPLOAD_NOT_FOUND_ERROR_STATUS,
    ARTICLE_UPLOAD_DUPLICATE_ERROR_STATUS,
} from './constants/bookmarks';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import AlertType from '@leap-store/core/src/lib/ui/alerts/enums/alert-type.enum';
import PaginatedBookmarks from './interfaces/paginated-bookmarks.interface';
import Bookmark from './interfaces/bookmark.interface';
import BookmarkType from './enums/bookmark.enum';
import CountPerBookmarkType from './interfaces/count-per-bookmark-type.interface';
import Project from '../projects/interfaces/project.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import FilterCountsPerBookmarkType from './interfaces/filter-counts-per-bookmark-type.interface';
import RangePerBookmarkType from './interfaces/range-per-bookmark-type.interface';
import BookmarksPerType from './interfaces/bookmarks-per-type.interface';
import ArticleUploadStatus from './enums/article-upload-status.enum';

export const adapter: EntityAdapter<Bookmark> = createEntityAdapter<Bookmark>();

export const initialState: BookmarksState = adapter.getInitialState({
    bookmarks: null,
    loading: null,
    loaded: null,
    errors: [],
    success: [],
    pageIndex: 0,
    pageSize: 0,
    countPerBookmarkType: null,
    filterCounts: null,
    dateRange: null,
    cowMilkConcentrationRange: null,
    moleculeWeightRange: null,
    preferences: null,
    articleUploadStatus: null,
    isArticleUploading: false,
    isArticleUploaded: false,
    deletePendingBookmarks: [],
    blob: null,
});

const bookmarksReducer: ActionReducer<BookmarksState, Action> = createReducer(
    initialState,
    on(
        bookmarksActions.getBookmarksRequest,
        (
            state: BookmarksState,
            {
                suppressLoading,
                bookmarkType,
            }: { suppressLoading: boolean; bookmarkType: BookmarkType },
        ) => ({
            ...state,
            loading: suppressLoading
                ? state.loading
                : {
                      ...state.loading,
                      [bookmarkType]: true,
                  },
            loaded: suppressLoading
                ? state.loaded
                : {
                      ...state.loaded,
                      [bookmarkType]: false,
                  },
        }),
    ),
    on(
        bookmarksActions.getBookmarksSuccess,
        (
            state: BookmarksState,
            {
                paginatedBookmarks,
                bookmarkType,
            }: { paginatedBookmarks: PaginatedBookmarks; bookmarkType: BookmarkType },
        ) => ({
            ...state,
            bookmarks: { type: bookmarkType, bookmarks: paginatedBookmarks.results },
            pageIndex: paginatedBookmarks.pageIndex,
            pageSize: paginatedBookmarks.pageSize,
            countPerBookmarkType: {
                bookmarkType,
                count: paginatedBookmarks.total,
            },
            filterCounts: {
                type: bookmarkType,
                counts: paginatedBookmarks.filterCounts,
            },
            dateRange: {
                type: bookmarkType,
                range: paginatedBookmarks.dateRange,
            },
            cowMilkConcentrationRange: {
                type: bookmarkType,
                range: paginatedBookmarks.cowMilkConcentrationRange,
            },
            moleculeWeightRange: {
                type: bookmarkType,
                range: paginatedBookmarks.moleculeWeightRange,
            },
            preferences: { ...state.preferences, [bookmarkType]: paginatedBookmarks.preferences },
            loading: { ...state.loading, [bookmarkType]: false },
            loaded: { ...state.loaded, [bookmarkType]: true },
        }),
    ),
    on(
        bookmarksActions.getBookmarksFailure,
        (
            state: BookmarksState,
            {
                errorResponse,
                bookmarkType,
            }: { errorResponse: HttpErrorResponse; bookmarkType: BookmarkType },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: { ...state.loading, [bookmarkType]: false },
            loaded: { ...state.loaded, [bookmarkType]: false },
        }),
    ),
    on(bookmarksActions.clearBookmarks, (state: BookmarksState) => ({
        ...state,
        bookmarks: null as BookmarksPerType,
        filterCounts: null as FilterCountsPerBookmarkType,
        dateRange: null as RangePerBookmarkType,
        cowMilkConcentrationRange: null as RangePerBookmarkType,
        moleculeWeightRange: null as RangePerBookmarkType,
        preferences: null as Partial<Record<BookmarkType, UserPreferences>>,
        articleUploadStatus: null as ArticleUploadStatus,
    })),
    on(bookmarksActions.createEntityBookmarksRequest, (state: BookmarksState) => ({
        ...state,
        loading: { ...state.loading, [BookmarkType.entity]: true },
        loaded: { ...state.loaded, [BookmarkType.entity]: false },
    })),
    on(
        bookmarksActions.createEntityBookmarksSuccess,
        (state: BookmarksState, { alert }: { alert: Alert }) => ({
            ...state,
            project: null as Project,
            success: [...state.success, alert],
            loading: { ...state.loading, [BookmarkType.entity]: false },
            loaded: { ...state.loaded, [BookmarkType.entity]: true },
        }),
    ),
    on(
        bookmarksActions.createEntityBookmarksFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            loading: { ...state.loading, [BookmarkType.entity]: false },
            loaded: { ...state.loaded, [BookmarkType.entity]: false },
        }),
    ),
    on(bookmarksActions.createAssociationOpenBookmarksRequest, (state: BookmarksState) => ({
        ...state,
        loading: { ...state.loading, [BookmarkType.associationOpen]: true },
        loaded: { ...state.loaded, [BookmarkType.associationOpen]: false },
    })),
    on(
        bookmarksActions.createAssociationOpenBookmarksSuccess,
        (state: BookmarksState, { alert }: { alert: Alert }) => ({
            ...state,
            project: null as Project,
            success: [...state.success, alert],
            loading: { ...state.loading, [BookmarkType.associationOpen]: false },
            loaded: { ...state.loaded, [BookmarkType.associationOpen]: true },
        }),
    ),
    on(
        bookmarksActions.createAssociationOpenBookmarksFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            loading: { ...state.loading, [BookmarkType.associationOpen]: false },
            loaded: { ...state.loaded, [BookmarkType.associationOpen]: false },
        }),
    ),
    on(bookmarksActions.createAssociationClosedBookmarksRequest, (state: BookmarksState) => ({
        ...state,
        loading: { ...state.loading, [BookmarkType.associationClosed]: true },
        loaded: { ...state.loaded, [BookmarkType.associationClosed]: false },
    })),
    on(
        bookmarksActions.createAssociationClosedBookmarksSuccess,
        (state: BookmarksState, { alert }: { alert: Alert }) => ({
            ...state,
            project: null as Project,
            success: [...state.success, alert],
            loading: { ...state.loading, [BookmarkType.associationClosed]: false },
            loaded: { ...state.loaded, [BookmarkType.associationClosed]: true },
        }),
    ),
    on(
        bookmarksActions.createAssociationClosedBookmarksFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            loading: { ...state.loading, [BookmarkType.associationClosed]: false },
            loaded: { ...state.loaded, [BookmarkType.associationClosed]: false },
        }),
    ),
    on(bookmarksActions.createArticleBookmarksRequest, (state: BookmarksState) => ({
        ...state,
        loading: { ...state.loading, [BookmarkType.article]: true },
        loaded: { ...state.loaded, [BookmarkType.article]: false },
    })),
    on(
        bookmarksActions.createArticleBookmarksSuccess,
        (state: BookmarksState, { alert }: { alert: Alert }) => ({
            ...state,
            project: null as Project,
            success: [...state.success, alert],
            loading: { ...state.loading, [BookmarkType.article]: false },
            loaded: { ...state.loaded, [BookmarkType.article]: true },
        }),
    ),
    on(
        bookmarksActions.createArticleBookmarksFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            loading: { ...state.loading, [BookmarkType.article]: false },
            loaded: { ...state.loaded, [BookmarkType.article]: false },
        }),
    ),
    on(bookmarksActions.uploadArticleRequest, (state: BookmarksState) => ({
        ...state,
        articleUploadStatus: null as ArticleUploadStatus,
        isArticleUploading: true,
        isArticleUploaded: false,
    })),
    on(bookmarksActions.uploadArticleSuccess, (state: BookmarksState) => ({
        ...state,
        articleUploadStatus: ArticleUploadStatus.success,
        isArticleUploading: false,
        isArticleUploaded: true,
    })),
    on(
        bookmarksActions.uploadArticleFailure,
        (
            state: BookmarksState,
            {
                errorResponse,
                isFileError,
            }: { errorResponse: HttpErrorResponse; isFileError?: boolean },
        ) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            articleUploadStatus: isFileError
                ? ArticleUploadStatus.fileError
                : errorResponse.status === ARTICLE_UPLOAD_INVALID_ERROR_STATUS ||
                  errorResponse.status === ARTICLE_UPLOAD_NOT_FOUND_ERROR_STATUS
                ? ArticleUploadStatus.invalid
                : errorResponse.status === ARTICLE_UPLOAD_DUPLICATE_ERROR_STATUS
                ? ArticleUploadStatus.duplicate
                : null,
            isArticleUploading: false,
            isArticleUploaded: false,
        }),
    ),
    on(bookmarksActions.downloadArticleBookmarksRequest, (state: BookmarksState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        bookmarksActions.downloadArticleBookmarksSuccess,
        (state: BookmarksState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        bookmarksActions.downloadArticleBookmarksFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(bookmarksActions.updateNotesRequest, (state: BookmarksState) => ({
        ...state,
    })),
    on(bookmarksActions.updateNotesSuccess, (state: BookmarksState) => ({
        ...state,
        success: [
            ...state.success,
            {
                type: AlertType.success,
                icon: 'check',
                messages: [NOTES_SUCCESS_MESSAGE],
            },
        ],
    })),
    on(
        bookmarksActions.updateNotesFailure,
        (state: BookmarksState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(
        bookmarksActions.deleteBookmarkRequest,
        (state: BookmarksState, { bookmarkId }: { bookmarkId: string }) => ({
            ...state,
            deletePendingBookmarks: [...state.deletePendingBookmarks, bookmarkId],
        }),
    ),
    on(
        bookmarksActions.deleteBookmarkSuccess,
        (
            state: BookmarksState,
            {
                bookmarkId,
                bookmarks,
                bookmarkType,
            }: { bookmarkId: string; bookmarks: Bookmark[]; bookmarkType: BookmarkType },
        ) => ({
            ...state,
            deletePendingBookmarks: state.deletePendingBookmarks.filter(
                (pendingBookmark: string) => pendingBookmark !== bookmarkId,
            ),
            bookmarks: {
                type: bookmarkType,
                bookmarks: bookmarks.filter(
                    ({ id, deleting }: Bookmark) =>
                        id !== bookmarkId &&
                        (!deleting || state.deletePendingBookmarks.includes(id)), // handles case with multiple concurrent deletions
                ),
            },
            countPerBookmarkType: { bookmarkType, count: bookmarks.length - 1 },
            success: [
                ...state.success,
                {
                    type: AlertType.success,
                    icon: 'trash-alt',
                    messages: ['Bookmark deleted'],
                },
            ],
        }),
    ),
    on(
        bookmarksActions.deleteBookmarkFailure,
        (
            state: BookmarksState,
            { bookmarkId, errorResponse }: { bookmarkId: string; errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            deletePendingBookmarks: state.deletePendingBookmarks.filter(
                (pendingBookmark: string) => pendingBookmark !== bookmarkId,
            ),
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(bookmarksActions.clearNextSuccess, (state: BookmarksState) => ({
        ...state,
        success: state.success.slice(1),
    })),
    on(bookmarksActions.clearNextError, (state: BookmarksState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(bookmarksActions.clearShouldFetch, (state: BookmarksState) => ({
        ...state,
    })),
);

export const reducer = (state: BookmarksState | undefined, action: Action): BookmarksState =>
    bookmarksReducer(state, action);

// selectors
export const getBookmarks: (state: BookmarksState) => BookmarksPerType = (state: BookmarksState) =>
    state.bookmarks;
export const getLoading: (state: BookmarksState) => Partial<Record<BookmarkType, boolean>> = (
    state: BookmarksState,
) => state.loading;
export const getLoaded: (state: BookmarksState) => Partial<Record<BookmarkType, boolean>> = (
    state: BookmarksState,
) => state.loaded;
export const getErrors: (state: BookmarksState) => ErrorResponse[] = (state: BookmarksState) =>
    state.errors;
export const getSuccess: (state: BookmarksState) => Alert[] = (state: BookmarksState) =>
    state.success;
export const getPageIndex: (state: BookmarksState) => number = (state: BookmarksState) =>
    state.pageIndex;
export const getPageSize: (state: BookmarksState) => number = (state: BookmarksState) =>
    state.pageSize;
export const getCountPerBookmarkType: (state: BookmarksState) => CountPerBookmarkType = (
    state: BookmarksState,
) => state.countPerBookmarkType;
export const getFilterCounts: (state: BookmarksState) => FilterCountsPerBookmarkType = (
    state: BookmarksState,
) => state.filterCounts;
export const getDateRange: (state: BookmarksState) => RangePerBookmarkType = (
    state: BookmarksState,
) => state.dateRange;
export const getCowMilkConcentrationRange: (state: BookmarksState) => RangePerBookmarkType = (
    state: BookmarksState,
) => state.cowMilkConcentrationRange;
export const getMoleculeWeightRange: (state: BookmarksState) => RangePerBookmarkType = (
    state: BookmarksState,
) => state.moleculeWeightRange;
export const getArticleUploadStatus: (state: BookmarksState) => ArticleUploadStatus = (
    state: BookmarksState,
) => state.articleUploadStatus;
export const getArticleUploading: (state: BookmarksState) => boolean = (state: BookmarksState) =>
    state.isArticleUploading;
export const getArticleUploaded: (state: BookmarksState) => boolean = (state: BookmarksState) =>
    state.isArticleUploaded;
export const getBlob: (state: BookmarksState) => Blob = (state: BookmarksState) => state.blob;
export const getDeletePendingBookmarks: (state: BookmarksState) => string[] = (
    state: BookmarksState,
) => state.deletePendingBookmarks;
export const getPreferences: (
    state: BookmarksState,
) => Partial<Record<BookmarkType, UserPreferences>> = (state: BookmarksState) => state.preferences;
