enum ArticleFilter {
    studyType = 'studyType',
    journal = 'journal',
    origin = 'origin',
    relationship = 'relationship',
    association = 'association',
    dateRange = 'dateRange',
    fullText = 'fullText',
    coOccurrences = 'coOccurrences',
    animalMilk = 'animalMilk',
    biomarkers = 'biomarkers',
    effect = 'effect',
}

export default ArticleFilter;
