import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordContainerComponent } from './components/containers/forgot-password/forgot-password.container.component';

const routes: Routes = [
    {
        path: '',
        component: ForgotPasswordContainerComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ForgotPasswordRoutingModule {}
