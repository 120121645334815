import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'kebabcase',
})
export class KebabCasePipe implements PipeTransform {
    constructor() {}

    public transform(value: string): string {
        if (!value) {
            return;
        }
        return value.split(' ').join('-');
    }
}
