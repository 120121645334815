import { createSelector } from '@ngrx/store';
import { DataState } from '../../data-state.interface';
import { getDataState } from '../../index';
import * as fromDatabases from './databases.reducer';

export const getDatabasesState = createSelector(
    getDataState,
    (state: DataState) => state.discovery.databases,
);

export const getDatabases = createSelector(getDatabasesState, fromDatabases.getDatabases);
export const getErrors = createSelector(getDatabasesState, fromDatabases.getErrors);
export const getLoading = createSelector(getDatabasesState, fromDatabases.getLoading);
export const getLoaded = createSelector(getDatabasesState, fromDatabases.getLoaded);
