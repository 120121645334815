/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ReportParser } from '../parsers/report.parser';
import ReportPayloadRestApi from '../rest-api-interfaces/report-payload.rest.interface';
import ReportPayload from '../interfaces/report-payload.interface';
import BackgroundRestApi from '../rest-api-interfaces/background.rest.interface';
import Background from '../interfaces/background.interface';
import StatisticsRestApi from '../rest-api-interfaces/statistics.rest.interface';
import Statistics from '../interfaces/statistics.interface';
import TopResultsRestApi from '../rest-api-types/top-results.rest.type';
import TopResults from '../types/top-results.type';
import ReferenceSummarizationPerCategory from '../interfaces/reference-summarization-per-category.interface';
import ReferenceRestApi from '../rest-api-interfaces/reference.rest.interface';
import Reference from '../interfaces/reference.interface';
import FileExtension from '@leap-common/enums/file-extension.enum';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import ReportFormatRestApi from '../enums/report-format.rest.enum';

@Injectable()
export class ReportService {
    constructor(private http: HttpClient, private reportParser: ReportParser) {}

    getIntroduction(payload: ReportPayload): Observable<string> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=introduction`, {
                ...serializedPayload,
            })
            .pipe(map((response: Record<string, string>) => response.introduction));
    }

    getBackground(payload: ReportPayload): Observable<Background> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=background`, {
                ...serializedPayload,
            })
            .pipe(
                map((response: Record<string, BackgroundRestApi>) =>
                    this.reportParser.parseBackground(response.background),
                ),
            );
    }

    getStatistics(payload: ReportPayload): Observable<Statistics> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=statistics`, {
                ...serializedPayload,
            })
            .pipe(
                map((response: Record<string, StatisticsRestApi>) =>
                    this.reportParser.parseStatistics(response.statistics),
                ),
            );
    }

    getTopResults(
        payload: ReportPayload,
        discovery: Discovery,
        studyTypesOrder: string[],
    ): Observable<TopResults> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=top_results`, {
                ...serializedPayload,
            })
            .pipe(
                map((response: Record<string, TopResultsRestApi>) =>
                    this.reportParser.parseTopResults(
                        response.top_results,
                        discovery,
                        studyTypesOrder,
                    ),
                ),
            );
    }

    getReferencesSummarization(
        payload: ReportPayload,
    ): Observable<ReferenceSummarizationPerCategory[]> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=references_summarization`, {
                ...serializedPayload,
            })
            .pipe(
                map((response: Record<string, Record<string, string>>) =>
                    this.reportParser.parseReferencesSummarization(
                        response.references_summarization,
                    ),
                ),
            );
    }

    getReferences(payload: ReportPayload): Observable<Reference[]> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);

        return this.http
            .post(`${environment.reportServerUrl}/report/?section=references`, {
                ...serializedPayload,
            })
            .pipe(
                map((response: Record<string, ReferenceRestApi[]>) =>
                    this.reportParser.parseReferences(response.references),
                ),
            );
    }

    download(payload: ReportPayload, format: FileExtension): Observable<Blob> {
        const serializedPayload: ReportPayloadRestApi = this.reportParser.serializePayload(payload);
        const formatRest: ReportFormatRestApi =
            format === FileExtension.docx
                ? ReportFormatRestApi.wordExcel
                : ReportFormatRestApi.pdfExcel;

        return this.http.post(
            `${environment.reportServerUrl}/report/download/`,
            { ...serializedPayload },
            {
                params: { fmt: formatRest },
                responseType: 'blob',
            },
        );
    }
}
