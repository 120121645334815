/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { MetadataState } from './metadata-state.interface';
import * as metadataActions from './metadata.actions';
import HealthLabel from './interfaces/health-label.interface';
import Identifiers from './types/identifiers.type';
import ProteinOrigins from './types/protein-origins.type';
import PatentsCounts from './types/patents-counts.type';
import OriginsInfoPerRelationship from './types/origins-info-per-relationship.type';
import UnitConversion from './interfaces/unit-conversion.interface';
import ContainingIngredients from './types/containing-ingredients.type';
import Prevalences from './types/prevalences.type';

export const initialState: MetadataState = {
    errors: [],
    labs: [],
    labsLoading: false,
    labsLoaded: false,
    healthLabels: [],
    healthLabelsLoading: false,
    healthLabelsLoaded: false,
    prevalences: null,
    prevalencesLoading: false,
    prevalencesLoaded: false,
    identifiers: null,
    identifiersLoading: false,
    identifiersLoaded: false,
    proteinOrigins: null,
    proteinOriginsLoading: false,
    proteinOriginsLoaded: false,
    patentsCounts: null,
    patentsCountsLoading: false,
    patentsCountsLoaded: false,
    containingIngredients: null,
    containingIngredientsLoading: false,
    containingIngredientsLoaded: false,
    relationshipsInfo: null,
    relationshipsInfoLoading: false,
    relationshipsInfoLoaded: false,
    studyTypes: [],
    studyTypesLoading: false,
    studyTypesLoaded: false,
    journals: [],
    journalsLoading: false,
    journalsLoaded: false,
    unitConversions: null,
    unitConversionsLoading: false,
    unitConversionsLoaded: false,
    articlesIngestionDate: null,
    articlesIngestionDateLoading: false,
    articlesIngestionDateLoaded: false,
};

const metadataReducer: ActionReducer<MetadataState, Action> = createReducer(
    initialState,
    on(metadataActions.getLabsRequest, (state: MetadataState) => ({
        ...state,
        labsLoading: true,
        labsLoaded: false,
    })),
    on(metadataActions.getLabsSuccess, (state: MetadataState, { labs }: { labs: string[] }) => ({
        ...state,
        labs,
        labsLoading: false,
        labsLoaded: true,
    })),
    on(
        metadataActions.getLabsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            labsLoading: false,
            labsLoaded: false,
        }),
    ),
    on(metadataActions.getHealthLabelsRequest, (state: MetadataState) => ({
        ...state,
        healthLabelsLoading: true,
        healthLabelsLoaded: false,
    })),
    on(
        metadataActions.getHealthLabelsSuccess,
        (state: MetadataState, { healthLabels }: { healthLabels: HealthLabel[] }) => ({
            ...state,
            healthLabels,
            healthLabelsLoading: false,
            healthLabelsLoaded: true,
        }),
    ),
    on(
        metadataActions.getHealthLabelsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            healthLabelsLoading: false,
            healthLabelsLoaded: false,
        }),
    ),
    on(metadataActions.getPrevalencesRequest, (state: MetadataState) => ({
        ...state,
        prevalencesLoading: true,
        prevalencesLoaded: false,
    })),
    on(
        metadataActions.getPrevalencesSuccess,
        (state: MetadataState, { prevalences }: { prevalences: Prevalences }) => ({
            ...state,
            prevalences,
            prevalencesLoading: false,
            prevalencesLoaded: true,
        }),
    ),
    on(
        metadataActions.getPrevalencesFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            prevalencesLoading: false,
            prevalencesLoaded: false,
        }),
    ),
    on(metadataActions.clearPrevalences, (state: MetadataState) => ({
        ...state,
        prevalences: null as Prevalences,
        prevalencesLoading: false,
        prevalencesLoaded: false,
    })),
    on(metadataActions.getIdentifiersRequest, (state: MetadataState) => ({
        ...state,
        identifiers: null as Identifiers,
        identifiersLoading: true,
        identifiersLoaded: false,
    })),
    on(
        metadataActions.getIdentifiersSuccess,
        (state: MetadataState, { identifiers }: { identifiers: Identifiers }) => ({
            ...state,
            identifiers,
            identifiersLoading: false,
            identifiersLoaded: true,
        }),
    ),
    on(
        metadataActions.getIdentifiersFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            identifiersLoading: false,
            identifiersLoaded: false,
        }),
    ),
    on(metadataActions.clearIdentifiers, (state: MetadataState) => ({
        ...state,
        identifiers: null as Identifiers,
        identifiersLoading: false,
        identifiersLoaded: false,
    })),
    on(metadataActions.getProteinOriginsRequest, (state: MetadataState) => ({
        ...state,
        proteinOrigins: null as ProteinOrigins,
        proteinOriginsLoading: true,
        proteinOriginsLoaded: false,
    })),
    on(
        metadataActions.getProteinOriginsSuccess,
        (state: MetadataState, { proteinOrigins }: { proteinOrigins: ProteinOrigins }) => ({
            ...state,
            proteinOrigins,
            proteinOriginsLoading: false,
            proteinOriginsLoaded: true,
        }),
    ),
    on(
        metadataActions.getProteinOriginsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            proteinOriginsLoading: false,
            proteinOriginsLoaded: false,
        }),
    ),
    on(metadataActions.clearProteinOrigins, (state: MetadataState) => ({
        ...state,
        proteinOrigins: null as ProteinOrigins,
        proteinOriginsLoading: false,
        proteinOriginsLoaded: false,
    })),
    on(metadataActions.getPatentsCountsRequest, (state: MetadataState) => ({
        ...state,
        patentsCounts: null as PatentsCounts,
        patentsCountsLoading: true,
        patentsCountsLoaded: false,
    })),
    on(
        metadataActions.getPatentsCountsSuccess,
        (state: MetadataState, { patentsCounts }: { patentsCounts: PatentsCounts }) => ({
            ...state,
            patentsCounts,
            patentsCountsLoading: false,
            patentsCountsLoaded: true,
        }),
    ),
    on(
        metadataActions.getPatentsCountsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            patentsCountsLoading: false,
            patentsCountsLoaded: false,
        }),
    ),
    on(metadataActions.clearPatentsCounts, (state: MetadataState) => ({
        ...state,
        patentsCounts: null as PatentsCounts,
        patentsCountsLoading: false,
        patentsCountsLoaded: false,
    })),
    on(metadataActions.getContainingIngredientsRequest, (state: MetadataState) => ({
        ...state,
        containingIngredients: null as ContainingIngredients,
        containingIngredientsLoading: true,
        containingIngredientsLoaded: false,
    })),
    on(
        metadataActions.getContainingIngredientsSuccess,
        (
            state: MetadataState,
            { containingIngredients }: { containingIngredients: ContainingIngredients },
        ) => ({
            ...state,
            containingIngredients,
            containingIngredientsLoading: false,
            containingIngredientsLoaded: true,
        }),
    ),
    on(
        metadataActions.getContainingIngredientsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            containingIngredientsLoading: false,
            containingIngredientsLoaded: false,
        }),
    ),
    on(metadataActions.clearContainingIngredients, (state: MetadataState) => ({
        ...state,
        containingIngredients: null as ContainingIngredients,
        containingIngredientsLoading: false,
        containingIngredientsLoaded: false,
    })),
    on(metadataActions.getRelationshipsInfoRequest, (state: MetadataState) => ({
        ...state,
        relationshipsInfoLoading: true,
        relationshipsInfoLoaded: false,
    })),
    on(
        metadataActions.getRelationshipsInfoSuccess,
        (
            state: MetadataState,
            {
                relationshipsInfo,
            }: { relationshipsInfo: Record<string, OriginsInfoPerRelationship> },
        ) => ({
            ...state,
            relationshipsInfo,
            relationshipsInfoLoading: false,
            relationshipsInfoLoaded: true,
        }),
    ),
    on(
        metadataActions.getRelationshipsInfoFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(metadataActions.clearRelationshipsInfo, (state: MetadataState) => ({
        ...state,
        relationshipsInfo: null as Record<string, OriginsInfoPerRelationship>,
        relationshipsInfoLoading: false,
        relationshipsInfoLoaded: false,
        errors: [] as ErrorResponse[],
    })),
    on(metadataActions.getStudyTypesRequest, (state: MetadataState) => ({
        ...state,
        studyTypesLoading: true,
        studyTypesLoaded: false,
    })),
    on(
        metadataActions.getStudyTypesSuccess,
        (state: MetadataState, { studyTypes }: { studyTypes: string[] }) => ({
            ...state,
            studyTypes,
            studyTypesLoading: false,
            studyTypesLoaded: true,
        }),
    ),
    on(
        metadataActions.getStudyTypesFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            studyTypesLoading: false,
            studyTypesLoaded: false,
        }),
    ),
    on(metadataActions.getJournalsRequest, (state: MetadataState) => ({
        ...state,
        journalsLoading: true,
        journalsLoaded: false,
    })),
    on(
        metadataActions.getJournalsSuccess,
        (state: MetadataState, { journals }: { journals: string[] }) => ({
            ...state,
            journals,
            journalsLoading: false,
            journalsLoaded: true,
        }),
    ),
    on(
        metadataActions.getJournalsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            journalsLoading: false,
            journalsLoaded: false,
        }),
    ),
    on(metadataActions.getUnitConversionsRequest, (state: MetadataState) => ({
        ...state,
        unitConversionsLoading: true,
        unitConversionsLoaded: false,
    })),
    on(
        metadataActions.getUnitConversionsSuccess,
        (state: MetadataState, { unitConversions }: { unitConversions: UnitConversion[] }) => ({
            ...state,
            unitConversions,
            unitConversionsLoading: false,
            unitConversionsLoaded: true,
        }),
    ),
    on(
        metadataActions.getUnitConversionsFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            unitConversionsLoading: false,
            unitConversionsLoaded: false,
        }),
    ),
    on(metadataActions.getArticlesIngestionDateRequest, (state: MetadataState) => ({
        ...state,
        articlesIngestionDateLoading: true,
        articlesIngestionDateLoaded: false,
    })),
    on(
        metadataActions.getArticlesIngestionDateSuccess,
        (state: MetadataState, { articlesIngestionDate }: { articlesIngestionDate: string }) => ({
            ...state,
            articlesIngestionDate,
            articlesIngestionDateLoading: false,
            articlesIngestionDateLoaded: true,
        }),
    ),
    on(
        metadataActions.getArticlesIngestionDateFailure,
        (state: MetadataState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            articlesIngestionDateLoading: false,
            articlesIngestionDateLoaded: false,
        }),
    ),
    on(metadataActions.clearNextError, (state: MetadataState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: MetadataState | undefined, action: Action): MetadataState =>
    metadataReducer(state, action);

// selectors
export const getErrors: (state: MetadataState) => ErrorResponse[] = (state: MetadataState) =>
    state.errors;
export const getLabs: (state: MetadataState) => string[] = (state: MetadataState) => state.labs;
export const getLabsLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.labsLoading;
export const getLabsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.labsLoaded;
export const getHealthLabels: (state: MetadataState) => HealthLabel[] = (state: MetadataState) =>
    state.healthLabels;
export const getHealthLabelsLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.healthLabelsLoading;
export const getHealthLabelsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.healthLabelsLoaded;
export const getPrevalences: (state: MetadataState) => Prevalences = (state: MetadataState) =>
    state.prevalences;
export const getPrevalencesLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.prevalencesLoading;
export const getPrevalencesLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.prevalencesLoaded;
export const getIdentifiers: (state: MetadataState) => Identifiers = (state: MetadataState) =>
    state.identifiers;
export const getIdentifiersLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.identifiersLoading;
export const getIdentifiersLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.identifiersLoaded;
export const getProteinOrigins: (state: MetadataState) => ProteinOrigins = (state: MetadataState) =>
    state.proteinOrigins;
export const getProteinOriginsLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.proteinOriginsLoading;
export const getProteinOriginsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.proteinOriginsLoaded;
export const getPatentsCounts: (state: MetadataState) => PatentsCounts = (state: MetadataState) =>
    state.patentsCounts;
export const getPatentsCountsLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.patentsCountsLoading;
export const getPatentsCountsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.patentsCountsLoaded;
export const getContainingIngredients: (state: MetadataState) => ContainingIngredients = (
    state: MetadataState,
) => state.containingIngredients;
export const getContainingIngredientsLoading: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.containingIngredientsLoading;
export const getContainingIngredientsLoaded: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.containingIngredientsLoaded;
export const getRelationshipsInfo: (
    state: MetadataState,
) => Record<string, OriginsInfoPerRelationship> = (state: MetadataState) => state.relationshipsInfo;
export const getRelationshipsInfoLoading: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.relationshipsInfoLoading;
export const getRelationshipsInfoLoaded: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.relationshipsInfoLoaded;
export const getStudyTypes: (state: MetadataState) => string[] = (state: MetadataState) =>
    state.studyTypes;
export const getStudyTypesLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.studyTypesLoading;
export const getStudyTypesLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.studyTypesLoaded;
export const getJournals: (state: MetadataState) => string[] = (state: MetadataState) =>
    state.journals;
export const getJournalsLoading: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.journalsLoading;
export const getJournalsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.journalsLoaded;
export const getUnitConversions: (state: MetadataState) => UnitConversion[] = (
    state: MetadataState,
) => state.unitConversions;
export const getUnitConversionsLoading: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.unitConversionsLoading;
export const getUnitConversionsLoaded: (state: MetadataState) => boolean = (state: MetadataState) =>
    state.unitConversionsLoaded;
export const getArticlesIngestionDate: (state: MetadataState) => string = (state: MetadataState) =>
    state.articlesIngestionDate;
export const getArticlesIngestionDateLoading: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.articlesIngestionDateLoading;
export const getArticlesIngestionDateLoaded: (state: MetadataState) => boolean = (
    state: MetadataState,
) => state.articlesIngestionDateLoaded;
