/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { SynonymsParser } from '../parsers/synonyms.parser';

/** Interfaces */
import SynonymsRestApi from '../rest-api-types/synonyms.rest.type';
import Synonyms from '../types/synonyms.type';

@Injectable()
export class SynonymsService {
    constructor(private http: HttpClient, private synonymsParser: SynonymsParser) {}

    getSynonyms(ids: string[]): Observable<Synonyms> {
        return this.http
            .post(`${environment.metadataServerUrl}/nodes/synonyms`, {
                cuis: ids,
            })
            .pipe(map((synonyms: SynonymsRestApi) => this.synonymsParser.parseSynonyms(synonyms)));
    }
}
