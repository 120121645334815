import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[libInputAutoFocus]',
})
export class InputAutoFocusDirective implements OnInit {
    @Input() searchChildNodes: boolean;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        const inputElement: HTMLElement = this.searchChildNodes
            ? (this.elementRef.nativeElement.querySelector('input, textarea') as HTMLElement)
            : this.elementRef.nativeElement;

        if (!inputElement) {
            throw new Error('Could not find element.');
        }
        if (!inputElement.focus) {
            throw new Error('Element does not accept focus.');
        }

        inputElement.focus();
    }
}
