/** Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAlertModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { DecoratedInputModule } from '@leap-libs/decorated-input/src/public-api';
import { InputModule } from '@leap-libs/input/src/public-api';

/** Services */
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';
import { ResetPasswordService } from './services/reset-password.service';

/** Components */
import { ResetPasswordContainerComponent } from './components/containers/reset-password/reset-password.container.component';
import { ResetPasswordFormViewComponent } from './components/views/reset-password-form/reset-password-form.view.component';

@NgModule({
    imports: [
        CommonModule,
        ResetPasswordRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        NgbAlertModule,
        NgbModalModule,
        DecoratedInputModule,
        InputModule,
    ],
    declarations: [ResetPasswordContainerComponent, ResetPasswordFormViewComponent],
    providers: [ErrorHandlerService, ResetPasswordService],
})
export class ResetPasswordModule {}
