/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { CategoriesParser } from '../parsers/categories.parser';

@Injectable()
export class CategoriesService {
    constructor(private http: HttpClient, private categoriesParser: CategoriesParser) {}

    getCategories(): Observable<Record<string, string[]>> {
        return this.http
            .get(`${environment.metadataServerUrl}/subcategory-mapping?grouped&names`)
            .pipe(
                map((categories: Record<string, string[]>) =>
                    this.categoriesParser.parseCategories(categories),
                ),
            );
    }
}
