import { Pipe, PipeTransform } from '@angular/core';
import { roundAndApplyThreshold } from '@leap-common/utilities/helpers';

@Pipe({
    name: 'roundAndApplyThreshold',
})
export class RoundAndApplyThresholdPipe implements PipeTransform {
    constructor() {}

    public transform(
        value: number,
        locale?: string,
        decimalDigits?: number,
        threshold?: number,
    ): string {
        return roundAndApplyThreshold(value, locale, decimalDigits, threshold);
    }
}
