import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'initials',
})
export class InitialsPipe implements PipeTransform {
    constructor() {}

    public transform(value: string): string {
        return value && value[0].toUpperCase();
    }
}
