import { Pipe, PipeTransform } from '@angular/core';

/** Services */
import { FormatterService } from '@leap-common/services/formatter.service';
import { TypeGuardService } from '@apps/leap/src/app/shared/services/type-guard.service';

/** Interfaces */
import Measurement from '@apps/leap/src/app/shared/interfaces/measurement.interface';

@Pipe({
    name: 'localeNotation',
})
export class LocaleNotationPipe implements PipeTransform {
    constructor(
        private formatterService: FormatterService,
        private typeGuardService: TypeGuardService,
    ) {}

    // TODO: Remove the Measurement case so that we can also remove the imports from '@apps/leap'
    public transform(value: number | Measurement, locale?: string, decimalDigits?: number): string {
        // when dealing with measurement also append the unit to the transformed value
        if (this.typeGuardService.isMeasurement(value)) {
            return `${this.formatterService.formatToLocaleString(
                value.value,
                locale,
                decimalDigits,
            )} ${value.unit}`;
        }
        return this.formatterService.formatToLocaleString(value, locale, decimalDigits);
    }
}
