/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromReport from './report.reducer';

export const getReportState = createSelector(getDataState, (state: DataState) => state.report);

export const getErrors = createSelector(getReportState, fromReport.getErrors);
export const getIntroduction = createSelector(getReportState, fromReport.getIntroduction);
export const getIntroductionLoading = createSelector(
    getReportState,
    fromReport.getIntroductionLoading,
);
export const getIntroductionLoaded = createSelector(
    getReportState,
    fromReport.getIntroductionLoaded,
);
export const getBackground = createSelector(getReportState, fromReport.getBackground);
export const getBackgroundLoading = createSelector(getReportState, fromReport.getBackgroundLoading);
export const getBackgroundLoaded = createSelector(getReportState, fromReport.getBackgroundLoaded);
export const getStatistics = createSelector(getReportState, fromReport.getStatistics);
export const getStatisticsLoading = createSelector(getReportState, fromReport.getStatisticsLoading);
export const getStatisticsLoaded = createSelector(getReportState, fromReport.getStatisticsLoaded);
export const getTopResults = createSelector(getReportState, fromReport.getTopResults);
export const getTopResultsLoading = createSelector(getReportState, fromReport.getTopResultsLoading);
export const getTopResultsLoaded = createSelector(getReportState, fromReport.getTopResultsLoaded);
export const getReferencesSummarization = createSelector(
    getReportState,
    fromReport.getReferencesSummarization,
);
export const getReferencesSummarizationLoading = createSelector(
    getReportState,
    fromReport.getReferencesSummarizationLoading,
);
export const getReferencesSummarizationLoaded = createSelector(
    getReportState,
    fromReport.getReferencesSummarizationLoaded,
);
export const getReferences = createSelector(getReportState, fromReport.getReferences);
export const getReferencesLoading = createSelector(getReportState, fromReport.getReferencesLoading);
export const getReferencesLoaded = createSelector(getReportState, fromReport.getReferencesLoaded);
export const getLoading = createSelector(getReportState, fromReport.getLoading);
export const getBlob = createSelector(getReportState, fromReport.getBlob);
