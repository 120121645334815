/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ExplainabilityParser } from '../parsers/explainability.parser';

/** Interfaces - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplainabilityPathRestApi from '../rest-api-interfaces/explainability-path.rest.interface';
import ExplorationPath from '../../paths/interfaces/exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class ExplainabilityService {
    constructor(private http: HttpClient, private explainabilityParser: ExplainabilityParser) {}

    /**
     * Gets paths, parses them into the desired format and
     * returns an Observable of PaginatedResults<ExplorationPath>.
     */
    getPaths(
        sourceId: string,
        targetId: string,
        sourceCategories: string[],
        targetCategories: string[],
        sourceSubcategories: string[],
        targetSubcategories: string[],
        relations: string[],
        sortingOrder: SortingOrder,
    ): Observable<PaginatedResults<ExplorationPath>> {
        return this.http
            .post(`${environment.metapathsServerUrl}/graphmeta/explainability/`, {
                sourceUid: sourceId,
                targetUid: targetId,
                sourceMainCategories: sourceCategories,
                targetMainCategories: targetCategories,
                sourceSubCategories: sourceSubcategories,
                targetSubCategories: targetSubcategories,
                relations,
                sortingOrder,
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<ExplainabilityPathRestApi>) =>
                    this.explainabilityParser.parsePaginatedPaths(paginatedResults),
                ),
            );
    }
}
