export enum GroupsActionTypes {
    GET_GROUPS_REQUEST = '[GROUPS] Get Groups Request',
    GET_GROUPS_SUCCESS = '[GROUPS] Get Groups Success',
    GET_GROUPS_FAILURE = '[GROUPS] Get Groups Failure',
    CREATE_GROUP_REQUEST = '[GROUPS] Create Group Request',
    CREATE_GROUP_SUCCESS = '[GROUPS] Create Group Success',
    CREATE_GROUP_FAILURE = '[GROUPS] Create Group Failure',
    GET_DEPARTMENTS_REQUEST = '[GROUPS] Get Departments Request',
    GET_DEPARTMENTS_SUCCESS = '[GROUPS] Get Departments Success',
    GET_DEPARTMENTS_FAILURE = '[GROUPS] Get Departments Failure',
    UPDATE_GROUP_REQUEST = '[GROUPS] Update Group Request',
    UPDATE_GROUP_SUCCESS = '[GROUPS] Update Group Success',
    UPDATE_GROUP_FAILURE = '[GROUPS] Update Group Failure',
    DELETE_GROUP_REQUEST = '[GROUPS] Delete Group Request',
    DELETE_GROUP_SUCCESS = '[GROUPS] Delete Group Success',
    DELETE_GROUP_FAILURE = '[GROUPS] Delete Group Failure',
    DELETE_GROUPS_REQUEST = '[GROUPS] Delete Groups Request',
    DELETE_GROUPS_SUCCESS = '[GROUPS] Delete Groups Success',
    DELETE_GROUPS_FAILURE = '[GROUPS] Delete Groups Failure',
    SEARCH_DEPARTMENTS = '[GROUPS] Search Departments',
    CLEAR_CREATED_DEPARTMENT = '[GROUPS] Clear Created Department',
    CLEAR_NEXT_ERROR = '[GROUPS] Clear Next Error',
    CLEAR_NEXT_SUCCESS = '[GROUPS] Clear Next Success',
    CLEAR_SHOULD_FETCH_DEPARTMENTS = '[GROUPS] Clear shouldFetchDepartments',
}
