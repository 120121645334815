import { Pipe, PipeTransform } from '@angular/core';
import { AMPERSAND_DELIMITER } from '@leap-common/constants/delimiters';

@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
    constructor() {}

    public transform(value: string, splitCharacter: string = AMPERSAND_DELIMITER): string[] {
        if (!value) {
            return;
        }

        return value.split(splitCharacter);
    }
}
