import { Component, Input } from '@angular/core';

/** Constants */
import { INGESTION_DATE_LABEL } from '../../../constants/footer';

@Component({
    selector: 'app-articles-ingestion-date',
    templateUrl: 'articles-ingestion-date.view.component.html',
    styleUrls: ['articles-ingestion-date.view.component.scss'],
})
export class ArticlesIngestionDateViewComponent {
    @Input() date: string;

    readonly label: string = INGESTION_DATE_LABEL;

    constructor() {}
}
