import { environment } from '@environments/leap/environment';

/** Modules */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FeatureFlagsModule } from '@leap-libs/feature-flags/src/public-api';
import { AlertModule } from '@leap-libs/alert/src/public-api';
import { LegalModule } from './legal/legal.module';
import { LayoutModule } from './layout/layout.module';
import { AuthModule } from './auth/auth.module';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { CoreStoreModule } from '@leap-store/core/src/public-api';
import { PouchDBStoreModule } from '@leap-store/pouchdb/pouchdb.module';

/** Services - Facades */
import { AppService } from './app.service';
import { JwtOptionsService } from './auth/core/services/jwt-options.service';
import { DownloadService } from '@leap-common/services/download.service';
import { AnalyticsService } from './analytics/services/analytics.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Components */
import { AppComponent } from './app.component';

const oktaAuth: OktaAuth = new OktaAuth({
    clientId: environment.oktaClientId,
    issuer: environment.oktaIssuer,
    redirectUri: environment.oktaRedirectUri,
    scopes: environment.oktaScopes,
    pkce: true,
});

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FeatureFlagsModule,
        AlertModule,
        LegalModule,
        LayoutModule,
        AuthModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useClass: JwtOptionsService,
            },
        }),
        OktaAuthModule,
        CoreStoreModule,
        PouchDBStoreModule,
    ],
    providers: [
        AppService,
        DownloadService,
        AnalyticsService,
        AlertsFacade,
        { provide: OKTA_CONFIG, useValue: { oktaAuth } },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
