/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DefinitionsState } from './definitions-state.interface';
import { getDefinitionsRequest, clearDefinitions, clearNextError } from './definitions.actions';
import { getDefinitions, getErrors, getLoading, getLoaded } from './definitions.selectors';
import Definitions from './types/definitions.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class DefinitionsFacade {
    definitions$: Observable<Definitions> = this.store.pipe(select(getDefinitions));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    constructor(private store: Store<DefinitionsState>) {}

    getDefinitions(ids: string[], bookmarkIds?: BookmarkIds): void {
        this.store.dispatch(getDefinitionsRequest({ ids, bookmarkIds }));
    }

    clearDefinitions(): void {
        this.store.dispatch(clearDefinitions());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
