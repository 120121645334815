/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { UsersParser } from '../parsers/users.parser';
import { HttpParamsService } from '@leap-common/services/http-params.service';

/** Interfaces */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRestApi from '../rest-api-interfaces/user.rest.interface';
import User from '../interfaces/user.interface';
import UserPropertiesRestApi from '../rest-api-interfaces/user-properties.rest.interface';
import UserProperties from '../interfaces/user-properties.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class UsersService {
    constructor(
        private http: HttpClient,
        private usersParser: UsersParser,
        private httpParamsService: HttpParamsService,
    ) {}

    /**
     * Gets an array of users, parses them into the desired format and
     * returns an Observable of PaginatedResults<User>.
     */
    getUsers(
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
        app: string,
        department: string,
        search: string,
    ): Observable<PaginatedResults<User>> {
        const serializedOrder: string = this.usersParser.serializeUsersOrderByParameter(
            sortDirection,
            sortColumn,
        );

        const params: HttpParams = this.httpParamsService.createHttpParams({
            app,
            pageIndex: (pageIndex + 1).toString(),
            pageSize: pageSize.toString(),
            orderBy: serializedOrder,
            department,
            search,
        });

        return this.http
            .get(`${environment.serverUrl}/users/`, { params })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<UserRestApi>) =>
                    this.usersParser.parsePaginatedResults(paginatedResults),
                ),
            );
    }

    /**
     * Sends the serialized user to server in order to create a new user and
     * maps the response to the desired format. It returns an Observable of User.
     */
    createUser(user: User, app: string): Observable<User> {
        const serializedUser: UserRestApi = this.usersParser.serializeUser(user);

        return this.http
            .post(`${environment.serverUrl}/users/?app=${app}`, serializedUser)
            .pipe(map((responseUser: UserRestApi) => this.usersParser.parseUser(responseUser)));
    }

    /**
     * Sends the serialized user properties to server in order to update an existing user and
     * maps the response to the desired format. It returns an Observable of User.
     */
    updateUser(id: string, userProperties: UserProperties): Observable<User> {
        const serializedUserProperties: UserPropertiesRestApi =
            this.usersParser.serializeUserProperties(userProperties);

        return this.http
            .patch(`${environment.serverUrl}/users/${id}/`, serializedUserProperties)
            .pipe(map((user: UserRestApi) => this.usersParser.parseUser(user)));
    }

    /**
     * Makes a call with the id to server in order to delete the specific user and
     * maps the response to the desired format. It returns an Observable of User.
     */
    deleteUser(id: string): Observable<User> {
        return this.http
            .delete(`${environment.serverUrl}/users/${id}/`)
            .pipe(map((user: UserRestApi) => this.usersParser.parseUser(user)));
    }

    /**
     * Makes a call with the user ids to server in order to delete the specific users and
     * maps the response to the desired format. It returns an Observable of PaginatedResults<User>.
     */
    deleteUsers(ids: string[]): Observable<PaginatedResults<User>> {
        return this.http
            .request('delete', `${environment.serverUrl}/users/`, {
                body: this.usersParser.serializeUserIds(ids),
            })
            .pipe(
                map((paginatedUsers: PaginatedResultsRestApi<UserRestApi>) =>
                    this.usersParser.parsePaginatedResults(paginatedUsers),
                ),
            );
    }
}
