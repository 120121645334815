/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as explainabilityActions from './explainability.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ExplainabilityState } from './explainability-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplorationPath from '../paths/interfaces/exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const adapter: EntityAdapter<ExplorationPath> = createEntityAdapter<ExplorationPath>();

export const initialState: ExplainabilityState = adapter.getInitialState({
    paths: [],
    parentInsightId: '',
    errors: [],
    loading: false,
    loaded: false,
    sortingOrder: SortingOrder.descending,
});

const explainabilityReducer: ActionReducer<ExplainabilityState, Action> = createReducer(
    initialState,
    on(
        explainabilityActions.getPathsRequest,
        (state: ExplainabilityState, { parentInsightId }: { parentInsightId: string }) =>
            adapter.setAll([], {
                ...state,
                parentInsightId,
                loading: true,
                loaded: false,
            }),
    ),
    on(
        explainabilityActions.getPathsSuccess,
        (
            state: ExplainabilityState,
            {
                parentInsightId,
                paginatedPaths,
                sortingOrder,
            }: {
                parentInsightId: string;
                paginatedPaths: PaginatedResults<ExplorationPath>;
                sortingOrder: SortingOrder;
            },
        ) =>
            adapter.setAll(paginatedPaths.results, {
                ...state,
                parentInsightId,
                loading: false,
                loaded: true,
                sortingOrder,
            }),
    ),
    on(
        explainabilityActions.getPathsFailure,
        (state: ExplainabilityState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(explainabilityActions.clearNextError, (state: ExplainabilityState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (
    state: ExplainabilityState | undefined,
    action: Action,
): ExplainabilityState => explainabilityReducer(state, action);

// selectors
export const getEntities: (state: ExplainabilityState) => ExplorationPath[] =
    adapter.getSelectors().selectAll;
export const getParentInsightId: (state: ExplainabilityState) => string = (
    state: ExplainabilityState,
) => state.parentInsightId;
export const getErrors: (state: ExplainabilityState) => ErrorResponse[] = (
    state: ExplainabilityState,
) => state.errors;
export const getLoading: (state: ExplainabilityState) => boolean = (state: ExplainabilityState) =>
    state.loading;
export const getLoaded: (state: ExplainabilityState) => boolean = (state: ExplainabilityState) =>
    state.loaded;
export const getSortingOrder: (state: ExplainabilityState) => SortingOrder = (
    state: ExplainabilityState,
) => state.sortingOrder;
