/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DataState } from '../../data-state.interface';
import { getCategoriesRequest, clearNextError } from './categories.actions';
import {
    getCategories,
    getSubcategoriesPerCategory,
    getErrors,
    getLoading,
    getLoaded,
} from './categories.selectors';

@Injectable()
export class CategoriesFacade {
    categories$: Observable<string[]> = this.store.pipe(select(getCategories));
    subcategoriesPerCategory$: Observable<Record<string, string[]>> = this.store.pipe(
        select(getSubcategoriesPerCategory),
    );
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));

    constructor(private store: Store<DataState>) {}

    getCategories(): void {
        this.store.dispatch(getCategoriesRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
