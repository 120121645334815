import { environment } from '@environments/leap/environment';

/** Interfaces - Enums */
import AnalyticsProvider from '../enums/analytics-provider.enum';
import AnalyticsProviderConfiguration from '../interfaces/analytics-provider-configuration.interface';

export const CONFIGURATION_PER_ANALYTICS_PROVIDER: Record<
    AnalyticsProvider,
    AnalyticsProviderConfiguration
> = {
    [AnalyticsProvider.googleAnalytics]: {
        scripts: [
            {
                async: true,
                src: `https://www.googletagmanager.com/gtag/js?id=${environment.gaKey}`,
            },
            {
                type: 'text/javascript',
                innerHTML: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${environment.gaKey}');
                `,
            },
        ],
    },
    [AnalyticsProvider.googleTagManager]: {
        scripts: [
            {
                type: 'text/javascript',
                innerHTML: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${environment.gtKey}');
                `,
            },
        ],
    },
};
