import { environment } from '@environments/leap/environment';
import { BACKGROUND_COLOR, ICON_COLOR } from '@leap-common/themes/header';
import Header from '@leap-common/interfaces/header.interface';

export const PRIVACY_POLICY_HEADER: Header = {
    title: 'Privacy policy',
    icon: {
        backgroundColor: BACKGROUND_COLOR,
        color: ICON_COLOR,
        class: 'fa-solid fa-shield-alt',
    },
};
export const PRIVACY_POLICY_FILENAME: string = 'privacy-policy.html';
export const PRIVACY_POLICY_PATH: string = `assets/docs/${environment.app}/${PRIVACY_POLICY_FILENAME}`;
export const PRIVACY_POLICY_LOADING_TEXT: string = 'Loading privacy policy...';

export const TERMS_OF_USE_HEADER: Header = {
    title: 'Terms of service',
    icon: {
        backgroundColor: BACKGROUND_COLOR,
        color: ICON_COLOR,
        class: 'fa-solid fa-file-signature',
    },
};
export const TERMS_OF_USE_INFORMATION_ICON: string = 'fa-solid fa-circle-info';
export const TERMS_OF_USE_INFORMATION_TEXT: string = `Please read through the Terms of Service and click "Accept the terms of service and login" to login into ${environment.product}. The button is enabled after you read through the entire page.`;
export const TERMS_OF_USE_FILENAME: string = 'terms-of-use.html';
export const TERMS_OF_USE_PATH: string = `assets/docs/${environment.app}/${TERMS_OF_USE_FILENAME}`;
export const TERMS_OF_USE_LOADING_TEXT: string = 'Loading terms of service...';

export const ACCEPT_TERMS_OF_USE_BUTTON_TEXT: string = 'Accept the terms of service and login';
