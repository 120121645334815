export enum ArticlesReportActionTypes {
    GET_OVERVIEW_REQUEST = '[ARTICLES REPORT][OVERVIEW] Get Overview Request',
    GET_OVERVIEW_SUCCESS = '[ARTICLES REPORT][OVERVIEW] Get Overview Success',
    GET_OVERVIEW_FAILURE = '[ARTICLES REPORT][OVERVIEW] Get Overview Failure',
    GET_ASSOCIATIONS_REQUEST = '[ARTICLES REPORT][ASSOCIATIONS] Get Associations Request',
    GET_ASSOCIATIONS_SUCCESS = '[ARTICLES REPORT][ASSOCIATIONS] Get Associations Success',
    GET_ASSOCIATIONS_FAILURE = '[ARTICLES REPORT][ASSOCIATIONS] Get Associations Failure',
    GET_KEY_FINDINGS_REQUEST = '[ARTICLES REPORT][KEY FINDINGS] Get Key Findings Request',
    GET_KEY_FINDINGS_SUCCESS = '[ARTICLES REPORT][KEY FINDINGS] Get Key Findings Success',
    GET_KEY_FINDINGS_FAILURE = '[ARTICLES REPORT][KEY FINDINGS] Get Key Findings Failure',
    GET_TERM_SUMMARIES_REQUEST = '[ARTICLES REPORT][TERM SUMMARIES] Get Term Summaries Request',
    GET_TERM_SUMMARIES_SUCCESS = '[ARTICLES REPORT][TERM SUMMARIES] Get Term Summaries Success',
    GET_TERM_SUMMARIES_FAILURE = '[ARTICLES REPORT][TERM SUMMARIES] Get Term Summaries Failure',
    GET_CRITICAL_ANALYSIS_REQUEST = '[ARTICLES REPORT][CRITICAL ANALYSIS] Get Critical Analysis Request',
    GET_CRITICAL_ANALYSIS_SUCCESS = '[ARTICLES REPORT][CRITICAL ANALYSIS] Get Critical Analysis Success',
    GET_CRITICAL_ANALYSIS_FAILURE = '[ARTICLES REPORT][CRITICAL ANALYSIS] Get Critical Analysis Failure',
    GET_THEORETICAL_FRAMEWORK_REQUEST = '[ARTICLES REPORT][THEORETICAL FRAMEWORK] Get Theoretical Framework Request',
    GET_THEORETICAL_FRAMEWORK_SUCCESS = '[ARTICLES REPORT][THEORETICAL FRAMEWORK] Get Theoretical Framework Success',
    GET_THEORETICAL_FRAMEWORK_FAILURE = '[ARTICLES REPORT][THEORETICAL FRAMEWORK] Get Theoretical Framework Failure',
    GET_REFERENCES_REQUEST = '[ARTICLES REPORT][REFERENCES] Get References Request',
    GET_REFERENCES_SUCCESS = '[ARTICLES REPORT][REFERENCES] Get References Success',
    GET_REFERENCES_FAILURE = '[ARTICLES REPORT][REFERENCES] Get References Failure',
    DOWNLOAD_REPORT_REQUEST = '[ARTICLES REPORT][DOWNLOAD] Download Report Request',
    DOWNLOAD_REPORT_SUCCESS = '[ARTICLES REPORT][DOWNLOAD] Download Report Success',
    DOWNLOAD_REPORT_FAILURE = '[ARTICLES REPORT][DOWNLOAD] Download Report Failure',
    RESET_REPORT = '[ARTICLES REPORT] Reset Report',
    CLEAR_NEXT_ERROR = '[ARTICLES REPORT] Clear Next Error',
}
