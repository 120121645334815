/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { ReportState } from './report-state.interface';
import {
    getIntroductionRequest,
    getBackgroundRequest,
    getStatisticsRequest,
    getTopResultsRequest,
    getReferencesSummarizationRequest,
    getReferencesRequest,
    clearNextError,
    downloadRequest,
} from './report.actions';
import {
    getErrors,
    getIntroduction,
    getIntroductionLoading,
    getIntroductionLoaded,
    getBackground,
    getBackgroundLoading,
    getBackgroundLoaded,
    getStatistics,
    getStatisticsLoading,
    getStatisticsLoaded,
    getTopResults,
    getTopResultsLoading,
    getTopResultsLoaded,
    getReferencesSummarization,
    getReferencesSummarizationLoading,
    getReferencesSummarizationLoaded,
    getReferences,
    getReferencesLoading,
    getReferencesLoaded,
    getLoading,
    getBlob,
} from './report.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import ReportPayload from './interfaces/report-payload.interface';
import Background from './interfaces/background.interface';
import Statistics from './interfaces/statistics.interface';
import TopResults from './types/top-results.type';
import ReferenceSummarizationPerCategory from './interfaces/reference-summarization-per-category.interface';
import Reference from './interfaces/reference.interface';
import FileExtension from '@leap-common/enums/file-extension.enum';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';

@Injectable()
export class ReportFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    introduction$: Observable<string> = this.store.pipe(select(getIntroduction));
    introductionLoading$: Observable<boolean> = this.store.pipe(select(getIntroductionLoading));
    introductionLoaded$: Observable<boolean> = this.store.pipe(select(getIntroductionLoaded));
    background$: Observable<Background> = this.store.pipe(select(getBackground));
    backgroundLoading$: Observable<boolean> = this.store.pipe(select(getBackgroundLoading));
    backgroundLoaded$: Observable<boolean> = this.store.pipe(select(getBackgroundLoaded));
    statistics$: Observable<Statistics> = this.store.pipe(select(getStatistics));
    statisticsLoading$: Observable<boolean> = this.store.pipe(select(getStatisticsLoading));
    statisticsLoaded$: Observable<boolean> = this.store.pipe(select(getStatisticsLoaded));
    topResults$: Observable<TopResults> = this.store.pipe(select(getTopResults));
    topResultsLoading$: Observable<boolean> = this.store.pipe(select(getTopResultsLoading));
    topResultsLoaded$: Observable<boolean> = this.store.pipe(select(getTopResultsLoaded));
    referencesSummarization$: Observable<ReferenceSummarizationPerCategory[]> = this.store.pipe(
        select(getReferencesSummarization),
    );
    referencesSummarizationLoading$: Observable<boolean> = this.store.pipe(
        select(getReferencesSummarizationLoading),
    );
    referencesSummarizationLoaded$: Observable<boolean> = this.store.pipe(
        select(getReferencesSummarizationLoaded),
    );
    references$: Observable<Reference[]> = this.store.pipe(select(getReferences));
    referencesLoading$: Observable<boolean> = this.store.pipe(select(getReferencesLoading));
    referencesLoaded$: Observable<boolean> = this.store.pipe(select(getReferencesLoaded));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));

    constructor(private store: Store<ReportState>) {}

    getReportIntroduction({ payload }: { payload: ReportPayload }): void {
        this.store.dispatch(getIntroductionRequest({ payload }));
    }

    getReportBackground({ payload }: { payload: ReportPayload }): void {
        this.store.dispatch(getBackgroundRequest({ payload }));
    }

    getReportStatistics({ payload }: { payload: ReportPayload }): void {
        this.store.dispatch(getStatisticsRequest({ payload }));
    }

    getReportTopResults({
        payload,
        discovery,
        studyTypesOrder,
    }: {
        payload: ReportPayload;
        discovery: Discovery;
        studyTypesOrder: string[];
    }): void {
        this.store.dispatch(getTopResultsRequest({ payload, discovery, studyTypesOrder }));
    }

    getReportReferencesSummarization({ payload }: { payload: ReportPayload }): void {
        this.store.dispatch(getReferencesSummarizationRequest({ payload }));
    }

    getReportReferences({ payload }: { payload: ReportPayload }): void {
        this.store.dispatch(getReferencesRequest({ payload }));
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    downloadReport({ payload, format }: { payload: ReportPayload; format: FileExtension }): void {
        this.store.dispatch(downloadRequest({ payload, format }));
    }
}
