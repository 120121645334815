/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as metapathsActions from './metapaths.actions';
import QueryStatus from '@apps/leap/src/app/shared/enums/query-status.enum';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { MetapathsState } from './metapaths-state.interface';
import PaginatedMetapaths from './interfaces/paginated-metapaths.interface';
import Metapath from './interfaces/metapath.interface';

export const adapter: EntityAdapter<Metapath> = createEntityAdapter<Metapath>();

export const initialState: MetapathsState = adapter.getInitialState({
    metapaths: [],
    parentInsightId: '',
    errors: [],
    loading: false,
    loaded: false,
    pageSize: 0,
    pageIndex: 0,
    total: 0,
    queryStatus: null,
    estimatedDuration: null,
});

const metapathsReducer: ActionReducer<MetapathsState, Action> = createReducer(
    initialState,
    on(
        metapathsActions.getMetapathsRequest,
        (state: MetapathsState, { parentInsightId }: { parentInsightId: string }) =>
            adapter.setAll([], {
                ...state,
                parentInsightId,
                loading: true,
                loaded: false,
            }),
    ),
    on(
        metapathsActions.getMetapathsSuccess,
        (
            state: MetapathsState,
            {
                parentInsightId,
                paginatedMetapaths,
            }: {
                parentInsightId: string;
                paginatedMetapaths: PaginatedMetapaths;
            },
        ) =>
            adapter.setAll(paginatedMetapaths.results, {
                ...state,
                parentInsightId,
                // Triggered and Running states are considered still loading as we only have part of the
                // information we need to render the loader (estimatedDuration), but we are missing the actual results
                loading: paginatedMetapaths.queryStatus !== QueryStatus.completed,
                loaded: paginatedMetapaths.queryStatus === QueryStatus.completed,
                pageSize: paginatedMetapaths.pageSize,
                pageIndex: paginatedMetapaths.pageIndex,
                total: paginatedMetapaths.total,
                estimatedDuration: paginatedMetapaths.estimatedDuration,
                queryStatus: paginatedMetapaths.queryStatus,
            }),
    ),
    on(
        metapathsActions.getMetapathsFailure,
        (state: MetapathsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [
                ...state.errors,
                errorResponse.status === 0
                    ? // Unknown error from the gateway between FE and BE
                      { errors: [{ message: errorResponse.statusText }] }
                    : errorResponse.error,
            ],
            loading: false,
            loaded: false,
        }),
    ),
    on(metapathsActions.clearNextError, (state: MetapathsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: MetapathsState | undefined, action: Action): MetapathsState =>
    metapathsReducer(state, action);

// selectors
export const getEntities: (state: MetapathsState) => Metapath[] = adapter.getSelectors().selectAll;
export const getParentInsightId: (state: MetapathsState) => string = (state: MetapathsState) =>
    state.parentInsightId;
export const getErrors: (state: MetapathsState) => ErrorResponse[] = (state: MetapathsState) =>
    state.errors;
export const getLoading: (state: MetapathsState) => boolean = (state: MetapathsState) =>
    state.loading;
export const getLoaded: (state: MetapathsState) => boolean = (state: MetapathsState) =>
    state.loaded;
export const getPageSize: (state: MetapathsState) => number = (state: MetapathsState) =>
    state.pageSize;
export const getPageIndex: (state: MetapathsState) => number = (state: MetapathsState) =>
    state.pageIndex;
export const getTotal: (state: MetapathsState) => number = (state: MetapathsState) => state.total;
export const getEstimatedDuration: (state: MetapathsState) => number = (state: MetapathsState) =>
    state.estimatedDuration;
