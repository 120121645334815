import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromLayout from './layout.reducer';

export const getLayoutState = createSelector(getUIState, (state: UIState) => state.layout);

export const getFooterCopyrightDisplay = createSelector(
    getLayoutState,
    fromLayout.getFooterCopyrightDisplay,
);

export const getTermsOfUseAcceptanceStatus = createSelector(
    getLayoutState,
    fromLayout.getTermsOfUseAcceptanceStatus,
);
