export enum IngredientComparisonActionTypes {
    GET_INGREDIENTS_REQUEST = '[INGREDIENT COMPARISON] Get Ingredients Request',
    GET_INGREDIENTS_SUCCESS = '[INGREDIENT COMPARISON] Get Ingredients Success',
    GET_INGREDIENTS_FAILURE = '[INGREDIENT COMPARISON] Get Ingredients Failure',
    GET_BIOACTIVITY_REQUEST = '[INGREDIENT COMPARISON] Get Bioactivity Request',
    GET_BIOACTIVITY_SUCCESS = '[INGREDIENT COMPARISON] Get Bioactivity Success',
    GET_BIOACTIVITY_FAILURE = '[INGREDIENT COMPARISON] Get Bioactivity Failure',
    GET_AMINO_ACIDS_REQUEST = '[INGREDIENT COMPARISON] Get Amino Acids Request',
    GET_AMINO_ACIDS_SUCCESS = '[INGREDIENT COMPARISON] Get Amino Acids Success',
    GET_AMINO_ACIDS_FAILURE = '[INGREDIENT COMPARISON] Get Amino Acids Failure',
    GET_MOLECULAR_WEIGHTS_REQUEST = '[INGREDIENT COMPARISON] Get Molecular Weights Request',
    GET_MOLECULAR_WEIGHTS_SUCCESS = '[INGREDIENT COMPARISON] Get Molecular Weights Success',
    GET_MOLECULAR_WEIGHTS_FAILURE = '[INGREDIENT COMPARISON] Get Molecular Weights Failure',
    CLEAR_NEXT_ERROR = '[INGREDIENT COMPARISON] Clear Next Error',
}
