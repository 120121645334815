import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: 'reset-password-form.view.component.html',
    styleUrls: ['reset-password-form.view.component.scss'],
})
export class ResetPasswordFormViewComponent {
    @Input() form: FormGroup;
    @Input() newPasswordOptions: DecoratedInputOptions;
    @Input() confirmNewPasswordOptions: DecoratedInputOptions;
    @Input() submitButtonTitle: string;
    @Input() isTextWhite: boolean;
    @Output() inputVisibilityToggled: EventEmitter<string> = new EventEmitter<string>();
    @Output() submitForm: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    onInputVisibilityToggled(event: string): void {
        this.inputVisibilityToggled.emit(event);
    }

    onSubmit(): void {
        const newPassword: string = this.form.value?.newPassword;

        if (this.form.valid) {
            this.submitForm.emit(newPassword);
        }
    }
}
