import {
    Component,
    ContentChild,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import MenuItem from './menu-item.interface';
import MenuOrientation from './menu-orientation.type';
import HelperMenuItem from '@leap-libs/helper-menu/src/lib/helper-menu-item.interface';
import Tooltip from '@leap-libs/tooltip/src/lib/tooltip.interface';

@Component({
    selector: 'lib-main-menu',
    templateUrl: 'main-menu.component.html',
    styleUrls: ['main-menu.component.scss'],
})
export class MainMenuComponent implements OnChanges {
    @Input() mainMenuItems: MenuItem[];
    @Input() userMenuItems: MenuItem[];
    @Input() orientation: MenuOrientation;
    @Input() customContent: TemplateRef<unknown>;
    @Input() isLightVersion: boolean;
    @Output() discovery: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() login: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() logout: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ContentChild(TemplateRef, { static: true }) customIconTemplate: TemplateRef<MenuItem>;

    // currently keeps only one displayed helper menu since we don't have requirements for multiple.
    displayedHelperMenu: Record<string, boolean> = {};
    hoveredItemTitle: string;
    activePaths: Record<string, boolean>;

    tooltip: Tooltip;
    @ViewChild('tooltipTemplate', { static: true }) tooltipTemplate: TemplateRef<unknown>;

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        event.stopPropagation();

        if (Object.keys(this.displayedHelperMenu).length > 0) {
            this.displayedHelperMenu = {};
        }
    }

    constructor() {}

    ngOnChanges(): void {
        const basePath: string = window.location.pathname?.split('/')?.[1];
        this.updateActivePaths(basePath);
    }

    onMenuItemHover(item: MenuItem): void {
        this.hoveredItemTitle = item.title;

        this.tooltip = {
            content: this.tooltipTemplate,
            class: 'main-menu-tooltip no-arrow no-transparency custom-top-position',
            placement: 'bottom',
        };

        if (this.displayedHelperMenu?.[item.path]) {
            this.tooltip = null;
        }
    }

    onMenuItemClick(item: MenuItem, event: MouseEvent): void {
        const basePath: string = window.location.pathname?.split('/')?.[1];
        this.updateActivePaths(basePath);

        this.displayedHelperMenu = item.helperMenuConfig
            ? { [item.path]: !this.displayedHelperMenu?.[item.path] }
            : { [item.path]: false };

        if (item.path === 'discovery') {
            this.discovery.emit(true);
        }

        if (item.path === 'login') {
            this.login.emit(true);
        }

        if (item.path === 'logout') {
            this.logout.emit(true);
        }

        event.stopPropagation();
    }

    onHelperMenuItemClick(item: HelperMenuItem): void {
        if (item?.isLinkExternal) {
            return;
        }

        const basePath: string = item.path?.split('/')?.[0];
        this.updateActivePaths(basePath);
    }

    updateActivePaths(basePath: string): void {
        this.activePaths = {
            ...this.activePaths,
            'power-notebooks': basePath === 'power-notebooks',
        };
    }
}
