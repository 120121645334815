/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { TagsEffects } from './tags.effects';
import { TagsFacade } from './tags.facade';
import { TagsParser } from './parsers/tags.parser';
import { TagsService } from './services/tags.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([TagsEffects]),
    ],
    declarations: [],
    providers: [TagsParser, TagsService, TagsFacade],
})
export class TagsStoreModule {}
