import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromDiscovery from './discovery.reducer';

export const getDiscoveryState = createSelector(getUIState, (state: UIState) => state.discovery);

export const getActiveTabId = createSelector(getDiscoveryState, fromDiscovery.getActiveTabId);
export const getActiveNetworkView = createSelector(
    getDiscoveryState,
    fromDiscovery.getActiveNetworkView,
);
export const getExecution = createSelector(getDiscoveryState, fromDiscovery.getExecution);
export const getDiscovery = createSelector(getDiscoveryState, fromDiscovery.getDiscovery);
export const getSummary = createSelector(getDiscoveryState, fromDiscovery.getSummary);
