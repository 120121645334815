export enum IngredientManagementActionTypes {
    UPLOAD_FILE_REQUEST = '[INGREDIENT MANAGEMENT][UPLOAD] Upload File Request',
    UPLOAD_FILE_SUCCESS = '[INGREDIENT MANAGEMENT][UPLOAD] Upload File Success',
    UPLOAD_FILE_FAILURE = '[INGREDIENT MANAGEMENT][UPLOAD] Upload File Failure',
    GET_INGREDIENTS_REQUEST = '[INGREDIENT MANAGEMENT][INGREDIENTS] Get Ingredients Request',
    GET_INGREDIENTS_SUCCESS = '[INGREDIENT MANAGEMENT][INGREDIENTS] Get Ingredients Success',
    GET_INGREDIENTS_FAILURE = '[INGREDIENT MANAGEMENT][INGREDIENTS] Get Ingredients Failure',
    UPDATE_NAME_REQUEST = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Name Request',
    UPDATE_NAME_SUCCESS = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Name Success',
    UPDATE_NAME_FAILURE = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Name Failure',
    UPDATE_NOTES_REQUEST = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Notes Request',
    UPDATE_NOTES_SUCCESS = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Notes Success',
    UPDATE_NOTES_FAILURE = '[INGREDIENT MANAGEMENT][INGREDIENT] Update Notes Failure',
    DOWNLOAD_INGREDIENT_REQUEST = '[INGREDIENT MANAGEMENT][INGREDIENT] Download Ingredient Request',
    DOWNLOAD_INGREDIENT_SUCCESS = '[INGREDIENT MANAGEMENT][INGREDIENT] Download Ingredient Success',
    DOWNLOAD_INGREDIENT_FAILURE = '[INGREDIENT MANAGEMENT][INGREDIENT] Download Ingredient Failure',
    DELETE_INGREDIENT_REQUEST = '[INGREDIENT MANAGEMENT][INGREDIENT] Delete Ingredient Request',
    DELETE_INGREDIENT_SUCCESS = '[INGREDIENT MANAGEMENT][INGREDIENT] Delete Ingredient Success',
    DELETE_INGREDIENT_FAILURE = '[INGREDIENT MANAGEMENT][INGREDIENT] Delete Ingredient Failure',
    DOWNLOAD_TEMPLATE_REQUEST = '[INGREDIENT MANAGEMENT] Download Template Request',
    DOWNLOAD_TEMPLATE_SUCCESS = '[INGREDIENT MANAGEMENT] Download Template Success',
    DOWNLOAD_TEMPLATE_FAILURE = '[INGREDIENT MANAGEMENT] Download Template Failure',
    DOWNLOAD_INSTRUCTIONS_REQUEST = '[INGREDIENT MANAGEMENT] Download Instructions Request',
    DOWNLOAD_INSTRUCTIONS_SUCCESS = '[INGREDIENT MANAGEMENT] Download Instructions Success',
    DOWNLOAD_INSTRUCTIONS_FAILURE = '[INGREDIENT MANAGEMENT] Download Instructions Failure',
    UPDATE_SHOULD_FETCH_INGREDIENTS = '[INGREDIENT MANAGEMENT][INGREDIENTS] Update Should Fetch Ingredients',
    CLEAR_NEXT_SUCCESS = '[INGREDIENT MANAGEMENT] Clear Next Success',
    CLEAR_NEXT_ERROR = '[INGREDIENT MANAGEMENT] Clear Next Error',
    CLEAR_SHOULD_FETCH_INGREDIENTS = '[INGREDIENT MANAGEMENT][INGREDIENTS] Clear Should Fetch Ingredients',
    RESET_INGREDIENT_MANAGEMENT = '[INGREDIENT MANAGEMENT] Reset Ingredient Management',
}
