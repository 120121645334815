/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/** custom imports */
import { ClosedDiscoveryService } from './services/closed-discovery.service';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DataState } from '../../data-state.interface';
import {
    performDiscoveryRequest,
    performDiscoverySuccess,
    performDiscoveryFailure,
    downloadDiscoveryRequest,
    getSearchSuggestionsRequest,
    clearSearchSuggestions,
    resetDiscovery,
    clearNextError,
    saveSessionId,
} from './closed-discovery.actions';
import {
    getFilterCounts,
    getDisplaying,
    getTotal,
    getOldestOccurrence,
    getNewestOccurrence,
    getMinCowMilkConcentration,
    getMaxCowMilkConcentration,
    getMinSourceConcentration,
    getMaxSourceConcentration,
    getMinTargetConcentration,
    getMaxTargetConcentration,
    getMinMoleculeWeight,
    getMaxMoleculeWeight,
    getInsightsPerCategory,
    getGroupedCategories,
    getShouldShowSourceConcentration,
    getShouldShowTargetConcentration,
    getPreferences,
    getSearchSuggestions,
    getSearchSuggestionsLoading,
    getSearchSuggestionsLoaded,
    getErrors,
    getLoading,
    getLoaded,
    getBlob,
    getSessionId,
} from './closed-discovery.selectors';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Insight from './interfaces/insight.interface';
import InsightsPerCategory from './types/insights-per-category.type';
import InsightGroupedCategories from '@apps/leap/src/app/shared/types/insight-grouped-categories.type';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

@Injectable()
export class ClosedDiscoveryFacade {
    insights$: BehaviorSubject<Insight[] | null> = new BehaviorSubject(null);
    filterCounts$: Observable<InsightFilterCounts> = this.store.pipe(select(getFilterCounts));
    displaying$: Observable<number> = this.store.pipe(select(getDisplaying));
    total$: Observable<number> = this.store.pipe(select(getTotal));
    oldestOccurrence$: Observable<number> = this.store.pipe(select(getOldestOccurrence));
    newestOccurrence$: Observable<number> = this.store.pipe(select(getNewestOccurrence));
    minCowMilkConcentration$: Observable<number> = this.store.pipe(
        select(getMinCowMilkConcentration),
    );
    maxCowMilkConcentration$: Observable<number> = this.store.pipe(
        select(getMaxCowMilkConcentration),
    );
    minSourceConcentration$: Observable<number> = this.store.pipe(
        select(getMinSourceConcentration),
    );
    maxSourceConcentration$: Observable<number> = this.store.pipe(
        select(getMaxSourceConcentration),
    );
    minTargetConcentration$: Observable<number> = this.store.pipe(
        select(getMinTargetConcentration),
    );
    maxTargetConcentration$: Observable<number> = this.store.pipe(
        select(getMaxTargetConcentration),
    );
    minMoleculeWeight$: Observable<number> = this.store.pipe(select(getMinMoleculeWeight));
    maxMoleculeWeight$: Observable<number> = this.store.pipe(select(getMaxMoleculeWeight));
    insightsPerCategory$: Observable<InsightsPerCategory> = this.store.pipe(
        select(getInsightsPerCategory),
    );
    groupedCategories$: Observable<InsightGroupedCategories> = this.store.pipe(
        select(getGroupedCategories),
    );
    shouldShowSourceConcentration$: Observable<boolean> = this.store.pipe(
        select(getShouldShowSourceConcentration),
    );
    shouldShowTargetConcentration$: Observable<boolean> = this.store.pipe(
        select(getShouldShowTargetConcentration),
    );
    preferences$: Observable<UserPreferences> = this.store.pipe(select(getPreferences));
    searchSuggestions$: Observable<string[]> = this.store.pipe(select(getSearchSuggestions));
    searchSuggestionsLoading$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoading),
    );
    searchSuggestionsLoaded$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoaded),
    );
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    sessionId$: Observable<string> = this.store.pipe(select(getSessionId));

    // internal variables
    discovery$: Observable<void>;
    discoverySubscription: Subscription;

    constructor(
        private store: Store<DataState>,
        private closedDiscoveryService: ClosedDiscoveryService,
    ) {}

    performDiscovery({
        source,
        target,
        preferences,
        filters,
        pageSize,
        pageIndex,
        studyTypesOrder,
    }: {
        source: string;
        target: string;
        preferences: UserPreferences;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        studyTypesOrder?: string[];
    }): void {
        this.store.dispatch(
            performDiscoveryRequest({
                source,
                target,
                preferences,
                filters,
                pageSize,
                pageIndex,
            }),
        );

        this.discoverySubscription?.unsubscribe();

        this.discovery$ = this.closedDiscoveryService
            .performDiscovery(
                source,
                target,
                preferences,
                filters,
                pageSize,
                pageIndex,
                studyTypesOrder,
            )
            .pipe(
                map((paginatedInsights: PaginatedInsights) => {
                    this.insights$.next(paginatedInsights?.results);
                    this.store.dispatch(
                        performDiscoverySuccess({
                            paginatedInsights: { ...paginatedInsights, results: null },
                        }),
                    );
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(performDiscoveryFailure({ errorResponse }))),
                ),
            );

        this.discoverySubscription = this.discovery$.subscribe();
    }

    downloadDiscovery(
        source: string,
        target: string,
        preferences: UserPreferences,
        filters?: ExecutionFilters,
    ): void {
        this.store.dispatch(
            downloadDiscoveryRequest({
                source,
                target,
                preferences,
                filters,
            }),
        );
    }

    getSearchSuggestions(source: string, target: string, query: string): void {
        this.store.dispatch(getSearchSuggestionsRequest({ source, target, query }));
    }

    clearSearchSuggestions(): void {
        this.store.dispatch(clearSearchSuggestions());
    }

    resetDiscovery(): void {
        this.insights$.next(null);
        this.store.dispatch(resetDiscovery());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    saveSessionId(id: string): void {
        this.store.dispatch(saveSessionId({ id }));
    }
}
