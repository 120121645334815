import {
    Directive,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    Output,
    EventEmitter,
    Input,
    OnChanges,
} from '@angular/core';

@Directive({
    selector: '[libIntersectionObserver]',
})
export class IntersectionObserverDirective implements OnChanges, AfterViewInit, OnDestroy {
    /**
     * The element against which intersections are to be observed
     */
    @Input() root: HTMLElement = null;
    @Input() rootMargin: number = 0;
    @Input() threshold: number = 0;

    /** Takes advantage of the IntersectionObserver native API */
    @Output() intersected: EventEmitter<IntersectionObserverEntry[]> = new EventEmitter<
        IntersectionObserverEntry[]
    >();

    observer: IntersectionObserver;
    callback: IntersectionObserverCallback = (
        intersectionEntries: IntersectionObserverEntry[],
    ): void => this.intersected.emit(intersectionEntries);
    options: IntersectionObserverInit;

    constructor(private elementRef: ElementRef) {}

    ngOnChanges(): void {
        this.updateOptions();
        this.updateObserver();
    }

    ngAfterViewInit(): void {
        this.observer.observe(this.elementRef.nativeElement);
    }

    ngOnDestroy(): void {
        // Stop observing
        this.observer.disconnect();
    }

    updateOptions(): void {
        this.options = {
            root: this.root,
            rootMargin: `${this.rootMargin}px`,
            threshold: this.threshold,
        };
    }

    updateObserver(): void {
        this.observer?.disconnect();
        this.observer = new IntersectionObserver(this.callback, this.options);
    }
}
