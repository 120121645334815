/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/**  custom imports */
import { GPTState } from './gpt-state.interface';
import {
    performStructuredOpenDiscoveryRequest,
    performStructuredClosedDiscoveryRequest,
    performFreeQueryRequest,
    regenerateQueryRequest,
    downloadSessionHistoryRequest,
    resetSession,
    performAssistantQueryRequest,
    getAssistantQueriesRequest,
    deleteAssistantQueriesRequest,
    clearNextError,
} from './gpt.actions';
import {
    getStructuredQuery,
    getFreeQuery,
    getLatestQuery,
    getQueries,
    getBlob,
    getLoading,
    getLoaded,
    getErrors,
    getAssistantQueries,
    getAssistantQuery,
    getAssistantQueryLoading,
    getAssistantQueryLoaded,
    getAssistantQueryErrors,
} from './gpt.selectors';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import QueryResponse from './interfaces/query-response.interface';
import AssistantQuery from './interfaces/assistant-query.interface';

@Injectable()
export class GPTFacade {
    structuredQuery$: Observable<QueryResponse> = this.store.pipe(select(getStructuredQuery));
    freeQuery$: Observable<QueryResponse> = this.store.pipe(select(getFreeQuery));
    latestQuery$: Observable<QueryResponse> = this.store.pipe(select(getLatestQuery));
    queries$: Observable<QueryResponse[]> = this.store.pipe(select(getQueries));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    assistantQueries$: Observable<AssistantQuery[]> = this.store.pipe(select(getAssistantQueries));
    assistantQuery$: Observable<AssistantQuery> = this.store.pipe(select(getAssistantQuery));
    assistantQueryLoading$: Observable<boolean> = this.store.pipe(select(getAssistantQueryLoading));
    assistantQueryLoaded$: Observable<boolean> = this.store.pipe(select(getAssistantQueryLoaded));
    assistantQueryErrors$: Observable<ErrorResponse[]> = this.store.pipe(
        select(getAssistantQueryErrors),
    );

    constructor(private store: Store<GPTState>) {}

    performStructuredOpenDiscovery(
        source: string,
        sourceId: string,
        sourceCategories: string[],
        targetCategories: string[],
    ): void {
        this.store.dispatch(
            performStructuredOpenDiscoveryRequest({
                source,
                sourceId,
                sourceCategories,
                targetCategories,
            }),
        );
    }

    performStructuredClosedDiscovery(
        source: string,
        sourceId: string,
        sourceCategories: string[],
        target: string,
        targetId: string,
        targetCategories: string[],
    ): void {
        this.store.dispatch(
            performStructuredClosedDiscoveryRequest({
                source,
                sourceId,
                sourceCategories,
                target,
                targetId,
                targetCategories,
            }),
        );
    }

    performFreeQuery(query: string, sessionId: string): void {
        this.store.dispatch(performFreeQueryRequest({ query, sessionId }));
    }

    regenerateQuery(id: string): void {
        this.store.dispatch(regenerateQueryRequest({ id }));
    }

    downloadSessionHistory(id: string, extension: string): void {
        this.store.dispatch(downloadSessionHistoryRequest({ id, extension }));
    }

    resetSession(): void {
        this.store.dispatch(resetSession());
    }

    performAssistantQuery(query: string): void {
        this.store.dispatch(performAssistantQueryRequest({ query }));
    }

    getAssistantQueries(): void {
        this.store.dispatch(getAssistantQueriesRequest());
    }

    deleteAssistantQueries(): void {
        this.store.dispatch(deleteAssistantQueriesRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
