import { Injectable } from '@angular/core';
import { environment, appSwitcherItems } from '@environments/leap/environment';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';
import {
    APP_SWITCH_ICON,
    APP_SWITCH_TITLE,
    LANDING_ICON,
    LANDING_ICON_ACTIVE,
    LAYOUT_LANDING_PATH,
    LAYOUT_LANDING_TITLE,
    CONVERSATIONAL_SEARCH_PATH,
    CONVERSATIONAL_SEARCH_TITLE,
    INGREDIENT_PROFILER_PATH,
    DISCOVERY_ICON,
    DISCOVERY_ICON_ACTIVE,
    DISCOVERY_PATH,
    MY_DISCOVERY_PATH,
    DISCOVER_TITLE,
    MY_DISCOVERY_TITLE,
    OPEN_DISCOVERY_TITLE,
    PROJECTS_ICON,
    PROJECTS_ICON_ACTIVE,
    PROJECTS_PATH,
    PROJECTS_TITLE,
    CONVERSATIONAL_SEARCH_MENU_ITEM,
    ARTICLES_MENU_ITEM,
    COW_MILK_PROFILER_MENU_ITEM,
    DAIRY_PROFILER_MENU_ITEM,
    INGREDIENT_PROFILER_MENU_ITEM,
    INGREDIENT_VIEWER_MENU_ITEM,
    INGREDIENT_COMPARISON_MENU_ITEM,
    INGREDIENT_MANAGEMENT_MENU_ITEM,
    NOTEBOOKS_MENU_ITEM,
    HELP_CENTER_ICON,
    HELP_CENTER_ICON_ACTIVE,
    HELP_CENTER_PATH,
    HELP_CENTER_TITLE,
    USER_GUIDE_MENU_ITEM,
    RELEASE_NOTES_MENU_ITEM,
    FAQS_MENU_ITEM,
    CONTACT_SUPPORT_MENU_ITEM,
    SUGGEST_IDEA_MENU_ITEM,
    LEGAL_ICON,
    LEGAL_ICON_ACTIVE,
    LEGAL_PATH,
    LEGAL_TITLE,
    CREDITS_MENU_ITEM,
    TERMS_OF_USE_MENU_ITEM,
    PRIVACY_POLICY_MENU_ITEM,
    USER_SETTINGS_MENU_ITEM,
    USER_MANAGEMENT_MENU_ITEM,
    LOGOUT_MENU_ITEM,
} from '../constants/menu-items';

/** Helpers */
import { isTruthy } from '@leap-common/utilities/helpers';

/** Services */
import { FeatureFlagsService } from '@leap-libs/feature-flags/src/public-api';
import { TokenService } from '@leap-store/core/src/lib/data/auth/services/token.service';

/** Interfaces - Enums */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';
import HelperMenuConfig from '@leap-libs/helper-menu/src/lib/helper-menu-config.interface';
import AppSwitcherItem from '@apps/leap/src/app/shared/interfaces/app-switcher-item.interface';
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Injectable()
export class MenuItemsService {
    app: string = environment.app;
    enabledAppsToSwitch: AppSwitcherItem[];

    // feature flags
    isLayoutLandingEnabled: boolean;
    isDiscoveryEnabled: boolean;
    areProjectsEnabled: boolean;
    areNotebooksEnabled: boolean;
    isConversationalSearchEnabled: boolean;
    areArticlesEnabled: boolean;
    isCowMilkProfilerEnabled: boolean;
    isDairyProfilerEnabled: boolean;
    isIngredientProfilerEnabled: boolean;
    isIngredientViewerEnabled: boolean;
    isIngredientComparisonEnabled: boolean;
    isIngredientManagementEnabled: boolean;
    isAppSwitcherEnabled: boolean;
    isSupportEnabled: boolean;
    isSuggestEnabled: boolean;
    areFAQsEnabled: boolean;
    areCreditsEnabled: boolean;

    // menu items
    landingMenuItem: MenuItem;
    discoveryMenuItem: MenuItem;
    appSwitcherMenuItem: MenuItem;
    projectsMenuItem: MenuItem;

    constructor(
        private featureFlagsService: FeatureFlagsService,
        private tokenService: TokenService,
    ) {}

    initializeEnabledAppsToSwitch(): void {
        this.enabledAppsToSwitch = appSwitcherItems.filter(
            (item: AppSwitcherItem) => !item.isDisabled,
        );
    }

    initializeEnabledFeatures(): void {
        this.isLayoutLandingEnabled = this.featureFlagsService.isFeatureEnabled('layoutLanding');
        this.isDiscoveryEnabled = this.featureFlagsService.isFeatureEnabled('discovery');
        this.areProjectsEnabled = this.featureFlagsService.isFeatureEnabled('projects');
        this.areNotebooksEnabled = this.featureFlagsService.isFeatureEnabled('notebooks');
        this.isConversationalSearchEnabled =
            this.featureFlagsService.isFeatureEnabled('conversationalSearch');
        this.areArticlesEnabled = this.featureFlagsService.isFeatureEnabled('articlesBrowser');
        this.isCowMilkProfilerEnabled =
            this.featureFlagsService.isFeatureEnabled('cowMilkProfiler');
        this.isDairyProfilerEnabled = this.featureFlagsService.isFeatureEnabled('dairyProfiler');
        this.isIngredientProfilerEnabled =
            this.featureFlagsService.isFeatureEnabled('ingredientProfiler');
        this.isIngredientViewerEnabled =
            this.featureFlagsService.isFeatureEnabled('ingredientViewer');
        this.isIngredientComparisonEnabled =
            this.featureFlagsService.isFeatureEnabled('ingredientComparison');
        this.isIngredientManagementEnabled =
            this.featureFlagsService.isFeatureEnabled('ingredientManagement');
        this.isAppSwitcherEnabled = this.featureFlagsService.isFeatureEnabled('appSwitcher');
        this.isSupportEnabled = this.featureFlagsService.isFeatureEnabled('support');
        this.isSuggestEnabled = this.featureFlagsService.isFeatureEnabled('suggest');
        this.areFAQsEnabled = this.featureFlagsService.isFeatureEnabled('faqs');
        this.areCreditsEnabled = this.featureFlagsService.isFeatureEnabled('credits');
    }

    initializeMainMenuItems(routerUrl: string): void {
        this.landingMenuItem = {
            id: 'menu-landing',
            icon: LANDING_ICON,
            activeIcon: LANDING_ICON_ACTIVE,
            path:
                this.app === 'ingpro'
                    ? INGREDIENT_PROFILER_PATH
                    : this.isLayoutLandingEnabled
                    ? LAYOUT_LANDING_PATH
                    : this.isConversationalSearchEnabled
                    ? CONVERSATIONAL_SEARCH_PATH
                    : DISCOVERY_PATH,
            title: this.isLayoutLandingEnabled
                ? LAYOUT_LANDING_TITLE
                : this.isConversationalSearchEnabled
                ? CONVERSATIONAL_SEARCH_TITLE
                : DISCOVER_TITLE,
            isPathExactMatch: true,
        };

        this.discoveryMenuItem = {
            id: 'menu-discovery',
            icon: DISCOVERY_ICON,
            activeIcon: DISCOVERY_ICON_ACTIVE,
            path:
                this.isLayoutLandingEnabled || this.isConversationalSearchEnabled
                    ? DISCOVERY_PATH
                    : MY_DISCOVERY_PATH,
            title:
                this.app === 'd3' // this condition is based on the app, not the feature, since the title is related to the app
                    ? OPEN_DISCOVERY_TITLE
                    : this.isConversationalSearchEnabled
                    ? DISCOVER_TITLE
                    : MY_DISCOVERY_TITLE,
            isUnclickable: !this.isLayoutLandingEnabled && !this.isConversationalSearchEnabled,
        };

        this.appSwitcherMenuItem = {
            id: 'menu-app-switcher',
            icon: APP_SWITCH_ICON,
            path: EMPTY_STRING,
            title: APP_SWITCH_TITLE,
            helperMenuConfig: this.getAppSwitcherHelperMenu('auth/login'),
        };

        this.projectsMenuItem = {
            id: 'menu-projects',
            icon: PROJECTS_ICON,
            activeIcon: PROJECTS_ICON_ACTIVE,
            path: PROJECTS_PATH,
            title: PROJECTS_TITLE,
            shouldOpenInNewTab: this.getActiveRoute(routerUrl) !== PROJECTS_PATH,
        };
    }

    getActiveRoute(url: string): string {
        return url?.split('/')[1];
    }

    getMainMenuItems(routerUrl: string, currentUserApps: string[]): MenuItem[] {
        this.initializeEnabledAppsToSwitch();
        this.initializeEnabledFeatures();
        this.initializeMainMenuItems(routerUrl);

        const shouldAppSwitcherMenuItemIncluded: boolean = currentUserApps?.includes(
            this.enabledAppsToSwitch?.[0]?.id,
        );

        const orderedItems: MenuItem[] = this.getOrderedMainMenuItems();

        return shouldAppSwitcherMenuItemIncluded
            ? Array.from(
                  new Set([this.isAppSwitcherEnabled && this.appSwitcherMenuItem, ...orderedItems]),
              ).filter(isTruthy)
            : Array.from(new Set(orderedItems)).filter(isTruthy);
    }

    getOrderedMainMenuItems(): MenuItem[] {
        return this.app === 'd3'
            ? Array.from(
                  new Set([
                      this.landingMenuItem,
                      this.isCowMilkProfilerEnabled && COW_MILK_PROFILER_MENU_ITEM,
                      this.isDairyProfilerEnabled && DAIRY_PROFILER_MENU_ITEM,
                      this.areArticlesEnabled && ARTICLES_MENU_ITEM,
                      this.isDiscoveryEnabled && this.discoveryMenuItem,
                      this.areProjectsEnabled && this.projectsMenuItem,
                  ]),
              ).filter(isTruthy)
            : this.app === 'dia'
            ? Array.from(
                  new Set([
                      this.landingMenuItem,
                      this.isIngredientViewerEnabled && INGREDIENT_VIEWER_MENU_ITEM,
                      this.isIngredientComparisonEnabled && INGREDIENT_COMPARISON_MENU_ITEM,
                      this.isIngredientManagementEnabled && INGREDIENT_MANAGEMENT_MENU_ITEM,
                  ]),
              ).filter(isTruthy)
            : this.app === 'phi'
            ? Array.from(
                  new Set([
                      this.landingMenuItem,
                      this.isConversationalSearchEnabled && CONVERSATIONAL_SEARCH_MENU_ITEM,
                      this.isDiscoveryEnabled && this.discoveryMenuItem,
                      this.areArticlesEnabled && ARTICLES_MENU_ITEM,
                      this.areProjectsEnabled && this.projectsMenuItem,
                      this.areNotebooksEnabled && NOTEBOOKS_MENU_ITEM,
                  ]),
              ).filter(isTruthy)
            : Array.from(
                  new Set([
                      this.landingMenuItem,
                      this.isConversationalSearchEnabled && CONVERSATIONAL_SEARCH_MENU_ITEM,
                      this.isDiscoveryEnabled && this.discoveryMenuItem,
                      this.areProjectsEnabled && this.projectsMenuItem,
                      this.areArticlesEnabled && ARTICLES_MENU_ITEM,
                      this.isCowMilkProfilerEnabled && COW_MILK_PROFILER_MENU_ITEM,
                      this.isDairyProfilerEnabled && DAIRY_PROFILER_MENU_ITEM,
                      this.isIngredientProfilerEnabled && INGREDIENT_PROFILER_MENU_ITEM,
                      this.areNotebooksEnabled && NOTEBOOKS_MENU_ITEM,
                  ]),
              ).filter(isTruthy);
    }

    getUserMenuItems(shouldUserManagementMenuItemIncluded: boolean): MenuItem[] {
        const helpMenuItem: MenuItem = {
            id: 'help-center',
            icon: HELP_CENTER_ICON,
            activeIcon: HELP_CENTER_ICON_ACTIVE,
            path: HELP_CENTER_PATH,
            title: HELP_CENTER_TITLE,
            helperMenuConfig: this.getHelpCenterHelperMenu(),
        };

        const legalMenuItem: MenuItem = {
            id: 'about-app',
            icon: LEGAL_ICON,
            activeIcon: LEGAL_ICON_ACTIVE,
            path: LEGAL_PATH,
            title: LEGAL_TITLE,
            helperMenuConfig: this.getLegalHelperMenu(),
        };

        return shouldUserManagementMenuItemIncluded
            ? Array.from(
                  new Set([
                      helpMenuItem,
                      USER_SETTINGS_MENU_ITEM,
                      USER_MANAGEMENT_MENU_ITEM,
                      legalMenuItem,
                      LOGOUT_MENU_ITEM,
                  ]),
              )
            : Array.from(
                  new Set([helpMenuItem, USER_SETTINGS_MENU_ITEM, legalMenuItem, LOGOUT_MENU_ITEM]),
              );
    }

    getAppSwitcherHelperMenu(path: string): HelperMenuConfig {
        const accessToken: string = this.tokenService.getToken(TokenTypes.access);
        const refreshToken: string = this.tokenService.getToken(TokenTypes.refresh);

        return {
            mainSection: {
                title: 'Switch to',
                items: appSwitcherItems.map((item: AppSwitcherItem) => ({
                    id: `menu-app-switch-${item.id}`,
                    title: item.title,
                    path: `${item.url}/${path}?access=${accessToken}&refresh=${refreshToken}`,
                    icon: `fa-kit fa-${item.id}-icon-1`,
                    shouldOpenInNewTab: true,
                    isLinkExternal: true,
                    isDisabled: item.isDisabled,
                })),
            },
        };
    }

    getHelpCenterHelperMenu(): HelperMenuConfig {
        return {
            mainSection: {
                items: Array.from(
                    new Set([
                        USER_GUIDE_MENU_ITEM,
                        RELEASE_NOTES_MENU_ITEM,
                        this.areFAQsEnabled && FAQS_MENU_ITEM,
                        this.isSupportEnabled && CONTACT_SUPPORT_MENU_ITEM,
                        this.isSuggestEnabled && SUGGEST_IDEA_MENU_ITEM,
                    ]),
                ).filter(isTruthy),
            },
        };
    }

    getLegalHelperMenu(): HelperMenuConfig {
        return {
            mainSection: {
                items: Array.from(
                    new Set([
                        this.areCreditsEnabled && CREDITS_MENU_ITEM,
                        TERMS_OF_USE_MENU_ITEM,
                        PRIVACY_POLICY_MENU_ITEM,
                    ]),
                ).filter(isTruthy),
            },
            isRightAligned: true,
        };
    }
}
