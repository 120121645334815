import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { environment } from '@environments/leap/environment';

/** Constants */
import {
    PASSWORD_ICON,
    INPUT_SHOWN_ICON,
    INPUT_HIDDEN_ICON,
    NEW_PASSWORD_LABEL,
    CONFIRM_NEW_PASSWORD_LABEL,
    SUBMIT_TEXT,
} from '../../../../core/constants/auth';

/** Services - Facades */
import { FormValidationService } from '@leap-libs/form/src/lib/form-validation.service';
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';
import { ResetPasswordService } from '../../../services/reset-password.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Interfaces - Enums */
import { HttpErrorResponse } from '@angular/common/http';
import Organization from '@apps/leap/src/app/shared/enums/organization.enum';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';

@Component({
    selector: 'app-reset-password',
    templateUrl: 'reset-password.container.component.html',
    styleUrls: ['reset-password.container.component.scss'],
})
export class ResetPasswordContainerComponent implements OnInit {
    @HostBinding('class') classes = 'full-width';

    // organization
    organization: string = environment.organization;

    // text color
    isTextWhite: boolean;

    // form
    form: FormGroup;
    formNewPasswordOptions: DecoratedInputOptions;
    formConfirmNewPasswordOptions: DecoratedInputOptions;
    formSubmitButtonTitle: string;
    passwordValidators: ValidatorFn;
    confirmPasswordValidators: ValidatorFn;

    // Token that we get from url and is used to reset user password
    resetPasswordKey: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private formValidationService: FormValidationService,
        private errorHandlerService: ErrorHandlerService,
        private resetPasswordService: ResetPasswordService,
        private alertsFacade: AlertsFacade,
    ) {}

    ngOnInit(): void {
        this.getResetPasswordKey();
        this.initializeFormValidators();
        this.initializeForm();
        this.initializeIsTextWhite();
    }

    getResetPasswordKey(): void {
        this.resetPasswordKey = this.activatedRoute.snapshot.queryParams.key;
    }

    /** Initializes the form validators. */
    initializeFormValidators(): void {
        // initialize password validators
        this.passwordValidators = Validators.compose([
            this.formValidationService.requiredValidator({
                required: 'Password is required',
            }),
            this.formValidationService.minLengthValidator(12, {
                minLength: 'Must be at least 12 characters long',
            }),
            this.formValidationService.maxLengthValidator(32, {
                maxLength: 'Must not exceed 32 characters',
            }),
            this.formValidationService.patternValidator(/\d/, {
                hasDigit: 'Must contain at least one digit',
            }),
            this.formValidationService.patternValidator(/[A-Z]/, {
                hasUpperCase: 'Must contain at least one capital letter',
            }),
            this.formValidationService.patternValidator(/[a-z]/, {
                hasLowerCase: 'Must contain at least one small letter',
            }),
            this.formValidationService.patternValidator(/[\W]/, {
                hasSpecialCharacter: 'Must contain at least one symbol',
            }),
        ]);

        // initialize confirm password validators
        this.confirmPasswordValidators = Validators.compose([
            this.formValidationService.requiredValidator({
                required: 'Confirm password is required',
            }),
        ]);
    }

    /** Initializes the form options */
    initializeForm(): void {
        const newPasswordId: string = 'newPassword';
        const confirmNewPasswordId: string = 'confirmNewPassword';

        const fieldsMatchValidator: ValidatorFn = this.formValidationService.fieldsMatchValidator(
            newPasswordId,
            confirmNewPasswordId,
            {
                fieldsMismatch: 'Please make sure your passwords match',
            },
        );

        this.form = this.formBuilder.group(
            {
                [newPasswordId]: [null, this.passwordValidators],
                [confirmNewPasswordId]: [null, this.confirmPasswordValidators],
            },
            { validators: fieldsMatchValidator },
        );

        this.formNewPasswordOptions = {
            type: 'password',
            id: newPasswordId,
            label: NEW_PASSWORD_LABEL,
            placeholder: NEW_PASSWORD_LABEL,
            iconsBegin: [{ class: PASSWORD_ICON }],
            iconsEnd: [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }],
        };

        this.formConfirmNewPasswordOptions = {
            type: 'password',
            id: confirmNewPasswordId,
            label: CONFIRM_NEW_PASSWORD_LABEL,
            placeholder: CONFIRM_NEW_PASSWORD_LABEL,
            iconsBegin: [{ class: PASSWORD_ICON }],
            iconsEnd: [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }],
        };

        this.formSubmitButtonTitle = SUBMIT_TEXT;
    }

    initializeIsTextWhite(): void {
        this.isTextWhite = environment.organization !== Organization.dmi;
    }

    onSubmit(password: string): void {
        this.resetPasswordService.resetPassword(password, this.resetPasswordKey).subscribe(
            // Success: redirect to login
            () => this.router.navigate(['/auth/login']),
            // Error: display alert
            (httpErrorResponse: HttpErrorResponse) => {
                const alert: Alert = this.errorHandlerService.handleErrorResponse(
                    httpErrorResponse.error,
                );

                // push error to ui facade
                this.alertsFacade.pushAlertEvent({ content: alert });
            },
        );
    }

    onInputVisibilityToggled(inputId: string): void {
        this.toggleInputVisibility(inputId);
    }

    toggleInputVisibility(inputId: string): void {
        if (inputId === 'newPassword') {
            this.formNewPasswordOptions.type =
                this.formNewPasswordOptions.type === 'password' ? 'text' : 'password';

            this.formNewPasswordOptions.iconsEnd =
                this.formNewPasswordOptions.type === 'password'
                    ? [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }]
                    : [
                          {
                              class: INPUT_SHOWN_ICON,
                              shouldToggleVisibility: true,
                          },
                      ];
        }
        if (inputId === 'confirmNewPassword') {
            this.formConfirmNewPasswordOptions.type =
                this.formConfirmNewPasswordOptions.type === 'password' ? 'text' : 'password';

            this.formConfirmNewPasswordOptions.iconsEnd =
                this.formConfirmNewPasswordOptions.type === 'password'
                    ? [{ class: INPUT_HIDDEN_ICON, shouldToggleVisibility: true }]
                    : [
                          {
                              class: INPUT_SHOWN_ICON,
                              shouldToggleVisibility: true,
                          },
                      ];
        }
    }
}
