export enum ClosedDiscoveryActionTypes {
    PERFORM_DISCOVERY_REQUEST = '[DISCOVERY][CLOSED] Perform Discovery Request',
    PERFORM_DISCOVERY_SUCCESS = '[DISCOVERY][CLOSED] Perform Discovery Success',
    PERFORM_DISCOVERY_FAILURE = '[DISCOVERY][CLOSED] Perform Discovery Failure',
    DOWNLOAD_DISCOVERY_REQUEST = '[DISCOVERY][CLOSED] Download Discovery Request',
    DOWNLOAD_DISCOVERY_SUCCESS = '[DISCOVERY][CLOSED] Download Discovery Success',
    DOWNLOAD_DISCOVERY_FAILURE = '[DISCOVERY][CLOSED] Download Discovery Failure',
    GET_SEARCH_SUGGESTIONS_REQUEST = '[DISCOVERY][CLOSED] Get Search Suggestions Request',
    GET_SEARCH_SUGGESTIONS_SUCCESS = '[DISCOVERY][CLOSED] Get Search Suggestions Success',
    GET_SEARCH_SUGGESTIONS_FAILURE = '[DISCOVERY][CLOSED] Get Search Suggestions Failure',
    CLEAR_SEARCH_SUGGESTIONS = '[DISCOVERY][CLOSED] Clear Search Suggestions',
    RESET_DISCOVERY = '[DISCOVERY][CLOSED] Reset Discovery',
    CLEAR_NEXT_ERROR = '[DISCOVERY][CLOSED] Clear Next Error',
    SAVE_SESSION_ID = '[DISCOVERY][CLOSED] Save Session Id',
}
