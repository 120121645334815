/** third-party imports */
import { createSelector } from '@ngrx/store';

/** custom imports */
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromGPT from './gpt.reducer';

export const getGPTState = createSelector(getDataState, (state: DataState) => state.gpt);

export const getStructuredQuery = createSelector(getGPTState, fromGPT.getStructuredQuery);
export const getFreeQuery = createSelector(getGPTState, fromGPT.getFreeQuery);
export const getLatestQuery = createSelector(getGPTState, fromGPT.getLatestQuery);
export const getQueries = createSelector(getGPTState, fromGPT.getQueries);
export const getBlob = createSelector(getGPTState, fromGPT.getBlob);
export const getErrors = createSelector(getGPTState, fromGPT.getErrors);
export const getLoading = createSelector(getGPTState, fromGPT.getLoading);
export const getLoaded = createSelector(getGPTState, fromGPT.getLoaded);
export const getAssistantQueries = createSelector(getGPTState, fromGPT.getAssistantQueries);
export const getAssistantQuery = createSelector(getGPTState, fromGPT.getAssistantQuery);
export const getAssistantQueryLoading = createSelector(
    getGPTState,
    fromGPT.getAssistantQueryLoading,
);
export const getAssistantQueryLoaded = createSelector(getGPTState, fromGPT.getAssistantQueryLoaded);
export const getAssistantQueryErrors = createSelector(getGPTState, fromGPT.getAssistantQueryErrors);
