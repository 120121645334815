/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { ProfilerReportParser } from '../parsers/profiler-report.parser';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import RelationshipGroup from '../enums/relationship-group.enum';
import HealthLabelStatistics from '../interfaces/health-label-statistics.interface';
import HealthLabelStatisticsRestApi from '../rest-api-interfaces/health-label-statistics.rest.interface';
import HealthLabelSummaries from '../interfaces/health-label-summaries.interface';
import HealthLabelSummariesRestApi from '../rest-api-types/health-label-summaries.rest.type';
import HealthLabelTopCompounds from '../interfaces/health-label-top-compounds.interface';
import HealthLabelTopCompoundsRestApi from '../rest-api-types/health-label-top-compounds.rest.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';

@Injectable()
export class ProfilerReportService {
    constructor(private http: HttpClient, private profilerReportParser: ProfilerReportParser) {}

    getHealthLabelsStatistics(
        ingredientId: string,
        filters: ExecutionFilters,
        preferences: UserPreferences,
        areEffectsEnabled: boolean,
    ): Observable<{
        healthLabelsStatistics: HealthLabelStatistics[];
        relationshipsPerGroup: Record<RelationshipGroup, string[]>;
    }> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/report?section=Health area&subsection=statistics`,
                {
                    ingredientUid: ingredientId,
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(
                    ({
                        healthAreas,
                        relationshipGroups,
                    }: {
                        healthAreas: HealthLabelStatisticsRestApi[];
                        relationshipGroups: Record<RelationshipGroup, string[]>;
                    }) => ({
                        healthLabelsStatistics:
                            this.profilerReportParser.parseHealthLabelsStatistics(
                                healthAreas,
                                areEffectsEnabled,
                            ),
                        relationshipsPerGroup: relationshipGroups,
                    }),
                ),
            );
    }

    getHealthLabelSummaries(
        ingredientId: string,
        healthLabel: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
        areEffectsEnabled?: boolean,
    ): Observable<HealthLabelSummaries> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/report?section=Health area&subsection=summary`,
                {
                    ingredientUid: ingredientId,
                    healthArea: healthLabel,
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(({ summaries }: { summaries: HealthLabelSummariesRestApi }) =>
                    this.profilerReportParser.parseHealthLabelSummaries(
                        healthLabel,
                        summaries,
                        areEffectsEnabled,
                    ),
                ),
            );
    }

    getHealthLabelTopCompounds(
        ingredientId: string,
        healthLabel: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
        areEffectsEnabled?: boolean,
    ): Observable<HealthLabelTopCompounds> {
        return this.http
            .post(
                `${environment.profilerServerUrl}/report?section=Health area&subsection=topAssociations`,
                {
                    ingredientUid: ingredientId,
                    healthArea: healthLabel,
                    filters,
                    preferences,
                },
            )
            .pipe(
                map(({ topMolecules }: { topMolecules: HealthLabelTopCompoundsRestApi }) =>
                    this.profilerReportParser.parseHealthLabelTopCompounds(
                        healthLabel,
                        topMolecules,
                        areEffectsEnabled,
                    ),
                ),
            );
    }
}
