import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import * as rolesActions from './roles.actions';
import { RolesState } from './roles-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';

export const initialState: RolesState = {
    errors: [],
    list: [],
    loading: false,
    loaded: false,
};

const rolesReducer: ActionReducer<RolesState, Action> = createReducer(
    initialState,
    on(rolesActions.getRolesRequest, (state: RolesState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(
        rolesActions.getRolesSuccess,
        (
            state: RolesState,
            { paginatedRoles }: { paginatedRoles: PaginatedResults<UserRole> },
        ) => ({
            ...state,
            list: paginatedRoles.results,
            loading: false,
            loaded: true,
        }),
    ),
    on(
        rolesActions.getRolesFailure,
        (state: RolesState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(rolesActions.clearNextError, (state: RolesState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: RolesState | undefined, action: Action): RolesState =>
    rolesReducer(state, action);

export const getRolesErrors: (state: RolesState) => ErrorResponse[] = (state: RolesState) =>
    state.errors;
export const getRolesList: (state: RolesState) => UserRole[] = (state: RolesState) => state.list;
export const getRolesLoading: (state: RolesState) => boolean = (state: RolesState) => state.loading;
export const getRolesLoaded: (state: RolesState) => boolean = (state: RolesState) => state.loaded;
