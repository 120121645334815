import { Injectable } from '@angular/core';
import { environment } from '@environments/leap/environment';

/** Interfaces - Enums */
import CurrentUserIdentityRestApi from '../rest-api-interfaces/current-user-identity.rest.interface';
import CurrentUser from '../interfaces/current-user.interface';
import UserRoleRestApi from '../rest-api-interfaces/user-role.rest.interface';
import UserRole from '@leap-store/core/src/lib/data/auth/interfaces/user-role.interface';
import UserAppRestApi from '../rest-api-interfaces/user-app.rest.interface';
import UserApp from '../interfaces/user-app.interface';
import UserOrganizationRestApi from '../rest-api-interfaces/user-organization.rest.interface';
import UserOrganization from '../interfaces/user-organization.interface';
import UserGroupRestApi from '../rest-api-interfaces/user-group.rest.interface';
import UserGroup from '../interfaces/user-group.interface';
import UserRoleEnum from '@leap-store/core/src/lib/data/roles/enums/user-role.enum';

@Injectable()
export class CurrentUserParser {
    constructor() {}

    parseCurrentUser(identity: CurrentUserIdentityRestApi): CurrentUser {
        return {
            id: identity.user_id,
            email: identity.user_email,
            roles: identity.user_roles ? this.parseUserRoles(identity.user_roles) : [],
            apps: identity.user_apps ? this.parseUserApps(identity.user_apps) : [],
            organizations: identity.user_organizations
                ? this.parseUserOrganizations(identity.user_organizations)
                : [],
            departments: identity.user_departments
                ? this.parseUserGroups(identity.user_departments)
                : [],
            groups: identity.user_groups ? this.parseUserGroups(identity.user_groups) : [],
            acceptedTermsOfUse: identity.terms_accepted || [],
            areTermsOfUseAccepted:
                identity.terms_accepted && this.parseAreTermsOfUseAccepted(identity.terms_accepted),
        };
    }

    parseUserRoles(roles: UserRoleRestApi[]): UserRole[] {
        return roles.map((role: UserRoleRestApi) => this.parseUserRole(role));
    }

    parseUserRole(role: UserRoleRestApi): UserRole {
        return {
            id: role.uuid,
            name:
                role.name === UserRoleEnum.reader
                    ? UserRoleEnum.user
                    : role.name === UserRoleEnum.groupAdmin
                    ? UserRoleEnum.admin
                    : (role.name as UserRoleEnum),
        };
    }

    parseUserApps(apps: UserAppRestApi[]): UserApp[] {
        return apps.map((app: UserAppRestApi) => this.parseUserApp(app));
    }

    parseUserApp(app: UserAppRestApi): UserApp {
        return { id: app.uuid, name: app.name };
    }

    parseUserOrganizations(organizations: UserOrganizationRestApi[]): UserOrganization[] {
        return organizations.map((organization: UserOrganizationRestApi) =>
            this.parseUserOrganization(organization),
        );
    }

    parseUserOrganization(organization: UserOrganizationRestApi): UserOrganization {
        return { id: organization.uuid, name: organization.name, alias: organization.alias };
    }

    parseUserGroups(groups: UserGroupRestApi[]): UserGroup[] {
        return groups.map((group: UserGroupRestApi) => this.parseUserGroup(group));
    }

    parseUserGroup(group: UserGroupRestApi): UserGroup {
        return { id: group.uuid, name: group.name };
    }

    parseAreTermsOfUseAccepted(acceptedTermsOfUse: string[]): boolean {
        const runningApp: string = environment.app;

        return acceptedTermsOfUse?.map((app: string) => app.toLowerCase())?.includes(runningApp);
    }
}
