/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as actions from './terms.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { TermsState } from './terms-state.interface';
import TermOverview from './interfaces/term-overview.interface';

export const initialState: TermsState = {
    overview: [],
    errors: [],
    loading: false,
    loaded: false,
};

const articleReducer: ActionReducer<TermsState, Action> = createReducer(
    initialState,
    on(actions.getOverviewRequest, (state: TermsState) => ({
        ...state,
        loading: true,
        loaded: false,
    })),
    on(actions.getOverviewSuccess, (state: TermsState, { terms }: { terms: TermOverview[] }) => ({
        ...state,
        overview: terms,
        loading: false,
        loaded: true,
    })),
    on(
        actions.getOverviewFailure,
        (state: TermsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            loading: false,
            loaded: false,
        }),
    ),
    on(actions.clearOverview, (state: TermsState) => ({
        ...state,
        overview: null as TermOverview[],
    })),
    on(actions.clearNextError, (state: TermsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: TermsState | undefined, action: Action): TermsState =>
    articleReducer(state, action);

// selectors
export const getOverview: (state: TermsState) => TermOverview[] = (state: TermsState) =>
    state.overview;
export const getErrors: (state: TermsState) => ErrorResponse[] = (state: TermsState) =>
    state.errors;
export const getLoading: (state: TermsState) => boolean = (state: TermsState) => state.loading;
export const getLoaded: (state: TermsState) => boolean = (state: TermsState) => state.loaded;
