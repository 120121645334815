import { Injectable } from '@angular/core';

/** pouchDB */
import PouchDB from 'pouchdb';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class DatabaseService {
    constructor(private localStorageService: LocalStorageService) {}

    /** Database */

    /**
     * Gets the database info.
     * Whether the info responds with an "indexed_db_went_bad" error it returns true.
     */
    async checkDB(db: PouchDB.Database): Promise<boolean> {
        try {
            await db.get('info');
        } catch (error) {
            if (error.name === 'indexed_db_went_bad') {
                console.info('Accessing IndexedDB failed. Falling back to in-memory.');
                return true;
            }
        }
    }

    /**
     * Switches to a database on the indexedDB or on the memory, based on the second argument.
     */
    switchDB(name: string, isIndexedDBUnknown?: boolean): PouchDB.Database {
        return isIndexedDBUnknown ? new PouchDB(name, { adapter: 'memory' }) : new PouchDB(name);
    }

    /**
     * Creates a database on the indexedDB or on the memory, based on the second argument.
     * Returns the newly created database.
     */
    createDB(name: string, isIndexedDBUnknown?: boolean): PouchDB.Database {
        return isIndexedDBUnknown ? new PouchDB(name, { adapter: 'memory' }) : new PouchDB(name);
    }

    /** Destroys database based on the given name. */
    async destroyDB(name: string): Promise<void> {
        const db: PouchDB.Database = new PouchDB(name);
        await db.destroy();
    }

    /**
     * Destroys all databases.
     * Retrieves all database names from the local storage and calls the destroyDB() for each of
     * them.
     */
    async destroyDBs(localStorageKey: string, localStorageDelimiter: string): Promise<void[]> {
        const activeDBs: string[] = this.localStorageService.splitValueInDelimiter(
            this.localStorageService.getItem(localStorageKey),
            localStorageDelimiter,
        );

        const promises: Promise<void>[] = activeDBs.map((dbName: string) => this.destroyDB(dbName));

        return Promise.all(promises);
    }

    /** Index */
    async createIndex(db: PouchDB.Database, { fields }: { fields: string[] }): Promise<void> {
        for (const field of fields) {
            await db.createIndex({
                index: { fields: [field] },
            });
        }

        return;
    }
}
