import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { DebouncedFunc, throttle } from 'lodash';

/**
 * Recalculates element height in order to always fit in the viewport.
 * Should be used as a last resort when a CSS solution doesn't suffice.
 */
@Directive({
    selector: '[libFitInViewportHeight]',
})
export class FitInViewportHeightDirective implements OnInit, OnDestroy {
    element: HTMLElement;

    /**
     * Bypass directive effect.
     */
    @Input() isFitInViewportDisabled: boolean = false;

    /**
     * Use ancestor element positioning instead of directive host element.
     * Set this to true when third party interferes with the directive
     * (e.g. ngDropdown may affect the element's offset properties).
     */
    @Input() useAncestorPosition: boolean = false;

    /**
     * Force element to stretch until it matches viewport bottom end.
     * Otherwise, the element maintains auto height but always stays
     * within the viewport.
     */
    @Input() forceFullHeight: boolean = false;

    /**
     * Distance from viewport's bottom.
     */
    @Input() offsetBottom: number = 0;

    constructor(private elementRef: ElementRef) {}

    @HostListener('window:resize')
    // Throttle resize handler calls for better performance
    throttledListener: DebouncedFunc<() => void> = throttle(this.onResize, 100);

    ngOnInit(): void {
        this.calculateHeight();
    }

    ngOnDestroy(): void {
        this.throttledListener.cancel();
    }

    isDisabled(): boolean {
        return this.isFitInViewportDisabled;
    }

    calculateHeight(): void {
        if (this.isDisabled()) {
            return;
        }

        this.element = this.elementRef.nativeElement;
        this.updateHeight();
    }

    updateHeight(): void {
        const height: number = this.getAvailableHeight();

        if (this.forceFullHeight) {
            this.element.style.height = `${height}px`;
        } else {
            this.element.style.maxHeight = `${height}px`;
        }
    }

    getAvailableHeight(): number {
        const elementOffsetTop: number = this.getElementY();
        const viewportHeight: number = window.innerHeight;

        return viewportHeight - (elementOffsetTop + this.offsetBottom);
    }

    getElementY(): number {
        return this.useAncestorPosition
            ? this.getAncestorY()
            : this.element.getBoundingClientRect().top;
    }

    getAncestorY(): number {
        let element: HTMLElement = this.element.parentElement;
        let clientRect: DOMRect = element.getBoundingClientRect();

        while (clientRect.width === 0 && clientRect.height === 0) {
            element = element.parentElement;
            clientRect = element.getBoundingClientRect();
        }

        return clientRect.top;
    }

    onResize(): void {
        this.calculateHeight();
    }
}
