import { Injectable } from '@angular/core';

/** Interfaces */
import TermOverviewRestApi from '../rest-api-interfaces/term-overview.rest.interface';
import TermOverview from '../interfaces/term-overview.interface';

@Injectable()
export class TermsParser {
    constructor() {}

    parseTermsOverview(termsOverview: TermOverviewRestApi[]): TermOverview[] {
        return termsOverview.map((termOverview: TermOverviewRestApi) =>
            this.parseTermOverview(termOverview),
        );
    }

    parseTermOverview(termOverview: TermOverviewRestApi): TermOverview {
        return {
            id: termOverview.cui,
            articlesCount: termOverview.articleOccurrences,
            dateRange: [termOverview.oldestPublicationYear, termOverview.newestPublicationYear],
        };
    }
}
