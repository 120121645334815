/** Modules */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

/** Components */
import { HelperMenuComponent } from './helper-menu.component';

@NgModule({
    declarations: [HelperMenuComponent],
    imports: [CommonModule, RouterModule, NgbDropdownModule],
    exports: [HelperMenuComponent],
})
export class HelperMenuModule {}
