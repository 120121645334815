import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'cellPlaceholder',
})
export class CellPlaceholderPipe implements PipeTransform {
    constructor() {}

    public transform(value: string, placeholder: string = '-'): string {
        return value || placeholder;
    }
}
