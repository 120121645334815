export const AMINO_ACIDS_ORDER: string[] = [
    'His',
    'Ile',
    'Leu',
    'Lys',
    'Met',
    'Phe',
    'Thr',
    'Trp',
    'Val',
    'Glu',
    'Asp',
    'Tyr',
    'Cys',
    'Asn',
    'Ser',
    'Gln',
    'Gly',
    'Arg',
    'Ala',
    'Pro',
];
