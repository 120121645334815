import { EMPTY_STRING } from '@leap-common/constants/common';
import { UNDERSCORE_DELIMITER } from '@leap-common/constants/delimiters';

/**
 * Generates a cryptographically random code verifier string.
 * Used for PKCE (Proof Key for Code Exchange) in OAuth2.
 * The resulting string will have a length between 43 and 128 characters.
 * Returns a random string of alphanumeric characters.
 */
export const generateCodeVerifier = (): string => {
    // create an array of 64 random bytes
    const array: Uint8Array = new Uint8Array(64);
    crypto.getRandomValues(array);

    // map each byte to an alphanumeric character and join into a string
    return Array.from(array)
        .map((byte: number) => `0${(byte % 36).toString(36)}`.slice(-1))
        .join(EMPTY_STRING);
};

/**
 * Generates a code challenge from a code verifier for PKCE (OAuth2).
 * Returns the Base64URL-encoded code challenge.
 */
export const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
    // create a TextEncoder to convert the code verifier string into a Uint8Array.
    const encoder: TextEncoder = new TextEncoder();
    const data: Uint8Array = encoder.encode(codeVerifier);

    // compute the SHA-256 hash of the code verifier.
    const hash: ArrayBuffer = await crypto.subtle.digest('SHA-256', data);

    // convert the hash into a Base64URL-encoded string
    return btoa(String.fromCharCode(...new Uint8Array(hash)))
        .replace(/\+/g, '-')
        .replace(/\//g, UNDERSCORE_DELIMITER)
        .replace(/=+$/, EMPTY_STRING); // Base64URL encoding
};
