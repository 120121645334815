/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { TagsParser } from '../parsers/tags.parser';

/** Interfaces */
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import Tag from '../interfaces/tag.interface';

@Injectable()
export class TagsService {
    constructor(private http: HttpClient, private tagsParser: TagsParser) {}

    /**
     * Gets the tags, parses them into the desired format and returns an Observable of Tag[].
     */
    getTags(): Observable<Tag[]> {
        const app: string = environment.app;

        return this.http
            .get(`${environment.projectsServerUrl}/tags/?app=${app}`)
            .pipe(map((tags: ResultsRestApi<string>) => this.tagsParser.parseTags(tags.results)));
    }
}
