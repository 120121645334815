import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

/** Constants */
import { COW_MILK_NAME } from '@apps/leap/src/app/shared/constants/discovery';

/** Interfaces */
import Concentration from '../interfaces/concentration.interface';
import ConcentrationRestApi from '../rest-api-interfaces/concentration.rest.interface';
import ConcentrationDetailsRestApi from '../rest-api-interfaces/concentration-details.rest.interface';

@Injectable()
export class ConcentrationsParser {
    constructor() {}

    parseConcentrations(concentrations: ConcentrationRestApi[]): Concentration[] {
        return concentrations?.map(this.parseConcentration, this) || [];
    }

    parseConcentration(concentration: ConcentrationRestApi): Concentration {
        return {
            id: this.getUuid(),
            source: concentration.source,
            sourceLink: concentration.sourceLink,
            avg: Number(concentration.avg),
            min: Number(concentration.min),
            max: Number(concentration.max),
            unit: concentration.unit,
            ingredient: concentration.ingredientType || COW_MILK_NAME, // BE currently has no `IngredientType` for Cow Milk concentrations.
                                                                       // Therefore, we use the COW_MILK_NAME as a fallback value until
                                                                       // the feature (Cow Milk Ingredients) is introduced.
            type: concentration.type,
            isQuantified: concentration.quantified,
        };
    }

    parseConcentrationDetails(
        concentrations: ConcentrationDetailsRestApi[],
        sourceName: string,
    ): Concentration[] {
        return (
            concentrations?.map((concentration: ConcentrationDetailsRestApi) =>
                this.parseConcentrationDetailsItem(concentration, sourceName),
            ) || []
        );
    }

    parseConcentrationDetailsItem(
        concentration: ConcentrationDetailsRestApi,
        sourceName: string,
    ): Concentration {
        return {
            source: concentration.source,
            sourceLink: concentration.sourceLink,
            avg: Number(concentration.concentration),
            min: Number(concentration.minConcentration),
            max: Number(concentration.maxConcentration),
            unit: concentration.unit,
            ingredient: concentration.ingredientType || sourceName, // If, for any BE reason, the discovery source/target term yields zero ingredient results
                                                                    // we fallback to the discovery source/target term name as an ingredient type.
            isQuantified: true,
        };
    }

    getUuid(): string {
        return uuid();
    }
}
