import { Injectable } from '@angular/core';

@Injectable()
export class CategoriesParser {
    constructor() {}

    parseCategories(subcategoriesPerCategory: Record<string, string[]>): Record<string, string[]> {
        return subcategoriesPerCategory;
    }
}
