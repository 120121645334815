/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { FilesActionTypes } from './files-action-types.enum';
import AbortSettings from './interfaces/abort-settings.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import File from '@leap-store/core/src/lib/data/files/interfaces/file.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const stageFileToUpload = createAction(
    FilesActionTypes.STAGE_FILE_TO_UPLOAD,
    props<{ file: File }>(),
);

export const unstageFileToUpload = createAction(FilesActionTypes.UNSTAGE_FILE_TO_UPLOAD);

export const createFilePathRequest = createAction(
    FilesActionTypes.CREATE_FILE_PATH_REQUEST,
    props<{ file: File; onProgress: (progress: any) => void }>(),
);

export const createFilePathSuccess = createAction(
    FilesActionTypes.CREATE_FILE_PATH_SUCCESS,
    props<{ sas: string; id: string }>(),
);

export const createFilePathFailure = createAction(
    FilesActionTypes.CREATE_FILE_PATH_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const uploadFileRequest = createAction(
    FilesActionTypes.UPLOAD_FILE_REQUEST,
    props<{
        sas: string;
        id: string;
        file: File;
        onProgress: (progress: any) => void;
        abortSettings: AbortSettings;
    }>(),
);

export const uploadFileSuccess = createAction(FilesActionTypes.UPLOAD_FILE_SUCCESS);

export const uploadFileFailure = createAction(FilesActionTypes.UPLOAD_FILE_FAILURE);

export const updateUploadProgress = createAction(
    FilesActionTypes.UPDATE_UPLOAD_PROGRESS,
    props<{ loadedBytes: number }>(),
);

export const cancelFileUpload = createAction(FilesActionTypes.CANCEL_FILE_UPLOAD);

export const deleteFilePathRequest = createAction(
    FilesActionTypes.DELETE_FILE_PATH_REQUEST,
    props<{ id: string }>(),
);

export const deleteFilePathSuccess = createAction(FilesActionTypes.DELETE_FILE_PATH_SUCCESS);

export const deleteFilePathFailure = createAction(
    FilesActionTypes.DELETE_FILE_PATH_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const deleteFileRequest = createAction(
    FilesActionTypes.DELETE_FILE_REQUEST,
    props<{ id: string }>(),
);

export const deleteFileSuccess = createAction(
    FilesActionTypes.DELETE_FILE_SUCCESS,
    props<{ id: string }>(),
);

export const deleteFileFailure = createAction(
    FilesActionTypes.DELETE_FILE_FAILURE,
    props<{ id: string; errorResponse: HttpErrorResponse }>(),
);

export const getFilesRequest = createAction(
    FilesActionTypes.GET_FILES_REQUEST,
    props<{
        pageIndex: number;
        pageSize: number;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getFilesSuccess = createAction(
    FilesActionTypes.GET_FILES_SUCCESS,
    props<{
        paginatedFiles: PaginatedResults<File>;
        sortDirection: SortingOrder;
        sortColumn: string;
    }>(),
);

export const getFilesFailure = createAction(
    FilesActionTypes.GET_FILES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(FilesActionTypes.CLEAR_NEXT_ERROR);

export const clearShouldFetchFiles = createAction(FilesActionTypes.CLEAR_SHOULD_FETCH_FILES);
