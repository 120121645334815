/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { HealthLabelReportParser } from '../parsers/health-label-report.parser';
import { CompoundReportParser } from '../../compound-report/parsers/compound-report.parser';
import { COW_MILK_NAME } from '@apps/leap/src/app/shared/constants/discovery';
import { DAIRY_PRODUCT_NAME } from '@apps/leap/src/app/dairy-profiler/constants/profiler';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Overview from '../interfaces/overview.interface';
import HealthLabelInfoRestApi from '../rest-api-interfaces/health-label-info.rest.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import Compound from '../interfaces/compound.interface';
import CompoundRestApi from '../rest-api-interfaces/compound.rest.interface';
import Target from '../interfaces/target.interface';
import TargetRestApi from '../rest-api-interfaces/target.rest.interface';
import TopTermsPayload from '../interfaces/top-terms-payload.interface';
import TermSentencesRestApi from '../../compound-report/rest-api-interfaces/term-sentences.rest.interface';
import Effect from '../../ingredient-profiler/enums/effect.enum';
import TermSentences from '../../compound-report/interfaces/term-sentences.interface';
import ProfilerEntity from '../../ingredient-profiler/enums/entity.enum';

@Injectable()
export class HealthLabelReportService {
    constructor(
        private http: HttpClient,
        private healthLabelReportParser: HealthLabelReportParser,
        private compoundReportParser: CompoundReportParser,
    ) {}

    getBaseURL(ingredientName: string, ingredientId?: string): string {
        return ingredientName === COW_MILK_NAME || ingredientId
            ? environment.profilerServerUrl
            : ingredientName === DAIRY_PRODUCT_NAME
            ? environment.dairyProfilerServerUrl
            : null;
    }

    getOverview(
        ingredientId: string,
        ingredientName: string,
        countBy: string,
        filters: ExecutionFilters,
        preferences?: UserPreferences,
    ): Observable<Overview> {
        const baseURL: string = this.getBaseURL(ingredientName, ingredientId);

        return this.http
            .post(`${baseURL}/report?section=Overview`, {
                ingredientUid: ingredientId,
                countBy,
                filters,
                preferences,
            })
            .pipe(
                map(({ healthAreas }: { healthAreas: HealthLabelInfoRestApi[] }) =>
                    this.healthLabelReportParser.parseOverview(healthAreas),
                ),
            );
    }

    getTopCompounds({
        ingredientName,
        healthLabel,
        effect,
        filters,
        preferences,
    }: TopTermsPayload): Observable<Compound[]> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}/report?section=top terms&subsection=sources`, {
                healthArea: healthLabel,
                effect,
                filters,
                preferences,
            })
            .pipe(
                map(({ sources }: { sources: CompoundRestApi[] }) =>
                    this.healthLabelReportParser.parseCompounds(sources),
                ),
            );
    }

    getTopTargets({
        ingredientName,
        healthLabel,
        effect,
        filters,
        preferences,
    }: TopTermsPayload): Observable<Target[]> {
        const baseURL: string = this.getBaseURL(ingredientName);

        return this.http
            .post(`${baseURL}/report?section=top terms&subsection=targets`, {
                healthArea: healthLabel,
                effect,
                filters,
                preferences,
            })
            .pipe(
                map(({ targets }: { targets: TargetRestApi[] }) =>
                    this.healthLabelReportParser.parseTargets(targets),
                ),
            );
    }

    getSentences(
        ingredientName: string,
        id: string,
        termType: ProfilerEntity,
        healthLabel: string,
        effect: Effect,
        filters: ExecutionFilters,
        preferences: UserPreferences,
    ): Observable<TermSentences[]> {
        const baseURL: string = this.getBaseURL(ingredientName);
        const serializedTermType: string = this.healthLabelReportParser.serializeTermType(termType);

        return this.http
            .post(`${baseURL}/report?section=top sentences`, {
                termUid: id,
                healthArea: healthLabel,
                termType: serializedTermType,
                effect,
                filters,
                preferences,
            })
            .pipe(
                map(
                    ({ associatedTerms }: { associatedTerms: TermSentencesRestApi[] }) =>
                        this.compoundReportParser.parseTermsSentences(associatedTerms, effect)
                            .sentences,
                ),
            );
    }

    downloadReport(
        ingredientName: string,
        healthLabel: string,
        effect: Effect,
        termId: string,
        termType: ProfilerEntity,
        countBy: string,
        filters: ExecutionFilters,
        preferences: UserPreferences,
    ): Observable<Blob> {
        const baseURL: string = this.getBaseURL(ingredientName);
        const serializedTermType: string = this.healthLabelReportParser.serializeTermType(termType);

        return this.http.post(
            `${baseURL}/report/export`,
            {
                healthArea: healthLabel || undefined,
                effect: effect || undefined,
                termUid: termId,
                termType: serializedTermType || undefined,
                countBy,
                filters,
                preferences,
            },
            {
                responseType: 'blob',
            },
        );
    }
}
