import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromPipelineExecutions from './pipeline-executions.reducer';

export const getPipelineExecutionsState = createSelector(
    getDataState,
    (state: DataState) => state.pipelineExecutions,
);
export const getPipelineExecutions = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getPipelineExecutionsEntities,
);
export const getDownloadableResult = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getPipelineResult,
);
export const getError = createSelector(getPipelineExecutionsState, fromPipelineExecutions.getError);
export const getLoading = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getLoading,
);
export const getLoaded = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getLoaded,
);
export const getPageIndex = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getPageIndex,
);
export const getPageSize = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getPageSize,
);
export const getTotal = createSelector(getPipelineExecutionsState, fromPipelineExecutions.getTotal);
export const getSortDirection = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getSortDirection,
);
export const getSortColumn = createSelector(
    getPipelineExecutionsState,
    fromPipelineExecutions.getSortColumn,
);
