/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { NotebookServersState } from './notebook-servers-state.interface';
import {
    getNotebookServersRequest,
    createNotebookServerRequest,
    deleteNotebookServerRequest,
    logoutJupyterHubRequest,
    clearNextError,
} from './notebook-servers.actions';
import {
    getError,
    getLoading,
    getLoaded,
    getConnections,
    getIsConnectionOpen,
    getIsConnectionClosed,
} from './notebook-servers.selectors';
import TokenTypes from '../auth/enums/token-types.enum';

@Injectable()
export class NotebookServersFacade {
    error$: Observable<ErrorResponse[]> = this.store.pipe(select(getError));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    connections$: Observable<number | boolean> = this.store.pipe(select(getConnections));
    isConnectionOpen$: Observable<boolean> = this.store.pipe(select(getIsConnectionOpen));
    isConnectionClosed$: Observable<boolean> = this.store.pipe(select(getIsConnectionClosed));

    constructor(private store: Store<NotebookServersState>) {}

    getNotebookServers(): void {
        this.store.dispatch(getNotebookServersRequest());
    }

    createNotebookServer(key: TokenTypes): void {
        this.store.dispatch(createNotebookServerRequest({ key }));
    }

    deleteNotebookServer(): void {
        this.store.dispatch(deleteNotebookServerRequest());
    }

    logoutJupyterHub(): void {
        this.store.dispatch(logoutJupyterHubRequest());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
