/** third-party imports */
import { createAction, props } from '@ngrx/store';

/** custom imports */
import { IngredientViewerActionTypes } from './ingredient-viewer-action-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import Ingredient from './interfaces/ingredient.interface';
import Statistics from './interfaces/statistics.interface';

export const getIngredientsRequest = createAction(
    IngredientViewerActionTypes.GET_INGREDIENTS_REQUEST,
    props<{ query: string }>(),
);

export const getIngredientsSuccess = createAction(
    IngredientViewerActionTypes.GET_INGREDIENTS_SUCCESS,
    props<{ ingredients: Ingredient[] }>(),
);

export const getIngredientsFailure = createAction(
    IngredientViewerActionTypes.GET_INGREDIENTS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getIngredientRequest = createAction(
    IngredientViewerActionTypes.GET_INGREDIENT_REQUEST,
    props<{ id: string }>(),
);

export const getIngredientSuccess = createAction(
    IngredientViewerActionTypes.GET_INGREDIENT_SUCCESS,
    props<{ ingredient: Ingredient }>(),
);

export const getIngredientFailure = createAction(
    IngredientViewerActionTypes.GET_INGREDIENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getStatisticsRequest = createAction(
    IngredientViewerActionTypes.GET_STATISTICS_REQUEST,
    props<{ ingredientId: string }>(),
);

export const getStatisticsSuccess = createAction(
    IngredientViewerActionTypes.GET_STATISTICS_SUCCESS,
    props<{ statistics: Statistics }>(),
);

export const getStatisticsFailure = createAction(
    IngredientViewerActionTypes.GET_STATISTICS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearIngredients = createAction(IngredientViewerActionTypes.CLEAR_INGREDIENTS);

export const clearNextError = createAction(IngredientViewerActionTypes.CLEAR_NEXT_ERROR);
