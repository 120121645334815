/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

/**  custom imports */
import { DairyProfilerService } from './services/dairy-profiler.service';
import { DairyProfilerState } from './dairy-profiler-state.interface';
import {
    getCompoundsRequest,
    getCompoundsSuccess,
    getCompoundsFailure,
    getInsightsRequest,
    getDairyProductInsightsRequest,
    getDairyProductInsightsSuccess,
    getDairyProductInsightsFailure,
    getTargetInsightsRequest,
    downloadInsightsRequest,
    getSearchSuggestionsRequest,
    clearSearchSuggestions,
    resetDiscovery,
    clearNextError,
} from './dairy-profiler.actions';
import {
    getCompoundsTotal,
    getCompoundsDisplaying,
    getCompoundsLoading,
    getCompoundsLoaded,
    getParentCompoundId,
    getInsights,
    getEnhancedInsights,
    getInsightsTotal,
    getInsightsDisplaying,
    getInsightsPageIndex,
    getInsightsLoading,
    getInsightsLoaded,
    getDairyProductInsightsTotal,
    getDairyProductInsightsLoading,
    getDairyProductInsightsLoaded,
    getTargetInsights,
    getTargetInsightsTotal,
    getTargetInsightsLoading,
    getTargetInsightsLoaded,
    getOldestOccurrence,
    getNewestOccurrence,
    getPreferences,
    getMinMoleculeWeight,
    getMaxMoleculeWeight,
    getBlob,
    getSearchSuggestions,
    getSearchSuggestionsLoading,
    getSearchSuggestionsLoaded,
    getErrors,
} from './dairy-profiler.selectors';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import PaginatedCompounds from '../ingredient-profiler/interfaces/paginated-compounds.interface';
import Compound from '../ingredient-profiler/interfaces/compound.interface';
import Insight from '../ingredient-profiler/interfaces/insight.interface';
import EnhancedInsights from '../ingredient-profiler/interfaces/enhanced-insights';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

@Injectable()
export class DairyProfilerFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    compounds$: BehaviorSubject<Compound[] | null> = new BehaviorSubject(null);
    compoundsTotal$: Observable<number> = this.store.pipe(select(getCompoundsTotal));
    compoundsDisplaying$: Observable<number> = this.store.pipe(select(getCompoundsDisplaying));
    compoundsLoading$: Observable<boolean> = this.store.pipe(select(getCompoundsLoading));
    compoundsLoaded$: Observable<boolean> = this.store.pipe(select(getCompoundsLoaded));
    parentCompoundId$: Observable<string> = this.store.pipe(select(getParentCompoundId));
    insights$: Observable<Insight[]> = this.store.pipe(select(getInsights));
    enhancedInsights$: Observable<EnhancedInsights> = this.store.pipe(
        select(getEnhancedInsights),
        createDistinctUntilObjectChanged<EnhancedInsights>(),
    );
    insightsTotal$: Observable<number> = this.store.pipe(select(getInsightsTotal));
    insightsDisplaying$: Observable<number> = this.store.pipe(select(getInsightsDisplaying));
    insightsPageIndex$: Observable<number> = this.store.pipe(select(getInsightsPageIndex));
    insightsLoading$: Observable<boolean> = this.store.pipe(select(getInsightsLoading));
    insightsLoaded$: Observable<boolean> = this.store.pipe(select(getInsightsLoaded));
    dairyProductInsightsTotal$: Observable<number> = this.store.pipe(
        select(getDairyProductInsightsTotal),
    );
    dairyProductInsights$: BehaviorSubject<Insight[] | null> = new BehaviorSubject(null);
    dairyProductInsightsLoading$: Observable<boolean> = this.store.pipe(
        select(getDairyProductInsightsLoading),
    );
    dairyProductInsightsLoaded$: Observable<boolean> = this.store.pipe(
        select(getDairyProductInsightsLoaded),
    );
    targetInsights$: Observable<Insight[]> = this.store.pipe(select(getTargetInsights));
    targetInsightsTotal$: Observable<number> = this.store.pipe(select(getTargetInsightsTotal));
    targetInsightsLoading$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoading));
    targetInsightsLoaded$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoaded));
    filterCounts$: BehaviorSubject<InsightFilterCounts | null> = new BehaviorSubject(null);
    oldestOccurrence$: Observable<number> = this.store.pipe(select(getOldestOccurrence));
    newestOccurrence$: Observable<number> = this.store.pipe(select(getNewestOccurrence));
    preferences$: Observable<UserPreferences> = this.store.pipe(select(getPreferences));
    minMoleculeWeight$: Observable<number> = this.store.pipe(select(getMinMoleculeWeight));
    maxMoleculeWeight$: Observable<number> = this.store.pipe(select(getMaxMoleculeWeight));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    searchSuggestions$: Observable<string[]> = this.store.pipe(select(getSearchSuggestions));
    searchSuggestionsLoading$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoading),
    );
    searchSuggestionsLoaded$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoaded),
    );

    // internal variables
    internalCompounds$: Observable<void>;
    compoundsSubscription: Subscription;
    internalDairyProductInsights$: Observable<void>;
    dairyProductInsightsSubscription: Subscription;

    constructor(
        private store: Store<DairyProfilerState>,
        private dairyProfilerService: DairyProfilerService,
    ) {}

    getCompounds({
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(getCompoundsRequest({ filters, pageSize, pageIndex, preferences }));

        this.compoundsSubscription?.unsubscribe();

        this.internalCompounds$ = this.dairyProfilerService
            .getCompounds(filters, pageSize, pageIndex, preferences)
            .pipe(
                map((paginatedCompounds: PaginatedCompounds) => {
                    this.compounds$.next(paginatedCompounds?.results);
                    this.filterCounts$.next(paginatedCompounds?.filterCounts);
                    this.store.dispatch(
                        getCompoundsSuccess({
                            paginatedCompounds: { ...paginatedCompounds, results: null },
                        }),
                    );
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getCompoundsFailure({ errorResponse }))),
                ),
            );

        this.compoundsSubscription = this.internalCompounds$.subscribe();
    }

    getInsights({
        compoundId,
        filters,
        pageSize,
        pageIndex,
        preferences,
        sortingOptions,
    }: {
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
        sortingOptions?: SortingOptions;
    }): void {
        this.store.dispatch(
            getInsightsRequest({
                compoundId,
                filters,
                pageSize,
                pageIndex,
                preferences,
                sortingOptions,
            }),
        );
    }

    getDairyProductInsights({
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(
            getDairyProductInsightsRequest({
                filters,
                pageSize,
                pageIndex,
                preferences,
            }),
        );

        this.dairyProductInsightsSubscription?.unsubscribe();

        this.internalDairyProductInsights$ = this.dairyProfilerService
            .getDairyProductInsights(filters, pageSize, pageIndex, preferences)
            .pipe(
                map((paginatedInsights: PaginatedInsights) => {
                    this.dairyProductInsights$.next(paginatedInsights?.results);
                    this.store.dispatch(
                        getDairyProductInsightsSuccess({
                            paginatedInsights: { ...paginatedInsights, results: null },
                        }),
                    );
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getDairyProductInsightsFailure({ errorResponse }))),
                ),
            );

        this.dairyProductInsightsSubscription = this.internalDairyProductInsights$.subscribe();
    }

    getTargetInsights({
        targetId,
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        targetId: string;
        filters?: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(
            getTargetInsightsRequest({ targetId, filters, pageSize, pageIndex, preferences }),
        );
    }

    downloadInsights(filters: ExecutionFilters, preferences: UserPreferences): void {
        this.store.dispatch(downloadInsightsRequest({ filters, preferences }));
    }

    getSearchSuggestions(query: string, activeSearch: ProfilerSearch): void {
        this.store.dispatch(getSearchSuggestionsRequest({ query, activeSearch }));
    }

    clearSearchSuggestions(): void {
        this.store.dispatch(clearSearchSuggestions());
    }

    resetDiscovery(): void {
        this.compounds$.next(null);
        this.dairyProductInsights$.next(null);
        this.filterCounts$.next(null);
        this.store.dispatch(resetDiscovery());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
