/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as pathsActions from './paths.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { PathsState } from './paths-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplorationPath from './interfaces/exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const adapter: EntityAdapter<ExplorationPath> = createEntityAdapter<ExplorationPath>();

export const initialState: PathsState = adapter.getInitialState({
    paths: [],
    parentInsightId: '',
    parentMetapathIndex: 0,
    errors: [],
    loading: false,
    loaded: false,
    pageSize: 0,
    pageIndex: 0,
    total: 0,
    sortingOrder: SortingOrder.descending,
    blob: null,
    filename: '',
});

const pathsReducer: ActionReducer<PathsState, Action> = createReducer(
    initialState,
    on(
        pathsActions.getPathsRequest,
        (
            state: PathsState,
            {
                parentInsightId,
                parentMetapathIndex,
            }: {
                parentInsightId: string;
                parentMetapathIndex: number;
            },
        ) => ({
            ...state,
            parentInsightId,
            parentMetapathIndex,
            loading: true,
            loaded: false,
        }),
    ),
    on(
        pathsActions.getPathsSuccess,
        (
            state: PathsState,
            {
                parentInsightId,
                parentMetapathIndex,
                paginatedPaths,
                sortingOrder,
            }: {
                parentInsightId: string;
                parentMetapathIndex: number;
                paginatedPaths: PaginatedResults<ExplorationPath>;
                sortingOrder: SortingOrder;
            },
        ) =>
            adapter.setAll(paginatedPaths.results, {
                ...state,
                parentInsightId,
                parentMetapathIndex,
                loading: false,
                loaded: true,
                pageSize: paginatedPaths.pageSize,
                pageIndex: paginatedPaths.pageIndex,
                total: paginatedPaths.total,
                sortingOrder,
            }),
    ),
    on(
        pathsActions.getPathsFailure,
        (state: PathsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [
                ...state.errors,
                errorResponse.status === 0
                    ? // Unknown error from the gateway between FE and BE
                      { errors: [{ message: errorResponse.statusText }] }
                    : errorResponse.error,
            ],
            loading: false,
            loaded: false,
        }),
    ),
    on(pathsActions.downloadPathsRequest, (state: PathsState) => ({
        ...state,
        blob: null as Blob,
        filename: '',
    })),
    on(
        pathsActions.downloadPathsSuccess,
        (state: PathsState, { blob, filename }: { blob: Blob; filename: string }) => ({
            ...state,
            blob,
            filename,
        }),
    ),
    on(
        pathsActions.downloadPathsFailure,
        (state: PathsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(pathsActions.clearNextError, (state: PathsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
);

export const reducer = (state: PathsState | undefined, action: Action): PathsState =>
    pathsReducer(state, action);

// selectors
export const getEntities: (state: PathsState) => ExplorationPath[] =
    adapter.getSelectors().selectAll;
export const getParentInsightId: (state: PathsState) => string = (state: PathsState) =>
    state.parentInsightId;
export const getParentMetapathIndex: (state: PathsState) => number = (state: PathsState) =>
    state.parentMetapathIndex;
export const getErrors: (state: PathsState) => ErrorResponse[] = (state: PathsState) =>
    state.errors;
export const getLoading: (state: PathsState) => boolean = (state: PathsState) => state.loading;
export const getLoaded: (state: PathsState) => boolean = (state: PathsState) => state.loaded;
export const getPageSize: (state: PathsState) => number = (state: PathsState) => state.pageSize;
export const getPageIndex: (state: PathsState) => number = (state: PathsState) => state.pageIndex;
export const getTotal: (state: PathsState) => number = (state: PathsState) => state.total;
export const getSortingOrder: (state: PathsState) => SortingOrder = (state: PathsState) =>
    state.sortingOrder;
export const getBlob: (state: PathsState) => Blob = (state: PathsState) => state.blob;
export const getFilename: (state: PathsState) => string = (state: PathsState) => state.filename;
