import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { IngredientViewerEffects } from './ingredient-viewer.effects';
import { IngredientViewerFacade } from './ingredient-viewer.facade';
import { IngredientViewerParser } from './parsers/ingredient-viewer.parser';
import { IngredientViewerService } from './services/ingredient-viewer.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([IngredientViewerEffects]),
    ],
    declarations: [],
    providers: [IngredientViewerParser, IngredientViewerService, IngredientViewerFacade],
})
export class IngredientViewerStoreModule {}
