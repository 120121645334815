/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { ArticlesReportService } from './services/articles-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './articles-report.actions';
import Associations from './interfaces/associations.interface';
import KeyFinding from './interfaces/key-finding.interface';
import TheoreticalFrameworkItem from './interfaces/theoretical-framework-item.interface';
import BookmarkArticle from '../bookmarks/types/article.type';
import Reference from '../report/interfaces/reference.interface';
import Summary from '../report/interfaces/summary.interface';

@Injectable()
export class ArticlesReportEffects {
    constructor(private actions$: Actions, private articlesReportService: ArticlesReportService) {}

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(
                ({
                    projectId,
                    bookmarkIds,
                    shouldRegenerate,
                }: {
                    projectId: string;
                    bookmarkIds: string[];
                    shouldRegenerate: boolean;
                }) =>
                    this.articlesReportService
                        .getOverview(projectId, bookmarkIds, shouldRegenerate)
                        .pipe(
                            map((overview: string) => actions.getOverviewSuccess({ overview })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getOverviewFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getAssociations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getAssociationsRequest),
            switchMap(({ projectId, bookmarkIds }: { projectId: string; bookmarkIds: string[] }) =>
                this.articlesReportService.getAssociations(projectId, bookmarkIds).pipe(
                    map((associations: Associations) =>
                        actions.getAssociationsSuccess({ associations }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getAssociationsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getKeyFindings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getKeyFindingsRequest),
            switchMap(
                ({
                    projectId,
                    bookmarks,
                    shouldRegenerate,
                }: {
                    projectId: string;
                    bookmarks: BookmarkArticle[];
                    shouldRegenerate: boolean;
                }) =>
                    this.articlesReportService
                        .getKeyFindings(projectId, bookmarks, shouldRegenerate)
                        .pipe(
                            map((keyFindings: KeyFinding[]) =>
                                actions.getKeyFindingsSuccess({ keyFindings }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getKeyFindingsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getTermSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTermSummariesRequest),
            switchMap(
                ({
                    projectId,
                    bookmarkIds,
                    shouldRegenerate,
                }: {
                    projectId: string;
                    bookmarkIds: string[];
                    shouldRegenerate: boolean;
                }) =>
                    this.articlesReportService
                        .getTermSummaries(projectId, bookmarkIds, shouldRegenerate)
                        .pipe(
                            map((termSummaries: Summary[]) =>
                                actions.getTermSummariesSuccess({ termSummaries }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getTermSummariesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getCriticalAnalysis$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getCriticalAnalysisRequest),
            switchMap(
                ({
                    projectId,
                    bookmarkIds,
                    shouldRegenerate,
                }: {
                    projectId: string;
                    bookmarkIds: string[];
                    shouldRegenerate: boolean;
                }) =>
                    this.articlesReportService
                        .getCriticalAnalysis(projectId, bookmarkIds, shouldRegenerate)
                        .pipe(
                            map((criticalAnalysis: string) =>
                                actions.getCriticalAnalysisSuccess({ criticalAnalysis }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getCriticalAnalysisFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getTheoreticalFramework$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTheoreticalFrameworkRequest),
            switchMap(
                ({
                    projectId,
                    bookmarks,
                    shouldRegenerate,
                }: {
                    projectId: string;
                    bookmarks: BookmarkArticle[];
                    shouldRegenerate: boolean;
                }) =>
                    this.articlesReportService
                        .getTheoreticalFramework(projectId, bookmarks, shouldRegenerate)
                        .pipe(
                            map((theoreticalFrameworkItems: TheoreticalFrameworkItem[]) =>
                                actions.getTheoreticalFrameworkSuccess({
                                    theoreticalFrameworkItems,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getTheoreticalFrameworkFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getReferences$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getReferencesRequest),
            switchMap(({ projectId, bookmarkIds }: { projectId: string; bookmarkIds: string[] }) =>
                this.articlesReportService.getReferences(projectId, bookmarkIds).pipe(
                    map((references: Reference[]) => actions.getReferencesSuccess({ references })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getReferencesFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadReport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadReportRequest),
            switchMap(({ projectId, bookmarkIds }: { projectId: string; bookmarkIds: string[] }) =>
                this.articlesReportService.download(projectId, bookmarkIds).pipe(
                    map((blob: Blob) => actions.downloadReportSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadReportFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
