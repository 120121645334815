import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

/** Parsers */
import { PathsParser } from '../../paths/parsers/paths.parser';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';

/** Interfaces - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplainabilityPathRestApi from '../rest-api-interfaces/explainability-path.rest.interface';
import ExplorationPath from '../../paths/interfaces/exploration-path.interface';

@Injectable()
export class ExplainabilityParser {
    constructor(private pathsParser: PathsParser, private insightParser: InsightParser) {}

    /** Parses Paginated Paths */
    parsePaginatedPaths(
        paginatedPaths: PaginatedResultsRestApi<ExplainabilityPathRestApi>,
    ): PaginatedResults<ExplorationPath> {
        return {
            results: paginatedPaths.results ? this.parsePaths(paginatedPaths.results) : [],
            pageSize: paginatedPaths.pageSize,
            pageIndex: paginatedPaths.pageIndex,
            total: paginatedPaths.total,
        };
    }

    /** Parses BE Paths to integrate them on the FE */
    parsePaths(paths: ExplainabilityPathRestApi[]): ExplorationPath[] {
        return paths.map((path: ExplainabilityPathRestApi) => this.parsePath(path));
    }

    /** Parses PathRestApi to ExplorationPath */
    parsePath(path: ExplainabilityPathRestApi): ExplorationPath {
        return {
            id: this.getUuid(),
            segments: this.pathsParser.parsePathSegments(path),
            importance: path.confidence,
            relation: this.insightParser.parseRelationshipType(path.path_relation),
        };
    }

    getUuid(): string {
        return uuid();
    }
}
