/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

/** custom imports */
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { DataState } from '../../data-state.interface';
import {
    performDiscoveryRequest,
    fetchLocalDiscoveryRequest,
    downloadDiscoveryRequest,
    getSearchSuggestionsRequest,
    clearSearchSuggestions,
    resetDiscovery,
    clearNextError,
    saveDBId,
    removeDBId,
    saveSessionId,
} from './combinatorial-discovery.actions';
import {
    getErrors,
    getLoading,
    getLoaded,
    getBlob,
    getSearchSuggestions,
    getSearchSuggestionsLoading,
    getSearchSuggestionsLoaded,
    getDBsIds,
    getNotFoundErrorLoaded,
    getSessionId,
} from './combinatorial-discovery.selectors';
import Filter from '@apps/leap/src/app/shared/modules/filters/interfaces/filter.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';

@Injectable()
export class CombinatorialDiscoveryFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    loading$: Observable<boolean> = this.store.pipe(select(getLoading));
    loaded$: Observable<boolean> = this.store.pipe(select(getLoaded));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    searchSuggestions$: Observable<string[]> = this.store.pipe(select(getSearchSuggestions));
    searchSuggestionsLoading$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoading),
    );
    searchSuggestionsLoaded$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoaded),
    );
    dbsIds$: Observable<string[]> = this.store.pipe(select(getDBsIds));
    notFoundErrorLoaded$: Observable<boolean> = this.store.pipe(select(getNotFoundErrorLoaded));
    sessionId$: Observable<string> = this.store.pipe(select(getSessionId));

    constructor(private store: Store<DataState>) {}

    performDiscovery({
        categories,
        term,
        indexFields,
        sessionId,
    }: {
        categories: string[];
        term: string;
        indexFields?: string[];
        sessionId: string;
    }): void {
        this.store.dispatch(
            performDiscoveryRequest({
                categories,
                term,
                indexFields,
                sessionId,
            }),
        );
    }

    fetchDiscovery({
        limit,
        sortingOptions,
        filters,
        fetchMetadata,
    }: {
        limit?: number;
        sortingOptions?: SortingOptions;
        filters?: Record<string, Filter[] | [number, number]>;
        fetchMetadata?: boolean;
    }): void {
        this.store.dispatch(
            fetchLocalDiscoveryRequest({ limit, sortingOptions, filters, fetchMetadata }),
        );
    }

    downloadDiscovery(categories: string[], term: string, isGrouped: boolean = false): void {
        this.store.dispatch(
            downloadDiscoveryRequest({
                categories,
                term,
                isGrouped,
            }),
        );
    }

    getSearchSuggestions(source: string, target: string, target2: string, query: string): void {
        this.store.dispatch(getSearchSuggestionsRequest({ source, target, target2, query }));
    }

    clearSearchSuggestions(): void {
        this.store.dispatch(clearSearchSuggestions());
    }

    resetDiscovery(): void {
        this.store.dispatch(resetDiscovery());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }

    saveDBId(id: string): void {
        this.store.dispatch(saveDBId({ id }));
    }

    removeDBId(id: string): void {
        this.store.dispatch(removeDBId({ id }));
    }

    saveSessionId(id: string): void {
        this.store.dispatch(saveSessionId({ id }));
    }
}
