/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as projectsActions from './projects.actions';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import { ProjectsState } from './projects-state.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Project from './interfaces/project.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import AlertType from '@leap-store/core/src/lib/ui/alerts/enums/alert-type.enum';

export const adapter: EntityAdapter<Project> = createEntityAdapter<Project>();

export const initialState: ProjectsState = adapter.getInitialState({
    project: null,
    projectsWithBookmarks: [],
    projectsWithoutBookmarks: [],
    projectsLoading: false,
    projectsLoaded: false,
    projectLoading: false,
    projectLoaded: false,
    updatingProject: false,
    deletingProject: false,
    errors: [],
    success: [],
    nameUnavailableError: false,
    shouldFetchProjects: false,
    pageIndex: 0,
    pageSize: 0,
    total: 0,
    blob: null,
});

const projectsReducer: ActionReducer<ProjectsState, Action> = createReducer(
    initialState,
    on(projectsActions.getProjectsRequest, (state: ProjectsState) => ({
        ...state,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.getProjectsSuccess,
        (
            state: ProjectsState,
            {
                paginatedProjects,
            }: {
                paginatedProjects: PaginatedResults<Project>;
            },
        ) =>
            adapter.setAll(paginatedProjects.results, {
                ...state,
                pageIndex: paginatedProjects.pageIndex,
                pageSize: paginatedProjects.pageSize,
                total: paginatedProjects.total,
                projectsLoading: false,
                projectsLoaded: true,
            }),
    ),
    on(
        projectsActions.getProjectsFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            projectsLoading: false,
            projectsLoaded: false,
        }),
    ),
    on(projectsActions.getProjectRequest, (state: ProjectsState) => ({
        ...state,
        project: null as Project,
        projectLoading: true,
        projectLoaded: false,
    })),
    on(
        projectsActions.getProjectSuccess,
        (state: ProjectsState, { project }: { project: Project }) =>
            adapter.upsertOne(project, {
                ...state,
                project,
                projectLoading: false,
                projectLoaded: true,
            }),
    ),
    on(
        projectsActions.getProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            projectLoading: false,
            projectLoaded: false,
        }),
    ),
    on(projectsActions.getProjectsWithBookmarksRequest, (state: ProjectsState) => ({
        ...state,
        projectsWithBookmarks: [] as Project[],
    })),
    on(
        projectsActions.getProjectsWithBookmarksSuccess,
        (state: ProjectsState, { projects }: { projects: Project[] }) => ({
            ...state,
            projectsWithBookmarks: projects,
        }),
    ),
    on(
        projectsActions.getProjectsWithBookmarksFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
        }),
    ),
    on(projectsActions.getProjectsWithoutBookmarksRequest, (state: ProjectsState) => ({
        ...state,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.getProjectsWithoutBookmarksSuccess,
        (
            state: ProjectsState,
            { paginatedProjects }: { paginatedProjects: PaginatedResults<Project> },
        ) => ({
            ...state,
            projectsWithoutBookmarks: paginatedProjects.results,
            total: paginatedProjects.total,
            projectsLoading: false,
            projectsLoaded: true,
        }),
    ),
    on(
        projectsActions.getProjectsWithoutBookmarksFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error: [...state.errors, errorResponse.error],
            projectsLoading: false,
            projectsLoaded: false,
        }),
    ),
    on(projectsActions.createProjectRequest, (state: ProjectsState) => ({
        ...state,
        project: null as Project,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.createProjectSuccess,
        (
            state: ProjectsState,
            { project, suppressAlert }: { project: Project; suppressAlert?: boolean },
        ) =>
            adapter.addOne(project, {
                ...state,
                entities: { ...state.entities, [project.id]: project },
                project,
                success: suppressAlert
                    ? state.success
                    : [
                          ...state.success,
                          {
                              ...project,
                              message: {
                                  type: AlertType.success,
                                  icon: 'check',
                                  messages: [`Project ${project.name} was created successfully`],
                              },
                          },
                      ],
                projectsLoading: false,
                projectsLoaded: true,
            }),
    ),
    on(
        projectsActions.createProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            error:
                errorResponse.status === 409
                    ? [...state.errors]
                    : [...state.errors, errorResponse.error],
            nameUnavailableError: errorResponse.status === 409,
            projectsLoading: false,
            projectsLoaded: errorResponse.status === 409,
        }),
    ),
    on(projectsActions.cloneProjectRequest, (state: ProjectsState) => ({
        ...state,
        project: null as Project,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.cloneProjectSuccess,
        (
            state: ProjectsState,
            { project, originalProjectName }: { project: Project; originalProjectName: string },
        ) =>
            adapter.addOne(project, {
                ...state,
                entities: { ...state.entities, [project.id]: project },
                project,
                success: [
                    ...state.success,
                    {
                        ...project,
                        message: {
                            type: AlertType.success,
                            icon: 'clone',
                            messages: [
                                `Project ${originalProjectName} duplicated to ${project.name}`,
                            ],
                        },
                    },
                ],
                projectsLoading: false,
                projectsLoaded: true,
            }),
    ),
    on(
        projectsActions.cloneProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            projectsLoading: false,
            projectsLoaded: false,
        }),
    ),
    on(projectsActions.updateProjectRequest, (state: ProjectsState) => ({
        ...state,
        project: null as Project,
        updatingProject: true,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.updateProjectSuccess,
        (
            state: ProjectsState,
            { project, successMessage }: { project: Project; successMessage: Alert },
        ) =>
            adapter.upsertOne(project, {
                ...state,
                project,
                shouldFetchProjects: true,
                success: successMessage
                    ? [
                          ...state.success,
                          {
                              ...project,
                              message: {
                                  type: AlertType.success,
                                  icon: successMessage.icon,
                                  messages: [successMessage.messages[0]],
                              },
                          },
                      ]
                    : [...state.success],
                updatingProject: false,
                projectsLoading: false,
                projectsLoaded: true,
            }),
    ),
    on(
        projectsActions.updateProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            nameUnavailableError: errorResponse.status === 409,
            updatingProject: false,
            projectsLoading: false,
            projectsLoaded: false,
        }),
    ),
    on(projectsActions.toggleFavoriteProjectRequest, (state: ProjectsState) => ({
        ...state,
        project: null as Project,
        projectsLoading: true,
        projectsLoaded: false,
    })),
    on(
        projectsActions.toggleFavoriteProjectSuccess,
        (state: ProjectsState, { project }: { project: Project }) =>
            adapter.upsertOne(project, {
                ...state,
                project,
                shouldFetchProjects: true,
                projectsLoading: false,
                projectsLoaded: true,
            }),
    ),
    on(
        projectsActions.toggleFavoriteProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            projectsLoading: false,
            projectsLoaded: false,
        }),
    ),
    on(projectsActions.deleteProjectRequest, (state: ProjectsState) => ({
        ...state,
        deletingProject: true,
        projectsLoading: true,
        projectsLoaded: false,
        projectLoading: true,
        projectLoaded: false,
    })),
    on(
        projectsActions.deleteProjectSuccess,
        (state: ProjectsState, { id, name }: { id: string; name: string }) =>
            adapter.removeOne(id, {
                ...state,
                deletingProject: false,
                shouldFetchProjects: true,
                success: [
                    ...state.success,
                    {
                        ...state.project,
                        message: {
                            type: AlertType.success,
                            icon: 'trash',
                            messages: [`Project ${name} deleted`],
                        },
                    },
                ],
                projectsLoading: false,
                projectsLoaded: true,
                projectLoading: false,
                projectLoaded: true,
            }),
    ),
    on(
        projectsActions.deleteProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            deletingProject: false,
            projectsLoading: false,
            projectsLoaded: false,
            projectLoading: false,
            projectLoaded: false,
        }),
    ),
    on(projectsActions.downloadProjectRequest, (state: ProjectsState) => ({
        ...state,
        blob: null as Blob,
    })),
    on(
        projectsActions.downloadProjectSuccess,
        (state: ProjectsState, { blob }: { blob: Blob }) => ({
            ...state,
            blob,
        }),
    ),
    on(
        projectsActions.downloadProjectFailure,
        (state: ProjectsState, { errorResponse }: { errorResponse: HttpErrorResponse }) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
        }),
    ),
    on(projectsActions.clearNextSuccess, (state: ProjectsState) => ({
        ...state,
        success: state.success.slice(1),
    })),
    on(projectsActions.clearNextError, (state: ProjectsState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),
    on(projectsActions.clearNameUnavailableError, (state: ProjectsState) => ({
        ...state,
        nameUnavailableError: false,
    })),
    on(projectsActions.clearShouldFetchProjects, (state: ProjectsState) => ({
        ...state,
        shouldFetchProjects: false,
    })),
);

export const reducer = (state: ProjectsState | undefined, action: Action): ProjectsState =>
    projectsReducer(state, action);

// selectors
export const getEntities: (state: ProjectsState) => Project[] = adapter.getSelectors().selectAll;
export const getProject: (state: ProjectsState) => Project = (state: ProjectsState) =>
    state.project;
export const getProjectsWithBookmarks: (state: ProjectsState) => Project[] = (
    state: ProjectsState,
) => state.projectsWithBookmarks;
export const getProjectsWithoutBookmarks: (state: ProjectsState) => Project[] = (
    state: ProjectsState,
) => state.projectsWithoutBookmarks;
export const getProjectsLoading: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.projectsLoading;
export const getProjectsLoaded: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.projectsLoaded;
export const getProjectLoading: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.projectLoading;
export const getProjectLoaded: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.projectLoaded;
export const getUpdatingProject: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.updatingProject;
export const getDeletingProject: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.deletingProject;
export const getNameUnavailableError: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.nameUnavailableError;
export const getShouldFetchProjects: (state: ProjectsState) => boolean = (state: ProjectsState) =>
    state.shouldFetchProjects;
export const getErrors: (state: ProjectsState) => ErrorResponse[] = (state: ProjectsState) =>
    state.errors;
export const getSuccess: (state: ProjectsState) => Project[] = (state: ProjectsState) =>
    state.success;
export const getPageIndex: (state: ProjectsState) => number = (state: ProjectsState) =>
    state.pageIndex;
export const getPageSize: (state: ProjectsState) => number = (state: ProjectsState) =>
    state.pageSize;
export const getTotal: (state: ProjectsState) => number = (state: ProjectsState) => state.total;
export const getBlob: (state: ProjectsState) => Blob = (state: ProjectsState) => state.blob;
