import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'lib-preloader',
    templateUrl: 'preloader.component.html',
    styleUrls: ['preloader.component.scss'],
})
export class PreloaderComponent implements OnInit {
    @Input() text: string;

    dots: number[] = [];

    constructor() {}

    ngOnInit(): void {
        for (let i = 0; i < 21; i += 1) {
            this.dots.push(i);
        }
    }
}
