import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '..';
import { FilesEffects } from './files.effects';
import { FilesService } from './services/files.service';
import { FilesFacade } from './files.facade';
import { FilesParser } from './parsers/files.parser';
import { FilesUtilitiesService } from './services/files-utilities.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([FilesEffects]),
    ],
    declarations: [],
    providers: [FilesService, FilesFacade, FilesParser, FilesUtilitiesService],
})
export class FilesStoreModule {}
