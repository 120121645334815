/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** Interfaces - Enums */
import { ContentWriterActionTypes } from './content-writer-action-types.enum';
import FileExtension from '@leap-common/enums/file-extension.enum';
import ContentWriterFormatId from './enums/format-id.enum';
import GeneratedContent from './interfaces/generated-content.interface';
import Configuration from './interfaces/configuration.interface';

export const getTopicsRequest = createAction(
    ContentWriterActionTypes.GET_TOPICS_REQUEST,
    props<{ externalIds: string[] }>(),
);

export const getTopicsSuccess = createAction(
    ContentWriterActionTypes.GET_TOPICS_SUCCESS,
    props<{ topics: string[] }>(),
);

export const getTopicsFailure = createAction(
    ContentWriterActionTypes.GET_TOPICS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getAllContentRequest = createAction(
    ContentWriterActionTypes.GET_ALL_CONTENT_REQUEST,
    props<{
        externalIds: string[];
        configurationPerFormat: Record<ContentWriterFormatId, Configuration>;
    }>(),
);

export const getAllContentSuccess = createAction(
    ContentWriterActionTypes.GET_ALL_CONTENT_SUCCESS,
    props<{ longContent: GeneratedContent; shortContent: GeneratedContent }>(),
);

export const getAllContentFailure = createAction(
    ContentWriterActionTypes.GET_ALL_CONTENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getLongContentRequest = createAction(
    ContentWriterActionTypes.GET_LONG_CONTENT_REQUEST,
    props<{ externalIds: string[]; configuration: Configuration }>(),
);

export const getLongContentSuccess = createAction(
    ContentWriterActionTypes.GET_LONG_CONTENT_SUCCESS,
    props<{ longContent: GeneratedContent }>(),
);

export const getLongContentFailure = createAction(
    ContentWriterActionTypes.GET_LONG_CONTENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getShortContentRequest = createAction(
    ContentWriterActionTypes.GET_SHORT_CONTENT_REQUEST,
    props<{ longContentText: string; configuration: Configuration }>(),
);

export const getShortContentSuccess = createAction(
    ContentWriterActionTypes.GET_SHORT_CONTENT_SUCCESS,
    props<{ shortContent: GeneratedContent }>(),
);

export const getShortContentFailure = createAction(
    ContentWriterActionTypes.GET_SHORT_CONTENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadContentRequest = createAction(
    ContentWriterActionTypes.DOWNLOAD_CONTENT_REQUEST,
    props<{ extension: FileExtension; longContentText: string; shortContentText: string }>(),
);

export const downloadContentSuccess = createAction(
    ContentWriterActionTypes.DOWNLOAD_CONTENT_SUCCESS,
    props<{ contentBlob: Blob }>(),
);

export const downloadContentFailure = createAction(
    ContentWriterActionTypes.DOWNLOAD_CONTENT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);
