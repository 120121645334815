import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';

/** Interfaces - Types */
import PaginatedMetapathsRestApi from '../rest-api-interfaces/paginated-metapaths.rest.interface';
import PaginatedMetapaths from '../interfaces/paginated-metapaths.interface';
import MetapathRestApi from '../rest-api-interfaces/metapath.rest.interface';
import Metapath from '../interfaces/metapath.interface';
import PathSegmentEdge from '../../paths/interfaces/path-segment-edge.interface';
import MetapathSegmentNode from '../interfaces/metapath-segment-node.interface';
import MetapathSegments from '../types/metapath-segments.type';

@Injectable()
export class MetapathsParser {
    constructor() {}

    /** Parses Paginated Metapaths */
    parsePaginatedMetapaths(paginatedMetapaths: PaginatedMetapathsRestApi): PaginatedMetapaths {
        return {
            results: paginatedMetapaths.results
                ? this.parseMetapaths(paginatedMetapaths.results)
                : [],
            pageSize: paginatedMetapaths.pageSize,
            pageIndex: paginatedMetapaths.pageIndex,
            queryStatus: paginatedMetapaths.queryStatus,
            estimatedDuration: paginatedMetapaths.estimatedDuration,
            total: paginatedMetapaths.total,
        };
    }

    /** Parses BE Metapaths to integrate them on the FE */
    parseMetapaths(metapaths: MetapathRestApi[]): Metapath[] {
        return metapaths.map((metapath: MetapathRestApi) => this.parseMetapath(metapath));
    }

    /** Parses MetapathRestApi to Metapath */
    parseMetapath(metapath: MetapathRestApi): Metapath {
        return {
            id: this.getUuid(),
            basePath: {
                id: this.getUuid(),
                segments: this.parseMetapathSegments(metapath),
            },
            score: metapath.DWPC,
        };
    }

    /**
     * Parses MetapathRestApi to MetapathSegments.
     * Last node has no associated edge hence we assign `null` for the edge.
     */
    parseMetapathSegments(metapath: MetapathRestApi): MetapathSegments {
        return [
            {
                node: this.parseMetapathNodeSegment(
                    metapath.categories.source,
                    metapath.subcategories.source,
                ),
                edge: this.parseEdgeSegment(metapath.relations.source),
            },
            {
                node: this.parseMetapathNodeSegment(
                    metapath.categories.intermediate,
                    metapath.subcategories.intermediate,
                ),
                edge: this.parseEdgeSegment(metapath.relations.target),
            },
            {
                node: this.parseMetapathNodeSegment(
                    metapath.categories.target,
                    metapath.subcategories.target,
                ),
                edge: null,
            },
        ];
    }

    /**
     * Creates a metapath node segment from a category and associated array of subcategories
     */
    parseMetapathNodeSegment(
        category: string,
        subcategories: string | string[],
    ): MetapathSegmentNode {
        return {
            category,
            subcategories: Array.isArray(subcategories)
                ? subcategories
                : subcategories
                ? [subcategories]
                : null,
        };
    }

    /**
     * Creates an edge path segment from a relation string
     */
    parseEdgeSegment(relation: string): PathSegmentEdge {
        return {
            relation,
        };
    }

    getUuid(): string {
        return uuid();
    }
}
