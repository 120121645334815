/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../index';
import { BookmarksEffects } from './bookmarks.effects';
import { BookmarksFacade } from './bookmarks.facade';
import { BookmarksParser } from './parsers/bookmarks.parser';
import { PollingService } from '@leap-common/services/polling.service';
import { BookmarksService } from './services/bookmarks.service';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([BookmarksEffects]),
    ],
    declarations: [],
    providers: [
        BookmarksParser,
        PollingService,
        BookmarksService,
        BookmarksFacade,
        DiscoveryParser,
    ],
})
export class BookmarksStoreModule {}
