/** third-party imports */
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

/** custom imports */
import { reducers } from '../../index';
import { NotebookServersEffects } from './notebook-servers.effects';
import { NotebookServersFacade } from './notebook-servers.facade';
import { NotebookServersParser } from './parsers/notebook-servers.parser';
import { NotebookServersService } from './services/notebook-servers.service';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({ maxAge: 10 }),
        EffectsModule.forRoot([NotebookServersEffects]),
    ],
    declarations: [],
    providers: [NotebookServersParser, NotebookServersService, NotebookServersFacade],
})
export class NotebookServersStoreModule {}
