import { Directive, Input, ElementRef, OnDestroy, AfterViewInit, OnChanges } from '@angular/core';

@Directive({
    selector: '[libEventInhibitor]',
})
export class EventInhibitorDirective implements OnChanges, AfterViewInit, OnDestroy {
    /**
     * The event type that is going to be inhibited
     */
    @Input() inhibitedEventType: string;
    /**
     * Optional selector that refines the target element where the inhibitor will be
     * added. If not provided the host element will be used.
     */
    @Input() targetSelector: string;
    /**
     * Optional callback that provides the criterion by which the event should
     * be inhibited or allowed. If not provided the event is always going to be inhibited.
     *
     * @return a return value of `true` means that the event is going to be prevented and `false`
     * that the restrictions of `interceptEvent()` will not be applied
     */
    @Input() shouldPreventCallback: (event: Event) => boolean = (): boolean => true;
    /**
     * Whether the inhibitor listener is going to use the capture phase or not
     */
    @Input() useCapturePhase: boolean = false;
    /**
     * Whether the intercepted event should disable further processing on parent element listeners of
     * the same event in the same event phase;
     * by default only `stopPropagation()` is executed
     */
    @Input() shouldStopImmediatePropagation: boolean;

    constructor(private elementRef: ElementRef) {}

    /**
     * the arguments are cached because when unregistering the event listener
     * they play a significant role in pinpointing the exact listener to be removed
     */
    eventListenerCallArguments: [string, (event: Event) => void, boolean];
    targetElement: Element;

    ngOnChanges(): void {
        this.removeInhibitorCallback();
        this.addInhibitorCallback();
    }

    ngAfterViewInit(): void {
        this.targetElement = this.targetSelector
            ? this.elementRef.nativeElement.querySelector(this.targetSelector)
            : this.elementRef.nativeElement;
        this.addInhibitorCallback();
    }

    ngOnDestroy(): void {
        this.removeInhibitorCallback();
    }

    addInhibitorCallback(): void {
        if (!this.inhibitedEventType || !this.targetElement) {
            return;
        }
        this.eventListenerCallArguments = [
            this.inhibitedEventType,
            this.inhibitEvent,
            this.useCapturePhase,
        ];
        this.targetElement.addEventListener(...this.eventListenerCallArguments);
    }

    removeInhibitorCallback(): void {
        if (!Array.isArray(this.eventListenerCallArguments)) {
            return;
        }
        this.targetElement?.removeEventListener(...this.eventListenerCallArguments);
        this.eventListenerCallArguments = null;
    }

    inhibitEvent = (event: Event): void => {
        const shouldPrevent: boolean = this.shouldPreventCallback(event);
        if (!shouldPrevent) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        if (this.shouldStopImmediatePropagation) {
            event.stopImmediatePropagation();
        }
    };
}
