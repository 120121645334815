import { Injectable } from '@angular/core';
import { flatten, groupBy, uniq } from 'lodash';

/** Constants */
import { COMMA_DELIMITER } from '@leap-common/constants/delimiters';
import {
    FILE_UPLOAD_DUPLICATE_AMINO_ACIDS_INGREDIENT_ERROR,
    FILE_UPLOAD_EMPTY_TABS_INGREDIENT_ERROR,
    FILE_UPLOAD_INVALID_FILE_ERROR,
    FILE_UPLOAD_INVALID_INGREDIENTS_ERROR,
} from '../constants/file-upload';

/** Services - Parsers */
import { FormatterService } from '@leap-common/services/formatter.service';
import { IngredientViewerParser } from '@leap-store/core/src/lib/data/ingredient-viewer/parsers/ingredient-viewer.parser';

/** Interfaces - Enums */
import FileStatus from '@leap-store/core/src/lib/data/files/enums/file-status.enum';
import FileUploadErrorRestApi from '../rest-api-interfaces/file-upload-error.rest.interface';
import FileUploadError from '../enums/file-upload-error.enum';
import FileUploadResultRestApi from '../rest-api-interfaces/file-upload-result.rest.interface';
import FileUploadResult from '../interfaces/file-upload-result.interface';

@Injectable()
export class IngredientManagementParser {
    constructor(
        private formatterService: FormatterService,
        private ingredientViewerParser: IngredientViewerParser,
    ) {}

    parseFileUploadResult(result: FileUploadResultRestApi): FileUploadResult {
        return {
            ingredients: this.ingredientViewerParser.parseIngredients(result.results),
            status: this.parseFileUploadStatus(result.status),
            messages: this.parseFileUploadErrors(result.errors),
        };
    }

    parseFileUploadStatus(status: string): FileStatus {
        return status?.toLowerCase() === FileStatus.success ? FileStatus.success : FileStatus.error;
    }

    parseFileUploadErrors(errorsPerTab: Record<string, FileUploadErrorRestApi[]>): string[] {
        if (!errorsPerTab) {
            return [];
        }

        const errors: FileUploadErrorRestApi[] = flatten(Object.values(errorsPerTab));
        const invalidFileErrors: string[] = this.parseInvalidFileErrors(errors);
        const invalidIngredientsErrors: string[] = this.parseInvalidIngredientsErrors(errors);
        const errorsPerIngredient: string[] = this.parseErrorsPerIngredient(errors);

        return uniq([...invalidFileErrors, ...invalidIngredientsErrors, ...errorsPerIngredient]);
    }

    parseInvalidFileErrors(errors: FileUploadErrorRestApi[]): string[] {
        const invalidFileErrors: FileUploadErrorRestApi[] = errors.filter(
            ({ ingredient }: FileUploadErrorRestApi) => !ingredient,
        );
        return invalidFileErrors.length ? [FILE_UPLOAD_INVALID_FILE_ERROR] : [];
    }

    parseInvalidIngredientsErrors(errors: FileUploadErrorRestApi[]): string[] {
        const invalidIngredients: string[] = uniq(
            errors
                .filter(
                    ({ reason }: FileUploadErrorRestApi) =>
                        reason === FileUploadError.invalidIngredient,
                )
                .map(({ ingredient }: FileUploadErrorRestApi) => ingredient),
        );

        return invalidIngredients.length
            ? [
                  this.formatterService.formatString(
                      FILE_UPLOAD_INVALID_INGREDIENTS_ERROR,
                      invalidIngredients.join(COMMA_DELIMITER),
                  ),
              ]
            : [];
    }

    parseErrorsPerIngredient(errors: FileUploadErrorRestApi[]): string[] {
        const parsedErrors: string[] = [];
        const errorsPerIngredient: Record<string, FileUploadErrorRestApi[]> = groupBy(
            errors.filter(
                ({ ingredient, reason }: FileUploadErrorRestApi) =>
                    ingredient && reason !== FileUploadError.invalidIngredient,
            ),
            'ingredient',
        );

        Object.entries(errorsPerIngredient).forEach(
            ([ingredient, ingredientErrors]: [string, FileUploadErrorRestApi[]]) => {
                if (
                    ingredientErrors.some(
                        ({ reason }: FileUploadErrorRestApi) =>
                            reason === FileUploadError.duplicateAminoAcids,
                    )
                ) {
                    const duplicateAminoAcidsError: string = this.formatterService.formatString(
                        FILE_UPLOAD_DUPLICATE_AMINO_ACIDS_INGREDIENT_ERROR,
                        ingredient,
                    );
                    parsedErrors.push(duplicateAminoAcidsError);
                }

                const emptyTabsErrors: FileUploadErrorRestApi[] = ingredientErrors.filter(
                    ({ reason }: FileUploadErrorRestApi) => reason === FileUploadError.emptyTabs,
                );

                if (emptyTabsErrors.length) {
                    const emptyTabs: string = emptyTabsErrors
                        .map(({ sheet }: FileUploadErrorRestApi) => sheet)
                        .join(COMMA_DELIMITER);
                    const emptyTabsError: string = this.formatterService.formatString(
                        FILE_UPLOAD_EMPTY_TABS_INGREDIENT_ERROR,
                        ingredient,
                        emptyTabs,
                    );
                    parsedErrors.push(emptyTabsError);
                }
            },
        );

        return parsedErrors;
    }

    serializeSortingField(field: string): string {
        return field === 'name' ? 'name' : field === 'uploadedAt' ? 'created_at' : undefined;
    }
}
