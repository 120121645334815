import { Injectable } from '@angular/core';

/** Interfaces */
import NotebookServerRestApi from '../rest-api-interfaces/notebook-server.rest.interface';
import NotebookServer from '../interfaces/notebook-server.interface';
import InitiateNotebookServerRestApi from '../rest-api-interfaces/initiate-notebook-server.rest.interface';

@Injectable()
export class NotebookServersParser {
    constructor() {}

    /** Parses NotebookServerRestApi to NotebookServer */
    parseNotebookServers(
        notebookServers: Record<string, NotebookServerRestApi>,
    ): Record<string, NotebookServer> {
        return Object.entries(notebookServers).reduce((res, [key, value]) => {
            res[key] = this.parseNotebookServer(value);
            return res;
        }, {} as any);
    }

    /** Parses NotebookServerRestApi to NotebookServer */
    parseNotebookServer(notebookServer: NotebookServerRestApi): NotebookServer {
        return {
            name: notebookServer.name,
            url: notebookServer.url,
            creationDate: notebookServer.created_on,
            notebookUrl: notebookServer.notebook_url,
            progressUrl: notebookServer.progress_url,
            startDate: notebookServer.started,
            lastActivity: notebookServer.last_activity,
            pending: notebookServer.pending,
            isReady: notebookServer.ready,
            state: {
                objectId: notebookServer.state.object_id,
            },
            userOptions: notebookServer.user_options,
        };
    }

    /** Accepts refreshToken param and serializes it to InitiateNotebookServerRestApi */
    serializeInitiateNotebookServer(refreshToken: string): InitiateNotebookServerRestApi {
        return { refresh_token: refreshToken };
    }
}
