/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { NotebooksParser } from '../parsers/notebooks.parser';

/** Interfaces - Enums */
import PaginatedResultsRestApi from '@leap-common/rest-api-interfaces/paginated-results.rest.interface';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import NotebookRestApi from '../rest-api-interfaces/notebook.rest.interface';
import Notebook from '../interfaces/notebook.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

@Injectable()
export class NotebooksService {
    constructor(private http: HttpClient, private notebooksParser: NotebooksParser) {}

    /**
     * Gets user notebooks, parses them into the desired format and
     * returns an Observable of PaginatedResults<Notebook>.
     */
    getNotebooks(
        pageIndex: number,
        pageSize: number,
        sortDirection: SortingOrder,
        sortColumn: string,
        type: string,
    ): Observable<{
        paginatedNotebooks: PaginatedResults<Notebook>;
        type: string;
    }> {
        const serializedOrder: string = this.notebooksParser.serializeNotebooksOrderByParameter(
            sortDirection,
            sortColumn,
        );

        return this.http
            .get(`${environment.notebookServerUrl}/notebooks/`, {
                params: {
                    pageIndex: (pageIndex + 1).toString(),
                    pageSize: pageSize.toString(),
                    orderBy: serializedOrder,
                    type,
                },
            })
            .pipe(
                map((paginatedResults: PaginatedResultsRestApi<NotebookRestApi>) => ({
                    paginatedNotebooks:
                        this.notebooksParser.parsePaginatedResults(paginatedResults),
                    type,
                })),
            );
    }

    /**
     * Gets a specific notebook, parses it into the desired format and
     * returns an Observable of Notebook.
     */
    getNotebook(id: string): Observable<Notebook> {
        return this.http
            .get(`${environment.notebookServerUrl}/notebooks/${id}/`)
            .pipe(map((notebook: NotebookRestApi) => this.notebooksParser.parseNotebook(notebook)));
    }

    /**
     * Sends the notebook name to server in order to create a new notebook and
     * maps the response to the desired format. It returns an Observable of Notebook.
     */
    createNotebook(name?: string): Observable<Notebook> {
        return this.http
            .post(`${environment.notebookServerUrl}/notebooks/`, { name })
            .pipe(map((notebook: NotebookRestApi) => this.notebooksParser.parseNotebook(notebook)));
    }

    /**
     * Sends the notebook template id to server in order to clone it and
     * maps the response to the desired format. It returns an Observable of Notebook.
     */
    cloneNotebook(id: string): Observable<Notebook> {
        return this.http
            .post(`${environment.notebookServerUrl}/notebooks/`, { copy_from: id })
            .pipe(map((notebook: NotebookRestApi) => this.notebooksParser.parseNotebook(notebook)));
    }

    /**
     * Sends the notebook name to server in order to update an existing notebook and
     * maps the response to the desired format. It returns an Observable of Notebook.
     */
    updateNotebook(id: string, name: string): Observable<Notebook> {
        return this.http
            .patch(`${environment.notebookServerUrl}/notebooks/${id}/`, { name })
            .pipe(map((notebook: NotebookRestApi) => this.notebooksParser.parseNotebook(notebook)));
    }

    /**
     * Makes a call with the notebook id to server in order to delete the specific notebook and
     * maps the response to the desired format. It returns an Observable of void.
     */
    deleteNotebook(id: string): Observable<void> {
        return this.http
            .delete(`${environment.notebookServerUrl}/notebooks/${id}/`)
            .pipe(map(() => undefined));
    }
}
