import { createSelector } from '@ngrx/store';
import { DataState } from '../data-state.interface';
import { getDataState } from '../index';
import * as fromGroups from './groups.reducer';

export const getGroupsState = createSelector(getDataState, (state: DataState) => state.groups);

export const getGroupsErrors = createSelector(getGroupsState, fromGroups.getGroupsErrors);
export const getGroupsSuccess = createSelector(getGroupsState, fromGroups.getGroupsSuccess);
export const getGroupsList = createSelector(getGroupsState, fromGroups.getGroupsList);
export const getDepartmentsList = createSelector(getGroupsState, fromGroups.getDepartmentsList);
export const getCreatedDepartment = createSelector(getGroupsState, fromGroups.getCreatedDepartment);
export const getSearchedDepartments = createSelector(
    getGroupsState,
    fromGroups.getSearchedDepartments,
);
export const getGroupsLoading = createSelector(getGroupsState, fromGroups.getGroupsLoading);
export const getGroupsLoaded = createSelector(getGroupsState, fromGroups.getGroupsLoaded);
export const getDeletePendingItems = createSelector(
    getGroupsState,
    fromGroups.getDeletePendingItems,
);
export const getShouldFetchDepartments = createSelector(
    getGroupsState,
    fromGroups.getShouldFetchDepartments,
);
