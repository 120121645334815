/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { PatentsService } from './services/patents.service';
import * as actions from './patents.actions';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import PaginatedPatents from './interfaces/paginated-patents.interface';

@Injectable()
export class PatentsEffects {
    constructor(private actions$: Actions, private patentsService: PatentsService) {}

    getPatents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getPatentsRequest),
            switchMap(
                ({
                    id,
                    sortingOptions,
                    startYear,
                    endYear,
                    pageSize,
                    pageIndex,
                }: {
                    id: string;
                    sortingOptions?: SortingOptions;
                    startYear?: number;
                    endYear?: number;
                    pageSize?: number;
                    pageIndex?: number;
                }) =>
                    this.patentsService
                        .getPatents(id, sortingOptions, startYear, endYear, pageSize, pageIndex)
                        .pipe(
                            map((paginatedPatents: PaginatedPatents) =>
                                actions.getPatentsSuccess({ paginatedPatents }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getPatentsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadPatents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadPatentsRequest),
            switchMap(({ id, sortingOptions, startYear, endYear }) =>
                this.patentsService.downloadPatents(id, sortingOptions, startYear, endYear).pipe(
                    map((blob: Blob) => actions.downloadPatentsSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadPatentsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
