import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { PathsActionTypes } from './paths-action-types.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplorationPath from './interfaces/exploration-path.interface';
import MetapathBaseExplorationPath from '../metapaths/interfaces/metapath-base-exploration-path.interface';
import SortingOrder from '@leap-common/enums/sorting-order.enum';

export const getPathsRequest = createAction(
    PathsActionTypes.GET_PATHS_REQUEST,
    props<{
        parentInsightId: string;
        parentMetapathIndex: number;
        sourceId: string;
        targetId: string;
        metapathBaseExplorationPath: MetapathBaseExplorationPath;
        pageSize: number;
        pageIndex: number;
        sortingOrder: SortingOrder;
    }>(),
);

export const getPathsSuccess = createAction(
    PathsActionTypes.GET_PATHS_SUCCESS,
    props<{
        parentInsightId: string;
        parentMetapathIndex: number;
        paginatedPaths: PaginatedResults<ExplorationPath>;
        sortingOrder: SortingOrder;
    }>(),
);

export const getPathsFailure = createAction(
    PathsActionTypes.GET_PATHS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadPathsRequest = createAction(
    PathsActionTypes.DOWNLOAD_PATHS_REQUEST,
    props<{
        filename: string;
        sourceId: string;
        targetId: string;
        metapathBaseExplorationPath: MetapathBaseExplorationPath;
        sortingOrder: SortingOrder;
    }>(),
);

export const downloadPathsSuccess = createAction(
    PathsActionTypes.DOWNLOAD_PATHS_SUCCESS,
    props<{
        blob: Blob;
        filename: string;
    }>(),
);

export const downloadPathsFailure = createAction(
    PathsActionTypes.DOWNLOAD_PATHS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const clearNextError = createAction(PathsActionTypes.CLEAR_NEXT_ERROR);
