/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { NotificationsService } from './services/notifications.service';
import * as NotificationsActions from './notifications.actions';
import Message from './interfaces/message.interface';
import MessageData from './interfaces/message-data.interface';

@Injectable()
export class NotificationsEffects {
    constructor(private actions$: Actions, private notificationsService: NotificationsService) {}

    sendMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsActions.sendMessageRequest),
            switchMap(({ name, data }: { name: string; data: MessageData }) =>
                this.notificationsService.sendMessage(name, data).pipe(
                    map((message: Message) => NotificationsActions.sendMessageSuccess({ message })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotificationsActions.sendMessageFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
