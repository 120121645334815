/** third-party imports */
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import { ArticlesReportActionTypes } from './articles-report-action-types.enum';
import Associations from './interfaces/associations.interface';
import KeyFinding from './interfaces/key-finding.interface';
import TheoreticalFrameworkItem from './interfaces/theoretical-framework-item.interface';
import BookmarkArticle from '../bookmarks/types/article.type';
import Reference from '../report/interfaces/reference.interface';
import Summary from '../report/interfaces/summary.interface';

export const getOverviewRequest = createAction(
    ArticlesReportActionTypes.GET_OVERVIEW_REQUEST,
    props<{ projectId: string; bookmarkIds: string[]; shouldRegenerate: boolean }>(),
);

export const getOverviewSuccess = createAction(
    ArticlesReportActionTypes.GET_OVERVIEW_SUCCESS,
    props<{ overview: string }>(),
);

export const getOverviewFailure = createAction(
    ArticlesReportActionTypes.GET_OVERVIEW_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getAssociationsRequest = createAction(
    ArticlesReportActionTypes.GET_ASSOCIATIONS_REQUEST,
    props<{ projectId: string; bookmarkIds: string[] }>(),
);

export const getAssociationsSuccess = createAction(
    ArticlesReportActionTypes.GET_ASSOCIATIONS_SUCCESS,
    props<{ associations: Associations }>(),
);

export const getAssociationsFailure = createAction(
    ArticlesReportActionTypes.GET_ASSOCIATIONS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getKeyFindingsRequest = createAction(
    ArticlesReportActionTypes.GET_KEY_FINDINGS_REQUEST,
    props<{ projectId: string; bookmarks: BookmarkArticle[]; shouldRegenerate: boolean }>(),
);

export const getKeyFindingsSuccess = createAction(
    ArticlesReportActionTypes.GET_KEY_FINDINGS_SUCCESS,
    props<{ keyFindings: KeyFinding[] }>(),
);

export const getKeyFindingsFailure = createAction(
    ArticlesReportActionTypes.GET_KEY_FINDINGS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTermSummariesRequest = createAction(
    ArticlesReportActionTypes.GET_TERM_SUMMARIES_REQUEST,
    props<{ projectId: string; bookmarkIds: string[]; shouldRegenerate: boolean }>(),
);

export const getTermSummariesSuccess = createAction(
    ArticlesReportActionTypes.GET_TERM_SUMMARIES_SUCCESS,
    props<{ termSummaries: Summary[] }>(),
);

export const getTermSummariesFailure = createAction(
    ArticlesReportActionTypes.GET_TERM_SUMMARIES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getCriticalAnalysisRequest = createAction(
    ArticlesReportActionTypes.GET_CRITICAL_ANALYSIS_REQUEST,
    props<{ projectId: string; bookmarkIds: string[]; shouldRegenerate: boolean }>(),
);

export const getCriticalAnalysisSuccess = createAction(
    ArticlesReportActionTypes.GET_CRITICAL_ANALYSIS_SUCCESS,
    props<{ criticalAnalysis: string }>(),
);

export const getCriticalAnalysisFailure = createAction(
    ArticlesReportActionTypes.GET_CRITICAL_ANALYSIS_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getTheoreticalFrameworkRequest = createAction(
    ArticlesReportActionTypes.GET_THEORETICAL_FRAMEWORK_REQUEST,
    props<{ projectId: string; bookmarks: BookmarkArticle[]; shouldRegenerate: boolean }>(),
);

export const getTheoreticalFrameworkSuccess = createAction(
    ArticlesReportActionTypes.GET_THEORETICAL_FRAMEWORK_SUCCESS,
    props<{ theoreticalFrameworkItems: TheoreticalFrameworkItem[] }>(),
);

export const getTheoreticalFrameworkFailure = createAction(
    ArticlesReportActionTypes.GET_THEORETICAL_FRAMEWORK_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const getReferencesRequest = createAction(
    ArticlesReportActionTypes.GET_REFERENCES_REQUEST,
    props<{ projectId: string; bookmarkIds: string[] }>(),
);

export const getReferencesSuccess = createAction(
    ArticlesReportActionTypes.GET_REFERENCES_SUCCESS,
    props<{ references: Reference[] }>(),
);

export const getReferencesFailure = createAction(
    ArticlesReportActionTypes.GET_REFERENCES_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const downloadReportRequest = createAction(
    ArticlesReportActionTypes.DOWNLOAD_REPORT_REQUEST,
    props<{ projectId: string; bookmarkIds: string[] }>(),
);

export const downloadReportSuccess = createAction(
    ArticlesReportActionTypes.DOWNLOAD_REPORT_SUCCESS,
    props<{ blob: Blob }>(),
);

export const downloadReportFailure = createAction(
    ArticlesReportActionTypes.DOWNLOAD_REPORT_FAILURE,
    props<{ errorResponse: HttpErrorResponse }>(),
);

export const resetReport = createAction(ArticlesReportActionTypes.RESET_REPORT);

export const clearNextError = createAction(ArticlesReportActionTypes.CLEAR_NEXT_ERROR);
