/** third-party imports */
import { createReducer, on, Action, ActionReducer } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

/** custom imports */
import * as ingredientViewerActions from './ingredient-viewer.actions';
import { IngredientViewerState } from './ingredient-viewer-state.interface';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Ingredient from './interfaces/ingredient.interface';
import Statistics from './interfaces/statistics.interface';

export const initialState: IngredientViewerState = {
    errors: [],
    ingredients: [],
    ingredientsLoading: false,
    ingredientsLoaded: false,
    ingredient: null,
    ingredientLoading: false,
    ingredientLoaded: false,
    statistics: null,
    statisticsLoading: false,
    statisticsLoaded: false,
};

const ingredientViewer: ActionReducer<IngredientViewerState, Action> = createReducer(
    initialState,
    on(ingredientViewerActions.getIngredientsRequest, (state: IngredientViewerState) => ({
        ...state,
        ingredientsLoading: true,
        ingredientsLoaded: false,
    })),
    on(
        ingredientViewerActions.getIngredientsSuccess,
        (state: IngredientViewerState, { ingredients }: { ingredients: Ingredient[] }) => ({
            ...state,
            ingredients,
            ingredientsLoading: false,
            ingredientsLoaded: true,
        }),
    ),
    on(
        ingredientViewerActions.getIngredientsFailure,
        (
            state: IngredientViewerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            ingredientsLoading: false,
            ingredientsLoaded: false,
        }),
    ),
    on(ingredientViewerActions.getIngredientRequest, (state: IngredientViewerState) => ({
        ...state,
        ingredientLoading: true,
        ingredientLoaded: false,
    })),
    on(
        ingredientViewerActions.getIngredientSuccess,
        (state: IngredientViewerState, { ingredient }: { ingredient: Ingredient }) => ({
            ...state,
            ingredient,
            ingredientLoading: false,
            ingredientLoaded: true,
        }),
    ),
    on(
        ingredientViewerActions.getIngredientFailure,
        (
            state: IngredientViewerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            ingredientLoading: false,
            ingredientLoaded: false,
        }),
    ),
    on(ingredientViewerActions.clearIngredients, (state: IngredientViewerState) => ({
        ...state,
        ingredients: [] as Ingredient[],
        ingredientsLoading: false,
        ingredientsLoaded: false,
    })),
    on(ingredientViewerActions.clearNextError, (state: IngredientViewerState) => ({
        ...state,
        errors: state.errors.slice(1),
    })),

    on(ingredientViewerActions.getStatisticsRequest, (state: IngredientViewerState) => ({
        ...state,
        statisticsLoading: true,
        statisticsLoaded: false,
    })),
    on(
        ingredientViewerActions.getStatisticsSuccess,
        (state: IngredientViewerState, { statistics }: { statistics: Statistics }) => ({
            ...state,
            statistics,
            statisticsLoading: false,
            statisticsLoaded: true,
        }),
    ),
    on(
        ingredientViewerActions.getStatisticsFailure,
        (
            state: IngredientViewerState,
            { errorResponse }: { errorResponse: HttpErrorResponse },
        ) => ({
            ...state,
            errors: [...state.errors, errorResponse.error],
            statisticsLoading: false,
            statisticsLoaded: false,
        }),
    ),
);

export const reducer = (
    state: IngredientViewerState | undefined,
    action: Action,
): IngredientViewerState => ingredientViewer(state, action);

// selectors
export const getErrors: (state: IngredientViewerState) => ErrorResponse[] = (
    state: IngredientViewerState,
) => state.errors;
export const getIngredients: (state: IngredientViewerState) => Ingredient[] = (
    state: IngredientViewerState,
) => state.ingredients;
export const getIngredientsLoading: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.ingredientsLoading;
export const getIngredientsLoaded: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.ingredientsLoaded;
export const getIngredient: (state: IngredientViewerState) => Ingredient = (
    state: IngredientViewerState,
) => state.ingredient;
export const getIngredientLoading: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.ingredientLoading;
export const getIngredientLoaded: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.ingredientLoaded;
export const getStatistics: (state: IngredientViewerState) => Statistics = (
    state: IngredientViewerState,
) => state.statistics;
export const getStatisticsLoading: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.statisticsLoading;
export const getStatisticsLoaded: (state: IngredientViewerState) => boolean = (
    state: IngredientViewerState,
) => state.statisticsLoaded;
