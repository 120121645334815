import { createAction, props } from '@ngrx/store';
import { IngredientProfilerActionTypes } from './ingredient-profiler-action-types.enum';
import TabId from '@apps/leap/src/app/shared/enums/tab-id.enum';
import DiscoveryExecution from '../discovery/interfaces/discovery-execution.interface';
import DiscoverySummary from '../discovery/interfaces/discovery-summary.interface';

export const updateActiveTabId = createAction(
    IngredientProfilerActionTypes.UPDATE_ACTIVE_TAB_ID,
    props<{ id: TabId }>(),
);

export const updateExecution = createAction(
    IngredientProfilerActionTypes.UPDATE_EXECUTION,
    props<{ execution: DiscoveryExecution }>(),
);

export const updateSummary = createAction(
    IngredientProfilerActionTypes.UPDATE_SUMMARY,
    props<{ summary: DiscoverySummary }>(),
);
