/** third-party libraries */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/leap/environment';
import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { ReportService } from '../../report/services/report.service';
import { ConversationalSearchParser } from '../parsers/conversational-search.parser';
import SearchQuery from '../interfaces/search-query.interface';
import Statistics from '../../report/interfaces/statistics.interface';
import ReportPayload from '../../report/interfaces/report-payload.interface';
import SearchQueryRestApi from '../rest-api-interfaces/search-query.rest.interface';

@Injectable()
export class ConversationalSearchService {
    constructor(
        private http: HttpClient,
        private reportService: ReportService,
        private conversationalSearchParser: ConversationalSearchParser,
    ) {}

    performQuery(query: string, sessionId: string): Observable<SearchQuery> {
        return this.http
            .post(`${environment.gptServerUrl}/conversational-search/`, {
                query,
                session_uuid: sessionId,
            })
            .pipe(
                map((result: SearchQueryRestApi) =>
                    this.conversationalSearchParser.parseSearchQuery(result),
                ),
            );
    }

    enhanceQueryWithReportSections(
        query: SearchQuery,
        categories: string[],
    ): Observable<SearchQuery> {
        if (!query.discovery) {
            return of(query);
        }

        const reportPayload: ReportPayload = {
            ...query.discovery,
            parameters: {
                ...query.discovery.parameters,
                categories,
            },
            preferences: undefined,
        };

        const introduction$: Observable<string> = this.reportService.getIntroduction(reportPayload);
        const statistics$: Observable<Statistics> = this.reportService.getStatistics(reportPayload);

        return forkJoin([introduction$, statistics$]).pipe(
            map(([introduction, statistics]: [string, Statistics]) => ({
                ...query,
                introduction,
                statistics,
            })),
        );
    }
}
