import { createSelector } from '@ngrx/store';
import { getUIState } from '../index';
import { UIState } from '../ui-state.interface';
import * as fromIngredientProfiler from './ingredient-profiler.reducer';

export const getIngredientProfilerState = createSelector(
    getUIState,
    (state: UIState) => state.ingredientProfiler,
);

export const getActiveTabId = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getActiveTabId,
);
export const getExecution = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getExecution,
);
export const getSummary = createSelector(
    getIngredientProfilerState,
    fromIngredientProfiler.getSummary,
);
